import React, { useEffect } from 'react';

// antd
import { Modal, Form, Button, Row, Card, Input } from 'antd';

// icons
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import { useDebounce } from '@utils/methods';

// styling
// import styles from './index.module.less';

import './index.less';

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: '100%',
  },
};

const ChangePasswordModal = ({ visible, onCancel, changePassword }) => {
  const { isMobile } = useWindowSize();
  const [form] = Form.useForm();

  const handleSubmit = useDebounce(() => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        changePassword(values);
      })
      .catch((er) => {
        console.log('Validate Failed:', er);
      });
  }, 300);

  const handleCancel = useDebounce(() => {
    form.resetFields();
    onCancel();
  }, 300);

  const handleFormValuesChange = () => {
    // console.log('[HANDLEFORMVALUECHANGE] ', form.getFieldsValue());
  };

  useEffect(() => {
    return () => form.resetFields();
  }, []);

  return (
    <Modal //
      getContainer={false}
      title="Change Password"
      open={visible}
      destroyOnClose
      centered={isMobile}
      width={isMobile ? '100%' : '30%'}
      footer={null}
      onCancel={onCancel}>
      <Form //
        {...layout}
        form={form}
        layout="vertical"
        onValuesChange={handleFormValuesChange}
        onFinish={handleSubmit}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>

        <Card>
          <Form.Item className="form-itemz-m" label="Old Password" name="oldPassword" rules={[{ required: true, message: 'Please input old password.' }]}>
            <Input.Password placeholder="Enter password" />
          </Form.Item>

          <Form.Item className="form-itemz-m" label="New Password" name="newPassword" rules={[{ required: true, message: 'Please input new password.' }]}>
            <Input.Password placeholder="Enter password" />
          </Form.Item>
        </Card>

        <Row className="list-label-add-label-footer">
          <Button onClick={handleCancel} style={{ marginRight: 10 }}>
            Cancel
          </Button>

          <Button type="primary" htmlType="submit">
            Update Password
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
