import React, { useEffect, useState } from 'react';
import ImgCrop from 'antd-img-crop';

// antd
import { Col, DatePicker, Form, Input, Row, Upload } from 'antd';

// hooks
import useWindowSize from '@hooks/useWindowSize';

// constants
import constants from '@utils/constants';

const { TextArea } = Input;

const PersonalInformation = ({ form, styles, clearImage }) => {
  const { isMobile } = useWindowSize();
  const [selectedFileList, setSelectedFileList] = useState([]);

  useEffect(() => {
    setSelectedFileList([]);
  }, [clearImage]);

  const handlePreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onChange = ({ fileList: newFileList }) => {
    setSelectedFileList(newFileList);
    form.setFieldsValue({ file: newFileList[0].originFileObj });
  };

  // const handleChange = (info) => {
  //   // console.log('[handleChange info] ', info);
  //   // const nextState = {};
  //   switch (info.file.status) {
  //     case 'uploading':
  //       // nextState.selectedFileList = [info.file];
  //       setSelectedFileList([info.file]);
  //       break;
  //     case 'done':
  //       // nextState.selectedFile = info.file;
  //       // nextState.selectedFileList = [info.file];
  //       setSelectedFile(info.file);
  //       setSelectedFileList([info.file]);
  //       break;

  //     default:
  //       // error or removed
  //       // nextState.selectedFile = null;
  //       // nextState.selectedFileList = [];
  //       setSelectedFile(null);
  //       setSelectedFileList([]);
  //   }
  // };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  return (
    <>
      <h5>Personal Information</h5>
      <div className={`${styles.rowWrapper} ${styles.pullLeft}`}>
        <Row style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }} gutter={8}>
          <Col span={24}>
            <Form.Item className="form-itemz-j" label="2 x 2 ID Picture" name="file" rules={[{ required: true, message: 'Please upload image.' }]}>
              <ImgCrop rotate>
                <Upload //
                  fileList={selectedFileList}
                  customRequest={dummyRequest}
                  onChange={onChange}
                  maxCount={1}
                  listType="picture-card"
                  onPreview={handlePreview}>
                  {selectedFileList.length < 2 && '+ Upload'}
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className={styles.rowWrapper}>
        {isMobile ? (
          <>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Full Name" name="fullName" rules={[{ required: true, message: 'Please input your full name.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Preferred Pronouns" name="pronouns" rules={[{ required: true, message: 'Please input your preferred pronouns.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Mobile Number" name="phone" rules={[{ required: true, message: 'Please input your mobile number.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Application Date" name="applicationDate" rules={[{ required: true, message: 'Please input your application date.' }]}>
                  <DatePicker className={[styles.datePicker, styles.pipipz]} style={{ height: 48, width: '100%', border: '2px solid #555 !important' }} format={constants.dateFormat.displayDate} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Date Available to Start" name="availableDate" rules={[{ required: true, message: 'Please input date of availability.' }]}>
                  <DatePicker className={[styles.datePicker, styles.pipipz]} style={{ height: 42, width: '100%' }} format={constants.dateFormat.displayDate} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Email Address" name="email" rules={[{ required: true, message: 'Please input your email address.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Facebook Account Link" name="facebookInput" placeholder="e.g. https://www.facebook.com/tutorbasecenter" rules={[{ required: true, message: 'Please input your facebook link.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Complete Address" name="address" rules={[{ required: true, message: 'Please input complete address.' }]}>
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item className="form-itemz-j" label="Full Name" name="fullName" rules={[{ required: true, message: 'Please input your full name.' }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item className="form-itemz-j" label="Preferred Pronouns" name="pronouns" rules={[{ required: true, message: 'Please input your preferred pronouns.' }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item className="form-itemz-j" label="Mobile Number" name="phone" rules={[{ required: true, message: 'Please input your mobile number.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={12}>
                <Form.Item className="form-itemz-j" label="Application Date" name="applicationDate" rules={[{ required: true, message: 'Please input your application date.' }]}>
                  <DatePicker className={styles.datePicker} style={{ height: 48, width: '100%', border: '2px solid #555' }} format={constants.dateFormat.displayDate} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item className="form-itemz-j" label="Date Available to Start" name="availableDate" rules={[{ required: true, message: 'Please input date of availability.' }]}>
                  <DatePicker className={styles.datePicker} style={{ height: 48, width: '100%', border: '2px solid #555' }} format={constants.dateFormat.displayDate} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={12}>
                <Form.Item className="form-itemz-j" label="Email Address" name="email" rules={[{ required: true, message: 'Please input your email address.' }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item className="form-itemz-j" label="Facebook Account Link" name="facebookInput" placeholder="e.g. https://www.facebook.com/tutorbasecenter" rules={[{ required: true, message: 'Please input your facebook link.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Complete Address" name="address" rules={[{ required: true, message: 'Please input complete address.' }]}>
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </div>

      {/* <div className="form-item">
          <label for="fullName" className="form-label">
            Full Name
          </label>
          <input type="text" className="form-control form-join" id="fullName" value={props.data.fullName} onChange={(e) => props.setData({ ...props.data, fullName: e.target.value })} required />
          <div className="invalid-feedback">Please input your Full Name.</div>
        </div>
        <div className="form-item">
          <label for="pronouns" className="form-label">
            Preferred Pronouns
          </label>
          <input type="text" className="form-control form-join" id="pronouns" value={props.data.pronouns} onChange={(e) => props.setData({ ...props.data, pronouns: e.target.value })} required />
          <div className="invalid-feedback">Please input your Preferred Pronouns.</div>
        </div>

        <div className="custom-file">
          <label className="custom-file-label" for="customFile">
            2 x 2 ID Picture
          </label>
          <input type="file" className="custom-file-input" id="customFile" accept=".png, .jpg, .jpeg" name="file" onChange={(e) => props.setData({ ...props.data, file: e.target.files[0] })} required />
          <div className="invalid-feedback">Please include your Photo.</div>
        </div>

        <div className="form-item">
          <label for="applicationDate" className="form-label">
            Application Date
          </label>
          <input data-provide="datepicker" type="date" className="form-control form-join" id="applicationDate" value={props.data.applicationDate} onChange={(e) => props.setData({ ...props.data, applicationDate: e.target.value })} required />
          <div className="invalid-feedback">Please input Application Date.</div>
        </div>

        <div className="form-item">
          <label for="availableDate" className="form-label">
            Date Available to Start
          </label>
          <input type="date" className="form-control form-join" id="availableDate" value={props.data.availableDate} onChange={(e) => props.setData({ ...props.data, availableDate: e.target.value })} required />
          <div className="invalid-feedback">Please input Date Available.</div>
        </div>
        <div className="form-item">
          <label for="phone" className="form-label">
            Mobile Number
          </label>
          <input type="text" className="form-control form-join" id="phone" value={props.data.phone} onChange={(e) => props.setData({ ...props.data, phone: e.target.value })} required />
          <div className="invalid-feedback">Please input Mobile Number.</div>
        </div>
        <div className="form-item">
          <label for="email" className="form-label">
            Email Address
          </label>
          <input type="text" className="form-control half" id="email" value={props.data.email} onChange={(e) => props.setData({ ...props.data, email: e.target.value })} required />
          <div className="invalid-feedback">Please input Email Address.</div>
        </div>
        <div className="form-item">
          <label for="facebookInput" className="form-label">
            Facebook Account Link
          </label>
          <input type="text" className="form-control half" id="facebookInput" placeholder="e.g. https://www.facebook.com/tutorbasecenter" value={props.data.facebook} onChange={(e) => props.setData({ ...props.data, facebook: e.target.value })} />
        </div>
        <div className="form-item">
          <label for="address" className="form-label">
            Complete Address
          </label>
          <textarea type="text" className="form-control full address" id="address" value={props.data.address} onChange={(e) => props.setData({ ...props.data, address: e.target.value })} required />
          <div className="invalid-feedback">Please input Complete Address.</div>
        </div> */}
    </>
  );
};

export default PersonalInformation;
