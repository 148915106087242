import React, { useEffect, useState } from 'react';

const areaMatrix = {
  subjectGroup1: { title: 'English', others: 'subjectGroup1Others' },
  subjectGroup2: { title: 'Filipino', others: 'subjectGroup2Others' },
  subjectGroup3: { title: 'Mathematics', others: 'subjectGroup3Others' },
  subjectGroup4: { title: 'Science', others: 'subjectGroup4Others' },
  subjectGroup5: { title: 'SocialScience', others: 'subjectGroup5Others' },
  subjectGroup6: { title: 'OtherSubjects', others: 'subjectGroup6Others' },
};

const DisplaySubjectCheckboxes = (props) => {
  const { subjects } = props.app;
  const [availableSubjects, setAvailableSubjects] = useState([]);

  const fetchSubjects = () => {
    const av = [];
    Object.keys(subjects).map((s) => {
      if (subjects[s].length > 0) {
        av.push(`${areaMatrix[s].title} (${subjects[s].join(', ')})`);
      }
      return s;
    });
    setAvailableSubjects(av);
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  return (
    <>
      {availableSubjects.map((s) => {
        return (
          <>
            <span style={{ fontWeight: 600 }}>{s}</span>
            <br />
          </>
        );
      })}
    </>
  );
};

export default DisplaySubjectCheckboxes;
