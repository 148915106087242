import React, { useCallback, useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { tutorbase } from '@redux/combineActions';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import constants from '@utils/constants';
import Navbar from '@components/layouts/NavbarDashboard';
import HeaderDashboard from '@components/layouts/HeaderDashboard';

import Spinner from '@components/layouts/Spinner';
import BulkScheduling from '@components/BulkScheduling';
import Calendar from '@components/calendar/Calendar';
// import ZoomDetails from '@components/zoomdetails/ZoomDetails';
import RecentActivities from '@components/homepage/RecentActivities';

import '@styles/homepage.less';
// styling
import styles from './index.module.less';

const HomePage = () => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const {
    common: {
      user: { account },
    },
  } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);

  const fetchScheduleJobApp = useCallback(() => {
    const { role } = account;
    // dispatch(
    //   tutorbase.schedule.getSchedule({
    //     id: role === constants.ROLE_TYPE.HEAD ? id : UserId,
    //   })
    // );
    if (role === constants.ROLE_TYPE.HEAD) {
      dispatch(tutorbase.job.listJobApp());
    }
  }, [account, dispatch]);

  useEffect(() => {
    fetchScheduleJobApp();
    setTimeout(() => setLoading(false), 900);
  }, [fetchScheduleJobApp]);

  if (loading) return <Spinner />;

  return (
    <>
      <Navbar />
      <HeaderDashboard name={account.name} />

      <div className={styles.bodyWrapper}>
        {!isMobile && account.role === 1 && (
          <div className={styles.sessionWrapper}>
            <BulkScheduling />
          </div>
        )}
        <div className={styles.scheduleWrapper}>
          {!isMobile && <Calendar />}
          {/* <div className={styles.zoomDetailsWrapper}>
            <ZoomDetails />
          </div> */}
        </div>
      </div>

      {account.role === 1 ? (
        <div className={styles.recentActivitiesWrapper}>
          <RecentActivities />
        </div>
      ) : null}
    </>
  );
};

export default HomePage;
