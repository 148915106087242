import { GET, POST, PUT } from '../request';

export async function getUserDetails() {
  return GET(`users/detailsv`);
}

export async function postUserLogin(payload) {
  return POST(`users/login`, payload);
}

export async function postStudentRegister(payload) {
  return POST(`students`, payload);
}

export async function postTutorRegister(payload) {
  return POST(`tutors`, payload);
}

export async function putChangePasswd(payload) {
  const { id, ...rest } = payload;
  return PUT(`users/update-access/${id}`, rest);
}

export async function deleteUser(id) {
  // return DELETE(`users/delete-user/${id}`);
  return PUT(`users/delete-user/${id}`, {});
}

export async function putUpdateUser(payload) {
  const { id, ...rest } = payload;
  return PUT(`users/${id}`, rest);
}

export async function putUpdateStudent(payload) {
  const { id, ...rest } = payload;
  return PUT(`students/${id}`, rest);
}

export async function putUpdateTutor(payload) {
  const { id, ...rest } = payload;
  return PUT(`tutors/${id}`, rest);
}

export async function putUpdateHead(payload) {
  const { id, ...rest } = payload;
  return PUT(`head/${id}`, rest);
}

export async function getHeadDetails() {
  return GET(`head`);
}
