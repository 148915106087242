import React, { useState, useEffect } from 'react';

// redux
import { useSelector } from 'react-redux';

import Banner from '@components/Banner';
import Spinner from '@components/layouts/Spinner';
import Navbar from '@components/layouts/Navbar';
// import ScrollUp from '@components/ScrollUp';

import NavbarDashboard from '@components/layouts/NavbarDashboard';

import './index.less';

const NavActive = ({ authenticated }) => {
  if (authenticated) return <NavbarDashboard />;
  return <Navbar />;
};

const PrivacyPolicy = () => {
  const { common } = useSelector((state) => state);
  const [loading, setLoading] = useState(true);
  const baseUrl = window.location.origin;

  useEffect(() => {
    window.scroll(0, 0);
    setTimeout(() => setLoading(false), 900);
  }, []);

  if (!common) return null;
  const { authenticated } = common.user;

  if (loading) return <Spinner />;

  return (
    <div>
      <NavActive authenticated={authenticated} />
      {/* <ScrollUp /> */}
      <Banner title="Privacy Policy" />
      <div className="privacy-policy-wrap">
        <div className="privacy-policy-content">
          {/* <div className="titles">
            <p className="page-title">Privacy Policy</p>
          </div> */}
          <div className="content">
            <h1>Privacy Policy for Tutor BASE Before and After-School Enrichment Center</h1>

            <p>
              This privacy policy describes how Tutor BASE Before and After-School Enrichment Center collects, uses, and discloses your personal information when you visit our website,{' '}
              <a className="linkinPark" href={baseUrl}>
                https://www.tutorbasecenter.com
              </a>
              , and when you use our services.
            </p>

            {/* <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Tutor BASE Before and After-School Enrichment Center. This policy is not applicable to any information collected offline or via channels other than this website. </p>

            <h2>Consent</h2>

            <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p> */}

            <h2>Personal Information We Collect</h2>

            <p>We collect the following types of personal information from you:</p>

            <ul>
              <li>Contact information: This includes your name, email address, phone number, and mailing address.</li>
              <li>Payment information: This may include your bank account number, billing address, and/or other pertinent payment information.</li>
              <li>Transaction information: This includes information about the products and services you purchase from us, as well as the dates and amounts of your purchases.</li>

              <li>Usage information: This includes information about how you use our website and services, such as the pages you visit, the links you click, and the searches you conduct.</li>

              <li>Device information: This includes information about the device you use to access our website and services, such as your IP address, browser type, and operating system.</li>
            </ul>

            <h2>How We Use Your Personal Information</h2>
            <p>We use your personal information for the following purposes:</p>

            <ul>
              <li>To provide you with our services: We use your contact information to contact you about your account, to process your payments, and to provide you with the products and services you have requested.</li>
              <li>To improve our services: We use your usage information to improve our website and services, such as by developing new features and making improvements to our existing features.</li>
              <li>To send you marketing communications: We may use your contact information to send you marketing communications about our products and services unless you have opted out of receiving such communications.</li>
              <li>To comply with the law: We may use your personal information to comply with applicable laws and regulations, such as those related to taxation and fraud prevention.</li>
            </ul>

            <h2>Disclosure of Your Personal Information</h2>
            <p>We may disclose your personal information to the following third parties:</p>

            <ul>
              <li>Our service providers: We may share your personal information with our service providers, who help us to provide our services, such as hosting our website and processing payments.</li>
              <li>Government agencies: We may disclose your personal information to government agencies if we are required to do so by law or if we believe that such disclosure is necessary to protect our rights, property, or the safety of others.</li>
              <li>Business transfers: If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the new owner.</li>
            </ul>

            <h2>International Transfers of Data</h2>
            <p>Our website is hosted in the Philippines. If you are located outside of the Philippines, your personal information will be transferred to and processed in the Philippines. By using our website and services, you consent to the transfer of your personal information to the Philippines.</p>

            <h2>Security</h2>
            <p>We take steps to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. These steps include:</p>

            <ul>
              <li>Physical security measures: We store your personal information on secure servers in a controlled environment.</li>
              <li>Technical security measures: We use encryption and other security technologies to protect your personal information when it is transmitted over the internet.</li>
              <li>Employee training: Our Tutors, and other Tutoring Staff, are trained on the importance of confidentiality and the need to protect your personal information.</li>
            </ul>

            <h2>Your Rights</h2>
            <p>You have the following rights with respect to your personal information:</p>

            <ul>
              <li>Access: You have the right to access your personal information.</li>
              <li>Correction: You have the right to correct any inaccurate or incomplete personal information that we hold about you.</li>
              <li>Deletion: You have the right to request that we delete your personal information.</li>
              <li>Objection: You have the right to object to the processing of your personal information.</li>
              <li>Portability: You have the right to request that we provide you with a copy of your personal information in a portable format.</li>
            </ul>

            <p>
              To exercise any of these rights, please contact us at{' '}
              <a className="linkinPark" href="mailto:tutorbasecenter@gmail.com">
                tutorbasecenter@gmail.com
              </a>
              .
            </p>

            <h2>Changes to this Policy</h2>
            <p>We may update this Policy from time to time. If we make any material changes to this Policy, we will notify you by email or through a prominent notice on our website.</p>

            <h2>Contact Us</h2>
            <p>
              If you have any questions about this Policy, please contact us at{' '}
              <a className="linkinPark" href={baseUrl}>
                tutorbasecenter.com
              </a>
              .
            </p>

            {/* <h2>Log Files</h2>

            <p>Tutor BASE Before and After-School Enrichment Center follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services&apos; analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users&apos; movement on the website, and gathering demographic information.</p>

            <h2>Cookies and Web Beacons</h2>

            <p>Like any other website, Tutor BASE Before and After-School Enrichment Center uses &apos;cookies&apos;. These cookies are used to store information including visitors&apos; preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users&apos; experience by customizing our web page content based on visitors&apos; browser type and/or other information.</p>

            <h2>Advertising Partners Privacy Policies</h2>

            <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Tutor BASE Before and After-School Enrichment Center.</p>

            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Tutor BASE Before and After-School Enrichment Center, which are sent directly to users&apos; browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

            <p>Note that Tutor BASE Before and After-School Enrichment Center has no access to or control over these cookies that are used by third-party advertisers.</p>

            <h2>Third Party Privacy Policies</h2>

            <p>Tutor BASE Before and After-School Enrichment Center&apos;s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

            <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers&apos; respective websites.</p>

            <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

            <p>Under the CCPA, among other rights, California consumers have the right to:</p>
            <p>Request that a business that collects a consumer&apos;s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
            <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
            <p>Request that a business that sells a consumer&apos;s personal data, not sell the consumer&apos;s personal data.</p>
            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

            <h2>GDPR Data Protection Rights</h2>

            <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
            <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
            <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
            <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
            <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
            <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
            <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

            <h2>Children&apos;s Information</h2>

            <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

            <p>Tutor BASE Before and After-School Enrichment Center does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

/* <!--<h2 className="mb-4">Who we are</h2>
									<p className="mb-5">Our website address is: http://test.tutorbasecenter.com.</p>

									<div className="section">
										<h2 >What personal data we collect and why we collect it</h2>
										<h3>Comments</h3>
										<p>
											When visitors leave comments on the site we collect the data shown in the 
											comments form, and also the visitor’s IP address and browser user agent 
											string to help spam detection.
										</p>
										<p>
											An anonymized string created from your email address (also called a hash) 
											may be provided to the Gravatar service to see if you are using it. The 
											Gravatar service privacy policy is available here: https://automattic.com/privacy/. 
											After approval of your comment, your profile picture is visible to the public 
											in the context of your comment.
										</p>

										<h3>Media</h3>
										<p>
											If you upload images to the website, you should avoid uploading images with
											embedded location data (EXIF GPS) included. Visitors to the website can 
											download and extract any location data from images on the website.
										</p>

										<h3>Contact forms</h3>

										<h3>Cookies</h3>
										<p>
											If you leave a comment on our site you may opt-in to saving your name, email 
											address and website in cookies. These are for your convenience so that you do 
											not have to fill in your details again when you leave another comment. These 
											cookies will last for one year.
										</p>

										<p>
											If you visit our login page, we will set a temporary cookie to determine if 
											your browser accepts cookies. This cookie contains no personal data and is 
											discarded when you close your browser.
										</p>
										<p>
											When you log in, we will also set up several cookies to save your login 
											information and your screen display choices. Login cookies last for two days, 
											and screen options cookies last for a year. If you select "Remember Me", your 
											login will persist for two weeks. If you log out of your account, the login 
											cookies will be removed.
										</p>
										<p>
											If you edit or publish an article, an additional cookie will be saved in your 
											browser. This cookie includes no personal data and simply indicates the post ID 
											of the article you just edited. It expires after 1 day.
										</p>

										<h3>Embedded content from other websites</h3>
										<p>
											Articles on this site may include embedded content (e.g. videos, images, articles, 
											etc.). Embedded content from other websites behaves in the exact same way as if 
											the visitor has visited the other website.
										</p>
										<p>
											These websites may collect data about you, use cookies, embed additional third-party 
											tracking, and monitor your interaction with that embedded content, including tracking 
											your interaction with the embedded content if you have an account and are logged in 
											to that website.
										</p>

										<h3>Analytics</h3>
									</div>

									<div className="section">
										<h2>Who we share your data with</h2>
										<p>
											If you request a password reset, your IP address will be included in the reset email.
										</p>
									</div>
									
									<div className="section">
										<h2>How long we retain your data</h2>
										<p>
											If you leave a comment, the comment and its metadata are retained indefinitely. 
											This is so we can recognize and approve any follow-up comments automatically instead 
											of holding them in a moderation queue.
										</p>
										<p>
											For users that register on our website (if any), we also store the personal information 
											they provide in their user profile. All users can see, edit, or delete their personal 
											information at any time (except they cannot change their username). Website administrators 
											can also see and edit that information.
										</p>
									</div>

									<div className="section">
										<h2>What rights you have over your data</h2>
										<p>
											If you have an account on this site, or have left comments, you can request to receive an 
											exported file of the personal data we hold about you, including any data you have provided 
											to us. You can also request that we erase any personal data we hold about you. This does 
											not include any data we are obliged to keep for administrative, legal, or security purposes.
										</p>
									</div>

									<div className="section">
										<h2>Where we send your data</h2>
										<p>
											Visitor comments may be checked through an automated spam detection service.
										</p>
									</div>
									
									<div className="section">
										<h2>Your contact information</h2>
									</div>

									<div className="section">
										<h2>Additional information</h2>

										<h3>How we protect your data</h3>
										<h3>What data breach procedures we have in place</h3>
										<h3>What third parties we receive data from</h3>
										<h3>What automated decision making and/or profiling we do with user data</h3>
										<h3>Industry regulatory disclosure requirements</h3>
									</div>--> */

export default PrivacyPolicy;
