import React from 'react';
import { Link } from 'react-router-dom';

// import '@styles/rates.less';
import styles from './index.module.less';

const SectionRates = () => {
  return (
    <div className={`container-fluid ${styles['rates-wrapper']}`}>
      <div className={styles['why-btn-wrapper']}>
        <span className={styles['why-btn']}>
          <Link to="/services">READ MORE ABOUT OUR SERVICESZ</Link>
          <i className="far fa-arrow-alt-circle-right" />
        </span>
      </div>
    </div>
  );
};

export default SectionRates;
