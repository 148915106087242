import {
  //
  postCreatePayment,
  postGetPayment,
  postListPayment,
  putEditAccount,
  postPayTutor,
} from '@service/api/payment';

import { swalError, swalSuccess } from '@utils/methods';

// types
import * as types from '../types';

export const createPayment = (payload) => async (dispatch) => {
  const res = await postCreatePayment(payload);
  // console.log('[CREATEPAYMENT] ', res);

  if (res.success) {
    dispatch({
      type: types.PAYMENT_ADD,
      payload: res.data.invoice,
    });

    window.location = '/payments';
  } else {
    swalError(res.msg);
  }
  /* <iframe title="etits2" src=${res.data.invoice.invoiceUrl} width="400" height="430">
      </iframe>
*/

  return res;
};

export const getPayment = (payload) => async (dispatch) => {
  const res = await postGetPayment(payload);
  // console.log('[GETPAYMENT] ', res);

  dispatch({
    type: res.data.status === 'EXPIRED' ? types.PAYMENT_DEACTIVE : types.PAYMENT_STATUS,
    payload: res.data,
  });

  return res;
};

export const listPayment = (payload) => async (dispatch) => {
  const res = await postListPayment(payload);
  // console.log('[LISTPAYMENT] ', res);

  dispatch({
    type: types.PAYMENT_LIST,
    payload: res.data.list,
  });

  return res;
};

export const editAccount = (payload) => async (dispatch) => {
  const res = await putEditAccount(payload);
  // console.log('[EDITACCOUNT] ', res);

  dispatch({
    type: types.PAYMENT_ACCOUNT,
    payload: res.data.data,
  });

  swalSuccess('<h3>Sucessfully changed <br /> payment details!<h3/>', true);

  return res;
};

export const payTutor = (payload) => async (dispatch) => {
  const res = await postPayTutor(payload);
  // console.log('[PAYTUTOR] ', res);

  if (res.success) {
    dispatch({
      type: types.DIST_ADD,
      payload: res.data.dist,
    });

    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};
