// types
import * as types from '../types';

const initialState = {
  jobApplicants: [],
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.JOB_APPLY:
      return {
        ...state,
        jobApplicants: [...state.jobApplicants, payload],
      };

    default:
      return state;
  }
};

export default reducer;
