import React from 'react';
import moment from 'moment';
// import { Button, Descriptions, Form, InputNumber, Modal, Select } from 'antd';
import { Descriptions, Form, Modal, Select } from 'antd';

// hooks
import useWindowSize from '@hooks/useWindowSize';

// import subjects from '@utils/subjects';
import { swalError, useDebounce } from '@utils/methods';

const { Option } = Select;

const AddSlotModal = (props) => {
  const { isMobile } = useWindowSize();
  const [form] = Form.useForm();
  const { data, onCancel, schedules, sessions, students, tutors, visible } = props;

  // console.log('[ADDSLOTMODAL] ', props);

  // const [availHours, setAvailHours] = useState(undefined);

  const remainingHours = (id) => {
    const { day } = data.slotId;
    const filtered = schedules.find((sch) => sch.StudentId === id && sch.day === day && sch.slotId === null);

    // console.log('[REMAINING HRS] ', filtered);

    return filtered ? filtered.hours : 0;
  };

  const disableIfNoExistingSession = (s, u) => {
    let kSwitch = true;
    const { day } = data.slotId;

    schedules.map((sch) => {
      // console.log('[DISABLEIFNOTEXISTINGSESSION sch] ', sch);
      //      if (sch.slotId !== null) {
      //        if ((sch.StudentId === s.id && sch.slotId.includes(dateId) && sch.slotId.includes(day) && sch.time === time) || sch.slotId.includes(slotId.substring(1))) {
      if (sch.StudentId === s.id && sch.day === day && u === 'stvd' && sch.slotId === null) {
        kSwitch = false;
      }
      return sch;
    });

    if (u === 'tvtor') {
      kSwitch = false;
    }
    return kSwitch;
  };

  /*
  const handleHoursBtn = (e) => {
    e.preventDefault();
    const { hours } = form.getFieldsValue();

    if (e.currentTarget.id === 'plus') {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        hours: hours + 1,
      });

      setAvailHours(availHours - 1);
    }

    if (e.currentTarget.id !== 'plus' && hours > 1) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        hours: hours - 1,
      });
      setAvailHours(availHours + 1);
    }
  };
  */

  const handleAddSchedule = useDebounce(() => {
    form
      .validateFields()
      .then((values) => {
        const hrs = remainingHours(values.StudentId);
        if (data.slotId.time + (hrs - 1) > 20) {
          swalError('Unable to set a session schedule from this date.');
          return false;
        }

        // console.log('[VALUES] ', values);
        // if (values.hours < 1 || values.hours > hrs) return false;
        form.resetFields();
        props.submitSchedule({ ...values, hours: hrs });
        return false;
      })
      .catch((er) => {
        console.log('Validate Failed:', er);
      });
  }, 300);

  /*
  const getMaxHours = () => {
    const { StudentId } = form.getFieldsValue();
    const { day } = data.slotId;
    if (StudentId) {
      const filtered = schedules.find((sch) => sch.StudentId === StudentId && sch.day === day && sch.slotId === null);
      return filtered ? filtered.hours : 8;
    }

    return 8;
  };
  */

  const handleFormValuesChange = () => {
    const { StudentId } = form.getFieldsValue();

    if (StudentId) {
      // const diffr = remainingHours(StudentId) - hours;
      // setAvailHours(diffr);
    }
  };

  /*
  const hoursLeft = () => {
    let hLeft = '';
    if (!availHours) hLeft = '';
    if (availHours < 1) hLeft = '';
    if (availHours && availHours > 0) hLeft = `(${availHours} ${availHours === 1 ? 'hour' : 'hours'} remaining)`;

    return hLeft;
  };
  */

  const disableAdd = () => {
    const v = false;
    // console.log('[DISABLE ADD] ', availHours);

    // if (availHours === undefined) v = true;
    // if (availHours < 0) v = true;
    // if (availHours) v = true;
    // if (availHours < 1) v = false;

    return v;
  };

  const handleCancel = () => {
    form.resetFields();
    // setAvailHours(undefined);
    onCancel();
  };

  const fetchSessionTime = () => moment(`${data.slotId.time}:00`, 'HH:mm').format('h:mma');

  if (sessions.length === 0) return false;

  return (
    <Modal //
      centered
      title="Set Schedule"
      open={visible}
      destroyOnClose
      width={isMobile ? '95%' : '40%'}
      onOk={handleAddSchedule}
      okText="Add Schedule"
      okButtonProps={{
        disabled: disableAdd(),
      }}
      zIndex={1021}
      onCancel={handleCancel}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Session Date:">{moment(data.slotId.date, 'MMMDD').format('dddd, MMMM D, YYYY')}</Descriptions.Item>

        <Descriptions.Item label="Time:">{fetchSessionTime()}</Descriptions.Item>
      </Descriptions>

      <Form //
        initialValues={data}
        form={form}
        layout="vertical"
        style={{ marginTop: 10 }}
        onValuesChange={handleFormValuesChange}>
        <Form.Item rules={[{ required: true, message: 'This field is required!' }]} label="Student's Name" name="StudentId">
          <Select //
            allowClear
            showSearch
            placeholder="Select Student's Name..."
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
            {students
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((e, i1) => {
                return (
                  <Option //
                    disabled={disableIfNoExistingSession(e, 'stvd')}
                    key={`st${i1}`}
                    value={e.id}>
                    {e.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'This field is required!' }]} label="Tutor's Name" name="TutorId">
          <Select //
            allowClear
            showSearch
            placeholder="Select Tutor's Name..."
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
            {tutors
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((e, iv) => {
                return (
                  <Option //
                    disabled={disableIfNoExistingSession(e, 'tvtor')}
                    key={`tn${iv}`}
                    value={e.id}>
                    {e.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        {/* <Form.Item rules={[{ required: true, message: 'This field is required!' }]} label="Subject" name="subject">
          <Select //
            allowClear
            showSearch
            placeholder="Select Subject..."
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
            {subjects
              .sort((a, b) => a.localeCompare(b))
              .map((e, iw) => {
                return (
                  <Option key={`su${iw}`} value={e}>
                    {e}
                  </Option>
                );
              })}
          </Select>
        </Form.Item> */}

        {/*  
        <Form.Item label={`Number of Hour(s) ${hoursLeft()}`}>
          <div style={{ height: '0rem' }} className="flex-center-start">
            <Form.Item label="&nbsp;">
              <Button style={{ marginRight: 5 }} type="primary" className="minus" id="minus" onClick={handleHoursBtn}>
                -
              </Button>
            </Form.Item>
            <Form.Item label="&nbsp;" name="hours">
              <InputNumber placeholder="Number of hours" className="num-input" controls={false} min={1} max={getMaxHours()} />
            </Form.Item>
            <Form.Item label="&nbsp;">
              <Button style={{ marginLeft: 5 }} type="primary" className="plus" id="plus" onClick={handleHoursBtn}>
                +
              </Button>
            </Form.Item>
          </div>
        </Form.Item>        
        */}
      </Form>
    </Modal>
  );
};

export default AddSlotModal;
