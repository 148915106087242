import React from 'react';
import { Col, Form, Input, Row } from 'antd';

// hooks
import useWindowSize from '@hooks/useWindowSize';

const { TextArea } = Input;

const EducationalBackground = (props) => {
  const { isMobile } = useWindowSize();
  const { styles } = props;

  return (
    <>
      <h5>Educational Background</h5>

      <div className={styles.rowWrapper}>
        {isMobile ? (
          <>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="High School" name="highschool" rules={[{ required: true, message: 'Please input your school name.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="High School Strand (if applicable)" name="highSchoolStrand" rules={[{ required: true, message: 'Please input your highschool strand.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Year Graduated from High School" name="highSchoolGraduation" rules={[{ required: true, message: 'Please input year graduated.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="College" name="college" rules={[{ required: true, message: 'Please input where you study college.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="College Course" name="collegeCourse" rules={[{ required: true, message: 'Please input your college course.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Year Graduated/Graduating" name="collegeGraduation" rules={[{ required: true, message: 'Please input year graduated/graduating.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Academic Achievements (if applicable)" name="academicAchievements" rules={[{ required: true, message: 'Please input your academic achievements.' }]}>
                  <TextArea rows={5} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Special Skills (if applicable)" name="specialSkills" rules={[{ required: true, message: 'Please input your special skills.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Languages/Dialects Spoken and Level of Proficiency" placeholder="e.g. English, fluent; Filipino, native" name="languages" rules={[{ required: true, message: 'Please input your language/dialects spoken.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item className="form-itemz-j" label="High School" name="highschool" rules={[{ required: true, message: 'Please input your school name.' }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item className="form-itemz-j" label="High School Strand (if applicable)" name="highSchoolStrand" rules={[{ required: true, message: 'Please input your highschool strand.' }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item className="form-itemz-j" label="Year Graduated from High School" name="highSchoolGraduation" rules={[{ required: true, message: 'Please input year graduated.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={8}>
                <Form.Item className="form-itemz-j" label="College" name="college" rules={[{ required: true, message: 'Please input where you study college.' }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item className="form-itemz-j" label="College Course" name="collegeCourse" rules={[{ required: true, message: 'Please input your college course.' }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item className="form-itemz-j" label="Year Graduated/Graduating" name="collegeGraduation" rules={[{ required: true, message: 'Please input year graduated/graduating.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Academic Achievements (if applicable)" name="academicAchievements" rules={[{ required: true, message: 'Please input your academic achievements.' }]}>
                  <TextArea rows={5} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Special Skills (if applicable)" name="specialSkills" rules={[{ required: true, message: 'Please input your special skills.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={24}>
                <Form.Item className="form-itemz-j" label="Languages/Dialects Spoken and Level of Proficiency" placeholder="e.g. English, fluent; Filipino, native" name="languages" rules={[{ required: true, message: 'Please input your language/dialects spoken.' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default EducationalBackground;
