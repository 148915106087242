import { GET, DELETE, PATCH, POST } from '../request';

export async function postAddSchedule(payload) {
  return POST(`schedules/create`, payload);
}
export async function postGetSchedule(payload) {
  return POST(`schedules/list`, payload);
}
// patch
export async function putSetNotif(payload) {
  const { id, ...rest } = payload;
  return PATCH(`schedules/ereminder`, rest);
}
export async function getHistory(payload) {
  return GET(`schedules/history?${payload}`);
}
// patch
export async function putSaveEdit(payload) {
  const { id, ...rest } = payload;
  return PATCH(`schedules/edit`, rest);
}

export async function resetSchedule(payload) {
  return PATCH(`schedules/reset/${payload}`);
}

export async function deleteSchedule(id) {
  return DELETE(`schedules/delete/${id}`);
}
