import React from 'react';
import { Link } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

import styles from './index.module.less';

const HeaderDashboard = () => {
  const {
    common: {
      user: { account },
    },
  } = useSelector((state) => state);

  return (
    <div className="header-wrap" id="header-dashboard" data-aos="fade-in" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-duration="600">
      {window.location.pathname === '/dashboard' && (
        <div className={styles.headerWelcome}>
          <h1>Welcome to your dashboard,</h1>
          <h2> {account.name}!</h2>
        </div>
      )}
      <div className="header-welcome">
        {/* {window.location.pathname === '/gdrive' && <h1>Google Drive</h1>} */}
        {window.location.pathname === '/reports' && <h1>End-of-Session Reports</h1>}
        {window.location.pathname === '/payments' && <h1>Payments</h1>}
        {window.location.pathname === '/program' && <h1>Program</h1>}
        {window.location.pathname === '/tos' && <h1>Terms of Service</h1>}
        {window.location.pathname === '/honorscode' && <h1>Tutors’ Honor Code</h1>}
        {window.location.pathname === '/jobapplication' && <h1>Application for Tutor Position</h1>}
        {window.location.pathname === '/settings' && <h1>Settings</h1>}

        <div className={styles.headerCta}>
          <span className={styles.cta}>
            <Link to="/main">RETURN TO MAIN WEBSITE</Link>
            <i className="far fa-arrow-alt-circle-right" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default HeaderDashboard;
