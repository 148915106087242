import React, { useEffect, useState } from 'react';
import { addDays, startOfWeek } from 'date-fns';
import moment from 'moment';

import { Button, Card, Checkbox, Col, Table } from 'antd';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import { formatPrice, useDebounce } from '@utils/methods';

// styling
import styles from './index.module.less';

const date = startOfWeek(new Date(), {
  weekStartsOn: 1,
});
// show the next week set
const fromDate = addDays(new Date(date), 7);

const StudentPaymentLogTable = (props) => {
  const { payment } = props;
  const { isMobile } = useWindowSize();
  const [showTable, setShowTable] = useState(false);

  const [dataSource, setDataSource] = useState([]);

  console.log('[StudentPaymentLogTable] ', props);

  useEffect(() => {
    if (payment) {
      // const activePayments = payment.list.filter((pl) => {
      //   const fd = moment(fromDate).format('MMM D YYYY');
      //   const sd = moment(pl.Session.date).format('MMM D YYYY');
      //   return fd === sd;
      // });

      // setDataSource(activePayments);
      setDataSource(payment.list);
    }
  }, [payment]);

  const handleZoomClick = useDebounce((e, record) => {
    if (e.target) {
      const pload = {
        id: record.SessionId,
        toggle: !record.Session.ZoomDetail.isVisible,
      };
      props.zoomToggle(pload);
    }
  }, 300);

  const handleSwitchChange = (e, record) => {
    if (e.target) {
      const pload = {
        id: record.SessionId,
        toggle: e.target.checked,
      };
      props.zoomToggle(pload);
    }
  };

  const groupArray = (arr, prop) => {
    const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    arr.forEach((obj) => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
  };

  const sendPayment = useDebounce((pmt) => {
    const amount = pmt.paymentAmount * 0.3;
    const schedule = groupArray(pmt.Session.Schedules, 'TutorId');
    let totalHour = 0;
    let amountPerHour = 0;
    let distData = [];

    schedule.map((v) => {
      let tmpHour = 0;
      v.map((v1) => {
        tmpHour += v1.hours;
        return v1;
      });
      totalHour += tmpHour;
      return v;
    });

    amountPerHour = (amount / totalHour).toFixed(0);

    schedule.map((v) => {
      let hours = 0;
      let tutorId = '';
      v.map((v1) => {
        hours += v1.hours;
        tutorId = v1.TutorId;
        return v1;
      });

      distData = {
        hours,
        tutorId,
        amount: hours * amountPerHour,
        studentName: pmt.studentName,
        SessionId: pmt.SessionId,
      };

      props.payTutor(distData);
      return v;
    });
  }, 300);

  const fetchWeekDateRange = (v) => {
    const start = moment(v.Session.date).format('MMM DD').toString();
    const end = moment(v.Session.date).add(6, 'days').format('MMM DD, YYYY').toString();

    return `${start} - ${end}`;
  };

  const columns = [
    {
      title: 'Session Week Date',
      align: 'center',
      render: (record) => {
        return fetchWeekDateRange(record);
      },
    },
    {
      title: 'Name',
      dataIndex: 'studentName',
      key: 'name',
      width: '15%',
    },
    {
      title: 'Done Hrs./Total Hrs.',
      align: 'center',
      render: (record) => {
        const hoursComplete = record.Session.doneTime === record.Session.defaultTime;
        return (
          <div style={{ borderRadius: 10, padding: 5, background: hoursComplete ? '#9acd32' : '#dedede' }}>
            {record.Session.doneTime} hrs. / {record.Session.defaultTime}hrs.
          </div>
        );
      },
    },
    {
      title: 'Amount Due',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      align: 'center',
      render: (record) => formatPrice(record),
    },

    {
      title: 'Amount Paid',
      align: 'center',
      render: (record) => (record.status === 'PAID' ? formatPrice(record.paymentAmount) : formatPrice(0)),
    },
    {
      title: 'Date Paid',
      dataIndex: 'paymentDate',
      key: 'date',
      align: 'center',
      render: (e) => {
        return moment(e).format('MMM DD YYYY').toString();
      },
    },
    {
      title: 'Zoom Details',
      key: 'zoom',
      align: 'center',
      render: (record) => {
        const disableChuck = moment(record.Session.date).isBefore(fromDate);
        const zoomDetail = record.Session.ZoomDetail;

        return (
          <>
            {/* <input disabled={disableChuck} className="form-check-input" type="checkbox" style={{ cursor: disableChuck ? 'not-allowed' : 'pointer', outline: 'none', pointerEvents: 'auto' }} id="flexCheckDefault" onChange={(e) => handleSwitchChange(e, record)} defaultChecked={record.Student?.showLink} />
            <label className="form-check-label" htmlFor="flexCheckDefault" /> */}

            <Checkbox disabled={disableChuck} checked={zoomDetail ? zoomDetail.isVisible : false} onChange={(e) => handleSwitchChange(e, record)} />
          </>
        );
      },
    },
    {
      title: 'Pay Tutor(s)',
      key: 'payment',
      align: 'center',
      render: (e, record) => {
        const reports = record.Session.Schedules.filter((sc) => sc.Report !== null).map((sc) => sc.Report);
        const approvedByHeadParents = reports.map((rpt) => {
          return rpt.isHeadChecked && rpt.isParentChecked;
        });
        const payTutor = approvedByHeadParents.length > 0 && !approvedByHeadParents.includes(false);
        console.log('[approvedByHeadParents] ', approvedByHeadParents);
        console.log('[payTutor] ', payTutor);
        console.log('[/////////////]');

        if (record.Session.isDisbursed) {
          return (
            <button type="submit" className={`btn btn-sm btn-paid ${styles.cta}`} disabled>
              <div className={`text-white ${styles.ctaText}`}>Paid</div>
            </button>
          );
        }

        if (!record.Session.isDisbursed && !payTutor) {
          return (
            <button type="submit" className={`btn btn-sm btn-primary ${styles.cta}`} disabled>
              <div className={`text-white ${styles.ctaText}`}>Pending Apprvl.</div>
            </button>
          );
        }

        if (!record.Session.isDisbursed && payTutor) {
          return (
            <button type="submit" className={`btn btn-sm btn-primary ${styles.cta}`} data-toggle="modal" data-target="#tutorPaymentModal" onClick={() => sendPayment(record)}>
              <div className={`text-white ${styles.ctaText}`}>Pay</div>
            </button>
          );
        }

        return null;
      },
    },
  ];

  const renderCardView = () => {
    return (
      <div className={styles.cardWrapper}>
        {dataSource.map((u, idx) => (
          <Card key={idx} className={styles.cardRow}>
            <Col span={24}>
              <h4>Name: {u.studentName}</h4>
              <span>Payment Date: {moment(u.paymentDate).format('MMM DD YYYY').toString()}</span>
              <br />
              <span>Amount Paid: {formatPrice(u.paymentAmount)}</span>
              <br />
              <span>
                Status: <b>{u.status}</b>
              </span>
              <br />

              <div className={styles.ctaWrapper}>
                <Button //
                  className={`btn btn-sm ${styles.btnLeft} ${u.Student?.showLink ? 'btn-paid' : 'btn-primary'}`}
                  onClick={(e) => handleZoomClick(e, u)}>
                  {u.Student?.showLink ? 'Disable Zoom' : 'Enable Zoom'}
                </Button>

                <Button
                  className={`btn btn-sm ${styles.btnRight} ${u.Session.isDisbursed ? 'btn-paid' : `btn-primary ${styles.payTutor}`}`}
                  disabled={u.Session.isDisbursed}
                  onClick={(event) => {
                    event.stopPropagation();
                    sendPayment(u);
                  }}>
                  {u.Session.isDisbursed ? 'Paid' : 'Pay Tutor'}
                </Button>
              </div>
            </Col>
          </Card>
        ))}
      </div>
    );
  };

  const renderTableView = () => {
    return (
      <Table //
        style={{ display: showTable ? 'block' : 'none' }}
        dataSource={dataSource}
        columns={columns}
        pagination={{ position: ['bottomCenter'] }}
      />
    );
  };

  useEffect(() => setShowTable(!isMobile), []);

  return <div>{isMobile ? renderCardView() : renderTableView()}</div>;
};

export default StudentPaymentLogTable;
