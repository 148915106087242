import React from 'react';
import DisplaySubjectCheckboxes from './DisplaySubjectCheckboxes';
import DisplayWorkSchedules from './DisplayWorkSchedules';

// styling
import styles from './index.module.less';

const DisplayApplicationForTutor = (props) => {
  const { app } = props;

  const sourceInformationOthers = (v) => {
    if (!v || v === null) return '-';
    let source = v.source.join(', ');
    if (v.others !== null) source = `${source} ${v.others}`;

    return source;
  };

  if (!app) return null;

  return (
    <>
      <h5>Application For Tutor Position</h5>

      <div className={styles.wrapper_level0}>
        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>How did you hear about Tutor BASE?:</p>
          <p>{sourceInformationOthers(app?.sourceInformation)}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Do you know anyone who is/was working or enrolled at Tutor BASE?:</p>
          <p>{app?.referral}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Please explain in no more than 5 sentences why you want to teach at Tutor BASE:</p>
          <p>{app?.reasons}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>
            Discipline area(s) and subject(s) you can teach in <span>(choose as many as applicable)</span>:
          </p>
          <p>
            <DisplaySubjectCheckboxes app={app} />
          </p>
        </div>
      </div>

      <div className={styles.wrapper_level0}>
        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>How many hours per week on average are you available to tutor?:</p>
          <p>{app?.hours}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>When are you available to work?:</p>
          <p>
            <DisplayWorkSchedules app={app} />
          </p>
        </div>
      </div>

      <div className={styles.wrapper_level0}>
        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>
            Please describe your teaching or tutoring experience <span>(if any).</span>:
          </p>
          <p>{app?.experience}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Do you have any training or experience in working with children with learning disabilities?:</p>
          <p>{app?.disability}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Are there any special skills you can teach for our summer fun programs and master classes?:</p>
          <p>{app?.summerSkills}</p>
        </div>
      </div>
    </>
  );
};

export default DisplayApplicationForTutor;
