import axios from 'axios';

// antd
// import { message } from 'antd';

// api
// import { login, logout, verifyAdmin } from '@service/api/account';
import {
  //
  postUserLogin,
  getUserDetails,
  // postStudentRegister,
  // postTutorRegister,
  // putUserChangePwdEmail,
} from '@service/api/user';

// utils
import { decode, swalError } from '@utils/methods';
// import { getLocation } from '@utils/common';

// types
import * as types from '../types';

export const setRole = (account) => (dispatch) => {
  dispatch({
    type: types.SET_USER_ROLE,
    payload: account.role === 'admin',
  });
};

export const setUnAuthenticated = () => ({
  type: types.SET_UNAUTHENTICATED,
});

export const setAuthenticated = () => ({
  type: types.SET_AUTHENTICATED,
});

export const checkAdmin = () => async () => {
  // const res = await verifyAdmin(payload);
  // const { success, data } = res;
  // const decoded = decode(data.token);
  // let isAdmin = false;

  // if (success) {
  //   isAdmin = decoded.role === 'admin';
  // }
  return false;
};

export const setAuthorizationHeader = (token) => {
  const bearerToken = `Bearer ${token}`;
  localStorage.setItem('token', token);
  axios.defaults.headers.common.Authorization = bearerToken;
};

export const setRefreshToken = (token) => {
  localStorage.setItem('rtoken', token);
};

export const setUserDetails = (account) => {
  localStorage.setItem('account', JSON.stringify(account));
  setRole(account);

  return {
    type: types.SET_USER_DETAILS,
    payload: account,
  };
};

export const setTutorBaseDetails = (v) => {
  return {
    type: types.MINATO_TUTORBASE,
    payload: v,
  };
};

export const userLogin = (payload) => async (dispatch) => {
  postUserLogin(payload)
    .then((res) => {
      // console.log('[COMMON userLogin] ', res);

      const { success, data: resData } = res;
      if (success) {
        // logdeeznuts({
        //   email: user.email,
        //   action: 'LOGGED IN',
        // });
        const { access_token: at, data, ...restObj } = resData;
        const user = decode(at);
        setAuthorizationHeader(at);
        dispatch(setUserDetails(user));
        dispatch(setTutorBaseDetails(restObj));
        // setRefreshToken(data.refreshToken);
        window.location.href = process.env.REACT_APP_ROUTER_BASE || '';
      } else {
        // console.log('[X X X 2222]');
        // swalError(msg);
      }
    })
    .catch((e) => {
      console.log('[ER] ', e);
      swalError(`<p>Error: ${e?.message}</p>`, true);
    });
};

export const fetchUpdatedDetails = () => async (dispatch) => {
  // console.log('[fetchUpdatedDetails]');

  const res = await getUserDetails();
  const { success, data: resData, msg } = res;
  if (success) {
    const { access_token: at, data, ...restObj } = resData;
    dispatch(setUserDetails(data));
    dispatch(setTutorBaseDetails(restObj));
  } else {
    // message.error(msg);
    swalError(msg);
  }
  return res;
};

export const userLogout = () => (dispatch) => {
  // const { email } = JSON.parse(localStorage.getItem('account'));
  // logdeeznuts({ email, action: 'LOGGED OUT' });
  // logout({ dig: 'dug' });

  localStorage.removeItem('tokenx');
  localStorage.removeItem('token');
  localStorage.removeItem('rtoken');
  localStorage.removeItem('account');
  localStorage.removeItem('localDetails');
  localStorage.removeItem('rt');

  dispatch(setUnAuthenticated());
  dispatch({
    type: types.USER_LOGOUT,
  });
  window.location.href = process.env.REACT_APP_ROUTER_BASE || '';
};
