import React from 'react';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import phoneIcon from '@images/phone_icon.png';

const Footer = () => {
  const { isMobile } = useWindowSize();
  const style = { fill: 'white', fontSize: '1.5rem' };
  const baseUrl = window.location.origin;

  return (
    <>
      <div className="footer">
        {isMobile ? (
          <div className="footer-left">
            <div className="facebook-dive">
              <div className="likes like-count">9.2k</div>
              <Link to={{ pathname: 'https://www.facebook.com/tutorbasecenter' }} target="_blank" className="likes like">
                <i className="fas fa-thumbs-up"> Like</i>
              </Link>
            </div>
            <div>
              <span>&copy; {new Date().getFullYear()}. All Rights Reserved.</span>
              <span style={{ display: 'block' }}>
                <Link to="/privacypolicy">Privacy Policy</Link>
              </span>
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <div className="footer-left" style={{ flex: 1 }}>
              <span className="baseurl">
                &copy; {new Date().getFullYear()} <a href={baseUrl}>{baseUrl}</a>. All Rights Reserved.
              </span>
              <span>
                <Link to="/privacypolicy">Privacy Policy</Link>
              </span>
              <br />
            </div>

            <div className="footer2" style={{ flex: 1, padding: 0, textAlign: 'right' }}>
              Unauthorized use and/or duplication of this website&apos;s content without express and written permission from the website owner is strictly prohibited. Excerpts and links may be used, provided that full and clear credit is given to Tutor BASE Center with appropriate and specific direction to the original content.
            </div>
          </div>
        )}

        {/* <span style={{ color: 'lightgray', fontSize: 10 }}>
            TEST Dimensions: {size.width} x {size.height}
          </span> */}
        {/* <div className="footer-right">
					<div className="social">
						<Link
							to={{ pathname: "https://www.facebook.com/tutorbasecenter" }}
							target="_blank"
							className="fab fa-facebook-f"
						></Link>
					</div>

					<div className="social">
						<Link
							to={{ pathname: "http://fb.me/msg/tutorbasecenter" }}
							target="_blank"
							className="fab fa-facebook-messenger"
						></Link>
					</div>

					<div className="social">
						<a
							href="tel:+639273127593"
							target="_blank"
							className="fab fa-viber"
						></a>
					</div>

					<div className="social">
						<a
							href="mailto:tutorbasecenter@gmail.com"
							target="_blank"
							className="far fa-envelope"
						></a>
					</div>
				</div> */}

        <div className="social-float fixed-top social-hbar">
          <div className="float-item float-fb">
            <div className="float-text" id="text-fb">
              Facebook
            </div>
            <div className="float-icon" id="fb">
              <Link to={{ pathname: 'https://www.facebook.com/tutorbasecenter' }} target="_blank">
                <FaIcons.FaFacebook style={style} />
              </Link>
            </div>
          </div>

          <div className="float-item float-im">
            <div className="float-text" id="text-im">
              Messenger
            </div>
            <div className="float-icon" id="im">
              <Link to={{ pathname: 'http://fb.me/msg/tutorbasecenter' }} target="_blank">
                <FaIcons.FaFacebookMessenger style={style} />
              </Link>
            </div>
          </div>

          <div className="float-item float-viber">
            <div className="float-text" id="text-viber">
              Viber
            </div>
            <div className="float-icon" id="viber">
              <a href="viber://chat?number=639273127593" rel="noreferrer" target="_blank">
                {/* <FaIcons.FaViber style={style} /> */}
                <img
                  alt="v"
                  src={phoneIcon}
                  style={{
                    width: '1.75em',
                    height: '1.75em',
                  }}
                />
              </a>
            </div>
          </div>

          <div className="float-item float-gmail">
            <div className="float-text" id="text-gmail">
              Email
            </div>
            <div className="float-icon" id="gmail">
              <a href="mailto:tutorbasecenter@gmail.com" rel="noreferrer" target="_blank">
                <FaIcons.FaRegEnvelope style={style} size={21} />
              </a>
            </div>
          </div>
        </div>

        <div className="social-float facebook-float fixed-top">
          <div className="likes like-count">9.2k</div>
          <Link to={{ pathname: 'https://www.facebook.com/tutorbasecenter' }} target="_blank" className="likes like">
            <i className="fas fa-thumbs-up"> Like</i>
          </Link>
        </div>
      </div>
      {isMobile && <div className="footer2">Unauthorized use and/or duplication of this website&apos;s content without express and written permission from the website owner is strictly prohibited. Excerpts and links may be used, provided that full and clear credit is given to Tutor BASE Center with appropriate and specific direction to the original content.</div>}
    </>
  );
};

export default Footer;
