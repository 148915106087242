import React, { useEffect } from 'react';

// antd
import { Col, Select, Modal, Form, Button, Row, Card, Input } from 'antd';

// icons
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import constants from '@utils/constants';

import { useDebounce } from '@utils/methods';

// styling
import '../../index.module.less';
import './index.less';

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: '100%',
  },
};

const { Option } = Select;

const RegisterModal = ({ check, visible, onCancel, addUser, data, students, tutors }) => {
  const { isMobile } = useWindowSize();
  const [form] = Form.useForm();

  const handleSubmit = useDebounce(() => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        addUser(values);
      })
      .catch((er) => {
        console.log('Validate Failed:', er);
      });
  }, 300);

  const handleCancel = useDebounce(() => {
    form.resetFields();
    onCancel();
  }, 300);

  const handleFormValuesChange = () => {
    // console.log('[HANDLEFORMVALUECHANGE] ', form.getFieldsValue());
  };

  useEffect(() => {
    form.setFieldsValue(data);
    return () => form.resetFields();
  }, [data]);

  return (
    <Modal //
      getContainer={false}
      title={check === 'student' ? 'Register Student' : 'Register Tutor'}
      open={visible}
      destroyOnClose
      width={1000}
      footer={null}
      onCancel={onCancel}>
      <Form //
        {...layout}
        // initialValues={data}
        form={form}
        layout="vertical"
        onValuesChange={handleFormValuesChange}
        onFinish={handleSubmit}>
        {/* <Space direction="vertical" style={{ width: '100%' }}> */}
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item hidden name="UserId">
          <Input />
        </Form.Item>

        {isMobile ? (
          <>
            <Card>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="First Name" name="firstName" rules={[{ required: true, message: 'Please input your first name.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Last Name" name="lastName">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item className="form-itemz-m" label="Nickname" name="nickName">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item className="form-itemz-m" label="Preferred Pronouns" name="prefPronoun">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Date of Birth" name="dob" rules={[{ required: true, message: 'Please input your birthday.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Email" name="email" rules={[{ required: true, message: 'Please input your email.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Phone/Mobile No." name="phone" rules={[{ required: true, message: 'Please input your phone.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            {check !== 'student' && (
              <>
                <Card className="mt-3">
                  <Row gutter={8}>
                    <Col span={24}>
                      <Form.Item className="form-itemz-m" label="Bank/E-Wallet" name="bCode" rules={[{ required: true, message: 'Please input your bank/e-wallet name.' }]}>
                        <Select
                          style={{
                            width: '100%',
                          }}
                          placeholder="Select Bank/E-Wallet">
                          {Object.keys(constants.validBanks)
                            .sort((a, b) => a.localeCompare(b))
                            .map((s, idx) => (
                              <Option key={idx} value={constants.validBanks[s]}>
                                {s}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={24}>
                      <Form.Item className="form-itemz-m" label="Account Name" name="bName" rules={[{ required: true, message: 'Please input your bank account name.' }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={24}>
                      <Form.Item className="form-itemz-m" label="Account Number" name="bNumber" rules={[{ required: true, message: 'Please input your bank account number.' }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                <Card className="mt-3">
                  <Form.Item className="form-itemz-m" label="Primary Students" name="primaryStudents">
                    <Select
                      mode="tags"
                      style={{
                        width: '100%',
                      }}
                      placeholder="Select Students">
                      {students.map((s) => (
                        <Option key={s.id}>{s.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Card>
              </>
            )}

            {check === 'student' && (
              <Card className="mt-3">
                <Form.Item className="form-itemz-m" label="Primary Tutor" name="primaryTutor">
                  <Select
                    mode="tags"
                    style={{
                      width: '100%',
                    }}
                    placeholder="Select Tutor">
                    {tutors.map((s) => (
                      <Option key={s.id}>{s.name}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item className="form-itemz-m" label="Grade" name="grade" rules={[{ required: true, message: 'Please input your grade.' }]}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item className="form-itemz-m" label="School" name="school" rules={[{ required: true, message: 'Please input your school.' }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Citizenship" name="international" rules={[{ required: true, message: 'Please select a citizenship.' }]}>
                      <Select>
                        <Select.Option value={0}>Local</Select.Option>
                        <Select.Option value={1}>International</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Parent 1's First Name" name="parentsFirstName1" rules={[{ required: true, message: "Please input your parent's name." }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Parent 1's Last Name" name="parentsLastName1">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Parent 1's Email" name="parentsEmail1" rules={[{ required: true, message: "Please input your parent's email." }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Parent 1's Phone" name="parentsPhone1" rules={[{ required: true, message: "Please input your parent's phone number." }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Parent 2's First Name" name="parentsFirstName2" rules={[{ required: true, message: "Please input your parent's name." }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Parent 2's Last Name" name="parentsLastName2">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Parent 2's Email" name="parentsEmail2" rules={[{ required: true, message: "Please input your parent's email." }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Parent 2's Phone" name="parentsPhone2" rules={[{ required: true, message: "Please input your parent's phone number." }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            )}

            <Card className="mt-3">
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Address" name="address" rules={[{ required: true, message: 'Please input your address.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="City" name="city" rules={[{ required: true, message: 'Please input your city.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Country" name="country" rules={[{ required: true, message: 'Please input your country.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            {/* </Space> */}

            {/* <Form.Item className="form-itemz-m" label="Password" name="password" rules={[{ required: true, message: 'Please input your password.' }]}>
              <Input.Password iconRender={(v) => (v ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} placeholder="Enter password" />
            </Form.Item> */}

            <Row className="list-label-add-label-footer">
              <Button onClick={handleCancel} style={{ marginRight: 10 }}>
                Cancel
              </Button>

              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Row>
          </>
        ) : (
          <>
            <Card>
              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="First Name" name="firstName" rules={[{ required: true, message: 'Please input your first name.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="Last Name" name="lastName">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item className="form-itemz-m" label="Nickname" name="nickName">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item className="form-itemz-m" label="Preferred Pronouns" name="prefPronoun">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="Date of Birth" name="dob" rules={[{ required: true, message: 'Please input your birthday.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="Email" name="email" rules={[{ required: true, message: 'Please input your email.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="Phone/Mobile No." name="phone" rules={[{ required: true, message: 'Please input your phone.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            {check !== 'student' && (
              <>
                <Card className="mt-3">
                  <Row gutter={8}>
                    <Col span={8}>
                      <Form.Item className="form-itemz-m" label="Bank/E-Wallet" name="bCode" rules={[{ required: true, message: 'Please input your bank/e-wallet name.' }]}>
                        <Select
                          style={{
                            width: '100%',
                          }}
                          placeholder="Select Bank/E-Wallet">
                          {Object.keys(constants.validBanks)
                            .sort((a, b) => a.localeCompare(b))
                            .map((s, idx) => (
                              <Option key={idx} value={constants.validBanks[s]}>
                                {s}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item className="form-itemz-m" label="Account Name" name="bName" rules={[{ required: true, message: 'Please input your bank account name.' }]}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item className="form-itemz-m" label="Account Number" name="bNumber" rules={[{ required: true, message: 'Please input your bank account number.' }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                <Card className="mt-3">
                  <Form.Item className="form-itemz-m" label="Primary Students" name="primaryStudents">
                    <Select
                      mode="tags"
                      style={{
                        width: '100%',
                      }}
                      placeholder="Select Students">
                      {students.map((s) => (
                        <Option key={s.id}>{s.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Card>
              </>
            )}

            {check === 'student' && (
              <Card className="mt-3">
                <Form.Item className="form-itemz-m" label="Primary Tutor" name="primaryTutor">
                  <Select
                    mode="tags"
                    style={{
                      width: '100%',
                    }}
                    placeholder="Select Tutor">
                    {tutors.map((s) => (
                      <Option key={s.id}>{s.name}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item className="form-itemz-m" label="Grade" name="grade" rules={[{ required: true, message: 'Please input your grade.' }]}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item className="form-itemz-m" label="School" name="school" rules={[{ required: true, message: 'Please input your school.' }]}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item className="form-itemz-m" label="Citizenship" name="international" rules={[{ required: true, message: 'Please select a citizenship.' }]}>
                      <Select>
                        <Select.Option value={0}>Local</Select.Option>
                        <Select.Option value={1}>International</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={6}>
                    <Form.Item className="form-itemz-m" label="Parent 1's First Name" name="parentsFirstName1" rules={[{ required: true, message: "Please input your parent's name." }]}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item className="form-itemz-m" label="Parent 1's Last Name" name="parentsLastName1">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item className="form-itemz-m" label="Parent 1's Email" name="parentsEmail1" rules={[{ required: true, message: "Please input your parent's email." }]}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item className="form-itemz-m" label="Parent 1's Phone" name="parentsPhone1" rules={[{ required: true, message: "Please input your parent's phone number." }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={6}>
                    <Form.Item className="form-itemz-m" label="Parent 2's First Name" name="parentsFirstName2" rules={[{ required: true, message: "Please input your parent's name." }]}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item className="form-itemz-m" label="Parent 2's Last Name" name="parentsLastName2">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item className="form-itemz-m" label="Parent 2's Email" name="parentsEmail2" rules={[{ required: true, message: "Please input your parent's email." }]}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item className="form-itemz-m" label="Parent 2's Phone" name="parentsPhone2" rules={[{ required: true, message: "Please input your parent's phone number." }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            )}

            <Card className="mt-3">
              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="Address" name="address" rules={[{ required: true, message: 'Please input your address.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="City" name="city" rules={[{ required: true, message: 'Please input your city.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="Country" name="country" rules={[{ required: true, message: 'Please input your country.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            {/* </Space> */}

            {/* <Form.Item className="form-itemz-m" label="Password" name="password" rules={[{ required: true, message: 'Please input your password.' }]}>
              <Input.Password iconRender={(v) => (v ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} placeholder="Enter password" />
            </Form.Item> */}

            <Row className="list-label-add-label-footer">
              <Button onClick={handleCancel} style={{ marginRight: 10 }}>
                Cancel
              </Button>

              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default RegisterModal;
