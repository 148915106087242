export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const JOB_APPLY = 'JOB_APPLY';
export const JOB_LIST = 'JOB_LIST';
export const ZOOM_EDIT = 'ZOOM_EDIT';
export const ZOOM_GET = 'ZOOM_GET';
export const PAYMENT_ADD = 'PAYMENT_ADD';
export const PAYMENT_STATUS = 'PAYMENT_STATUS';
export const PAYMENT_LIST = 'PAYMENT_LIST';
export const PAYMENT_DEACTIVE = 'PAYMENT_DEACTIVE';
export const PAYMENT_ACCOUNT = 'PAYMENT_ACCOUNT';
export const DIST_ADD = 'DIST_ADD';
export const DRIVE_LIST = 'DRIVE_LIST';
export const DRIVE_LINK_TUTOR = 'DRIVE_LINK_TUTOR';
export const DRIVE_LINK_TUTOR_EDIT = 'DRIVE_LINK_TUTOR_EDIT';
export const SCHEDULE_ADD = 'SCHEDULE_ADD';
export const SESSION_ADD = 'SESSION_ADD';
export const SCHEDULE_LIST = 'SCHEDULE_LIST';
export const SCHEDULE_EDIT = 'SCHEDULE_EDIT';
export const SCHEDULE_HISTORY = 'SCHEDULE_HISTORY';
export const REPORT_ADD = 'REPORT_ADD';
export const REPORT_HEAD_CHECK = 'REPORT_HEAD_CHECK';
export const REPORT_PARENT_CHECK = 'REPORT_PARENT_CHECK';
export const REPORT_EDIT = 'REPORT_EDIT';
export const NOTIF_SET = 'NOTIF_SET';

export const MINATO_TUTORBASE = 'MINATO_TUTORBASE';
export const DRIVE_RT = 'DRIVE_RT';

export const BULK_SESSION_ADD = 'BULK_SESSION_ADD';

export const NOTE_ADD = 'NOTE_ADD';
export const NOTE_EDIT = 'NOTE_EDIT';
export const NOTE_GET = 'NOTE_GET';
export const NOTE_DELETE = 'NOTE_DELETE';
