import {
  //
  postJobApply,
  postListJobApp,
} from '@service/api/job';

import { swalError, swalSuccess } from '@utils/methods';

// types
import * as types from '../types';

export const jobApply = (payload) => async (dispatch) => {
  const res = await postJobApply(payload);
  if (res.success) {
    dispatch({
      type: types.JOB_APPLY,
      payload: res.data,
    });
    swalSuccess(
      `
            <h4>Successfully submitted<br />application for Tutor position<h4/>
            <div className="swal-text-wrapper">
            <h5 className="swal-text">Your application form has been received.
            The Recruitment Committee will review your application and contact you as soon as possible if you
            have been chosen to proceed to the next step of the application process. <br /><br/> 
            Thank you for your interest in joining the Tutor BASE team and we wish you all the best!</h5>
            </div>        
            `,
      true
    );
  } else {
    swalError(res.msg);
  }

  return res;
};

export const listJobApp = (payload) => async (dispatch) => {
  const res = await postListJobApp(payload);
  // console.log('[LISTJOBAPP] ', res);
  dispatch({
    type: types.JOB_LIST,
    payload: res.data.jobs,
  });

  return res;
};
