import React, { useState, useEffect } from 'react';

import Spinner from '@components/layouts/Spinner';
import NavbarDashboard from '@components/layouts/NavbarDashboard';
import HeaderDashboard from '@components/layouts/HeaderDashboard';

import './index.less';

const TermOfService = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1600);
  }, []);

  if (loading) return <Spinner />;

  return (
    <>
      <NavbarDashboard />
      <HeaderDashboard title="Terms of Service" />
      <div className="tos-wrapper">
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="text-decoration-underline">
              <b>
                Parents’/Guardians’ and Students’ Terms of Service{' '}
                {/* and
										Contract of Agreement */}
              </b>
            </h3>
            {/* <span className="text-black-50">
									Please read carefully before accepting and signing at the end
									of this Agreement. This form is to be submitted online to the
									Center by you, the Parent/Guardian and Student, before the
									Student is officially enrolled
								</span> */}
          </div>
          <div className="col-md-12 mt-5">
            <p>Parents’/Guardians’ and Students’ use of our services is subject to legally binding terms of service as specified herein. Please carefully read the following terms of service before accessing or using our service. The use of our services indicates the acceptance of the following terms of service and the Parent’s/Guardian’s and Student’s agreement to be bound by them.</p>
            <p>The following terms of service form a binding agreement between the Parent/Guardian and Student and Tutor BASE Before and After-School Enrichment Center. Tutor BASE Center reserves the right to alter these terms and conditions without advance notice. The continued use of our services after any change means the Parent/Guardian and Student have accepted the changed terms of service.</p>
            <p>
              <ol>
                <b>
                  <li>Description of Services</li>
                </b>
                <p className="mt-2">
                  {/* <b>1.1</b> The Center offers remote and online tutorial services via Zoom video conferencing and the specialized Tutor BASE Center website. The Center is open for tutorial sessions from Monday-Saturday, from 12:00pm-8:00pm, Philippine Standard Time (tutorial sessions scheduled for Sunday can be held upon special arrangement). Tutorial sessions are scheduled in intervals of 1 hour. */}
                  <b>1.1</b> The Center offers remote and online tutorial services via Zoom video conferencing and the specialized Tutor BASE Center website. The Center is open for tutorial sessions from 5:00pm - 11:00pm Mondays to Fridays, and 5:00am - 11:00am on Saturdays, Philippine Standard Time. Tutorial sessions are scheduled in intervals of 1 hour.
                </p>
                <p>
                  <b>1.2</b> The fees for each grade level are as follows:
                  <br />
                  K-12, College Level - ₱400.00/hour (local Students residing in the Philippines)
                  <br />
                  K-12, College Level - $40.00/hour (international Students residing abroad)*
                  <br />
                  *Fees are to be calculated according to the current conversion rate from your country&apos;s currency to Philippine peso.
                </p>
                <p>
                  <b>1.3</b> The Center only allows no less than 1-hour tutorial sessions for 5 days a week, or no less than a total of 5 hours in a week of tutorial sessions with the Students. As one-on-one or individualized sessions are practiced with the Students, most of the Tutors maintain only one or two Students at the most. As a result, the Center can only get a commitment from the Tutors if the Student’s session is on a regular basis.
                </p>
                <p>
                  <b>1.4</b> If the Student cannot attend tutorial sessions regularly from Monday to Friday, a staggered once-a-week schedule every Saturday or Sunday (with special arrangement) may be allowed as long as the required minimum of 5 hours is fulfilled. The Head Teacher is to be informed about this preferred fixed schedule arrangement prior to enrolling in the Center.
                </p>
                <p>
                  <b>1.5</b> All Students and Parents/Guardians are entitled to an account on the Tutor BASE Center website that grants them access to the following:
                  <p className="ml-2 mt-2">
                    <b>1.5.1</b> The Student’s daily tutorial session schedule with the Center
                    <br />
                    <b>1.5.2</b> The weekly Zoom meeting details, provided that tutorial session fees for the week have been settled
                    <br />
                    {/* <b>1.5.3</b> Google Drive folder for storage of school files and learning materials of the Student
                    <br /> */}
                    <b>1.5.3</b> The payments system for settling fees, which accepts payments from the following banks:
                    <br />
                    <p className="mt-2">
                      <ul>
                        <li>Bank of the Philippine Islands (BPI)</li>
                        {/* <li>BPI Family Savings Bank</li> */}
                        {/* <li>BDO Unibank</li> */}
                        <li>China Bank</li>
                        {/* <li>China Bank Savings</li> */}
                        {/* <li>East West Banking Corporation</li> */}
                        {/* <li>Land Bank of the Philippines</li> */}
                        {/* <li>Metropolitan Bank and Trust Company (Metrobank)</li> */}
                        {/* <li>Philippine National Bank (PNB)</li> */}
                        {/* <li>PNB Savings Bank</li> */}
                        <li>Rizal Commercial Banking Corporation (RCBC)</li>
                        {/* <li>Security Bank Corporation</li> */}
                        {/* <li>United Coconut Planters Bank (UCPB)</li> */}
                        <li>Union Bank</li>
                      </ul>
                    </p>
                    <b>1.5.4</b> The end-of-session reports system, which outlines the subjects or topics discussed with the Student, tasks accomplished with the Student, points for improvement of the Student, goals for the next tutorial session, and any other additional concerns or notes (e.g. time extensions, substitute tutors, etc.) and
                    <br />
                    <b>1.5.4</b> A copy of these Terms of Service for reference.
                    <br />
                  </p>
                </p>
                <p>
                  <b>1.6</b> Each Student is to be assigned to a Tutor whose areas of expertise match the Student’s areas for improvement. This is so that we can more effectively help the Student build upon their knowledge in those specific areas, so as to guide them in achieving their academic goals. The Tutor assigned will primarily handle the Student in all tutorial sessions (unless the Parent/Guardian or Student requests for a different Tutor, or if the Head Teacher sees it fit to assign a different Tutor to the Student).
                </p>
                <p>
                  <b>1.7</b> In the event that the Tutor will be absent, a substitute Tutor may be assigned to handle the Student in the primary Tutor’s temporary absence without notice; in this case, the Student’s primary Tutor will endorse any pending lessons or tasks to finish to the substitute Tutor. Alternatively, the tutorial session may be rescheduled to later time or date for free.
                </p>
                <p>
                  <b>1.8</b> In a tutorial session, the Tutor will guide the Student in finishing their school tasks as soon as possible, and in preparing the Student for the next day’s activities or requirements in school. Furthermore, the Tutor is instructed to assess and focus on the Student’s areas of improvement to enhance their learning and mastery of that particular subject. As such, the Student will be requested to disclose or share school requirements, school files, and grades to the Tutor.
                </p>
                <p>
                  <b>1.9</b> In a tutorial session with a Student, the Tutor will check and accomplish the Student’s homework or project requirements, review for upcoming exams or quizzes, and/or teach the Student advance lessons or past lessons that need reinforcing.
                </p>
                <p>
                  <b>1.10</b> The Tutor may give mastery checks (seatwork or short quizzes) after a lesson is taught to ensure that the Student has understood the lesson discussed. The Tutor may also give or send brief homework to the Student, but only if the Student does not already have any homework from their school or if the Parent/Guardian requests us to do so.
                </p>
                <p>
                  <b>1.11</b> In order to inform the Parent/Guardian of the Student’s learning progress, end-of- session reports described in clause 1.5.5 will be made by the Tutor at the end of every tutorial session with the Student.
                </p>
                <p>
                  <b>1.12</b> The Tutor is, at no time, required or obligated to execute assignments or projects wholly on behalf of the Student.
                </p>
                <p>
                  <b>1.13</b> While the Tutor is confident in their skills and teaching ability, the Tutor makes no guarantee with regard to a Student’s performance in the school they currently attend as a result of any tutoring provided by the Center.
                </p>
                <p>
                  <b>1.14</b> Viber is used as the main platform for communication in the Center. Any matters of concern or otherwise (e.g. announcements from the Center, messages from the Tutor, etc.) are to be discussed on the Viber group chat set up by the Head Teacher.
                </p>
                <p>
                  <b>1.15</b> The Center may give tutoring evaluation forms randomly and at no specific time to the Student or Parent/Guardian to check if Tutors are performing well and embodying the Center’s goals. The Center requests the Student and Parent/Guardian to answer these forms in order for us to be aware of what areas need to be improved on in the Tutors’ teaching.
                </p>

                <b>
                  <li>Billing and Scheduling</li>
                </b>
                <p className="mt-2">
                  <b>2.1</b> Upon prior agreement with the Head Teacher, the bill for the week’s tutorial session fee will be sent via email every weekend which will cover the services for the coming new week. It is expected that payment for that billing will be settled strictly from Friday up to Sunday (11:59 p.m.) before the Students’ tutorial sessions commence. If the Student has no tutorial session scheduled on a Monday or if they will be absent, payment is still expected to be made by the Parent/Guardian.
                </p>
                <p>
                  <b>2.2</b> The Parent/Guardian is to pay using the payments system of the Tutor BASE Center website using any of the payment methods listed in clause 1.5.4. Upon verification of payment, the Student will be given access to view the week’s Zoom meeting details on the website.
                </p>
                <p>
                  <b>2.3</b> The Center has the right to refuse a Student who has not settled the bill in advance for the coming new week. Late payments will result in cancellation of the Student’s tutorial session scheduled on Monday (for Students whose tutorial sessions are scheduled from Monday-Friday) or on Saturday/Sunday (for Students whose tutorial sessions are scheduled on either Saturday or Sunday)
                </p>
                <p>
                  <b>2.4</b> Tutorial sessions will promptly begin and end at their pre-scheduled time, regardless of the time of the Student’s arrival online. No adjustment to fees shall be made for time lost because of the late arrival of the Student or by early termination of the tutorial session by the Student (e.g. if the Student is 10 minutes late, the tutorial session will not be extended)
                </p>
                <p>
                  <b>2.5</b> In the event that the Tutor is late to the tutorial session due to circumstances beyond their control, free time extensions equivalent to the time lost will be provided (e.g. if the Tutor is 10 minutes late, the tutorial session will be extended by 10 minutes for free).
                </p>
                <p>
                  <b>2.6</b> In the event that the duration of the tutorial session scheduled and paid for is insufficient, time extensions in increments of 1 hour are permitted so that the Student can complete and accomplish all tasks. In this case, the Parent/Guardian will immediately be informed of the time extension through the Viber group chat. Please note that the Center’s standard rates apply for extensions, and fees for extensions must be settled immediately within 24 hours
                </p>

                <b>
                  <li>Postponement, Rescheduling, Cancellation, and Make-ups</li>
                </b>
                <p className="mt-2">
                  <b>3.1</b> In the event that a Student’s tutorial session needs to be postponed, it is highly encouraged that they inform the Head Teacher at least 24 hours before the scheduled session via the Viber group chat. A tutorial session can be postponed only within the same week the original tutorial session was scheduled. Postponement of tutorial sessions is subject to the availability of a Tutor to handle the student at the preferred rescheduled time.
                </p>
                <p>
                  <b>3.2</b> In the event that a Student’s tutorial session needs to be cancelled, it is highly encouraged that they inform the Head Teacher at least 24 hours before the scheduled session via the Viber group chat. For cancelled tutorial sessions, a free optional make-up tutorial session may be scheduled within the same week; alternatively, the number of hours missed may be added to the Student’s next regular scheduled tutorial session in a free make-up extension.
                </p>
                <p>
                  <b>3.3</b> Free make-up tutorial sessions or extensions can only be given to Students who have cancelled their tutorial sessions for valid reasons, including sudden sickness, electricity or connectivity issues, and family or home emergencies.
                </p>
                <p>
                  <b>3.4</b> To avoid abusing the Center’s policy of giving free make-up tutorial sessions, the aforementioned excuses can only be used two (2) times each in a semester, after which the Parent/Guardian will be charged for any make-up tutorial sessions.
                </p>
                <p>
                  <b>3.5</b> The Parent/Guardian will not be refunded for cancellation of paid tutorial sessions due to reasons other than those specified in clause 3.3.
                </p>
                <p>
                  <b>3.6</b> A maximum grace period of until 8:00 a.m. on the exact day of the tutorials session is allowed for cancellations due to valid reasons, with optional make-up tutorial sessions. However, after this point, a make-up tutorial session can no longer be given. This is due to the fact that the slot for the Student has already been reserved and the Tutor is compensated in advance for their services for the Student’s session.
                </p>

                <b>
                  <li>Students’ Online Decorum in the Center</li>
                </b>
                <p className="mt-2">
                  <b>4.1</b> Students are strongly encouraged to stay in a quiet environment with no distractions so that they can focus on their tutorial session.
                </p>
                <p>
                  <b>4.2</b> Students are expected to be punctual and come on time to their tutorial sessions. It is recommended that Students have their device ready at least 15 minutes before the scheduled tutorial session.
                </p>
                <p>
                  <b>4.3</b> The Student is expected to turn on their device’s video and microphone once their tutorial session starts with the Tutor. This is to effectively simulate face-to-face learning.
                </p>
                <p>
                  <b>4.4</b> Making too much unnecessary noise, chatting, and taking prolonged breaks during a tutorial session is discouraged so as not to disrupt the tutorial session and waste time.
                </p>
                <p>
                  <b>4.5</b> Students who are being unruly, restless, and unfocused may take short breaks. However, the Tutor is instructed to resume right away so as not to waste time.
                </p>
                <p>
                  <b>4.6</b> Eating heavy meals is highly discouraged while the tutorial session is ongoing, but light snacks such as cookies, crackers, chips, cereals, and other simple finger food or beverages are allowed. The Student is generally discouraged to eat while their tutorial session is ongoing, but since there are Students who cannot focus when hungry, snacks like those specified above are permitted.
                </p>
                <p>
                  <b>4.7</b> The Center has the right to refuse Students who are destructive, unruly, pose a threat to others, or do not observe decorum while being tutored remotely.
                </p>

                <b>
                  <li>Center Operations During Holidays and Emergency Situations</li>
                </b>
                <p className="mt-2">
                  <b>5.1</b> Should there be a national or public holiday that falls within an upcoming week, the Center will still be open for tutorial sessions. The Center requests that Parents/Guardians and Students inform us before the week has begun whether tutorial sessions that fall on holidays are to take place as scheduled. If the Student decides to miss tutorial sessions with us due to a holiday, the corresponding fee will be deducted from the weekly bill accordingly.
                </p>
                <p>
                  <b>5.2</b> Should inclement weather conditions, natural calamities, man-made disasters, or widespread electricity or internet losses arise or be anticipated, the Center will temporarily halt operations. Any tutorial sessions that have already been paid for will be rescheduled to a later date. Operations will resume as usual when conditions have improved or when it is predicted to be safe and secure to do so, and the Center will inform all Parents/Guardians and Students accordingly.
                </p>

                <b>
                  <li>Obligations of the Tutor and Center</li>
                </b>
                <p className="mt-2">
                  <b>6.1</b> Tutors are prohibited from disclosing to others confidential information or knowledge pertaining to the Student. This includes but is not limited to the Student’s complete name, school, academic performance, learning disabilities or conditions, etc. They are also not allowed to share the Student’s end-of-session reports or Google Drive folder containing their school files and learning materials to others. Furthermore, they are not allowed to take and post photos or recordings of or with the Student without their explicit consent. This is to ensure the privacy of the Student and to conform to the Data Privacy Act of 2012.
                </p>
                <p>
                  <b>6.2</b> The Tutor and/or Head Teacher may contact other parties involved in the education of the Student, provided that they are given verbal permission by the Parent/Guardian to do so.
                </p>
                <p>
                  <b>6.3</b> All communication between the Student or Parent/Guardian and the Tutor is to be coursed through the official Viber group chat and will be disseminated by the Head Teacher to the respective party or parties involved.
                </p>
                <p>
                  <b>6.4</b> Tutor BASE Center does not discriminate in its tutorial service practices against any person because of race, color, nationality or ethnic origin, sex, gender, age, disability, or other factors.
                </p>

                <b>
                  <li>Obligations of the Parent/Guardian and Student </li>
                </b>
                <p className="mt-2">
                  <b>7.1</b> The Center’s goal is to partner with the Parent/Guardian in understanding and addressing the academic learning needs of the Student. The Center is here to work with you—not for you—to raise your child as a competent and diligent future leader. As such, the Parent/Guardian is highly encouraged to do their part in assisting the Tutor in identifying the Student’s learning style and problem areas in which the Student needs specific tutoring; being honest and transparent with feedback to improve the Center’s services; building the Student’s confidence in academics; and encouraging the Student to be diligent and focused in school, the Center, and beyond.
                </p>
                <p>
                  <b>7.2</b> Parents/Guardians and Students are requested to install the Viber application on their device and make Viber accounts in order to communicate with the Center. If the Parent/Guardian or Student has to relay any message or question to the Tutor, they are to course it through the Viber group chat.
                </p>
                <p>
                  <b>7.3</b> The Parent/Guardian is encouraged to be online while the Student’s tutorial session is ongoing to ensure that every need from the Student or their Tutor can be addressed in real time or as soon as possible (e.g. homework is not clear, books or notes forgotten or lost elsewhere, Student feels sick and requires assistance or medication, etc.).
                </p>
                <p>
                  <b>7.4</b> As the Center respects the Parent’s/Guardian’s and Student’s privacy and observes confidentiality, the Parent/Guardian and Student are requested to do the same for the Center, Head Teacher, and Tutor. The Center respectfully asks the Parent/Guardian and Student not to solicit personal information from the Tutor or Head Teacher, share any learning materials created and obtained from the Center, take and post pictures or recordings of tutorial sessions without the Tutor’s explicit consent, and disclose details about the business affairs and operations of the Center to other individuals.
                </p>
                <p>
                  <b>7.5</b> The Center strongly reiterates that the Parent/Guardian must read, observe, and follow all points stated in this contract, especially those regarding billing and cancellations.
                </p>

                <b>
                  <li>Termination</li>
                </b>
                <p className="mt-2">
                  <b>8.1</b> Either party may terminate this contract at any time by giving the other party at least seven (7) days or a week’s notice prior to the effectivity of the cancellation date. This is also to allow the Center to inform the Tutor concerned, and thus allow them to find other means of adding to their own livelihood.
                </p>
                <p>
                  <b>8.2</b> Please note that all tutorial fees, once settled, are non-refundable.
                </p>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermOfService;
