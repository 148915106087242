// got module isolation
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// redux
import { useSelector } from 'react-redux';

import NavbarDashboard from '@components/layouts/NavbarDashboard';
import HeaderDashboard from '@components/layouts/HeaderDashboard';

import Spinner from '@components/layouts/Spinner';
import DisplayPersonalInformation from '@components/jobApplication/DisplayPersonalInformation';
import DisplayEducationalBackground from '@components/jobApplication/DisplayEducationalBackground';
import DisplayEmploymentHistory from '@components/jobApplication/DisplayEmploymentHistory';
import DisplayApplicationForTutor from '@components/jobApplication/DisplayApplicationForTutor';
// import DisplayStatementAgreement from '@components/jobApplication/DisplayStatementAgreement';

// import '@styles/joinourteam.less';
import styles from './index.module.less';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const JobApplication = () => {
  const {
    tutorbase: {
      user: { jobs },
    },
  } = useSelector((state) => state);

  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const query = useQuery();
  const applicant = jobs?.find((job) => job.id === parseInt(query.get('applicant'), 10));

  const toPdf = (e) => {
    e.preventDefault();

    html2canvas(document.querySelector('#application')).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const doc = new jsPDF('p', 'mm');
      let position = 0;

      const imgWidth = 210;
      const pageHeight = doc.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width + 47;
      let heightLeft = imgHeight;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save(`tutor-application-${applicant.fullName}.pdf`);
    });

    setLoadingButton(true);
    setTimeout(() => setLoadingButton(false), 3000);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1600);
  });

  if (loading) return <Spinner />;

  return (
    <>
      <NavbarDashboard />
      <HeaderDashboard />
      <div className={`${styles['job-application-wrapper']} flex-col flex-center-center`}>
        <div className={`${styles['application-form-wrapper']}`}>
          <div className="container why-btn-wrapper d-flex justify-content-center mt-4 p-0">
            <button type="button" className="btn why-btn" onClick={(e) => toPdf(e)}>
              {loadingButton ? (
                <>
                  <i className="fa fa-spinner fa-spin" style={{ color: 'white' }} />{' '}
                  <Link className="mr-3" onClick={(e) => e.preventDefault()}>
                    DOWNLOADING...
                  </Link>
                </>
              ) : (
                <>
                  <Link className="mr-3" onClick={(e) => e.preventDefault()}>
                    DOWNLOAD PDF
                  </Link>{' '}
                  <i className="far fa-arrow-alt-circle-down" />
                </>
              )}
            </button>
          </div>

          <div className={`${styles['form-wrapper']} mt-5`} id="application">
            <h3>Tutor Application</h3>
            <form className={`${styles['application-form']} gap-3 mt-3`}>
              <DisplayPersonalInformation app={applicant} />
              <DisplayEducationalBackground app={applicant} />
              <DisplayEmploymentHistory app={applicant} />
              <DisplayApplicationForTutor app={applicant} />
            </form>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default JobApplication;
