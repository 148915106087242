import React, { useEffect } from 'react';

import styles from './index.module.less';
import '@styles/rates.less';

// import s1 from '@images/stock/gradeschool-boy.jpg';
// import s2 from '@images/stock/gradeschool-girl.jpg';

// import s3 from '@images/stock/jrhighschool-boy.jpg';
// import s4 from '@images/stock/jrhighshool-girl.jpg';
// import s5 from '@images/stock/srhighschool-boy.jpg';
// import s6 from '@images/stock/srhighschool-girl.jpg';

// import s7 from '@images/stock/college-boy.jpg';
import s8 from '@images/stock/college-girl.jpg';

// manually removed fragments here..
const SectionBecomeATutor = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className={`container-fluid ${styles['rates-wrapper']}`}>
      <div className={styles.col3} id="become-tutor">
        <h5>Become a Tutor</h5>

        <span className="rates">
          <h3>Join Our Team</h3>
        </span>
        <br />

        <img src={s8} alt="why-choose" style={{ width: '100%', height: 'auto', marginBottom: '2rem' }} />
        <br />
        <p //
          className={styles.subTitle}
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-delay="200">
          Tutor BASE Before and After-School Enrichment Center is looking for competent, hardworking, and patient teachers who can be a part of our growing team. <b>If you are passionate about helping children excel in their academics and would like to take part in Tutor BASE&apos;s ministry, you may send in your application below.</b> Come join the Tutor BASE team!
        </p>
      </div>
      <div className={styles.col4} id="become-tutor">
        <div //
          className="accordion"
          id="accordionExample"
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-delay="800">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Job Description
              </button>
            </h2>

            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li>Part-time position (independent contractor) for online teaching</li>
                  <li>Anywhere from 5:00pm-11:00pm on Mondays to Fridays, and 5:00am-11:00am on Saturdays, for a total of at least 5 hours a week</li>
                  <li>Schedule of teaching duty is flexible</li>

                  {/* <div className="accordionSpan">*Tutoring schedule may include Sundays upon special arrangement between the Head Teacher and the student or parent/guardian.</div> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Responsibilities
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li>Teach the required subject/s individually or in small groups (max. 5 students)</li>

                  <li>Do mastery checks in all subjects (short quizzes/exercises)</li>
                  <li>Guide and supervise students in their daily homework</li>
                  <li>Monitor and track progress of students</li>
                  <li>Inspire and motivate students</li>
                  {/* <li>Give feedback to parents about students’ performance and behavior (end-of-session report)</li> */}
                  <li>Support co-teachers (sub-teaching)</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Required Education, Skills, and Qualifications{' '}
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li>Has competent knowledge in teaching high school level English, Math, and/or Science</li>
                  <li>Can teach other subjects (e.g., Social Studies, etc.)</li>
                  <li>Skilled in written and verbal communication</li>
                  <li>We accept student teachers — those who are still in college or are about to graduate</li>
                  <li>No teaching experience required</li>
                  <li>Educational background from UP or other top universities in the country is preferred (not required)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionBecomeATutor;
