// got module isolation
import React from 'react';
import { Form, Input } from 'antd';

const { TextArea } = Input;

const AdditionalInfo = ({ styles }) => {
  return (
    <div className={styles.rowWrapper}>
      <h5>Additional Information, Comments, or Questions</h5>
      {/* <div className="input-group">
          <textarea type="text" className="form-control full experience" id="inputAdditional" value={data.additional} rows="5" onChange={(e) => setData({ ...data, additional: e.target.value })} />
        </div> */}

      <Form.Item className="form-itemz-j" label="Additional Information, Comments, or Questions" name="inputAdditional" rules={[{ required: true, message: 'Please input your additional information.' }]}>
        <TextArea maxLength={200} rows={4} />
      </Form.Item>
    </div>
  );
};

export default AdditionalInfo;
