import {
  //
  postBulkSession,
  deleteSession,
} from '@service/api/session';

import { swalError, swalSuccess } from '@utils/methods';

// types
// import * as types from '../types';

export const addBulkSession = (payload) => async () => {
  const res = await postBulkSession(payload);
  if (res.success) {
    // no need to call for now
    // dispatch({
    //   type: types.BULK_SESSION_ADD,
    //   payload: res.data,
    // });
    swalSuccess(res.msg, true);
  } else {
    swalError(res.msg);
  }

  return res;
};

export const removeSession = (payload) => async () => {
  const res = await deleteSession(payload);
  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};

// export const getSchedule = (payload) => async (dispatch) => {
//   // console.log('[GETSCHEDULE payload] ', payload);
//   const res = await postGetSchedule(payload);
//   // console.log('[GETSCHEDULE res] ', res);

//   if (res.success) {
//     dispatch({
//       type: types.SCHEDULE_LIST,
//       payload: res.data.sessions,
//     });
//   }

//   return res;
// };

// export const setNotif = (payload) => async (dispatch) => {
//   const res = await putSetNotif(payload);
//   // console.log('[SETNOTIF] ', res);

//   if (res.success) {
//     dispatch({
//       type: types.NOTIF_SET,
//       payload: res.data,
//     });
//     swalSuccess('Sucessfully updated notification settings!');
//   }

//   return res;
// };

// export const getHistory = (payload) => async (dispatch) => {
//   const res = await postGetHistory(payload);
//   // console.log('[GETHISTORY] ', res);

//   if (res.success) {
//     dispatch({
//       type: types.SCHEDULE_HISTORY,
//       payload: res.data.sessions,
//     });
//     // swalSuccess('Sucessfully updated notification!');
//   }

//   return res;
// };

// export const saveEdit = (payload) => async (dispatch) => {
//   const res = await putSaveEdit(payload);
//   // console.log('[SAVEDIT] ', res);

//   if (res.success) {
//     dispatch({
//       type: types.SCHEDULE_EDIT,
//       payload: res.data.data,
//     });
//     swalSuccess('Sucessfully updated schedule!');
//   }

//   return res;
// };
