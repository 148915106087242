import React, { useEffect } from 'react';
import AOS from 'aos';
import axios from 'axios';

import store from '@redux/store';
import { common } from '@redux/combineActions';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Footer from '@components/layouts/Footer';
import ScrollUp from '@components/ScrollUp';
import ScrollToTop from '@components/ScrollToTop';
import { checkToken, decode } from '@utils/methods';

import reportWebVitals from './reportWebVitals';

import PrivateRoute from './utils/routing/PrivateRoute';
import ResetScroll from './utils/routing/ResetScroll';
import LandingPage from './screens/LandingPage';
import HomePage from './screens/HomePage';
import MainPage from './screens/MainPage';
import AboutPage from './screens/AboutPage';
import JoinOurTeam from './screens/JoinOurTeam';
import ServicePage from './screens/ServicePage';
import TestimonialPage from './screens/TestimonialPage';
// import LoginPage from './screens/LoginPage';
// import ScrollToTop from './components/layouts/ScrollToTop';
import Settings from './screens/Settings';
import Reports from './screens/Reports';
import Program from './screens/Program';
import Payments from './screens/Payments';
import Checkout from './screens/Checkout';
// import Gdrive from './screens/Gdrive';
import PrivacyPolicy from './screens/PrivacyPolicy';
import JobApplication from './screens/JobApplication';
import TutorsHonorsCode from './screens/TutorsHonorsCode';
import TermOfService from './screens/TermOfService';

const token = localStorage.getItem('token');
const account = JSON.parse(localStorage.getItem('account'));
const localDetails = JSON.parse(localStorage.getItem('localDetails'));

if (token) {
  const decodedToken1 = decode(token);
  if (checkToken(decodedToken1)) {
    store.dispatch(common.user.userLogout());
    window.location.href = '/';
  } else {
    store.dispatch(common.user.setUserDetails(account));
    store.dispatch(common.user.setRole(account));
    store.dispatch(common.user.setTutorBaseDetails(localDetails));
    common.user.setAuthorizationHeader(token);
    store.dispatch(common.user.fetchUpdatedDetails());
  }
} else {
  axios.defaults.headers.common.Authorization = `Bearer`;
}

const App = () => {
  useEffect(() => {
    reportWebVitals((metrics) => {
      // console.log('[WV] ', metrics);
      const { name, delta, id } = metrics;
      window.gtag('event', name, {
        event_category: 'Web Vitals',
        event_label: id,
        value: delta,
        non_interaction: true,
      });
    });

    AOS.init({
      once: true,
    });

    if (window.pageYOffset === 0) {
      AOS.refresh();
    }
  }, []);

  return (
    <Router basename={process.env.REACT_APP_ROUTER_BASE || ''}>
      <ScrollToTop />
      <ResetScroll />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/index" component={LandingPage} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/joinourteam" component={JoinOurTeam} />
        <Route exact path="/services" component={ServicePage} />
        <Route exact path="/testimonials/:id" component={TestimonialPage} />
        <Route exact path="/testimonials" component={TestimonialPage} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        {/* <Route exact path="/login" component={LoginPage} /> */}
        <PrivateRoute exact path="/main" component={MainPage} />
        <PrivateRoute exact path="/dashboard" component={HomePage} />
        {/* <Route exact path="/gdrive" component={Gdrive} /> */}
        <PrivateRoute exact path="/reports" component={Reports} />
        <PrivateRoute exact path="/program" component={Program} />
        <PrivateRoute exact path="/payments" component={Payments} />
        <PrivateRoute exact path="/checkout" component={Checkout} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute exact path="/jobapplication" component={JobApplication} />
        <PrivateRoute exact path="/honorscode" component={TutorsHonorsCode} />
        <PrivateRoute exact path="/tos" component={TermOfService} />
      </Switch>

      {/* <ScrollToTop /> */}
      <ScrollUp />
      <Footer />
    </Router>
  );
};

export default App;
