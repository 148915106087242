import {
  //
  postAddSchedule,
  postGetSchedule,
  putSetNotif,
  getHistory,
  putSaveEdit,
  deleteSchedule,
  resetSchedule,
} from '@service/api/schedule';

import { convertMomentWithFormat, convertQueryString, swalError, swalSuccess } from '@utils/methods';

// types
import * as types from '../types';

export const addSchedule = (payload) => async (dispatch) => {
  const res = await postAddSchedule(payload);

  if (res.success) {
    if (res.data.type === 'SESSION_ADD') {
      dispatch({
        type: types.SESSION_ADD,
        payload: res.data.session,
      });
      swalSuccess(res.msg);
    } else {
      dispatch({
        type: types.SCHEDULE_ADD,
        payload: res.data.schedule,
      });
      swalSuccess(res.msg);
    }
  } else {
    swalError(res.msg);
  }

  return res;
};

export const getSchedule = (payload) => async (dispatch) => {
  // console.log('[GETSCHEDULE payload] ', payload);
  const res = await postGetSchedule(payload);
  // console.log('[GETSCHEDULE res] ', res);

  if (res.success) {
    dispatch({
      type: types.SCHEDULE_LIST,
      payload: res.data.sessions,
    });
  }

  return res;
};

export const setNotif = (payload) => async (dispatch) => {
  const res = await putSetNotif(payload);
  // console.log('[SETNOTIF] ', res);

  if (res.success) {
    dispatch({
      type: types.NOTIF_SET,
      payload: res.data,
    });
    swalSuccess('Sucessfully updated notification settings!');
  }

  return res;
};

export const fetchHistory = (payload) => async (dispatch) => {
  const { fromDate } = payload;
  const mvtatedDate = convertMomentWithFormat(fromDate, 'YYYY-MM-DD');
  const mvtatedPayload = convertQueryString({ fromDate: mvtatedDate });

  const res = await getHistory(mvtatedPayload);
  const { data, success } = res;

  if (success) {
    dispatch({
      type: types.SCHEDULE_HISTORY,
      payload: { sessions: data.sessions, fromDate },
    });
  }
  return res;
};

export const saveEdit = (payload) => async (dispatch) => {
  const res = await putSaveEdit(payload);
  // console.log('[SAVEDIT] ', res);

  if (res.success) {
    dispatch({
      type: types.SCHEDULE_EDIT,
      payload: res.data.data,
    });
    swalSuccess('Sucessfully updated schedule!');
  }

  return res;
};

export const resetScheduleById = (payload) => async () => {
  const res = await resetSchedule(payload);
  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }
  return res;
};

export const deleteScheduleById = (payload) => async () => {
  const res = await deleteSchedule(payload);
  // console.log('[SCHEDULEDELETE] ', res);
  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};
