import React, { useState, useEffect, useCallback } from 'react';

// antd
import { Button, Card, Col, Table } from 'antd';

// antd icons
import { EditOutlined } from '@ant-design/icons';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { common, tutorbase } from '@redux/combineActions';

// hooks
import useOpener from '@hooks/useOpener';
import useWindowSize from '@hooks/useWindowSize';

import { useDebounce } from '@utils/methods';

// component
import ZoomModal from '../../components/ZoomModal';

// utils
import { zoomListColumn } from '../../utils';

// styling
import styles from './index.module.less';

const initialState = {
  meetingID: '',
  meetingPasscode: '',
  meetingURL: '',
  SessionId: '',
};

const ZoomListSection = () => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const { isOpen: isZoomModalOpen, toggleOpen: toggleZoomModalOpen } = useOpener();
  const {
    tutorbase: {
      user: { zoom },
    },
  } = useSelector((state) => {
    console.log('[STATE] ', state);
    return state;
  });

  const [zoomList, setZoomList] = useState([]);
  const [data, setData] = useState(initialState);
  const [showTable, setShowTable] = useState(false);

  const handleGetZoomList = useCallback(() => {
    console.log('[ZOOM] ', zoom);
    setZoomList(zoom);
  }, [zoom]);

  useEffect(() => {
    handleGetZoomList();
  }, [handleGetZoomList]);

  const handleUpdate = useDebounce((payload) => {
    toggleZoomModalOpen();

    console.log('[HANDLEUPDATE] ', payload);

    if (payload.SessionId) {
      dispatch(tutorbase.zoom.zoomEdit({ ...payload, id: payload.SessionId })).then(async (res) => {
        const { success } = res;
        if (success) {
          const resv = await dispatch(common.user.fetchUpdatedDetails());
          if (resv.success) {
            handleGetZoomList();
          }
        }
      });
    }
  }, 300);

  const renderTableView = () => {
    return (
      <Table
        style={{ display: showTable ? 'block' : 'none' }}
        rowKey="id"
        dataSource={zoomList}
        columns={zoomListColumn()}
        pagination={false}
        loading={false}
        onRow={(record) => {
          return {
            onClick: () => {
              setData(record);
              toggleZoomModalOpen();
            },
          };
        }}
      />
    );
  };

  const renderCardView = () => {
    return (
      <div className={styles.cardWrapper}>
        {zoomList.map((u, idx) => (
          <Card key={idx} className={styles.cardRow}>
            <Col span={24}>
              <h4>Meeting ID: {u.meetingID}</h4>
              <span>Passcode: {u.meetingPasscode}</span>
              <br />
              <span>Meeting URL: {u.meetingURL}</span>
              <br />

              <div className={styles.ctaWrapper}>
                <Button
                  className={styles.btnLeft}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setData(u);
                    toggleZoomModalOpen();
                  }}
                />
              </div>
            </Col>
          </Card>
        ))}
      </div>
    );
  };

  useEffect(() => setShowTable(!isMobile), []);

  // if (!zoomList || zoomList.length === 0) return null;

  return (
    <>
      <div className={styles.contentWrapper}>{isMobile ? renderCardView() : renderTableView()}</div>

      <ZoomModal //
        visible={isZoomModalOpen}
        onCancel={toggleZoomModalOpen}
        handleUpdate={handleUpdate}
        data={data}
      />
    </>
  );
};

export default ZoomListSection;
