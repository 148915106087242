import React, { useCallback, useEffect, useState } from 'react';
import { startOfWeek, format, subDays, addDays, add, set } from 'date-fns';
import _ from 'lodash';
// import moment from 'moment';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { common, tutorbase } from '@redux/combineActions';

// hooks
import useOpener from '@hooks/useOpener';

import { useDebounce } from '@utils/methods';
import constants from '@utils/constants';

import AddSlotModal from '../modal/AddSlotModal';
import EditSlotModal from '../modal/EditSlotModal';
import ViewSlotModal from '../modal/ViewSlotModal';

// styling
import styles from './index.module.less';

// Define start and end times
const startTime = 8;
const endTime = 21;

const weekMatrix = {
  0: 'Mon',
  1: 'Tue',
  2: 'Wed',
  3: 'Thu',
  4: 'Fri',
  5: 'Sat',
  6: 'Sun',
};

const Calendar = () => {
  const dispatch = useDispatch();
  const { isOpen: isAddSlotOpen, toggleOpen: toggleAddSlotOpen } = useOpener();
  const { isOpen: isEditSlotOpen, toggleOpen: toggleEditSlotOpen } = useOpener();
  const { isOpen: isViewSlotOpen, toggleOpen: toggleViewSlotOpen } = useOpener();
  const {
    common: {
      user: { account },
    },
    tutorbase: {
      user: { students, tutors },
      calendar: { schedules, sessions },
    },
  } = useSelector((state) => {
    // console.log('[CALENDAR STATE] ', state);
    return state;
  });

  const [data, setData] = useState({
    // day: '',
    // time: '',
    // date: '',
    slotId: '',
    // slotNumber: '',
    // dateId: '',
    StudentId: '',
    TutorId: '',
    subject: '',
    hours: 1,
  });

  const [info, setInfo] = useState();
  const [week, setWeek] = useState(0);
  const [arrowsTouched, setArrowsTouched] = useState(false);

  const filterSessionsByAccount = (v) => {
    // console.log('[filterSessionsByAccount] ', v);
    let resv = true;
    if (account.role === constants.ROLE_TYPE.HEAD) resv = true;
    if (account.role === constants.ROLE_TYPE.TUTOR) resv = v.Schedules.map((s) => s.TutorId).includes(account.id);
    if (account.role === constants.ROLE_TYPE.STUDENT) resv = v.StudentId === account.id;
    return resv;
  };

  const fetchData = useCallback(() => {
    if (sessions) {
      const actives = document.querySelectorAll('.active-event');
      // console.log('[ACTIVES] ', actives);
      actives.forEach((a) => {
        a.firstChild.textContent = '';
        a.querySelector('span').textContent = '';
        a.classList.remove('active-event');
        a.dataset.target = '#addSlotModal';
        a.innerHTML = '';
      });

      const mvtatedSessions = sessions.filter((v) => {
        let resv = false;
        if (account.role === constants.ROLE_TYPE.HEAD) resv = true;
        if (account.role === constants.ROLE_TYPE.TUTOR) resv = v.Schedules.map((s) => s.TutorId).includes(account.id);
        if (account.role === constants.ROLE_TYPE.STUDENT) resv = v.StudentId === account.id;
        return resv;
      });

      /* for alternate coloring
      const fetchColorClass = (s, t) => {
        let resvlt = 'active-event';
        console.log('[S] ', s, s % 2);
        console.log('[T] ', t, t % 2);
        console.log('[/////////]');

        if (s % 2 === 0) {
          if (t % 2 === 0) {
            resvlt = `hacktiv ${styles.lightgray}`;
          }
        } else {
          if (t % 2 === 1) {
            resvlt = `hacktiv ${styles.lightgray}`;
          }
        }

        return resvlt;
      };
      */

      mvtatedSessions.map((sess) => {
        // console.log('[SESSION] ', sess);
        sess.Schedules.filter((sz) => {
          if (account.role === constants.ROLE_TYPE.TUTOR) {
            return sz.TutorId === account.id;
          }
          return true;
        }).map((scd) => {
          const slots = scd.slotId !== null ? JSON.parse(JSON.parse(scd.slotId)) : [];
          _.flatten(slots).map((slt) => {
            const { slot, day, time, date } = slt;
            const htmlId = `${slot}${day}${time}-${date}`;

            if (document.getElementById(htmlId) !== null) {
              document.getElementById(htmlId).className = 'active-event'; // fetchColorClass(slot, time);
              document.getElementById(htmlId).style.cursor = 'pointer';
              document.getElementById(htmlId).id = htmlId;
              document.getElementById(htmlId).dataset.target = '#viewSlotModal';
              // document
              //   .getElementById(slt)
              //   .addEventListener("click", (slt) => clicked(slt));
              document.getElementById(htmlId).innerHTML = `<p id=${htmlId}>${scd.Tutor?.name.slice(0, 10)}</p> <span>|<span/> <p id=${htmlId}>${sess.Student?.name.slice(0, 10)}</p>`;
            }
            return slt;
          });
          return scd;
        });
        return sess;
      });
    }
  }, [account, sessions]);

  const getScheduleHistory = useDebounce((e) => {
    // console.log('[getScheduleHistory data info week]]]]] ', data, info, week);
    setArrowsTouched(true);
    const date = startOfWeek(new Date(), {
      weekStartsOn: 1,
    });

    let fromDate;
    if (e.target.id === 'prev') {
      // console.log('[PREV date] ', date);
      // console.log('[PREV week] ', week - 1);
      if (week >= 2) {
        setWeek(week - 1);
        fromDate = addDays(new Date(date), (week - 1) * 7);
        // console.log('[PREVA fromDate] ', fromDate);
      } else {
        setWeek(week - 1);
        fromDate = subDays(new Date(date), Math.abs(week - 1) * 7);
        // console.log('[PREVB fromDate] ', fromDate);
      }
      dispatch(tutorbase.schedule.fetchHistory({ fromDate }));
    } else {
      setWeek(week + 1);
      fromDate = addDays(new Date(date), (week + 1) * 7);
      // console.log('[NEXT date] ', date);
      // console.log('[NEXT week] ', week + 1);
      // console.log('[NEXT fromDate] ', fromDate);
      dispatch(tutorbase.schedule.fetchHistory({ fromDate }));
    }
  }, 300);

  const getDoneTime = () => {
    let done = 0;
    sessions.forEach((sess) => {
      if (account.role === constants.ROLE_TYPE.HEAD) {
        done += sess.doneTime;
      }
      if (account.role === constants.ROLE_TYPE.TUTOR) {
        sess.Schedules.map((sz) => {
          if (sz.isDone && sz.hours > 0 && sz.TutorId === account.id) {
            done += sz.hours;
          }
          return sz;
        });
      }
      if (account.role === constants.ROLE_TYPE.STUDENT) {
        sess.Schedules.map((sz) => {
          if (sz.isDone && sz.hours > 0 && sz.StudentId === account.id) {
            done += sz.hours;
          }
          return sz;
        });
      }
    });

    return done;
  };

  const dateBomb = (n, l) => {
    const date = startOfWeek(new Date(), {
      weekStartsOn: 1,
    });

    let currentWeek = date;
    if (week > 0) {
      currentWeek = addDays(new Date(date), week * 7);
    }
    if (week < 0) {
      currentWeek = subDays(new Date(date), Math.abs(week) * 7);
    }

    if (l === 'short') {
      const d = addDays(new Date(currentWeek), n);
      return format(new Date(d), 'MMM dd');
    }

    const d = add(new Date(currentWeek), { days: n });
    const dt = set(new Date(d), { hours: l + 12, minutes: 0, seconds: 0 });
    return dt;
  };

  const isSlotExisting = (v, b) => {
    const slotIds = JSON.parse(JSON.parse(v));
    const resvlt = slotIds.find((s) => JSON.stringify(s) === JSON.stringify(b));
    return resvlt !== undefined;
  };

  /*
  const extractSlotId = (v, b) => {
    const slotIds = JSON.parse(JSON.parse(v));
    const resvlt = slotIds.find((s) => JSON.stringify(s) === JSON.stringify(b));
    return resvlt;
  };
  */

  const handleClick = (e, slotNumber, slotId, d, day, time) => {
    console.log('[handleClick e.target.id] ', e.target.id);
    console.log('[handleClick slotNumber] ', slotNumber);
    console.log('[handleClick slotId FFFF] ', slotId);
    console.log('[handleClick d] ', d);
    console.log('[handleClick day] ', day);
    console.log('[handleClick time] ', time);

    const mutatedSessions = sessions.filter(filterSessionsByAccount);
    const scheds = mutatedSessions.map((sess) => sess.Schedules);

    const slot = _.flatten(scheds).find((s) => s.slotId !== null && isSlotExisting(s.slotId, slotId) && s.day === day);
    console.log('[S L O T]]]]] ', slot);

    setData({
      ...data,
      // slotNumber,
      // day,
      // time,
      // date: dateBomb(d, time),
      slotId,
      // dateId: dateBomb(d, 'short').replace(' ', ''),
    });

    if (slot) {
      console.log('[SLOT SLOTID] ', slot, slotId);
      setInfo({
        ...slot,
        slotId,
      });
    }

    if (slot && account.role !== constants.ROLE_TYPE.HEAD) toggleViewSlotOpen();
    if (slot && account.role === constants.ROLE_TYPE.HEAD) toggleEditSlotOpen();
    if (!slot && account.role === constants.ROLE_TYPE.HEAD) toggleAddSlotOpen();
  };

  const closeBtn = () => {
    const options = document.querySelectorAll('.form-select option');
    for (let i = 0, l = options.length; i < l; i += 1) {
      options[i].selected = options[i].defaultSelected;
    }
  };

  const editSchedule = (v) => {
    dispatch(tutorbase.schedule.saveEdit(v));
    toggleViewSlotOpen();
  };

  const resetSchedule = (v) => {
    toggleEditSlotOpen();
    dispatch(tutorbase.schedule.resetScheduleById(v)).then(() => {
      dispatch(common.user.fetchUpdatedDetails()).then(() => {
        setWeek(0);
        setData({
          // day: '',
          // time: '',
          // slotNumber: '',
          // date: '',
          slotId: '',
          // dateId: '',
          StudentId: '',
          TutorId: '',
          subject: '',
          hours: 1,
        });
        const options = document.querySelectorAll('.form-select option');
        for (let i = 0, l = options.length; i < l; i += 1) {
          options[i].selected = options[i].defaultSelected;
        }
      });
    });
  };

  const submitSchedule = (v) => {
    dispatch(
      tutorbase.schedule.addSchedule({
        //
        ...data,
        ...v,
      })
    ).then(() => {
      dispatch(common.user.fetchUpdatedDetails()).then(() => {
        setData({
          // day: '',
          // time: '',
          // slotNumber: '',
          // date: '',
          slotId: '',
          // dateId: '',
          StudentId: '',
          TutorId: '',
          subject: '',
          hours: 1,
        });

        const options = document.querySelectorAll('.form-select option');
        for (let i = 0, l = options.length; i < l; i += 1) {
          options[i].selected = options[i].defaultSelected;
        }

        toggleAddSlotOpen();
        setWeek(0);
      });
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (week === 0 && !arrowsTouched) {
      const date = startOfWeek(new Date(), {
        weekStartsOn: 1,
      });

      // show the next week set
      // setWeek(week);
      setWeek(week + 1);
      const fromDate = addDays(new Date(date), (week + 1) * 7);
      // const fromDate = subDays(new Date(date), Math.abs(week) * 7);
      dispatch(tutorbase.schedule.fetchHistory({ fromDate }));
    }
  }, [dispatch, week]);

  if (!sessions) return null;

  const fetchTime = (h, ts, te) => {
    let resvlt = '';
    if (h > 12) {
      const pmHour = h === 12 ? 12 : h - 12;
      const pmTimeSlotStart = `${pmHour}pm`;
      const pmTimeSlotEnd = `${pmHour + 1}pm`;
      resvlt = `${pmTimeSlotStart} - ${pmTimeSlotEnd}`;
    } else {
      resvlt = `${ts} - ${te}`;
    }
    return resvlt;
  };

  const circleFive = (hour, timeSlotStart, timeSlotEnd, tugatog) => {
    Array.from(Array(5), (x, s) => {
      const dateRange = fetchTime(hour, timeSlotStart, timeSlotEnd);
      tugatog.push(
        <tr className="">
          {/* available time */}
          <td className={s % 5 === 0 ? `${styles.time} ${styles.block}` : `${styles.time}`} id={`slot-${s}`}>
            {s === 2 && dateRange}
          </td>

          {Array.from(Array(7), (e, d) => {
            const slot = s + 1;
            const day = weekMatrix[d];
            const time = hour;
            const date = dateBomb(d, 'short').replace(' ', '');

            // MUST BE CHANGED TO A MORE DECENT ONE!
            const id = `${slot}${day}${time}-${date}`;
            const slotID = { slot, day, time, date };

            return (
              <td //
                key={d}
                className={s % 5 === 0 ? `${styles.slot} ${styles.block}` : `${styles.slot}`}>
                <div //
                  id={id}
                  style={{ cursor: 'pointer' }}
                  className=""
                  onClick={(f) => handleClick(f, slot, slotID, d, day, time)}
                  // handleClick = (e, slotNumber, slotId, d, day, time) => {
                />
              </td>
            );
          })}
        </tr>
      );
      return x;
    });
  };

  const renderTabla = () => {
    const tugatog = [];
    let hour = startTime;

    do {
      let timeSlotStart = `${hour}am`;
      let timeSlotEnd = `${hour + 1}am`;

      timeSlotStart = timeSlotStart.replace('12am', '12pm');
      timeSlotStart = timeSlotStart.replace('13am', '1pm');

      timeSlotEnd = timeSlotEnd.replace('12am', '12pm');
      timeSlotEnd = timeSlotEnd.replace('13am', '1pm');

      circleFive(hour, timeSlotStart, timeSlotEnd, tugatog);

      hour += 1;
    } while (hour < endTime);

    return tugatog;
  };

  return (
    <>
      <div className={styles.calendarWrapper}>
        <div className={styles.calendarInfo}>
          <div className="d-flex gap-2 align-items-center">
            <button type="button" className={styles.prev} id="prev" onClick={(e) => getScheduleHistory(e)}>
              <i className="fas fa-chevron-left" id="prev" onClick={(e) => getScheduleHistory(e)} />
            </button>
            <button type="button" className={styles.next} id="next" onClick={(e) => getScheduleHistory(e)}>
              <i className="fas fa-chevron-right" id="next" onClick={(e) => getScheduleHistory(e)} />
            </button>
          </div>

          {account.role !== constants.ROLE_TYPE.HEAD ? (
            <div className={styles.totalHours}>
              <i className="far fa-check-circle" /> Total Tutored Hours: <span>{getDoneTime()} hours</span>
            </div>
          ) : null}
        </div>

        <div className={`${styles.calendar} mt-3`}>
          <table className={`${styles.headerTable} sticky-top`}>
            <tr>
              <th className={styles.htime}>Time </th>
              <th className={styles.thead}>
                Mon <br /> <span>{dateBomb(0, 'short')}</span>
              </th>
              <th className={styles.thead}>
                Tue <br />
                <span>{dateBomb(1, 'short')}</span>
              </th>
              <th className={styles.thead}>
                Wed <br />
                <span>{dateBomb(2, 'short')}</span>
              </th>
              <th className={styles.thead}>
                Thu <br />
                <span>{dateBomb(3, 'short')}</span>
              </th>
              <th className={styles.thead}>
                Fri <br />
                <span>{dateBomb(4, 'short')}</span>
              </th>
              <th className={styles.thead}>
                Sat <br />
                <span>{dateBomb(5, 'short')}</span>
              </th>
              <th className={styles.thead}>
                Sun <br />
                <span>{dateBomb(6, 'short')}</span>
              </th>
            </tr>
          </table>
          <table>{renderTabla()}</table>
        </div>
      </div>

      <AddSlotModal //
        students={students ?? []}
        schedules={schedules ?? []}
        sessions={sessions ?? []}
        tutors={tutors ?? []}
        data={data}
        setData={setData}
        submitSchedule={submitSchedule}
        closeBtn={closeBtn}
        visible={isAddSlotOpen}
        onCancel={toggleAddSlotOpen}
      />
      <EditSlotModal //
        account={account}
        resetSchedule={resetSchedule}
        info={info}
        onCancel={toggleEditSlotOpen}
        saveEdit={editSchedule}
        schedules={schedules}
        sessions={sessions ?? []}
        students={students ?? []}
        tutors={tutors}
        visible={isEditSlotOpen}
      />

      <ViewSlotModal //
        info={info}
        account={account}
        sessions={sessions ?? []}
        visible={isViewSlotOpen}
        onCancel={toggleViewSlotOpen}
      />
    </>
  );
};

export default Calendar;
