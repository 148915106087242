import React from 'react';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import 'react-alice-carousel/lib/alice-carousel.css';

// // to connect our app to store
import { Provider } from 'react-redux';
import store from '@redux/store';

import './App.css';
import App from './App';

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);
ReactDOM.render(app, document.getElementById('root'));

// registerServiceWorker();

// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import { HashRouter } from 'react-router-dom';
// import 'antd/dist/antd.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

// // // to connect our app to store
// import { Provider } from 'react-redux';
// import configureStore from '@redux/store';

// // redux-persist
// import { PersistGate } from 'redux-persist/integration/react';

// const { persistor, store } = configureStore();

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <HashRouter>
//           <App />
//         </HashRouter>
//       </PersistGate>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
