import { PATCH, PUT } from '../request';

export async function putZoomEdit(payload) {
  const { id, ...rest } = payload;
  return PUT(`zooms/${id}`, rest);
}

export async function putZoomToggle(payload) {
  const { id, ...rest } = payload;
  // console.log('[PUT ZOOM TOGGLE] ', payload, id, rest);
  return PATCH(`zooms/visible-link/${id}`, rest);
}
