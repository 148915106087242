// got module isolation
import React, { useState, useEffect } from 'react';

import Banner from '@components/Banner';
import Navbar from '@components/layouts/Navbar';

import Spinner from '@components/layouts/Spinner';
import SectionBecomeATutor from '@components/joinOurTeam/SectionBecomeATutor';
import SectionApplicationForm from '@components/joinOurTeam/SectionApplicationForm';

// import '@styles/joinourteam.less';
import styles from './index.module.less';

const JoinOurTeam = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scroll(0, 0);
    setTimeout(() => setLoading(false), 900);
  }, []);

  if (loading) return <Spinner />;

  return (
    <div className={styles['join-our-team-wrapper']}>
      <Navbar />
      <Banner title="Join Our Team" />
      <SectionBecomeATutor />
      <SectionApplicationForm />
    </div>
  );
};

export default JoinOurTeam;
