import React, { useState, useEffect, useCallback } from 'react';

// antd
import { Button, Card, Col, Modal, Table } from 'antd';

// antd icons
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { common, tutorbase } from '@redux/combineActions';

// hooks
import useOpener from '@hooks/useOpener';
import useWindowSize from '@hooks/useWindowSize';

import { useDebounce } from '@utils/methods';

// import constants from '@utils/constants';

// component
// import PageHeaderContainer from '@components/common/PageHeaderContainer';
// import LabelListHeader from '../../components/LabelListHeader';
// import LabelListFilterContainer from '../../components/LabelListFilterContainer';
import RegisterModal from '../../components/RegisterModal';

// utils
import { studentListColumn, tutorListColumn } from '../../utils';

// styling
import styles from './index.module.less';

const { confirm } = Modal;

const initialState = {
  firstName: '',
  lastName: '',
  nickName: '',
  prefPronoun: '',
  dob: '',
  email: '',
  phone: '',
  school: '',
  grade: '',
  address: '',
  city: '',
  country: '',
  international: false,
  parentsFirstName1: '',
  parentsLastName1: '',
  parentsEmail1: '',
  parentsPhone1: '',

  parentsFirstName2: '',
  parentsLastName2: '',
  parentsEmail2: '',
  parentsPhone2: '',

  primaryTutor: [],
  primaryStudents: [],
};

const UserListSection = () => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const { isOpen: isRegisterModalOpen, toggleOpen: toggleRegisterModalOpen } = useOpener();
  const {
    tutorbase: {
      user: {
        students,
        tutors,
        driveLink: { rt },
      },
    },
  } = useSelector((state) => state);

  const [check, setCheck] = useState('student');
  const [userList, setUserList] = useState([]);
  const [data, setData] = useState(initialState);
  const [showTable, setShowTable] = useState(false);

  const handleGetUserList = useCallback(() => {
    const vList = check === 'student' ? students : tutors;

    setUserList(
      vList.map((u) => ({
        ...u,
        primaryStudents: u.primaryStudents ? u.primaryStudents.split(',') : undefined,
        primaryTutor: u.primaryTutor ? u.primaryTutor.split(',') : undefined,
        email: u.User.email,
      }))
    );
  }, [check, students, tutors]);

  useEffect(() => {
    handleGetUserList();
  }, [handleGetUserList]);

  const handleAddUser = useDebounce((payload) => {
    // console.log('[HANDLE_ADD_USER] ', payload);
    toggleRegisterModalOpen();
    if (payload.id) {
      // console.log('[HANDLE_ADD_USER EDIT_YO] ', payload);
      dispatch(check === 'student' ? tutorbase.user.studentUpdate({ ...payload, primaryTutor: payload.primaryTutor ? payload.primaryTutor.toString() : '' }) : tutorbase.user.tutorUpdate({ ...payload, primaryStudents: payload.primaryStudents ? payload.primaryStudents.toString() : '' })).then(async (res) => {
        const { success } = res;
        if (success) {
          const resv = await dispatch(common.user.fetchUpdatedDetails());
          if (resv.success) {
            handleGetUserList();
          }
        }
      });
    } else {
      dispatch(check === 'student' ? tutorbase.user.studentRegister({ ...payload, primaryTutor: payload.primaryTutor ? payload.primaryTutor.toString() : '' }) : tutorbase.user.tutorRegister({ ...payload, primaryStudents: payload.primaryStudents ? payload.primaryStudents.toString() : '' })).then(async (res) => {
        const { success } = res;
        if (success) {
          if (check === 'student') {
            dispatch(tutorbase.drive.createStudentDirectory({ directoryName: `${payload.firstName} ${payload.lastName}`, rt }));
          }
          const resv = await dispatch(common.user.fetchUpdatedDetails());
          if (resv.success) {
            handleGetUserList();
          }
        }
      });
    }
  }, 300);

  const openRegisterForm = useDebounce(() => {
    setData(initialState);
    toggleRegisterModalOpen();
  }, 300);

  const handleRemoveUser = useDebounce((record) => {
    dispatch(tutorbase.user.deleteUserById(record.UserId)).then(async () => {
      const resv = await dispatch(common.user.fetchUpdatedDetails());
      if (resv.success) {
        handleGetUserList();
      }
    });
  }, 300);

  const handleShowConfirmDeleteModal = (v) => {
    confirm({
      title: 'Delete this record?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleRemoveUser(v);
      },
      onCancel() {
        return null;
      },
    });
  };

  const renderTableView = () => {
    return (
      <Table
        style={{ display: showTable ? 'block' : 'none' }}
        rowKey="UserId"
        dataSource={userList}
        columns={check === 'student' ? studentListColumn(handleRemoveUser) : tutorListColumn(handleRemoveUser)}
        pagination={false}
        loading={false}
        onRow={(record) => {
          return {
            onClick: () => {
              setData(record);
              toggleRegisterModalOpen();
            },
          };
        }}
      />
    );
  };

  const renderCardView = () => {
    return (
      <div className={styles.cardWrapper}>
        {userList.map((u, idx) => (
          <Card key={idx} className={styles.cardRow}>
            <Col span={24}>
              <h4>Name: {u.name}</h4>
              <span>Email: {u.email}</span>
              <br />
              <span>Contact No: {u.phone}</span>
              <br />

              <div className={styles.ctaWrapper}>
                <Button
                  className={styles.btnLeft}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setData(u);
                    toggleRegisterModalOpen();
                  }}
                />

                <Button
                  icon={<DeleteOutlined />}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleShowConfirmDeleteModal(u);
                  }}
                />
              </div>
            </Col>
          </Card>
        ))}
      </div>
    );
  };

  useEffect(() => setShowTable(!isMobile), []);

  return (
    <>
      {/* <LabelListHeader showModal={handleShowAddLabelModal} /> */}

      <div className={styles.contentWrapper}>
        {isMobile ? (
          <>
            <div className={styles.controlWrapper}>
              <div className="form-check" onClick={(e) => setCheck(e.target.value)}>
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="student" checked={check === 'student'} />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Student{' '}
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Teacher
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="teacher" checked={check === 'teacher'} onClick={(e) => setCheck(e.target.value)} />
                </label>
              </div>
            </div>
            <div className="form-check d-flex">
              <button type="button" className={`btn list-btn-primary ${styles.controlButton}`} onClick={openRegisterForm}>
                {check === 'student' ? 'Register Student' : 'Register Tutor'}
              </button>
            </div>
          </>
        ) : (
          <div className="flex-center-start mb-3">
            <div className="form-check" onClick={(e) => setCheck(e.target.value)}>
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="student" checked={check === 'student'} />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Student{' '}
              </label>
            </div>

            <div className="form-check">
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Teacher
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="teacher" checked={check === 'teacher'} onClick={(e) => setCheck(e.target.value)} />
              </label>
            </div>

            <div className="form-check">
              <button type="button" className="btn list-btn-primary" onClick={openRegisterForm}>
                {check === 'student' ? 'Register Student' : 'Register Tutor'}
              </button>
            </div>
          </div>
        )}

        {/* <LabelListFilterContainer
          name={name}
          pageDetails={pageDetails}
          pageSize={pageSize}
          setPageSize={handleChangePageSize}
          setName={handleSetName}
        /> */}

        {isMobile ? renderCardView() : renderTableView()}

        {/* <div className="label-list-pagination">
          <Pagination
            current={pageDetails.pageIndex}
            total={pageDetails.totalItem}
            pageSize={pageSize}
            onChange={handleChangePageIndex}
            hideOnSinglePage
            showSizeChanger={false}
          />
        </div> */}
      </div>

      <RegisterModal //
        check={check}
        students={students}
        tutors={tutors}
        visible={isRegisterModalOpen}
        onCancel={toggleRegisterModalOpen}
        addUser={handleAddUser}
        data={data}
      />
    </>
  );
};

export default UserListSection;
