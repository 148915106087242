import React from 'react';
import { format } from 'date-fns';

import { Descriptions, Form, Input, Modal, Select } from 'antd';

// hooks
import useWindowSize from '@hooks/useWindowSize';

// styling
// import styles from './index.module.less';

const { Option } = Select;

const AddReportModal = (props) => {
  const { data, onCancel, schedules, submitReport, visible } = props;
  const [form] = Form.useForm();
  const { isMobile } = useWindowSize();
  // console.log('[AddReportModal] ', props);

  const handleAddReport = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        const { scheduleSelector, ...resValues } = values;
        submitReport(resValues);
      })
      .catch((er) => {
        console.log('[Validate Failed]: ', er);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleFormValuesChange = (e) => {
    if (e.scheduleSelector) {
      const schedule = schedules?.find((sched) => sched.id === parseInt(e.scheduleSelector, 10));
      form.setFieldsValue({
        ...form.getFieldsValue(),
        TutorId: schedule?.Tutor.id,
        ScheduleId: schedule?.id,
        date: schedule?.date,
      });
    }
  };

  return (
    <Modal //
      centered
      title="Add Session Report"
      open={visible}
      destroyOnClose
      width={isMobile ? '100%' : '40%'}
      onOk={handleAddReport}
      okText="Save"
      zIndex={1021}
      onCancel={handleCancel}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Student:">{data.StudentName}</Descriptions.Item>

        <Descriptions.Item label="Tutor:">{data.TutorName}</Descriptions.Item>
      </Descriptions>

      <Form //
        initialValues={data}
        form={form}
        layout="vertical"
        style={{ marginTop: 10 }}
        onValuesChange={handleFormValuesChange}>
        <Form.Item hidden name="TutorId">
          <Input />
        </Form.Item>

        <Form.Item hidden name="ScheduleId">
          <Input />
        </Form.Item>
        <Form.Item hidden name="date">
          <Input />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'This field is required!' }]} label="Session Date" name="scheduleSelector">
          <Select //
            showSearch
            placeholder="Select Session Date..."
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
            {schedules
              .sort((a, b) => a.subject.localeCompare(b.subject))
              .filter((e) => e.Report === null)
              .map((e, i1) => {
                return (
                  <Option //
                    key={`st${i1}`}
                    value={e.id}>
                    {e?.day}, {format(new Date(e?.date), 'dd MMM yyyy')}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'This field is required!' }]} label="Subjects/Topics Discussed & Task Accomplished" name="subject">
          <Input />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'This field is required!' }]} label="Points Of Improvement & Goal for Next Session" name="reportGoal">
          <Input />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'This field is required!' }]} label="Additional Notes (extension, sub, etc.)" name="reportAdditional">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddReportModal;
