import React, { useCallback, useEffect } from 'react';

// antd
import { Col, Form, Button, Row, Card, Input, Select } from 'antd';

// redux
import { useDispatch } from 'react-redux';
import { tutorbase } from '@redux/combineActions';

// hooks
import useOpener from '@hooks/useOpener';
import useWindowSize from '@hooks/useWindowSize';

import constants from '@utils/constants';
// utils
import { useDebounce } from '@utils/methods';

import ChangePasswordModal from '@components/ChangePasswordModal';

// styling
import styles from './index.module.less';

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: '100%',
  },
};

const { Option } = Select;

const AccountEditForm = ({ account }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const [form] = Form.useForm();
  const { isOpen: isChangePasswordModalOpen, toggleOpen: toggleChangePasswordModalOpen } = useOpener();

  const handleSubmit = useDebounce(() => {
    form
      .validateFields()
      .then((values) => {
        if (account.role === 3) dispatch(tutorbase.user.studentUpdate(values));
        if (account.role === 2) dispatch(tutorbase.user.tutorUpdate(values));
        if (account.role === 1) dispatch(tutorbase.user.headUpdate(values));
      })
      .catch((er) => {
        console.log('Validate Failed:', er);
      });
  }, 300);

  const handleChangePassword = useDebounce((payload) => {
    toggleChangePasswordModalOpen();
    dispatch(tutorbase.user.userChangePasswd({ ...payload, id: account.UserId }));
  }, 300);

  const handleChangePasswordClick = () => {
    toggleChangePasswordModalOpen();
  };

  const handleFormValuesChange = () => {
    // console.log('[HANDLEFORMVALUECHANGE] ', form.getFieldsValue());
  };

  const fetchData = useCallback(() => {
    if (account) {
      form.setFieldsValue(account);
    }
  }, [account, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Form //
        {...layout}
        form={form}
        layout="vertical"
        onValuesChange={handleFormValuesChange}
        onFinish={handleSubmit}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>

        {isMobile ? (
          <>
            <Card>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="First Name" name="firstName" rules={[{ required: true, message: 'Please input your first name.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Last Name" name="lastName">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item className="form-itemz-m" label="Nickname" name="nickName">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item className="form-itemz-m" label="Preferred Pronouns" name="prefPronoun">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item className="form-itemz-m" label="Date of Birth" name="dob" rules={[{ required: true, message: 'Please input your birthday.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item className="form-itemz-m" label="Phone/Mobile No." name="phone" rules={[{ required: true, message: 'Please input your phone.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Email" name="email" rules={[{ required: true, message: 'Please input your email.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            {account && account.role === 2 && (
              <Card className="bank-wrapper mt-3">
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Bank/E-Wallet" name="bCode" rules={[{ required: true, message: 'Please input your bank/e-wallet name.' }]}>
                      <Select
                        style={{
                          width: '100%',
                        }}
                        placeholder="Select Bank/E-Wallet">
                        {Object.keys(constants.validBanks)
                          .sort((a, b) => a.localeCompare(b))
                          .map((s, idx) => (
                            <Option key={idx} value={constants.validBanks[s]}>
                              {s}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Account Name" name="bName" rules={[{ required: true, message: 'Please input your bank account name.' }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Account Number" name="bNumber" rules={[{ required: true, message: 'Please input your bank account number.' }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            )}

            <Card className="mt-3">
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Address" name="address" rules={[{ required: true, message: 'Please input your address.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="City" name="city" rules={[{ required: true, message: 'Please input your city.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Country" name="country" rules={[{ required: true, message: 'Please input your country.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Row className={styles.ctaWrapper}>
              <Button type="button" className={`btn list-btn-primary ${styles.ctaLeft}`} onClick={handleChangePasswordClick}>
                Change Password
              </Button>

              <Button type="button" className={`btn list-btn-primary ${styles.ctaRight}`} htmlType="submit">
                Update Details
              </Button>
            </Row>
          </>
        ) : (
          <>
            <Card>
              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="First Name" name="firstName" rules={[{ required: true, message: 'Please input your first name.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="Last Name" name="lastName">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item className="form-itemz-m" label="Nickname" name="nickName">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item className="form-itemz-m" label="Preferred Pronouns" name="prefPronoun">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="Date of Birth" name="dob" rules={[{ required: true, message: 'Please input your birthday.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="Email" name="email" rules={[{ required: true, message: 'Please input your email.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="Phone/Mobile No." name="phone" rules={[{ required: true, message: 'Please input your phone.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            {account && account.role === 2 && (
              <Card className="bank-wrapper mt-3">
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item className="form-itemz-m" label="Bank/E-Wallet" name="bCode" rules={[{ required: true, message: 'Please input your bank/e-wallet name.' }]}>
                      <Select
                        style={{
                          width: '100%',
                        }}
                        placeholder="Select Bank/E-Wallet">
                        {Object.keys(constants.validBanks)
                          .sort((a, b) => a.localeCompare(b))
                          .map((s, idx) => (
                            <Option key={idx} value={constants.validBanks[s]}>
                              {s}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item className="form-itemz-m" label="Account Name" name="bName" rules={[{ required: true, message: 'Please input your bank account name.' }]}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item className="form-itemz-m" label="Account Number" name="bNumber" rules={[{ required: true, message: 'Please input your bank account number.' }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            )}

            <Card className="mt-3">
              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="Address" name="address" rules={[{ required: true, message: 'Please input your address.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="City" name="city" rules={[{ required: true, message: 'Please input your city.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-itemz-m" label="Country" name="country" rules={[{ required: true, message: 'Please input your country.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Row className={styles.ctaWrapper}>
              <Button type="button" className={`btn list-btn-primary ${styles.ctaLeft}`} onClick={handleChangePasswordClick}>
                Change Password
              </Button>

              <Button type="button" className={`btn list-btn-primary ${styles.ctaRight}`} htmlType="submit">
                Update Details
              </Button>
            </Row>
          </>
        )}
      </Form>

      <ChangePasswordModal //
        visible={isChangePasswordModalOpen}
        onCancel={toggleChangePasswordModalOpen}
        changePassword={handleChangePassword}
      />
    </>
  );
};

export default AccountEditForm;
