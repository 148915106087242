import React, { useState, useEffect } from 'react';

import Navbar from '@components/layouts/Navbar';
import Banner from '@components/Banner';

import Spinner from '@components/layouts/Spinner';
import AboutTutorbase from '@components/about/AboutTutorbase';

import './index.less';

const AboutPage = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 900);
  }, []);

  if (loading) return <Spinner />;

  return (
    <div id="wrap">
      <Navbar />
      <Banner title="About Us" />
      <AboutTutorbase />
    </div>
  );
};

export default AboutPage;
