import { useState, useEffect } from 'react';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const [gotSmallScreen, setGotSmallScreen] = useState(false);
  const [gotTabletScreen, setGotTabletScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const { width } = windowSize;
    if (width) {
      setGotSmallScreen(width <= 480 && width >= 300);
      setGotTabletScreen(width < 1024 && width >= 600);
    }
  }, [gotSmallScreen, gotTabletScreen, windowSize]);

  return {
    windowSize,
    isMobile: gotSmallScreen,
    isTablet: gotTabletScreen,
  };
};

export default useWindowSize;
