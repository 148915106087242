import React from 'react';
import { Link } from 'react-router-dom';

// hooks
// import useWindowSize from '@hooks/useWindowSize';

import styles from './index.module.less';

const SectRates = () => {
  // const { isMobile } = useWindowSize();

  return (
    <div className={`container-fluid ${styles['rates-wrapper']}`}>
      <div className={styles['rates-wrap']} data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800">
        <div className={styles.col1} id="col1">
          <h5>Quality tutorial services at the hourly rate of only</h5>
          <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '2rem' }}>
            <div className={styles.rates}>
              <h3>₱400.00</h3>
              <span>for local students</span>
            </div>
            <div className={styles.rates}>
              <h3>&nbsp;&nbsp;&nbsp;$40.00</h3>
              {/* <h3>₱800.00</h3> */}
              <span>for international students</span>
            </div>
          </div>

          <br />
          <p>
            At the affordable cost of only <strong>₱400.00 per hour</strong> for local students or <strong>$40.00 per hour</strong> for international students, we at Tutor BASE promise to help your child excel in school and beyond. Our award-winning personalized services are targeted towards highlighting your child&apos;s individual academic strengths and working on their areas of improvement.
          </p>
        </div>
        <div className={styles.col2}>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Personalized Inclusions
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>
                    Our rate is only <strong>₱400.00 per hour</strong> for local students residing in the Philippines or&nbsp;
                    <strong>$40.00 per hour*</strong> for international students residing abroad**. This entails remote tutorial sessions for students of all levels, inclusive of all, either one, or any combination of our services.
                  </p>

                  {/* <p>
                    Our rate is only <strong>₱400.00 per hour</strong> for local students residing in the Philippines or&nbsp;
                    <strong>₱800.00 per hour*</strong> for international students residing abroad.** This entails remote tutorial sessions for students of all levels, inclusive of all, either one, or any combination of our services.
                  </p> */}

                  {/* <div className="accordionSpan">*Fees are to be calculated according to the current conversion rate from your country&apos;s currency to US dollar.</div> */}
                  <div className="accordionSpan">
                    *Fees are to be calculated according to the current conversion rate from your country&apos;s currency to the US dollar.
                    <br />
                    **Regardless of time differences between time zones
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              New Students
            </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <p>
                New students can book one <strong> (1) tutorial session with no minimum number of hours</strong>, and must enroll as regular students for succeeding tutorial sessions.
              </p>
            </div>
          </div>
        </div> */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Regular Students
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>
                    Regular students must book tutorial sessions for <strong> a minimum of 5 hours a week</strong> (at least 1 hour on Monday-Friday or 5 hours on Saturday, with breaks in between).
                    {/* Regular students are granted access to an account on the Tutor BASE website. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['why-btn-wrapper']}>
        <span className={styles['why-btn']}>
          <Link to="/services">READ MORE ABOUT OUR SERVICES</Link>
          <i className="far fa-arrow-alt-circle-right" />
        </span>
      </div>
    </div>
  );
};

export default SectRates;
