import React from 'react';
import teacherAnne from '@images/teacher-anne.jpg';

const AboutHead = () => {
  return (
    <div className="about-head-wrap">
      <div className="about-head-img-wrapper">
        <img src={teacherAnne} alt="Head Teacher" className="about-head-img" data-aos="fade-right" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-duration="800" />
      </div>
      <div className="about-head-info">
        <div data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800">
          <div className="post-mini-title">About Tutor BASE</div>
          <div className="post-big-title">Meet the Head Teacher</div>
        </div>

        <p className="text-post">It takes two parents to produce a child, but as the African proverb goes, it takes an entire village to raise one. With that said, it is my fervent goal to partner with you, a parent or guardian, in understanding and addressing the academic learning needs of your precious child.</p>
        <p className="text-post">My name is Teacher Anne, and teaching is my passion, ministry, and commitment. I believe that knowledge is a valuable legacy with which we could gift a child; hence, it is my vocation to help young minds reach their full potential and achieve their academic goals.</p>
        <p className="text-post">Here at Tutor BASE, my dedicated team of teachers and I will do our best to work with you in raising your child as a competent and diligent future leader. Rest assured that we will help you lead your child to thrive on the next day of school and beyond.</p>
        <br />
        <p className="head-name">Teacher Anne</p>
        <p className="head-info">
          MFLCD, MHRM, MBA, PgDMEIC, PgDCNLPTL, CLMWC
          <br />
          {/* BA English Studies <br />
          Master of Family Life and Child Development <br />
          University of the Philippines Diliman <br /> */}
        </p>
      </div>
    </div>
  );
};

export default AboutHead;
