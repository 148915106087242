import React from 'react';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import _ from 'lodash';
// redux
import { useSelector } from 'react-redux';

const RecentActivities = () => {
  const {
    tutorbase: {
      // jobs, tis will hold those who just submit using the form.
      user: { jobs, payment, sessions },
    },
  } = useSelector((state) => state);

  const reports = _.flatten(
    sessions?.map((sess) => {
      const mutatedScheds = sess.Schedules.map((scd) => {
        return { ...scd, recentActive: scd.Report?.updatedAt };
      });

      return {
        ...sess,
        Schedules: mutatedScheds,
      };
      // return sess.Schedules.filter((scd) => scd.Report);
    })
  );

  const paid = payment.list.map((p) => {
    p.recentActive = p.paymentDate;
    return p;
  });

  const merged = _.concat(paid, reports);
  const activities = _.orderBy(merged, ['recentActive'], 'desc');

  const mutatedJobs = _.orderBy(jobs, 'createdAt', 'desc');

  const ichiHyaku = activities?.filter((act) => act.paymentId !== undefined);
  const niHyaku = activities?.filter((act) => act.Report && act.Report.isParentChecked);
  const sanHyaku = activities?.filter((act) => act.Tutor !== undefined);

  const renderIchi = (act, i) => {
    return (
      <li key={`ich${i}`}>
        <div className="paid">
          <i className="fas fa-dollar-sign" />
        </div>
        <div className="recents-text">
          <Link to="/payments">
            <span>{`${_.words(act.studentName)[0]} ${_.words(act.studentName)[1].charAt(0)}`}</span> just made a payment for {act.hours} hours Session
          </Link>
          <p>
            {formatDistance(new Date(act.recentActive), new Date(), {
              addSuffix: true,
            })}
          </p>
        </div>
      </li>
    );
  };

  const renderNi = (act, i) => {
    return (
      <li key={`ni${i}`}>
        <div className="checked">
          <i className="fas fa-check" />
        </div>
        <div className="recents-text">
          <Link to="/reports">
            <span>
              {`${_.words(act.Student.name)[0]} ${_.words(act.Student.name)[1].charAt(0)}`}
              &apos;s Parents
            </span>{' '}
            just checked child&apos;s Session Report
          </Link>
          <p>
            {formatDistance(new Date(act.recentActive), new Date(), {
              addSuffix: true,
            })}
          </p>
        </div>
      </li>
    );
  };

  const renderSan = (act, i) => {
    return (
      <li key={`san${i}`}>
        <div className="report">
          <i className="fas fa-pen-alt" />
        </div>

        <div className="recents-text">
          <Link to="/reports">
            <span>{`${_.words(act.Tutor.name)[0]} ${_.words(act.Tutor.name)[1].charAt(0)}`}</span> just submitted Session Report for {`${_.words(act.Student.name)[0]} ${_.words(act.Student.name)[1].charAt(0)}`}
          </Link>
          <p>
            {formatDistance(new Date(act.recentActive), new Date(), {
              addSuffix: true,
            })}
          </p>
        </div>
      </li>
    );
  };

  // const renderYon = (act, i) => {
  //   return <li key={`yon${i}`}>{`${act.Student.school} ${i}`}</li>;
  // };

  // console.log('[d] ', activities);
  // console.log('[100] ', ichiHyaku);
  // console.log('[200] ', niHyaku);
  // console.log('[300] ', sanHyaku);

  return (
    <>
      <div className="col job-app-wrapper">
        <h4>Recent Activities</h4>

        <div className="recents">
          <ul>
            {ichiHyaku?.map((act, i) => i < 5 && renderIchi(act, i))}
            {niHyaku?.map((act, i) => i < 5 && renderNi(act, i))}
            {sanHyaku?.map((act, i) => i < 5 && renderSan(act, i))}
          </ul>

          {ichiHyaku.length === 0 && niHyaku.length === 0 && ichiHyaku.length === 0 && (
            <h5
              style={{
                color: 'rgb(157, 157, 157)',
                marginTop: '-1rem',
                fontWeight: '400',
              }}>
              No Activities
            </h5>
          )}
        </div>
      </div>

      <div className="col job-app-wrapper">
        <h4>Recent Tutor Applications</h4>
        <div className="recents">
          {mutatedJobs.length > 0 && (
            <ul>
              {mutatedJobs.map((job, i) => {
                if (i < 5) {
                  return (
                    <li key={`jb${i}`}>
                      <div className="job">
                        <i className="fas fa-address-card" />
                      </div>

                      <div className="recents-text">
                        <Link to={`/jobapplication?applicant=${job.id}`}>
                          <span>{job.fullName}</span> just applied for Tutor position
                        </Link>
                        <p>
                          {formatDistance(new Date(job.createdAt), new Date(), {
                            addSuffix: true,
                          })}
                        </p>
                      </div>
                    </li>
                  );
                }
                return job;
              })}
            </ul>
          )}

          {mutatedJobs.length === 0 && (
            <h5
              style={{
                color: 'rgb(157, 157, 157)',
                marginTop: '0rem',
                fontWeight: '400',
              }}>
              No Applications
            </h5>
          )}
        </div>
      </div>
    </>
  );
};

export default RecentActivities;
