import * as types from '../types';

const initialState = {
  loading: false,
  fromDoe: false,
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.SET_FROM_DOE_BUTTON:
      return { ...state, fromDoe: payload };
    case types.SET_LOADING:
      return { ...state, loading: true };

    case types.CLEAR_LOADING:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
