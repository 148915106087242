import { POST_FORM_DATA, POST } from '../request';
// import constants from '@utils/constants';

export async function postJobApply(params) {
  const { file, rt } = params;
  const modParams = { ...params, rt: decodeURIComponent(rt) };
  const obj = new FormData();
  obj.append('fileName', file.name);
  obj.append('file', file);
  obj.append('data', JSON.stringify(modParams));

  const payload = { data: obj };
  // console.log('[postJobApply payload] ', payload);

  return POST_FORM_DATA(`jobs/apply-tutor`, payload);
}

export async function postListJobApp(payload) {
  return POST(`jobs/list`, payload);
}
