import React from 'react';
import AboutHead from './AboutHead';
// import AboutTeam from './AboutTeam';

const AboutTutorbase = () => {
  return (
    <div className="about-tutorbase-wrap">
      <AboutHead />
      {/* <AboutTeam /> */}
    </div>
  );
};

export default AboutTutorbase;
