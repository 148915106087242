import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Banner from '@components/Banner';
import Navbar from '@components/layouts/Navbar';

import Spinner from '@components/layouts/Spinner';
import TestiSection from '@components/TestiSection';

import './index.less';

const TestimonialPage = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 900);
  }, []);

  if (loading) return <Spinner />;

  return (
    <div className="wrap">
      <Navbar />
      <Banner title="Testimonials" />
      <TestiSection id={id} />
    </div>
  );
};

export default TestimonialPage;
