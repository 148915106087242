import React, { useState, useEffect } from 'react';

import Spinner from '@components/layouts/Spinner';
import NavbarDashboard from '@components/layouts/NavbarDashboard';
import HeaderDashboard from '@components/layouts/HeaderDashboard';

import './index.less';

const Program = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1600);
  }, []);

  if (loading) return <Spinner />;

  return (
    <>
      <NavbarDashboard />
      <HeaderDashboard />
      <div className="program-wrapper">
        <div className="program-section-wrapper">
          <h3>Admin Program</h3>
          <div data-aos="fade-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800">
            {/* <iframe title="Admin Program" width="1050" height="600" frameBorder="0" scrolling="no" src="https://onedrive.live.com/embed?resid=2E44C6D80FECEFCE%21113&authkey=%21ACL8erhcIcqBKJ8&em=2&AllowTyping=True&ActiveCell='Students'!C11&wdDownloadButton=True&wdInConfigurator=True" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Program;
