import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import s1 from '@images/stock/gradeschool-boy.jpg';
import s2 from '@images/stock/gradeschool-girl.jpg';

import s3 from '@images/stock/jrhighschool-boy.jpg';
import s4 from '@images/stock/jrhighshool-girl.jpg';
import s5 from '@images/stock/srhighschool-boy.jpg';
import s6 from '@images/stock/srhighschool-girl.jpg';

import s7 from '@images/stock/college-boy.jpg';
import s8 from '@images/stock/college-girl.jpg';

// styling
import styles from './index.module.less';

const SectionWhyChoose = () => {
  const { isTablet, isMobile } = useWindowSize();

  return (
    <>
      {(isMobile || isTablet) && (
        <>
          <div className="container why-choose-wrapper first-wrapper" style={{ justifyContent: 'center' }}>
            <div className="">
              <h1 className="text-center" data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800">
                <strong>Why Choose Tutor BASE?</strong>
              </h1>

              <p data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800">
                Our goal is to equip each student with the knowledge and skills they need to stay ahead or get back on track in school.
              </p>
            </div>
          </div>

          <div className="container why-choose-wrapper" style={{ height: '2rem', justifyContent: 'center' }}></div>

          <div className="container why-choose-wrapper" style={{ alignItems: 'center' }}>
            <div className="container  d-flex flex-column why-text" data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="200">
              <div className="d-flex flex-md-row flex-sm-column why-card-wrapper">
                <div style={{ width: '100%', padding: '1rem' }}>
                  <i
                    style={{
                      position: 'absolute',
                      fontSize: '2.5rem',
                      color: '#9acd32',
                    }}
                    className="fas fa-check-circle"
                  />
                  <div
                    style={{
                      position: 'relative',
                      marginLeft: '3.1rem',
                      width: '100%',
                    }}>
                    <h5 className={styles.besideGreenText}>Award-Winning, Quality Services</h5>
                  </div>
                  <p>All of our knowledgeable and highly trained tutors are from the University of the Philippines and other top universities in the country.</p>
                </div>
              </div>

              <br />
            </div>

            {/* data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800" */}
            <div className="why-img">
              <img src={s2} alt="why-choose" />
            </div>
          </div>

          <div className="container why-choose-wrapper" style={{ height: '2rem', justifyContent: 'center' }}></div>
          <div className="container why-choose-wrapper" style={{ alignItems: 'center' }}>
            <div className="container  d-flex flex-column why-text" data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="400">
              <div className="d-flex flex-md-row flex-sm-column why-card-wrapper">
                <div style={{ width: '100%', padding: '1rem' }}>
                  <i
                    style={{
                      position: 'absolute',
                      fontSize: '2.5rem',
                      color: '#9acd32',
                    }}
                    className="fas fa-check-circle"
                  />
                  <div
                    style={{
                      position: 'relative',
                      marginLeft: '3.1rem',
                      width: '100%',
                    }}>
                    <h5 className={styles.besideGreenText}>Remote Learning Ready</h5>
                  </div>
                  <p>All of our classes are conducted in a remote and online setting using a video conferencing platform.</p>
                </div>
              </div>

              <br />
            </div>
            {/* data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="500"             */}
            <div className="why-img">
              <img src={s3} alt="why-choose" />
            </div>
          </div>

          <div className="container why-choose-wrapper" style={{ height: '2rem', justifyContent: 'center' }}></div>
          <div className="container why-choose-wrapper" style={{ alignItems: 'center' }}>
            <div className="container  d-flex flex-column why-text" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="700">
              <div className="d-flex flex-md-row flex-sm-column why-card-wrapper">
                <div style={{ width: '100%', padding: '1rem' }}>
                  <i
                    style={{
                      position: 'absolute',
                      fontSize: '2.5rem',
                      color: '#9acd32',
                    }}
                    className="fas fa-check-circle"
                  />
                  <div
                    style={{
                      position: 'relative',
                      marginLeft: '3.1rem',
                      width: '100%',
                    }}>
                    {isMobile ? (
                      <h5 className={styles.besideGreenText}>
                        One-on-One and <br />
                        Individualized
                      </h5>
                    ) : (
                      <h5 className={styles.besideGreenText}>One-on-One and Individualized</h5>
                    )}
                  </div>
                  <p>Each student of ours is matched to a tutor whose areas of expertise correspond with the student's areas for improvement.</p>
                </div>
              </div>

              <br />
            </div>
            {/* data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="600"             */}
            <div className="why-img">
              <img src={s6} alt="why-choose" />
            </div>
          </div>

          <div className="container why-choose-wrapper" style={{ height: '2rem', justifyContent: 'center' }}></div>
          <div className="container why-choose-wrapper" style={{ alignItems: 'center' }}>
            {/* data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="900" */}
            <div className="container  d-flex flex-column why-text" data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="800">
              <div className="d-flex flex-md-row flex-sm-column why-card-wrapper">
                <div style={{ width: '100%', padding: '1rem' }}>
                  <i
                    style={{
                      position: 'absolute',
                      fontSize: '2.5rem',
                      color: '#9acd32',
                    }}
                    className="fas fa-check-circle"
                  />
                  <div
                    style={{
                      position: 'relative',
                      marginLeft: '3.1rem',
                      width: '100%',
                    }}>
                    <h5 className={styles.besideGreenText}>Affordable Rates for Full Services</h5>
                  </div>
                  <p>Uniform rates for students of all levels, inclusive of homework help, test preparation, and many more tutorial and review services.</p>
                </div>
              </div>

              <br />
            </div>
            <div className="why-img">
              <img src={s7} alt="why-choose" />
            </div>
          </div>

          <div className="container why-choose-wrapper" style={{ height: '2rem', justifyContent: 'center' }}></div>
        </>
        // <div className="container why-choose-wrapper">
        //   {/* <div className="why-img" data-aos="zoom-in" data-aos-offset="400" data-aos-easing="ease-in-sine" data-aos-duration="800">
        //     <img src={s3} alt="why-choose" />
        //   </div> */}

        //   <div className={styles.carouselWrapper}>
        //     <Slider {...smSettings}>
        //       {mvtatedItems.map((mm, idx) => (
        //         <img className={styles.awardsImagez1} src={mm} alt="stock" key={idx} />
        //       ))}
        //     </Slider>
        //   </div>

        //   <div className="container  d-flex flex-column why-text">
        //     <div className="why-heading">
        //       <h1 className="text-center">
        //         <strong>Why Choose Tutor BASE?</strong>
        //       </h1>

        //       <p>Our goal is to equip each student with the knowledge and skills they need to stay ahead or get back on track in school.</p>
        //     </div>

        //     <div className="d-flex flex-md-row flex-sm-column why-card-wrapper">
        //       <div className="why-card">
        //         <i className="fas fa-check-circle" />
        //         <div>
        //           <h5>Award-Winning, Quality Services</h5>
        //           <p>All of our knowledgeable and highly trained tutors are from the University of the Philippines and other top universities.</p>
        //         </div>
        //       </div>

        //       <div className="why-card">
        //         <i className="fas fa-check-circle" />
        //         <div>
        //           <h5>Remote Learning Ready</h5>
        //           <p>All of our classes are conducted in a remote and online setting using a video conferencing platform.</p>
        //         </div>
        //       </div>
        //     </div>

        //     <div className="d-flex flex-md-row flex-sm-column why-card-wrapper">
        //       <div className="why-card">
        //         <i className="fas fa-check-circle" />
        //         <div>
        //           <h5>One-on-One and Individualized</h5>
        //           <p>Each student of ours is matched to a tutor whose areas of expertise correspond with the student's areas for improvement.</p>
        //         </div>
        //       </div>

        //       <div className="why-card">
        //         <i className="fas fa-check-circle" />
        //         <div>
        //           <h5>Affordable Rates for Full Services</h5>
        //           <p>Uniform rates for students of all levels, inclusive of homework help, test preparation, and many more tutorial and review services.</p>
        //         </div>
        //       </div>
        //     </div>
        //     <br />
        //   </div>
        // </div>
      )}

      {!isMobile && !isTablet && (
        <>
          <div className="container why-choose-wrapper" style={{ justifyContent: 'center' }}>
            <div className="">
              <h1 className="text-center" data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800">
                <strong>Why Choose Tutor BASE?</strong>
              </h1>

              <p data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800">
                Our goal is to equip each student with the knowledge and skills they need to stay ahead or get back on track in school.
              </p>
            </div>
          </div>

          <div className="container why-choose-wrapper divisionz"></div>

          <div className="container why-choose-wrapper imgTextDivi" style={{ alignItems: 'center' }}>
            {/* data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800" */}
            <div className="why-img">
              <img src={s2} alt="why-choose" />
            </div>

            <div className="container  d-flex flex-column why-text" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="200">
              <div className="d-flex flex-md-row flex-sm-column why-card-wrapper">
                <div style={{ width: '100%', padding: '1rem' }}>
                  <i
                    style={{
                      position: 'absolute',
                      fontSize: '2.5rem',
                      color: '#9acd32',
                    }}
                    className="fas fa-check-circle"
                  />
                  <div className="greenDetail">
                    <h5 className={styles.besideGreenText}>Award-Winning, Quality Services</h5>
                    <p>All of our knowledgeable and highly trained tutors are from the University of the Philippines and other top universities in the country.</p>
                  </div>
                </div>
              </div>

              <br />
            </div>
          </div>

          <div className="container why-choose-wrapper divisionz"></div>
          <div className="container why-choose-wrapper imgTextDivi" style={{ alignItems: 'center' }}>
            <div className="container  d-flex flex-column why-text" data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="400">
              <div className="d-flex flex-md-row flex-sm-column why-card-wrapper">
                <div style={{ width: '100%', padding: '1rem' }}>
                  <i
                    style={{
                      position: 'absolute',
                      fontSize: '2.5rem',
                      color: '#9acd32',
                    }}
                    className="fas fa-check-circle"
                  />
                  <div className="greenDetail">
                    <h5 className={styles.besideGreenText}>Remote Learning Ready</h5>
                    <p>All of our classes are conducted in a remote and online setting using a video conferencing platform.</p>
                  </div>
                </div>
              </div>

              <br />
            </div>

            {/* data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="500" */}
            <div className="why-img">
              <img src={s3} alt="why-choose" />
            </div>
          </div>

          <div className="container why-choose-wrapper divisionz"></div>
          <div className="container why-choose-wrapper imgTextDivi" style={{ alignItems: 'center' }}>
            {/* data-aos="zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="600" */}
            <div className="why-img">
              <img src={s6} alt="why-choose" />
            </div>

            <div className="container  d-flex flex-column why-text" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="700">
              <div className="d-flex flex-md-row flex-sm-column why-card-wrapper">
                <div style={{ width: '100%', padding: '1rem' }}>
                  <i
                    style={{
                      position: 'absolute',
                      fontSize: '2.5rem',
                      color: '#9acd32',
                    }}
                    className="fas fa-check-circle"
                  />
                  <div className="greenDetail">
                    <h5 className={styles.besideGreenText}>One-on-One and Individualized</h5>
                    <p>Each student of ours is matched to a tutor whose areas of expertise correspond with the student's areas for improvement.</p>
                  </div>
                </div>
              </div>

              <br />
            </div>
          </div>

          <div className="container why-choose-wrapper divisionz"></div>
          <div className="container why-choose-wrapper imgTextDivi" style={{ alignItems: 'center' }}>
            <div className="container  d-flex flex-column why-text" data-aos="zoom-in" data-aos-offset="400" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="1000">
              <div className="d-flex flex-md-row flex-sm-column why-card-wrapper">
                <div style={{ width: '100%', padding: '1rem' }}>
                  <i
                    style={{
                      position: 'absolute',
                      fontSize: '2.5rem',
                      color: '#9acd32',
                    }}
                    className="fas fa-check-circle"
                  />
                  <div className="greenDetail">
                    <h5 className={styles.besideGreenText}>Affordable Rates for Full Services</h5>
                    <p>Uniform rates for students of all levels, inclusive of homework help, test preparation, and many more tutorial and review services.</p>
                  </div>
                </div>
              </div>

              <br />
            </div>

            {/* data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="900" */}
            <div className="why-img">
              <img src={s7} alt="why-choose" />
            </div>
          </div>

          <div className="container why-choose-wrapper divisionx"></div>
        </>
      )}

      <div className="container why-choose-wrapper imgTextDivi">
        <div className={styles.whyBtnWrapper}>
          <span className={styles.whyBtn}>
            <Link to="/about">READ MORE ABOUT TUTOR BASE</Link>
            <i className="far fa-arrow-alt-circle-right" />
          </span>
        </div>
      </div>
    </>
  );
};

export default SectionWhyChoose;
