import React, { useEffect } from 'react';

// antd
import { Col, Modal, Form, Button, Row, Card, Input } from 'antd';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import { useDebounce } from '@utils/methods';

// styling
import '../../index.module.less';
import './index.less';

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: '100%',
  },
};

const ZoomModal = ({ visible, onCancel, handleUpdate, data }) => {
  const { isMobile } = useWindowSize();
  const [form] = Form.useForm();

  const handleSubmit = useDebounce(() => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        handleUpdate(values);
      })
      .catch((er) => {
        console.log('Validate Failed:', er);
      });
  }, 300);

  const handleCancel = useDebounce(() => {
    form.resetFields();
    onCancel();
  }, 300);

  const handleFormValuesChange = () => {
    // console.log('[HANDLEFORMVALUECHANGE] ', form.getFieldsValue());
  };

  useEffect(() => {
    form.setFieldsValue(data);
    return () => form.resetFields();
  }, [data]);

  return (
    <Modal //
      getContainer={false}
      title="Zoom Details"
      open={visible}
      destroyOnClose
      width={1000}
      footer={null}
      onCancel={onCancel}>
      <Form //
        {...layout}
        form={form}
        layout="vertical"
        onValuesChange={handleFormValuesChange}
        onFinish={handleSubmit}>
        <Form.Item hidden name="SessionId">
          <Input />
        </Form.Item>

        {isMobile ? (
          <>
            <Card>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Meeting ID" name="meetingID" rules={[{ required: true, message: 'Please input meeting ID.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Meeting Passcode" name="meetingPasscode" rules={[{ required: true, message: 'Please input meeting passcode.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Meeting URL" name="meetingURL" rules={[{ required: true, message: 'Please input meeting URL.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Row className="list-label-add-label-footer">
              <Button onClick={handleCancel} style={{ marginRight: 10 }}>
                Cancel
              </Button>

              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Row>
          </>
        ) : (
          <>
            <Card>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item className="form-itemz-m" label="Meeting ID" name="meetingID" rules={[{ required: true, message: 'Please input meeting ID.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item className="form-itemz-m" label="Meeting Passcode" name="meetingPasscode" rules={[{ required: true, message: 'Please input meeting passcode.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-m" label="Meeting URL" name="meetingURL" rules={[{ required: true, message: 'Please input meeting URL.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Row className="list-label-add-label-footer">
              <Button onClick={handleCancel} style={{ marginRight: 10 }}>
                Cancel
              </Button>

              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ZoomModal;
