import { POST, PUT } from '../request';

export async function postCreatePayment(payload) {
  return POST(`payments/checkout`, payload);
}
export async function postGetPayment(payload) {
  return POST(`payments/status`, payload);
}
export async function postListPayment(payload) {
  return POST(`payments/list`, payload);
}
export async function putEditAccount(payload) {
  const { id, ...rest } = payload;
  return PUT(`payments/account`, rest);
}
export async function postPayTutor(payload) {
  return POST(`payments/dist`, payload);
}
