import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Card, Col, Table } from 'antd';

// redux
import { useSelector } from 'react-redux';
import { formatPrice } from '@utils/methods';

// hooks
import useWindowSize from '@hooks/useWindowSize';

// styling
import styles from './index.module.less';

const TutorPaymentLogTable = () => {
  const { isMobile } = useWindowSize();
  const {
    tutorbase: {
      user: { disbursements },
    },
  } = useSelector((state) => state);

  const [showTable, setShowTable] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: 'Date Paid',
      align: 'center',
      render: (record) => {
        return moment(record.date).format('MMM DD YYYY').toString();
      },
    },
    {
      title: "Tutor's Name",
      dataIndex: 'TutorName',
      key: 'name',
    },
    {
      title: "Student's Name",
      dataIndex: 'studentName',
      key: 'name',
    },
    {
      title: 'Sessions',
      render: (record) => {
        return (
          <div className={styles.scheduleDate}>
            {record.Session.Schedules &&
              record.Session.Schedules.filter((sc) => sc.TutorId === record.TutorId).map((sc) => {
                const { date, time, hours } = sc;
                const mDate = moment(date).format('ddd, MMM D, YYYY');
                const endHours = time + hours;
                const startTime = moment(`${time}:00`, 'HH:mm').format('ha');
                const endTime = moment(`${endHours}:00`, 'HH:mm').format('ha');

                return (
                  <div>
                    {mDate} {startTime}-{endTime}
                  </div>
                );
              })}
          </div>
        );
      },
    },

    {
      title: 'Hours Done',
      align: 'center',
      render: (record) => {
        if (record.Session.Schedules) {
          const schedz = record.Session.Schedules.filter((ss) => ss.TutorId === record.TutorId && ss.isDone === true);
          let hoursDone = 0;
          schedz.map((sc) => {
            hoursDone += sc.hours;
            return sc;
          });
          return `${hoursDone}hrs.`;
        }
        return '-';
      },
      // },
      // {
      //   title: 'Hours Worked',
      //   dataIndex: 'hours',
      //   key: 'hours',
      //   align: 'center',
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      render: (record) => formatPrice(record),
    },
  ];

  const renderCardView = () => {
    return (
      <div className={styles.cardWrapper}>
        {dataSource.map((u, idx) => (
          <Card key={idx} className={styles.cardRow}>
            <Col span={24}>
              <h4>Name: {u.TutorName}</h4>
              <span>Student: {u.studentName}</span>
              <br />
              <span>Payment Date: {moment(u.date).format('MMM DD YYYY').toString()}</span>
              <br />
              <span>Hours Done: {u.Session?.doneTime}</span>
              <br />
              <span>Hours Worked: {u.hours}</span>
              <br />
              <span>Amount Due: {formatPrice(u.amount)}</span>
              <br />
              <span>Amount Paid: {formatPrice(u.amount)}</span>
              <br />
              <span>
                Status: <b>{u.status}</b>
              </span>
              <br />
            </Col>
          </Card>
        ))}
      </div>
    );
  };

  const renderTableView = () => {
    return (
      <div className="tutor-payment-wrapper mb-5">
        <h3 className="mb-1 text-center">Tutor Payment Log</h3>
        <p className="mb-5 text-center">Payments to Tutors</p>
        <Table //
          style={{ display: showTable ? 'block' : 'none' }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ position: ['bottomCenter'] }}
        />
      </div>
    );
  };

  useEffect(() => {
    setShowTable(!isMobile);
    if (disbursements) {
      setDataSource(disbursements);
    }
  }, [disbursements]);

  return isMobile ? renderCardView() : renderTableView();
};

export default TutorPaymentLogTable;
