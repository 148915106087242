import { combineReducers } from 'redux';

import common from './common/reducers';
// import form from './common/reducers/form';
import tutorbase from './tutorbase/reducers';

const reducers = combineReducers({
  common,
  tutorbase,
});

export default reducers;
