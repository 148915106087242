import React from 'react';
// import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';

import iconStep1 from '@images/icons/step-1.png';
import iconStep2 from '@images/icons/step-2.png';
import iconStep3 from '@images/icons/step-3.png';

import website from '@images/website.png';
import phoneIcon from '@images/phone_icon.png';

import styles from './index.module.less';
// import './index.less';

const SectionBookingYour = () => {
  const style = { fill: 'white', fontSize: '1.5em' };

  return (
    <div className={styles['section-booking-wrapper']}>
      <div className="text-center">
        <h5>3 Easy Steps</h5>
        <h3>Booking Your Tutorial Sessions</h3>
      </div>

      <div data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="600">
        <div className={styles['steps-wrapper']}>
          <div className={`col ${styles.step}`}>
            <img src={iconStep1} alt="" />
            <h4>STEP 1</h4>
            <p>
              Contact Tutor BASE so
              <br />
              that we can discuss how we
              <br />
              may help your child in their
              <br />
              academic learning needs.
            </p>
          </div>

          <div className={`col ${styles.step}`} id="mid">
            <img src={iconStep2} alt="" />
            <h4>STEP 2</h4>
            <p>
              Schedule a Tutor BASE tutorial
              <br />
              session with your personalized
              <br />
              type of services, date, time,
              <br />
              and number of hours.
            </p>
          </div>

          <div className={`col ${styles.step}`}>
            <img src={iconStep3} alt="" />
            <h4>STEP 3</h4>
            <p>
              Enroll your child as a regular
              <br />
              student of Tutor BASE for weekly
              <br />
              remote tutorial sessions with
              <br />
              our competent tutors.
            </p>
          </div>
        </div>

        <div className={`${styles['contact-tutorbase-wrapper']} mt-4`}>
          <div className={styles.con1}>
            <h5>Want to book a tutorial session? </h5>
            <h3>Contact Tutor BASE now!</h3>
          </div>
          <div className={styles.con2}>
            <div className={styles.wang}>
              <div className={styles.weng}>
                <i>
                  <FaIcons.FaFacebook style={style} />
                </i>

                <a href="https://www.facebook.com/tutorbasecenter" target="_blank" rel="noreferrer">
                  facebook.com/tutorbasecenter
                </a>
                {/* <Link
                  to={{
                    pathname: 'https://www.facebook.com/tutorbasecenter',
                  }}
                  target="_blank">
                  facebook.com/tutorbasecenter
                </Link> */}
              </div>

              <div className={styles.weng}>
                <i>
                  <img
                    alt="v"
                    src={website}
                    style={{
                      width: '1.88em',
                      height: '1.88em',
                      marginLeft: -3,
                      marginTop: 3,
                    }}
                  />
                </i>
                <a href="https://tutorbasecenter.com" target="_blank" rel="noreferrer">
                  www.tutorbasecenter.com
                </a>
                {/* <Link rel="noreferrer" to={{ pathname: 'https://tutorbasecenter.com' }} target="_blank">
                  www.tutorbasecenter.com
                </Link> */}
              </div>

              <div className={styles.weng}>
                <i>
                  <img
                    alt="v"
                    src={phoneIcon}
                    style={{
                      width: '2.2em',
                      height: '2.2em',
                      marginLeft: -4,
                    }}
                  />
                </i>
                <a href="viber://chat?number=639156979153" target="_blank" rel="noreferrer">
                  0915-697-9153 (Viber)
                </a>
              </div>

              <div className={styles.weng}>
                <i>
                  <FaIcons.FaEnvelope style={style} />
                </i>
                <a href="mailto:tutorbasecenter@gmail.com" target="_blank" rel="noreferrer">
                  tutorbasecenter@gmail.com
                </a>
              </div>
            </div>
            {/* <ul>
              <li>
                <i>
                  <FaIcons.FaEnvelope style={style} />
                </i>
                <a href="mailto:tutorbasecenter@gmail.com" target="_blank" rel="noreferrer">
                  tutorbasecenter@gmail.com
                </a>
              </li>

              <li>
                <i>
                  <img
                    src={phoneIcon}
                    style={{
                      width: '1.94em',
                      height: '1.94em',
                      marginLeft: -2,
                    }}
                  />
                </i>
                <a href="viber://chat?number=639156979153" target="_blank" rel="noreferrer">
                  0915-697-9153 (Mobile/Viber)
                </a>
              </li>

              <li>
                <i>
                  <FaIcons.FaFacebook style={style} />
                </i>
                <Link
                  to={{
                    pathname: 'https://www.facebook.com/tutorbasecenter',
                  }}
                  target="_blank">
                  facebook.com/tutorbasecenter
                </Link>
              </li>
              <li>
                <i>
                  <img
                    src={website}
                    style={{
                      width: '1.88em',
                      height: '1.88em',
                      marginLeft: -2,
                    }}
                  />
                </i>

                <Link rel="noreferrer" to={{ pathname: 'https://tutorbasecenter.com' }} target="_blank">
                  www.tutorbasecenter.com
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionBookingYour;
