import { PATCH, POST } from '../request';

export async function postAddReport(payload) {
  return POST(`reports`, payload);
}
// patch
export async function putEditReport(payload) {
  const { id, ...rest } = payload;
  return PATCH(`reports/edit/${id}`, rest);
}
// patch
export async function putHeadCheck(payload) {
  const { id, ...rest } = payload;
  return PATCH(`reports/head-check/${id}`, rest);
}
// patch
export async function putParentCheck(payload) {
  const { id, ...rest } = payload;
  return PATCH(`reports/parent-check/${id}`, rest);
}
