import React from 'react';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import RoleCard from '@components/RoleCard';
import AccountEditForm from '@components/AccountEditForm';

// styling
// import '@styles/settings.less';
import styles from './index.module.less';

const AccountSettings = ({ account }) => {
  const { isMobile } = useWindowSize();

  return (
    <div className={styles.accountWrapper}>
      {isMobile ? (
        <>
          <div className={styles.editInfo}>
            <div className={styles.roleCardWrapper}>
              <RoleCard account={account} />
            </div>
          </div>

          <div className={styles.basicInfo}>
            <AccountEditForm account={account} />
          </div>
        </>
      ) : (
        <>
          <div className={styles.basicInfo}>
            <AccountEditForm account={account} />
          </div>

          <div className={styles.editInfo}>
            <div style={{ marginLeft: 20 }}>
              <RoleCard account={account} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AccountSettings;
