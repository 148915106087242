import {
  //
  postAddReport,
  putEditReport,
  putHeadCheck,
  putParentCheck,
} from '@service/api/report';

// types
import * as types from '../types';

import { swalSuccess, swalError } from '@utils/methods';

export const addReport = (payload) => async (dispatch) => {
  const res = await postAddReport(payload);
  // console.log('[ADDREPORT] ', res);

  if (res.success) {
    dispatch({
      type: types.REPORT_ADD,
      payload: res.data,
    });

    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }
  return res;
};

export const editReport = (payload) => async (dispatch) => {
  const res = await putEditReport(payload);
  // console.log('[EDITREPORT] ', res);

  if (res.success) {
    dispatch({
      type: types.REPORT_EDIT,
      payload: res.data,
    });
  } else {
    swalError(res.msg);
  }

  // Swal.fire({
  //   icon: 'success',
  //   customClass: {
  //     confirmButton: 'swal-btn',
  //   },
  //   html: `
  //   <h3>${res.data.msg}<h3/>
  //   `,
  //   confirmButtonText: `OK`,
  // });

  // Swal.fire({
  //   icon: 'error',
  //   title: 'Edit report failed.',
  //   confirmButtonText: `Ok`,
  // });

  return res;
};

export const headCheck = (payload) => async (dispatch) => {
  const res = await putHeadCheck(payload);
  // console.log('[HEADCHECK] ', res);
  // data.ScheduleId

  if (res.success) {
    dispatch({
      type: types.REPORT_HEAD_CHECK,
      payload: res.data,
    });

    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }
};

export const parentCheck = (payload) => async (dispatch) => {
  const res = await putParentCheck(payload);
  // console.log('[PARENTCHECK] ', res);
  // data.ScheduleId

  if (res.success) {
    dispatch({
      type: types.REPORT_PARENT_CHECK,
      payload: res.data,
    });

    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};
