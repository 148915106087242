import React from 'react';

// hooks
import useWindowSize from '@hooks/useWindowSize';

// import banner from '@images/banner.jpg';
// import banner from '@images/banner-mobile.jpg';

const Banner = () => {
  const { isMobile } = useWindowSize();
  return (
    <div className="banner-wrap-main" data-aos="fade-in" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-duration="600">
      {/* <img src={banner} alt="" /> */}
      {/* <img src="https://tutorbasecenter.s3.ap-southeast-1.amazonaws.com/main-banner.jpeg" alt="main-banner" /> */}
      {isMobile ? <img src="https://tutorbasecenter.s3.ap-southeast-1.amazonaws.com/webheadermobile-oct18.png" alt="blue-banner" /> : <img src="https://tutorbasecenter.s3.ap-southeast-1.amazonaws.com/webheader-oct18.png" alt="blue-banner" />}
    </div>
  );
};

export default Banner;
