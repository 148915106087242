import React from 'react';
import './index.less';

const blueBanners = ['About Us', 'Testimonials', 'Services', 'Join Our Team', 'Privacy Policy'];

const Banner = ({ title }) => {
  return (
    <div className={blueBanners.includes(title) ? 'banner-wrap banner-wrap-about' : 'banner-wrap'}>
      <h2 data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800">
        {title}
      </h2>
    </div>
  );
};

export default Banner;
