import React from 'react';
// antd
import { Button, Col, Form, Input, Row } from 'antd';

// hooks
import useWindowSize from '@hooks/useWindowSize';

// utils
import { getRandomString } from '@utils/methods';

import CompanyInput from './CompanyInput';

const { TextArea } = Input;

const defaultCompany = {
  tempId: getRandomString(),
  company: '',
  position: '',
  yearsWorked: '',
  leavingReason: '',
};

const EmploymentHistory = ({ styles }) => {
  const { isMobile } = useWindowSize();

  // const addCompany = () => {
  //   if (data.workexp.length < 3) {
  //     const newCompany = {
  //       company: '',
  //       position: '',
  //       yearsWorked: '',
  //       leavingReason: '',
  //     };

  //     form.setFieldsValue({
  //       ...form.getFieldsValue(),
  //       workexp: [...data.workexp, newCompany],
  //     });

  //     // setData({
  //     //   ...data,
  //     //   company: [...data.company, newCompany],
  //     // });
  //   }
  // };

  // const removeCompany = () => {
  //   if (data.workexp.length > 1) {
  //     const newArr = [...data.workexp];
  //     newArr.pop();
  //     form.setFieldsValue({
  //       ...form.getFieldsValue(),
  //       workexp: newArr,
  //     });
  //     // setData({ ...data, company: newArr });
  //   }
  // };

  // const addData = (e, i) => {
  //   e.preventDefault();
  //   const newArr = [...data.workexp];
  //   newArr[i] = { ...newArr[i], [e.target.id]: e.target.value };
  //   setData({ ...data, workexp: newArr });
  // };

  /* 
            <div className={styles['company-wrapper']}>
              <div className="form-item">
                <label for="company" className="form-label">
                  Companyo {i === 0 ? null : `- ${i + 1}`}
                </label>
                <input type="text" className="form-control form-join" id="company" value={data.company[i].company} onChange={(e) => addData(e, i)} required />
                <div className="invalid-feedback">Please input Company.</div>
              </div>

              <div className="form-item">
                <label for="position" className="form-label">
                  Position {i === 0 ? null : `- ${i + 1}`}
                </label>
                <input type="text" className="form-control form-join" id="position" value={data.company[i].position} onChange={(e) => addData(e, i)} required />
                <div className="invalid-feedback">Please input Position.</div>
              </div>

              <div className="form-item">
                <label for="yearsWorked" className="form-label">
                  Years Worked <span>(from-to)</span>
                </label>
                <input type="text" className="form-control form-join" id="yearsWorked" placeholder="e.g. January 2020 – January 2021" value={data.company[i].yearsWorked} onChange={(e) => addData(e, i)} required />
                <div className="invalid-feedback">Please input Years Worked.</div>
              </div>

              <div className="form-item">
                <label for="leavingReason" className="form-label">
                  Reason/s for Leaving leavingReasonnPlease input Reason for Leaving
                </label>
                <input type="text" className="form-control form-join full" id="leavingReason" value={data.company[i].leavingReason} onChange={(e) => addData(e, i)} required />
                <div className="invalid-feedback">Please input Reason for Leaving.</div>
              </div>
            </div> 
            */

  return (
    <>
      <h5>Employment History</h5>
      <div className={styles.rowWrapper}>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => {
            return (
              <Form.List name="workexp">
                {(fields, { add, remove }) => {
                  const handleDuplicateClick = (field) => {
                    const selectedField = getFieldValue('workexp')[field.name];
                    const { id, ...rest } = selectedField;

                    add({ ...rest, tempId: getRandomString() }, field.name + 1);
                  };
                  return (
                    <>
                      <div className={styles.inputsContainer}>
                        {fields.map((field) => (
                          <CompanyInput styles={styles} field={field} key={field.fieldKey} onDuplicate={handleDuplicateClick} onRemove={remove} getFieldValue={getFieldValue} setFieldsValue={setFieldsValue} />
                        ))}
                      </div>
                      <div className={`mt-16 ${isMobile ? '' : 'align-right'}`}>
                        <Button
                          className="btn add-exp"
                          onClick={() =>
                            add({
                              ...defaultCompany,
                              tempId: getRandomString(),
                            })
                          }>
                          Add Work Experience
                        </Button>
                      </div>
                    </>
                  );
                }}
              </Form.List>
            );
          }}
        </Form.Item>

        {/* <p>/////////////////</p>
        {data.company?.map((cp, i) => {
          return (
            <>
              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item className="form-itemz-j" label={`Company${i === 0 ? '' : `- ${i + 1}`}`} name="company" rules={[{ required: true, message: 'Please input Company.' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-itemz-j" label={`Position${i === 0 ? '' : `- ${i + 1}`}`} name="position">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-itemz-j" label="Years Worked (from-to)" name="yearsWorked" rules={[{ required: true, message: 'Please input years worked.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-j" label="Reason/s for Leaving" name="leavingReason" rules={[{ required: true, message: 'Please input reason for leaving.' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          );
        })} */}
      </div>

      <div className={styles.rowWrapper}>
        {/* <Row gutter={8}>
          <Col span={8}>
            <Form.Item className="form-itemz-j" label={`${data.company?.length === 1 ? 'Add' : data.company?.length > 1 && data.company?.length < 3 ? 'Add/Remove' : 'Remove'} another company (if applicable)`}>
              <Button type="button" style={{ marginRight: '1rem' }} className="btn list-btn-primary" onClick={removeCompany}>
                -
              </Button>

              <Button type="button" className="btn list-btn-primary" onClick={addCompany}>
                +
              </Button>
            </Form.Item>
          </Col>
        </Row> */}

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item className="form-itemz-j" label="Informal Work/Volunteer Experience (if any)" name="informalWork" rules={[{ required: true, message: 'Please input your informal/volunteer experience.' }]}>
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </div>

      {/* <div className="form-item">
        <label for="informalWork" className="form-label">
          Informal Work/Volunteer Experience <span>(if any)</span>
        </label>
        <textarea type="text" className="form-control form-join full experience" id="informalWork" rows="5" value={data.informalWork} onChange={(e) => setData({ ...data, informalWork: e.target.value })} />
      </div> */}
    </>
  );
};

export default EmploymentHistory;
