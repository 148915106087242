import { DELETE, POST } from '../request';

export async function postBulkSession(payload) {
  return POST(`sessions/bulk-create`, payload);
}

export async function deleteSession(id) {
  return DELETE(`sessions/delete/${id}`);
}

// export async function postAddSchedule(payload) {
//   return POST(`schedules/create`, payload);
// }
// export async function postGetSchedule(payload) {
//   return POST(`schedules/list`, payload);
// }
// // patch
// export async function putSetNotif(payload) {
//   const { id, ...rest } = payload;
//   return PATCH(`schedules/ereminder`, rest);
// }
// export async function postGetHistory(payload) {
//   return POST(`schedules/history`, payload);
// }
// // patch
// export async function putSaveEdit(payload) {
//   const { id, ...rest } = payload;
//   return PATCH(`schedules/edit`, rest);
// }
