import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
// redux
import { useSelector } from 'react-redux';

import { formatPrice } from '@utils/methods';

import '@styles/payments.less';

const TutorPaymentList = () => {
  const {
    tutorbase: {
      user: { payout },
    },
  } = useSelector((state) => state);
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: (e) => {
        return moment(e).format('MMM DD YYYY').toString();
      },
    },
    {
      title: "Student's Name",
      dataIndex: 'studentName',
      key: 'name',
    },
    {
      title: 'Hours Taught',
      dataIndex: 'hours',
      key: 'hours',
      align: 'center',
    },
    {
      title: 'Amount Due',
      dataIndex: 'amount',
      key: 'amountPaid',
      align: 'center',
      render: (record) => formatPrice(record),
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amount',
      key: 'amountPaid',
      align: 'center',
      render: (record) => formatPrice(record),
    },
  ];

  return (
    <div className="payment-list-wrapper">
      <h3 className="mb-5 text-center">Tutor&apos;s Payment List</h3>

      <Table dataSource={payout} columns={columns} pagination={{ position: ['bottomCenter'] }} />
    </div>
  );
};

export default TutorPaymentList;
