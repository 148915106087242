import React, { useState, useEffect } from 'react';

import Navbar from '@components/layouts/Navbar';
import Banner from '@components/Banner';

import Spinner from '@components/layouts/Spinner';
import ServicesSection from '@components/service/ServicesSection';
import RemoteSection from '@components/service/RemoteSection';

import './index.less';

const ServicePage = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {

    setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false);
    }, 100);
  }, []);

  if (loading) return <Spinner />;

  return (
    <div className="wrap">
      <Navbar />
      <Banner title="Services" />
      <ServicesSection />
      <RemoteSection />
    </div>
  );
};

export default ServicePage;
