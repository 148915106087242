import React, { useCallback, useState, useEffect } from 'react';
import { Tabs } from 'antd';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { common, tutorbase } from '@redux/combineActions';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import StudentPaymentLogTable from '../tables/StudentPaymentLogTable';
import TutorPaymentLogTable from '../tables/TutorPaymentLogTable';

import '@styles/payments.less';

// styling
import styles from './index.module.less';

const { TabPane } = Tabs;

const HeadPaymentList = () => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const {
    tutorbase: {
      user: { payment, students },
    },
  } = useSelector((state) => state);

  const [activeKey, setActiveKey] = useState('1');

  const handleCallback = (key) => {
    setActiveKey(key);
  };

  const fetchSchedAndPayment = useCallback(() => {
    // dispatch(tutorbase.payment.listPayment());
    // dispatch(tutorbase.schedule.getSchedule());
  }, [dispatch]);

  useEffect(() => {
    fetchSchedAndPayment();
  }, [fetchSchedAndPayment]);

  const handleZoomToggle = (p) => {
    dispatch(tutorbase.zoom.zoomToggle(p)).then(() => {
      dispatch(common.user.fetchUpdatedDetails());
    });
    // dispatch(tutorbase.payment.listPayment());
  };

  const handlePayTutor = (v) => {
    dispatch(tutorbase.payment.payTutor(v)).then(() => {
      dispatch(common.user.fetchUpdatedDetails());
    });
    // dispatch(tutorbase.payment.listPayment());
  };

  return (
    <div>
      {isMobile ? (
        <div className="payment-list-wrapper">
          <Tabs activeKey={activeKey} onChange={handleCallback} type="card" className="tabs">
            <TabPane tab="Student" key="1" className="tab-panel">
              <div className={styles.tabContentWrapper}>
                <h3 className="mb-1 text-center"> Student&apos;s Payment Log</h3>
                <p className="mb-5 text-center">Payments from Students</p>

                <StudentPaymentLogTable //
                  payment={payment}
                  students={students}
                  pagination={{ position: ['bottomCenter'] }}
                  zoomToggle={handleZoomToggle}
                  payTutor={handlePayTutor}
                />
              </div>
            </TabPane>
            <TabPane tab="Tutors" key="2">
              <div className={styles.tabContentWrapper}>
                <h3 className="mb-1 text-center"> Tutor&apos;s Payment Log</h3>
                <p className="mb-5 text-center">Payments to Tutors</p>
                <TutorPaymentLogTable />
              </div>
            </TabPane>
          </Tabs>
        </div>
      ) : (
        <>
          <div className="payment-list-wrapper">
            <h3 className="mb-1 text-center">Student&apos;s Payment Log</h3>
            <p className="mb-5 text-center">Payments from Students</p>

            <StudentPaymentLogTable //
              payment={payment}
              students={students}
              pagination={{ position: ['bottomCenter'] }}
              zoomToggle={handleZoomToggle}
              payTutor={handlePayTutor}
            />
          </div>

          <TutorPaymentLogTable />
        </>
      )}
    </div>
  );
};

export default HeadPaymentList;
