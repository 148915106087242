import React from 'react';
// import * as FaIcons from 'react-icons/fa';

import s1 from '@images/stock/gradeschool-boy.jpg';
import s4 from '@images/stock/jrhighshool-girl.jpg';
import s5 from '@images/stock/srhighschool-boy.jpg';

const RemoteSection = () => {
  // const style = { fill: '#9ACD32', fontSize: '2.7rem' };
  // data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="800"
  return (
    <div className="remote-section">
      <div className="title-service-section">
        <div className="post-mini-title mt-5">Perks of Enrolling</div>
        <div className="post-big-title">
          Remote Learning
          <br />
          with Tutor BASE
        </div>
        <div className="service-text-post">
          <p>
            {/* Each regular student along with their parent/guardian is entitled to an <br />
            account on our specialized Tutor BASE website that grants access to the following: */}
            Each regular student along with their parent/guardian is entitled to the following:
          </p>
        </div>
      </div>

      <div className="remote-cards-container d-flex">
        <div className="row-container">
          <div className="remote-card" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="800">
            <div className="remote-card-content">
              <div className="remote-head-content">Online Tutorial Classes</div>
              <div className="remote-text-content">
                Online tutorial classes via
                <br />
                video conferencing featuring
                <br />
                an interactive whiteboard and chat
              </div>
              <div className="remote-img-content d-flex">
                {/* <i>
                  <FaIcons.FaChalkboardTeacher style={style} />
                </i> */}
                <img src={s1} alt="why-choose" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>

          <div className="remote-card" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="200">
            <div className="remote-card-content">
              <div className="remote-head-content fs">File Storage</div>
              <div className="remote-text-content">
                File storage of school files <br />
                and learning resources to be worked on or studied during tutorial sessions
              </div>
              <div className="remote-img-content d-flex">
                {/* <i>
                  <FaIcons.FaWindowRestore style={style} />
                </i> */}
                <img src={s4} alt="why-choose" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>

          {/* <div className="remote-card">
            <div className="remote-card-content">
              <div className="remote-head-content">Personalized User Dashboard</div>
              <div className="remote-text-content">
                Personalized user dashboard <br />
                containing student’s tutorial schedule and reminder notifications via email
              </div>
              <div className="remote-img-content d-flex">
                <i>
                  <FaIcons.FaLaptopCode style={style} />
                </i>
              </div>
            </div>
          </div> */}

          <div className="remote-card" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="400">
            <div className="remote-card-content">
              <div className="remote-head-content">End-Of-Session Reports</div>
              <div className="remote-text-content">
                End-of-session reports of student’s progress and achievements to keep <br />
                track of improvements
              </div>
              <div className="remote-img-content d-flex">
                {/* <i>
                  <FaIcons.FaIdCard style={style} />
                </i> */}
                <img src={s5} alt="why-choose" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
        </div>

        <div className="row-container" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="800">
          {/* <div className="remote-card">
            <div className="remote-card-content">
              <div className="remote-head-content">Payments System</div>
              <div className="remote-text-content">Quick, easy, and convenient payments system supporting various payment methods for settling fees</div>
              <div className="remote-img-content d-flex">
                <i>
                  <FaIcons.FaCcMastercard style={style} />
                </i>
              </div>
            </div>
          </div> */}

          {/* <div className="remote-card">
            <div className="remote-card-content">
              <div className="remote-head-content">End-Of-Session Report</div>
              <div className="remote-text-content">End-of-session report of student’s progress and achievements to keep track of improvements</div>
              <div className="remote-img-content d-flex">
                <i>
                  <FaIcons.FaIdCard style={style} />
                </i>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default RemoteSection;
