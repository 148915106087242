import {
  //
  postAddNote,
  putEditNote,
  deleteNote,
  getNoteByScheduleId,
} from '@service/api/note';

// types
import * as types from '../types';

import { swalSuccess, swalError } from '@utils/methods';

export const addNote = (payload) => async (dispatch) => {
  const res = await postAddNote(payload);
  console.log('[ADDNOTE] ', res);
  if (res.success) {
    dispatch({
      type: types.NOTE_ADD,
      payload: res.data,
    });

    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }
  return res;
};

export const editNote = (payload) => async (dispatch) => {
  const res = await putEditNote(payload);
  console.log('[EDITREPORT] ', res);

  if (res.success) {
    dispatch({
      type: types.NOTE_EDIT,
      payload: res.data,
    });
  } else {
    swalError(res.msg);
  }

  return res;
};

export const removeNote = (payload) => async () => {
  const res = await deleteNote(payload);
  console.log('[REMOVENOTE res] ', res);

  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};

export const getScheduleNotes = (payload) => async (dispatch) => {
  const res = await getNoteByScheduleId(payload);
  console.log('[GETNOTEBYID res] ', res);

  if (res.success) {
    dispatch({
      type: types.NOTE_GET,
      payload: res.data,
    });
  }

  return res;
};
