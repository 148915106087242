import _ from 'lodash';

// types
import * as types from '../types';

const initialState = {};

const reducer = (state = initialState, { type, payload } = {}) => {
  // console.log('[SECVRITY GVARD tutorbase user] ', type, payload);

  switch (type) {
    /*
    case types.DRIVE_RT:
      // console.log('[DRIVE_RT tutorbase rt] ', payload);
      return { ...state, rt: payload };
    case types.MINATO_TUTORBASE:
      // console.log('[MINATO_TUTORBASE SET LOCALST tutorbase] ', { ...state, ...payload });
      // console.log('[MINATO_TUTORBASE SET LOCALST state] ', state);
      // console.log('[MINATO_TUTORBASE SET LOCALST payload] ', payload);
      localStorage.setItem('localDetails', JSON.stringify({ ...state, ...payload }));
      return { ...state, ...payload };
    case types.USER_LOGIN:
      return payload;
    case types.USER_LOGOUT:
      return initialState;
    case types.ZOOM_GET:
      return {
        ...state,
        data: { ...state.data, zoom: payload },
      };
    case types.PAYMENT_ADD:
      return {
        ...state,
        payment: {
          active: payload,
        },
      };
    case types.PAYMENT_STATUS:
      return {
        ...state,
        // payment: {
        //   active: { ...state.payment.active, status: payload.status },
        // },
      };
    case types.PAYMENT_LIST:
      return { ...state, payment: { list: payload } };
    case types.PAYMENT_ACCOUNT:
      return state;
    // return {
    //   ...state,
    //   tutors: [
    //     ...state.tutors.map((e) => {
    //       if (e.id === payload.id) {
    //         return (e = payload);
    //       } else {
    //         return e;
    //       }
    //     }),
    //   ],
    // };
    case types.DIST_ADD:
      return {
        ...state,
        disbursements: [...state.disbursements, payload],
      };
    case types.ZOOM_EDIT:
      return {
        ...state,
        zoom: payload,
      };
    case types.DRIVE_LIST:
      // console.log('[DRIVE_LIST tutorbase drive] ', payload);
      return {
        ...state,
        drive: payload,
      };
    case types.DRIVE_LINK_TUTOR:
      return {
        ...state,
        driveLink: payload,
      };
    case types.DRIVE_LINK_TUTOR_EDIT:
      return {
        ...state,
        msg: payload.msg,
      };
    */

    case types.BULK_SESSION_ADD:
      console.log('[REDVX BULK_SESSION_ADD state.sessions] ', state.sessions);
      console.log('[REDVX BULK_SESSION_ADD payload] ', payload);
      return {
        ...state,
        sessions: [...state.sessions, payload],
      };
    case types.SESSION_ADD:
      console.log('[REDVX SESSION_ADD state.sessions] ', state.sessions);
      console.log('[REDVX SESSION_ADD payload] ', payload);
      return {
        ...state,
        sessions: [...state.sessions, payload],
      };
    case types.SCHEDULE_LIST:
      console.log('[REDVX SCHEDULE_LIST payload] ', payload);
      return {
        ...state,
        sessions: payload,
      };
    case types.SCHEDULE_ADD:
      // console.log('[SCHEDULE_ADD tutorbase sessions] ', payload);
      return {
        ...state,
        sessions: [
          ...state.sessions.map((sess) =>
            sess.id === payload.SessionId
              ? {
                  ...sess,
                  Schedules: [...sess.Schedules.filter((scd) => scd.id !== payload.id), payload],
                }
              : {
                  ...sess,
                }
          ),
        ],
      };
    case types.SCHEDULE_EDIT:
      return {
        ...state,
        sessions: [
          ...state.sessions.map((sess) => {
            return {
              ...sess,
              Schedules: sess.Schedules.map((scd) => {
                // return scd.id === payload.id ? (scd = payload) : scd
                return scd.id === payload.id ? payload : scd;
              }),
            };
          }),
        ],
      };
    case types.SCHEDULE_HISTORY:
      // console.log('[REDVX SCHEDULE_HISTORY state] ', state);
      // console.log('[REDVX SCHEDULE_HISTORY state.sessions] ', state.sessions);
      // console.log('[REDVX SCHEDULE_HISTORY payload] ', payload);

      return {
        ...state,
        fromDate: payload.fromDate,
        sessions: payload.sessions,
        schedules: _.flatten(payload.sessions.map((sz) => sz.Schedules)),
      };

    /*
    case types.REPORT_ADD:
      return {
        ...state,
        sessions: [
          ...state.sessions.map((sess) => {
            return {
              ...sess,
              Schedules: sess.Schedules.map((scd) => (scd.id === payload.ScheduleId ? { ...scd, Report: payload } : scd)),
            };
          }),
        ],
      };

    case types.REPORT_EDIT:
      return {
        ...state,
        sessions: [
          ...state.sessions.map((sess) => {
            return {
              ...sess,
              Schedules: sess.Schedules.map((scd) =>
                scd.id === payload.ScheduleId
                  ? {
                      ...scd,
                      Report: {
                        ...scd.Report,
                        subject: payload.subject,
                        reportGoal: payload.reportGoal,
                        reportAdditional: payload.reportAdditional,
                      },
                    }
                  : scd
              ),
            };
          }),
        ],
      };

    case types.REPORT_HEAD_CHECK:
      return {
        ...state,
        sessions: [
          ...state.sessions.map((sess) => {
            return {
              ...sess,
              Schedules: sess.Schedules.map((scd) =>
                scd.id === payload.ScheduleId
                  ? {
                      ...scd,
                      Report: {
                        ...scd.Report,
                        isHeadChecked: !scd.Report.isHeadChecked,
                      },
                    }
                  : scd
              ),
            };
          }),
        ],
      };

    case types.REPORT_PARENT_CHECK:
      return {
        ...state,
        sessions: [
          ...state.sessions.map((sess) => {
            return {
              ...sess,
              Schedules: sess.Schedules.map((scd) =>
                scd.id === payload.ScheduleId
                  ? {
                      ...scd,
                      Report: {
                        ...scd.Report,
                        isParentChecked: !scd.Report.isParentChecked,
                      },
                    }
                  : scd
              ),
            };
          }),
        ],
      };
*/

    case types.NOTIF_SET:
      if (state.students || state.tutors) {
        return {
          ...state,
          students: [...state.students.map((s) => (s.UserId === +payload.UserId ? { ...s, mReminder: +payload.option, eReminder: +payload.option } : s))],

          tutors: [...state.tutors.map((t) => (t.UserId === +payload.UserId ? { ...t, mReminder: +payload.option, eReminder: +payload.option } : t))],
        };
      }
      return state;
    case types.JOB_LIST:
      // console.log('[JOB_LIST tutorbase jobs] ', payload);
      return {
        ...state,
        jobs: payload,
      };
    default:
      return state;
  }
};

export default reducer;
