import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { tutorbase } from '@redux/combineActions';

import Spinner from '@components/layouts/Spinner';
import NavbarDashboard from '@components/layouts/NavbarDashboard';
import HeaderDashboard from '@components/layouts/HeaderDashboard';

// import RegistrationForm from '@components/RegistrationForm';
import AccountSettings from '@components/AccountSettings';
import Notifications from '@components/notifications/Notifications';
import UserListSection from './sections/UserListSection';
import ZoomListSection from './sections/ZoomListSection';
// import ZoomSettings from '@components/ZoomSettings';

// import GoogleDriveSettings from '@components/GoogleDriveSettings';

import constants from '@utils/constants';

// styling
// import styles from './index.module.less';

const { TabPane } = Tabs;

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    common: {
      user: { account },
    },
  } = useSelector((state) => state);

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const paramsRt = params.get('rt');

  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey] = useState('1');

  const handleCallback = (key) => {
    setActiveKey(key);
  };

  const handleActiveKey = () => {
    if (account.role === constants.ROLE_TYPE.HEAD && paramsRt !== null) {
      setActiveKey('3');
    } else {
      setActiveKey('1');
    }
  };

  const handleRtArrival = useCallback(async () => {
    if (paramsRt !== null) {
      dispatch(tutorbase.drive.setRt(paramsRt));
      // history.push('settings');
      handleActiveKey();
    }
  }, [dispatch, history, paramsRt]);

  useEffect(() => {
    handleRtArrival();
  }, [handleRtArrival]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1600);
  }, []);

  if (loading) return <Spinner />;

  return (
    <div className="settings-wrapper">
      <NavbarDashboard />
      <HeaderDashboard title="SETTINGS" />

      <div className="settings">
        <Tabs activeKey={activeKey} onChange={handleCallback} type="card" className="tabs">
          <TabPane tab="Account" key="1" className="tab-panel">
            <AccountSettings account={account} />
          </TabPane>

          {account.role === constants.ROLE_TYPE.HEAD && (
            <>
              <TabPane tab="Manage Tutors and Students" key="2">
                <UserListSection />
              </TabPane>
              <TabPane tab="Zoom Details" key="3">
                {/* <ZoomSettings account={account} /> */}
                <ZoomListSection />
              </TabPane>
              {/* <TabPane tab="Google Drive" key="3">
                <GoogleDriveSettings rt={rt} relogin={paramsRt !== null} />
              </TabPane> */}
            </>
          )}
          {account.role !== constants.ROLE_TYPE.HEAD && (
            <TabPane tab="Notifications" key="2">
              <Notifications />
            </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default Settings;
