import React from 'react';
import NumberFormat from 'react-number-format';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export const spaceShipLoader = (jvdge, v) => {
  let resv = [];
  if (v !== null) {
    resv = !jvdge ? [v] : v;
  }

  const sresv = resv.sort((a, b) => moment(a.date).weekday() < moment(b.date).weekday());
  // console.log('[UNSORTED] ', resv);
  // console.log('[SORTED] ', sresv);

  return sresv;
};

export const swalError = (v, raw = false) => {
  Swal.fire({
    icon: 'error',
    // title: v,
    confirmButtonText: `Ok`,
    html: raw ? v : `<h4>${v}<h4/>`,
  });
};

export const swalSuccess = (v, raw = false) => {
  Swal.fire({
    icon: 'success',
    customClass: {
      confirmButton: 'swal-btn',
    },
    html: raw ? v : `<h4>${v}<h4/>`,
    confirmButtonText: `OK`,
  });
};

export const modalOptionsWrapper = (v, fldValue, labelValue) => {
  let options = [];
  options = v.map((d) => ({ label: d[labelValue], value: d[fldValue] }));
  return options;
};

export const convertToTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

// import not working from @constants so this was here.
const knownBooleanFields = ['pealert', 'peambulimpleft', 'peambulimpright', 'pecoopex', 'pedist', 'peovrw', 'pesobpain', 'pewd', 'peweak', 'pewn'];

export const shuffle = (arr) => {
  let currentIndex = arr.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = arr[currentIndex];
    arr[currentIndex] = arr[randomIndex];
    arr[randomIndex] = temporaryValue;
  }

  return arr;
};

export const checkRecordExisting = (arr, fld, v) => {
  return arr.find((r) => r[fld] === v);
};

export const useDebounce = (func, wait) => {
  let timeout;
  return (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(this, args);
    }, wait);
  };
};

/**
 * decode jwt
 * @param {*} params
 */
export const decode = (params) => {
  return jwtDecode(params);
};

export const checkToken = (v) => {
  // console.log('[checktoken] ', (v.exp * 1000) < Date.now())
  return v.exp * 1000 < Date.now();
};

/**
 * anti boolean 'true'
 * @param {*} params
 */
export const evalBoolean = (params) => {
  return params === 'true' || params === true;
};

export const trimSelectionItems = (v) => v.map((i) => i.trim());

export const trimArrayFields = (v) => {
  const evalObj = v;
  Object.keys(evalObj).map((fld) => {
    if (Array.isArray(evalObj[fld])) {
      evalObj[fld] = trimSelectionItems(evalObj[fld]);
    }
    return fld;
  });
  return evalObj;
};

export const evaluateBooleanFields = (v) => {
  const evalObj = v;
  Object.keys(evalObj).map((fld) => {
    if (evalObj[fld] === 'true' || evalObj[fld] === 'false') {
      evalObj[fld] = evalBoolean(evalObj[fld]);
    }

    if (knownBooleanFields.includes(evalObj[fld])) {
      evalObj[fld] = !evalObj[fld] === '';
    }

    return fld;
  });
  return evalObj;
};

export const evaluateBooleanFieldsArray = (v) => {
  const finalArr = [];
  v.map((x) => {
    const evalObj = x;
    Object.keys(evalObj).map((fld) => {
      if (evalObj[fld] === 'true' || evalObj[fld] === 'false') {
        evalObj[fld] = evalBoolean(evalObj[fld]);
      }
      return fld;
    });
    finalArr.push(evalObj);
    return x;
  });
  return finalArr;
};

/**
 * convert object to query string
 * @param {*} params
 */
export const convertQueryString = (params) => {
  return Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
};

/**
 * convert moment
 * @param {*} params
 */
export const convertMomentWithFormat = (v, fmt = 'MM/DD/YYYY') => {
  // console.log('[convertMomentWithFormat v] ', v);
  // console.log('[convertMomentWithFormat fmt] ', fmt);
  // console.log('[convertMomentWithFormat m] ', moment(v).format(fmt));
  // console.log('[convertMomentWithFormat m.utc] ', moment.utc(v).format(fmt));
  // console.log('[////////]');

  // undefined will return date today
  if (v !== '') {
    moment.tz.guess(true);
    return moment.utc(v).format(fmt);
  }
  return '';
};

/**
 * convert moment
 * @param {*} params
 */
export const convertToMomentObj = (v) => {
  return moment(v);
};

export const formatPriceX = (price, key = '') => {
  const formattedPrice = parseFloat(price)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return key === '' ? `$ ${formattedPrice}` : `${key}$ ${formattedPrice}`;
};

/**
 * format price, fot example:
 * from 20, to $20.00
 * @param {*} price
 */
export const formatPrice = (price) => <NumberFormat decimalScale={2} fixedDecimalScale value={price} displayType="text" thousandSeparator prefix="₱" />;

export const clearSessionStorage = () => {};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

/**
 * generate random string
 */
export const getRandomString = () => {
  const r = Math.random().toString(36).substring(7);
  return r;
};

export const parseDateField = (v) => {
  return v !== '' ? convertToMomentObj(v) : '';
};

export const normalizeStringToBreakTag = (value) => {
  return value?.replace(/(?:\r\n|\r|\n)/g, '<br>')?.replace(/<br>/g, '<br/>');
};

const stringSorter = (a, b) => {
  return a?.localeCompare(b);
};

export const tableStringSorter = (a, b, { valuePropsName }) => {
  if (!valuePropsName) {
    return stringSorter(a, b);
  }
  return stringSorter(a[valuePropsName], b[valuePropsName]);
};

export const getDirtyFields = (x, v) => {
  const result = {};
  Object.keys(x).map((d) => {
    if (x[d] !== v[d] && d !== 'pname') {
      if (d === 'lname' || d === 'fname' || d === 'mname') {
        result.pname = `${v.fname} ${v.mname} ${v.lname}`;
        result.fname = v.fname;
        result.mname = v.mname;
        result.lname = v.lname;
      } else {
        result[d] = v[d];
      }
    }
    return d;
  });
  return result;
};
