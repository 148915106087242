import React, { useEffect, useState } from 'react';
import { Button } from 'antd';

// hooks
// import useWindowSize from '@hooks/useWindowSize';

// styling
import styles from './index.module.less';

import { useDebounce } from '@utils/methods';

const daysMatrix = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
};

const DayHoursSelector = (props) => {
  // const { isMobile } = useWindowSize();
  const [selected, setSelected] = useState(0);
  const { title, onSubmit, triggerReset } = props;

  const handleHoursBtn = useDebounce((v) => {
    v.stopPropagation();
    if (+v.target.textContent === selected) {
      setSelected(0);
      onSubmit({ day: daysMatrix[title], hours: undefined });
    } else {
      setSelected(+v.target.textContent);
      onSubmit({ day: daysMatrix[title], hours: +v.target.textContent });
    }
  }, 300);

  useEffect(() => {
    if (triggerReset) {
      setSelected(0);
    }
  }, [triggerReset]);

  return (
    <div className="flex-column flex-start-start">
      <p className={styles.pLabel}>{title}</p>
      <div className="flex-center-start">
        <Button type="primary" className={`minus ${styles.hoursBtn} ${selected === 1 ? styles.selectedHours : styles.unselectedHours}`} id="minus" onClick={(e) => handleHoursBtn(e)}>
          1
        </Button>
        <Button type="primary" className={`minus ${styles.hoursBtn} ${selected === 2 ? styles.selectedHours : styles.unselectedHours}`} id="plus" onClick={(e) => handleHoursBtn(e)}>
          2
        </Button>
        <Button type="primary" className={`minus ${styles.hoursBtn} ${selected === 3 ? styles.selectedHours : styles.unselectedHours}`} id="minus" onClick={(e) => handleHoursBtn(e)}>
          3
        </Button>
        <Button type="primary" className={`minus ${styles.hoursBtn} ${selected === 4 ? styles.selectedHours : styles.unselectedHours}`} id="plus" onClick={(e) => handleHoursBtn(e)}>
          4
        </Button>

        <Button type="primary" className={`minus ${styles.hoursBtn} ${selected === 5 ? styles.selectedHours : styles.unselectedHours}`} id="minus" onClick={(e) => handleHoursBtn(e)}>
          5
        </Button>
        <Button type="primary" className={`minus ${styles.hoursBtn} ${selected === 6 ? styles.selectedHours : styles.unselectedHours}`} id="plus" onClick={(e) => handleHoursBtn(e)}>
          6
        </Button>
        <Button type="primary" className={`minus ${styles.hoursBtn} ${selected === 7 ? styles.selectedHours : styles.unselectedHours}`} id="minus" onClick={(e) => handleHoursBtn(e)}>
          7
        </Button>
        <Button type="primary" className={`minus ${styles.hoursBtn} ${selected === 8 ? styles.selectedHours : styles.unselectedHours}`} id="plus" onClick={(e) => handleHoursBtn(e)}>
          8
        </Button>
      </div>
    </div>
  );
};

export default DayHoursSelector;
