import {
  //
  postStudentRegister,
  postTutorRegister,
  putChangePasswd,
  deleteUser,
  putUpdateUser,
  putUpdateStudent,
  putUpdateTutor,
  putUpdateHead,
} from '@service/api/user';

import { swalSuccess, swalError } from '@utils/methods';

export const studentRegister = (payload) => async () => {
  const res = await postStudentRegister(payload);
  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};

export const tutorRegister = (payload) => async () => {
  const res = await postTutorRegister(payload);
  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};

export const deleteUserById = (payload) => async () => {
  const res = await deleteUser(payload);
  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};

export const userUpdate = (payload) => async () => {
  const res = await putUpdateUser(payload);
  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};

export const userChangePasswd = (payload) => async () => {
  const res = await putChangePasswd(payload);
  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};

export const studentUpdate = (payload) => async () => {
  const res = await putUpdateStudent(payload);
  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};

export const tutorUpdate = (payload) => async () => {
  const res = await putUpdateTutor(payload);
  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};

export const headUpdate = (payload) => async () => {
  const res = await putUpdateHead(payload);
  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError(res.msg);
  }

  return res;
};
