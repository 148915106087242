import { GET, PATCH, POST } from '../request';

export async function postListDrive(payload) {
  const { rt } = payload;
  return POST(`drive/head`, { ...payload, rt: decodeURIComponent(rt) });
}

export async function postListDriveStud(payload) {
  const { rt } = payload;
  return POST(`drive/student`, { ...payload, rt: decodeURIComponent(rt) });
}

export async function postCreateDirectory(payload) {
  const { rt } = payload;
  return POST(`drive/createDir`, { ...payload, rt: decodeURIComponent(rt) });
}
// patch
export async function putLinkDriveEdit(payload) {
  const { id, ...rest } = payload;
  return PATCH(`drive/update/tutor-link`, rest);
}

export async function postLinkDrive(payload) {
  const { rt } = payload;
  return POST(`drive/tutor-link`, { ...payload, rt: decodeURIComponent(rt) });
}

export async function getGoogleAuthorization() {
  return GET(`drive/authorize`);
}

export async function getDriveLink() {
  return GET(`drive/drive-link`);
}

export async function postDriveRoot(payload) {
  const { rt } = payload;
  return POST(`drive/drive-root`, { ...payload, rt: decodeURIComponent(rt) });
}
