import { putZoomEdit, putZoomToggle } from '@service/api/zoom';

import { swalSuccess, swalError } from '@utils/methods';
// types
// import * as types from '../types';

export const zoomEdit = (payload) => async () => {
  const res = await putZoomEdit(payload);

  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError('Failed to update zoom details.');
  }

  return res;
};

// patch
export const zoomToggle = (payload) => async () => {
  const res = await putZoomToggle(payload);

  if (res.success) {
    swalSuccess(res.msg);
  } else {
    swalError('Failed.');
  }

  return res;
};
