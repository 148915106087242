import React, { useEffect } from 'react';
import { Button, Descriptions, Modal } from 'antd';
import moment from 'moment';
import { startOfWeek } from 'date-fns';
import { useHistory } from 'react-router-dom';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import { useDebounce } from '@utils/methods';

const sessionWeek = startOfWeek(new Date(), {
  weekStartsOn: 1,
});

const EditSlotModal = (props) => {
  const { isMobile } = useWindowSize();
  const history = useHistory();
  const { info, tutors, sessions, visible, onCancel } = props;

  // console.log('[EDITSLOTMODAL] ', props);

  // const checkHours = (id) => {
  //   const sess = sessions.find((s) => s.StudentId === id);
  //   return sess?.availTime === 0;
  // };

  const toReports = useDebounce(() => history.push('/reports'), 200);
  // const [editing, setEditing] = useState(false);
  // const [data, setData] = useState({
  //   ScheduleId: '',
  //   TutorId: '',
  //   subject: '',
  // });

  const resetSchedule = useDebounce(() => {
    props.resetSchedule(info.id);
  }, 200);

  const fetchSessionTime = () => {
    const time = info?.time ?? 0;
    const hours = info?.hours ?? 0;
    const endHours = time + hours;
    const startTime = moment(`${time}:00`, 'HH:mm').format('h:mma');
    const endTime = moment(`${endHours}:00`, 'HH:mm').format('h:mma');
    return `${startTime} - ${endTime}`;
  };

  const handleModalCancel = useDebounce(() => onCancel(), 200);

  const isZoomDetailsAvailable = () => {
    const sez = sessions?.find((sz) => sz.id === info?.SessionId);
    const isStudentPaid = sez?.isPaid;
    const isMeetingUrlValid = sez?.ZoomDetail.isVisible && sez?.ZoomDetail.meetingURL && sez?.ZoomDetail.meetingURL !== null;

    console.log('[EDIT isZoomDetailsAvailable sez, isStudentPaid, isMeetingUrlValid] ', sez, isStudentPaid, isMeetingUrlValid);
    return isStudentPaid && isMeetingUrlValid;
  };

  const handleJoinMeeting = useDebounce(() => {
    const sez = sessions?.find((sz) => sz.id === info?.SessionId);
    window.open(sez?.ZoomDetail.meetingURL, '_blank');
  }, 200);

  useEffect(() => {
    if (info) {
      // setData({
      //   ScheduleId: info.id,
      //   TutorId: info.TutorId,
      //   subject: info.subject,
      // });
    }
  }, [info]);

  if (!tutors) return null;

  return (
    <Modal //
      closable={false}
      centered
      title="Session Schedule"
      open={visible}
      width={isMobile ? '95%' : '40%'}
      // onOk={handleModalOk}
      // okText="Update"
      // cancelText="Cancel"
      // onCancel={handleModalCancel}
      zIndex={1021}
      footer={
        <div className="flex-start-end">
          <Button type="primary" onClick={handleModalCancel}>
            Cancel
          </Button>
          <Button disabled={moment(info?.slotId.date, 'MMMDD').isBefore(sessionWeek)} type="primary" onClick={resetSchedule}>
            Reset
          </Button>
        </div>
      }>
      <Descriptions column={1} bordered>
        <Descriptions.Item
          label={
            <>
              <i className="far fa-calendar-alt" /> <span>Schedule Date:</span>
            </>
          }>
          {moment(info?.slotId.date, 'MMMDD').format('dddd, MMMM D, YYYY')}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <>
              <i className="far fa-clock" /> <span>Time:</span>
            </>
          }>
          {fetchSessionTime()}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <>
              <i className="fa fa-graduation-cap" />
              <span>Student:</span>
            </>
          }>
          {info?.Student.name}
          {/* <Select //
            disabled={true}
            style={{ width: '100%' }}
            allowClear
            showSearch
            placeholder="Select Student's Name..."
            optionFilterProp="children"
            value={[info?.Student.id]}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
            {students
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((e, i1) => {
                return (
                  <Option //
                    disabled={checkHours(e.StudentId)}
                    key={`st${i1}`}
                    value={e.id}>
                    {e.name}
                  </Option>
                );
              })}
          </Select> */}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <>
              <i className="fa fa-chalkboard-teacher" /> <span>Tutor:</span>
            </>
          }>
          {info?.Tutor.name}
          {/* <Select //
            disabled={true}
            style={{ width: '100%' }}
            allowClear
            showSearch
            placeholder="Select Tutor's Name..."
            optionFilterProp="children"
            value={[info?.Tutor.id]}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
            {tutors
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((e, i1) => {
                return (
                  <Option //
                    key={`t${i1}`}
                    value={e.id}>
                    {e.name}
                  </Option>
                );
              })}
          </Select> */}
        </Descriptions.Item>

        {/* <Descriptions.Item
          label={
            <>
              <i className="fa fa-book-open" /> <span>Subject:</span>
            </>
          }>
          <select
            style={{ width: '100%' }}
            defaultValue={info?.subject}
            value={data.subject}
            selected
            onChange={(e) =>
              setData({
                ...data,
                subject: e.target.value,
              })
            }>
            {subjects.map((e) => {
              return <option value={e}>{e}</option>;
            })}
          </select>
        </Descriptions.Item> */}

        <Descriptions.Item
          label={
            <>
              <i className="fa fa-graduation-cap" />
              <span>Hours:</span>
            </>
          }>
          {info?.hours} hour/s
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <>
              <i className="far fa-file-alt" /> <span>Session Report:</span>
            </>
          }>
          <Button style={{ width: '50%' }} disabled={!info?.Report?.isHeadChecked || moment(info?.viewDate).isBefore(sessionWeek)} type="primary" onClick={() => toReports()}>
            {!info?.Report?.isHeadChecked ? 'Not Available' : 'Available'}
          </Button>
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <>
              <i className="fa fa-video" /> <span>Zoom Meeting:</span>
            </>
          }>
          <Button style={{ width: '50%' }} disabled={!isZoomDetailsAvailable()} type="primary" onClick={() => handleJoinMeeting()}>
            {!isZoomDetailsAvailable() ? 'Unavailable' : 'Join Meeting'}
          </Button>
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default EditSlotModal;
