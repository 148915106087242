import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
// redux
import { useSelector } from 'react-redux';

import NavbarDashboard from '@components/layouts/NavbarDashboard';
import HeaderDashboard from '@components/layouts/HeaderDashboard';

import Spinner from '@components/layouts/Spinner';
import HeadPaymentList from '@components/payments/HeadPaymentList';
import StudentPaymentList from '@components/payments/StudentPaymentList';
import TutorPaymentList from '@components/payments/TutorPaymentList';
import constants from '@utils/constants';

import '@styles/payments.less';

const Payments = () => {
  const {
    common: {
      user: { account },
    },
    tutorbase: {
      user: { sessions, payment },
    },
  } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1600);
  }, [payment]);

  const showStudentPaymentList = () => {
    let resvlt = false;

    if (payment.active && payment.active.status !== 'PAID') {
      resvlt = true;
    }
    if (payment.list && payment.list.length > 0) {
      resvlt = true;
    }

    // console.log('[SHOW PAYMENT LIST payment.active] ', payment.active);
    // // console.log('[SHOW PAYMENT LIST payment.active.status] ', payment.active.status);
    // console.log('[SHOW PAYMENT LIST payment.list] ', payment.list);
    // console.log('[SHOW PAYMENT LIST resvlt] ', resvlt);
    // console.log('[/////////]');

    return resvlt;
  };

  const showUnpaidSessions = () => {
    let resvlt = false;
    let sezs = [];

    if (account.role === constants.ROLE_TYPE.STUDENT) {
      sezs = account.Sessions;
    } else {
      sezs = sessions;
    }

    if (sezs && payment) {
      const session = sezs.filter((s) => s.isPaid === false && s.StudentId === account.id);
      if (session.length > 0 && !payment.active) {
        resvlt = true;
      }
    }

    // console.log('[SHOW UNPAID account.id] ', account, account.id);
    // console.log('[SHOW UNPAID sessions] ', sessions);
    // console.log('[SHOW UNPAID payment] ', payment);
    // console.log('[SHOW UNPAID resvlt] ', resvlt);
    return resvlt;
  };

  const renderPaymentContent = () => {
    return (
      <>
        {account.role === constants.ROLE_TYPE.HEAD && <HeadPaymentList />}
        {account.role === constants.ROLE_TYPE.TUTOR && <TutorPaymentList />}
        {account.role === constants.ROLE_TYPE.STUDENT && showStudentPaymentList() && <StudentPaymentList />}
        {account.role === constants.ROLE_TYPE.STUDENT && showUnpaidSessions() && <Redirect to="/checkout" />}
      </>
    );
  };
  if (loading) return <Spinner />;

  return (
    <>
      <NavbarDashboard />
      <HeaderDashboard title="PAYMENTS" />
      <div className="payments-wrapper">{renderPaymentContent()}</div>
    </>
  );
};

export default Payments;
