import React, { useEffect } from 'react';
import moment from 'moment';

import { Descriptions, Form, Input, Modal, Select } from 'antd';

// hooks
import useWindowSize from '@hooks/useWindowSize';

// styling
// import styles from './index.module.less';

const { Option } = Select;

const EditReportModal = (props) => {
  const { data, onCancel, schedules, submitReport, visible } = props;
  const { isMobile } = useWindowSize();
  const [form] = Form.useForm();
  const handleEditReport = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        const { scheduleSelector, ...resValues } = values;
        submitReport(resValues);
      })
      .catch((er) => {
        console.log('[Validate Failed]: ', er);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleFormValuesChange = (e) => {
    if (e.scheduleSelector) {
      const schedule = schedules?.find((sched) => sched.id === e.scheduleSelector);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        TutorId: schedule?.Tutor.id,
        ScheduleId: schedule?.id,
        date: schedule?.date,
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      scheduleSelector: data.ScheduleId,
    });
  }, [data]);

  return (
    <Modal //
      centered
      title="Edit Session Report"
      open={visible}
      destroyOnClose
      width={isMobile ? '95%' : '40%'}
      onOk={handleEditReport}
      okText="Save"
      zIndex={1021}
      onCancel={handleCancel}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Student:">{data.StudentName}</Descriptions.Item>

        <Descriptions.Item label="Tutor:">{data.TutorName}</Descriptions.Item>
      </Descriptions>

      <Form //
        initialValues={{ ...data, scheduleSelector: data.ScheduleId }}
        form={form}
        layout="vertical"
        style={{ marginTop: 10 }}
        onValuesChange={handleFormValuesChange}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item hidden name="TutorId">
          <Input />
        </Form.Item>

        <Form.Item hidden name="ScheduleId">
          <Input />
        </Form.Item>
        <Form.Item hidden name="date">
          <Input />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'This field is required!' }]} label="Session Date" name="scheduleSelector">
          <Select //
            showSearch
            placeholder="Select Session Date..."
            value={[data.ScheduleId]}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
            {schedules
              .sort((a, b) => a.date.localeCompare(b.date))
              .map((e, i1) => {
                return (
                  <Option //
                    key={`st${i1}`}
                    value={e.id}>
                    {e?.day}, {moment(e?.date).format('DD MMM yyyy')}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'This field is required!' }]} label="Subjects/Topics Discussed & Task Accomplished" name="subject">
          <Input />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'This field is required!' }]} label="Points Of Improvement & Goal for Next Session" name="reportGoal">
          <Input />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'This field is required!' }]} label="Additional Notes (extension, sub, etc.)" name="reportAdditional">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );

  // return (
  //   <>
  //     <div className="modal" id="editReportModal" tabIndex="-1" role="dialog" aria-labelledby="editReportModalTitle" aria-hidden="true">
  //       <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
  //         <div className="modal-content">
  //           <div className="modal-header">
  //             <h5 className="modal-title" id="addReportModalLongTitle">
  //               Edit Report
  //             </h5>
  //             <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => closeBtn()}>
  //               <span aria-hidden="true">&times;</span>
  //             </button>
  //           </div>

  //           <div className="modal-body ">
  //             <div className="add-report-wrapper">
  //               <table>
  //                 <tr>
  //                   <td>
  //                     Student<span>:</span>
  //                   </td>
  //                   <td>{props.schedules[0]?.Student.name}</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     Tutor<span>:</span>
  //                   </td>
  //                   {props.schedules[0]?.Tutor.name}
  //                 </tr>
  //               </table>

  //               <form className="form">
  //                 <div className="mb-1 d-flex flex-column">
  //                   <label for="studentName">Session Date | Subject</label>
  //                   <select
  //                     className="form-select modal-select"
  //                     style={{ width: '18rem', boxShadow: 'none' }}
  //                     aria-label="Default select example"
  //                     // value={props.data.date}
  //                     disabled={true}
  //                     onChange={(e) => setSchedule(e)}>
  //                     {/* <option selected>Select Tutor Session...</option> */}
  //                     {props.schedules?.map((e, k1) => {
  //                       return e.id === props.data.ScheduleId ? (
  //                         <option key={k1} value={e.id}>
  //                           {e?.day}, {format(new Date(e?.date), 'dd MMMM yyyy')} | {e?.subject}
  //                         </option>
  //                       ) : null;
  //                     })}
  //                   </select>

  //                   <div className="">
  //                     <label for="inputParentsEmail" className="form-label">
  //                       Subjects/Topics Discussed & Task Accomplished
  //                     </label>
  //                     <input
  //                       type="email"
  //                       className="form-control"
  //                       id="inputParentsEmail"
  //                       value={props.data.subject}
  //                       onChange={(e) =>
  //                         props.setData({
  //                           ...props.data,
  //                           subject: e.target.value,
  //                         })
  //                       }
  //                     />
  //                   </div>

  //                   <div className="col-md-12">
  //                     <label for="inputParentsEmail" className="form-label">
  //                       Points Of Improvement & Goal for Next Session
  //                     </label>
  //                     <input
  //                       type="email"
  //                       className="form-control"
  //                       id="inputParentsEmail"
  //                       value={props.data.reportGoal}
  //                       onChange={(e) =>
  //                         props.setData({
  //                           ...props.data,
  //                           reportGoal: e.target.value,
  //                         })
  //                       }
  //                     />
  //                   </div>

  //                   <div className="">
  //                     <label for="inputParentsEmail" className="form-label">
  //                       Additional Notes (extension, sub, etc.)
  //                     </label>
  //                     <input
  //                       type="email"
  //                       className="form-control"
  //                       id="inputParentsEmail"
  //                       value={props.data.reportAdditional}
  //                       onChange={(e) =>
  //                         props.setData({
  //                           ...props.data,
  //                           reportAdditional: e.target.value,
  //                         })
  //                       }
  //                     />
  //                   </div>
  //                 </div>
  //               </form>
  //             </div>
  //           </div>
  //           <div className="modal-footer">
  //             <button type="button" className="btn btn-primary" onClick={(e) => props.submitReport(e)} data-dismiss="modal" aria-label="Close">
  //               Update Report
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default EditReportModal;
