import React, { useState } from 'react';
import moment from 'moment';
import { format } from 'date-fns';

import { Button, Card, Col, Collapse, Form, Modal, Row, Space, Select, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

// hooks
import useWindowSize from '@hooks/useWindowSize';
import useOpener from '@hooks/useOpener';

import { formatPrice, useDebounce } from '@utils/methods';

import DayHoursSelector from '../DayHoursSelector';

// styling
import styles from './index.module.less';

const { Panel } = Collapse;
const { Option } = Select;

const initialValues = {
  StudentId: '',
  daysHours: [],
};

const BulkScheduleModal = (props) => {
  const { isMobile } = useWindowSize();
  const { isOpen: isResetTriggered, toggleOpen: toggleResetTrigger } = useOpener();
  const [form] = Form.useForm();
  const { sessionDate, students, onSubmit, visible, onCancel } = props;
  const [sessionList, setSessionList] = useState([]);

  const handleBulkSessions = useDebounce(() => {
    if (sessionList.length > 0) {
      onSubmit(sessionList);
      setSessionList([]);
    }
  }, 300);

  const handleFormValuesChange = () => {
    if (isResetTriggered) {
      toggleResetTrigger();
    }
  };

  const handleCancel = useDebounce(() => {
    form.resetFields();
    setSessionList([]);
    toggleResetTrigger();
    onCancel();
  }, 300);

  const handleDelete = useDebounce((e, v) => {
    e.stopPropagation();
    const filtered = sessionList.filter((sl) => sl.StudentId !== v);
    setSessionList(filtered);
  }, 300);

  const handleDayHours = (v) => {
    const daysHours = form.getFieldValue('daysHours');
    const existing = daysHours.find((dh) => dh.day === v.day);
    if (existing) {
      const filtered = daysHours.filter((dh) => dh.day !== existing.day);
      if (!v.hours) {
        form.setFieldsValue({
          ...form.getFieldsValue(),
          daysHours: filtered,
        });
      } else {
        form.setFieldsValue({
          ...form.getFieldsValue(),
          daysHours: [...filtered, v],
        });
      }
    } else {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        daysHours: [...daysHours, v],
      });
    }
    return false;
  };

  const getDateFromStartDateDays = (day) => {
    let dt = '';

    if (day === 'Mon') dt = moment(sessionDate);
    if (day === 'Tue') dt = moment(sessionDate).add('days', 1);
    if (day === 'Wed') dt = moment(sessionDate).add('days', 2);
    if (day === 'Thu') dt = moment(sessionDate).add('days', 3);
    if (day === 'Fri') dt = moment(sessionDate).add('days', 4);
    if (day === 'Sat') dt = moment(sessionDate).add('days', 5);
    if (day === 'Sun') dt = moment(sessionDate).add('days', 6);

    return format(new Date(dt), 'dd MMMM yyyy');
  };

  const handleAddSchedule = () => {
    form
      .validateFields()
      .then((values) => {
        const { daysHours } = values;
        const existing = sessionList.find((sl) => sl.StudentId === values.StudentId);
        let totalHrs = 0;

        form.resetFields();
        const modDaysHours = daysHours.map((dh) => {
          totalHrs += dh.hours;
          return {
            ...dh,
            date: getDateFromStartDateDays(dh.day),
          };
        });
        const student = students.find((s) => s.id === values.StudentId);
        const amount = totalHrs * (student.international ? 500 : 400);
        const payload = { ...values, daysHours: modDaysHours, date: sessionDate, defaultTime: totalHrs, amount };

        if (totalHrs === 0) {
          return false;
        }

        if (existing) {
          const filtered = sessionList.filter((sl) => sl.StudentId !== existing.StudentId);
          filtered.push(payload);
          setSessionList(filtered);
        } else {
          setSessionList([...sessionList, payload]);
        }

        toggleResetTrigger();

        return false;
      })
      .catch((er) => {
        console.log('Validate Failed:', er);
      });
  };

  const columns = [
    {
      title: 'Date',
      render: (record) => moment(record.date).format('MMM D YYYY').toString(),
    },
    {
      title: 'Student Name',
      render: (record) => {
        const student = students.find((s) => s.id === record.StudentId);
        return student.name;
      },
    },
    {
      title: 'Total Hours',
      dataIndex: 'defaultTime',
      key: 'defaultTime',
    },
    {
      title: 'Amount',
      render: (record) => formatPrice(record.amount),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          <Button className={styles.btnLeft} icon={<DeleteOutlined />} onClick={(e) => handleDelete(e, record.StudentId)} />
        </Space>
      ),
    },
  ];

  return (
    <Modal //
      centered
      title="Set Bulk Sessions"
      open={visible}
      destroyOnClose
      width={isMobile ? '95%' : '90%'}
      onOk={handleBulkSessions}
      okText="Add Sessions"
      zIndex={1021}
      onCancel={handleCancel}>
      <Row gutter={8}>
        <Col span={10}>
          <Card>
            <Form //
              initialValues={initialValues}
              form={form}
              layout="vertical"
              style={{ marginTop: 10 }}
              onValuesChange={handleFormValuesChange}>
              <Form.Item rules={[{ required: true, message: 'This field is required!' }]} label="Student's Name" name="StudentId">
                <Select //
                  allowClear
                  showSearch
                  placeholder="Select Student's Name..."
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                  {students
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((e, i1) => {
                      return (
                        <Option key={`st${i1}`} value={e.id}>
                          {e.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item hidden name="daysHours" />

              <Form.Item>
                <Collapse style={{ marginBottom: '1rem', width: '100%' }} accordion>
                  <Panel header="Select Days and Hours" key="1">
                    <DayHoursSelector title="Monday" onSubmit={(e) => handleDayHours(e)} triggerReset={isResetTriggered} />
                    <DayHoursSelector title="Tuesday" onSubmit={(e) => handleDayHours(e)} triggerReset={isResetTriggered} />
                    <DayHoursSelector title="Wednesday" onSubmit={(e) => handleDayHours(e)} triggerReset={isResetTriggered} />
                    <DayHoursSelector title="Thursday" onSubmit={(e) => handleDayHours(e)} triggerReset={isResetTriggered} />
                    <DayHoursSelector title="Friday" onSubmit={(e) => handleDayHours(e)} triggerReset={isResetTriggered} />
                    <DayHoursSelector title="Saturday" onSubmit={(e) => handleDayHours(e)} triggerReset={isResetTriggered} />
                    {/* <DayHoursSelector title="Sunday" onSubmit={(e) => handleDayHours(e)} triggerReset={isResetTriggered} /> */}
                  </Panel>
                </Collapse>
              </Form.Item>

              <Form.Item style={{ textAlign: 'end' }}>
                <Button type="primary" onClick={handleAddSchedule}>
                  &gt;&gt;
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        <Col span={14}>
          <Table columns={columns} dataSource={sessionList} />
        </Col>
      </Row>
    </Modal>
  );
};

export default BulkScheduleModal;
