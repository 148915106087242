import React, { useEffect, useState } from 'react';
// antd
import { Checkbox, Col, Form, Input, Radio, Row } from 'antd';

// hooks
import useOpener from '@hooks/useOpener';
import useWindowSize from '@hooks/useWindowSize';

import SubjectCheckboxes from '../SubjectCheckboxes';
import WorkScheduleCheckboxes from '../WorkScheduleCheckboxes';

const { TextArea } = Input;
const sourceOptions = [
  {
    label: 'Advertisement',
    value: 'Advertisement',
  },
  {
    label: 'Facebook',
    value: 'Facebook',
  },
  {
    label: 'Google',
    value: 'Google',
  },
  {
    label: 'LinkedIn',
    value: 'LinkedIn',
  },
  {
    label: 'Website',
    value: 'Website',
  },
  {
    label: 'Word Of Mouth',
    value: 'Word Of Mouth',
  },
  {
    label: 'Others',
    value: 'Others',
  },
];

const ApplicationForTutorPosition = (props) => {
  const { isMobile } = useWindowSize();
  const { resetBoxes, styles } = props;
  const { isOpen: showInputOthers, toggleOpen: toggleInputOthers } = useOpener();
  const [clearBoxes, setClearBoxes] = useState(false);

  useEffect(() => {
    if (resetBoxes && resetBoxes === true) {
      setClearBoxes(true);
    }
  }, [resetBoxes]);

  // const showHideOthers = () => {
  //   if (form.getFieldValue('sourceInformation').includes('Other')) {
  //     return false;
  //   }

  //   return true;
  // };

  // const addData = (e) => {
  //   if (!e.target.checked && e.target.value === 'others') {
  //     props.setData({
  //       ...props.data,
  //       sourceInformation: { ...props.data.sourceInformation, others: null },
  //     });
  //   } else if (e.target.checked && !props.data.sourceInformation.source.includes(e.target.value)) {
  //     props.setData({
  //       ...props.data,
  //       sourceInformation: {
  //         ...props.data.sourceInformation,
  //         source: [...props.data.sourceInformation.source, e.target.value],
  //       },
  //     });
  //   } else if (!e.target.checked && props.data.sourceInformation.source.includes(e.target.value)) {
  //     props.setData({
  //       ...props.data,
  //       sourceInformation: {
  //         ...props.daya.sourceInformation,
  //         source: props.data.sourceInformation.source.filter((item) => item !== e.target.value),
  //       },
  //     });
  //   } else {
  //     return;
  //   }
  // };

  const handleCheckboxChange = (v) => {
    // console.log('[HANDLE CHANGE] ', v);
    // if (v.includes('Others')) {
    //   toggleInputOthers();
    // } else {
    //   toggleInputOthers();
    // }
  };

  const CustomCheckbox = ({ className, label, value }) => {
    return (
      <Checkbox className={className} key={value} value={value}>
        {label}

        {className === 'rocket' && (
          <Form.Item style={{ width: '5rem' }} className="form-itemz-j astronaut" label="" name="howdidyouhearother">
            <Input />
          </Form.Item>
        )}
      </Checkbox>
    );
  };

  return (
    <>
      <h5>Application For Tutor Position</h5>

      <div className={styles.rowWrapper}>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item className="form-itemz-j" label="How did you hear about Tutor BASE?" name="howdidyouhear" rules={[{ required: true, message: 'Please tick a box.' }]}>
              {/* <Checkbox.Group //
                options={sourceOptions}
                onChange={handleCheckboxChange}
              /> */}

              <Checkbox.Group
                className="hdyh_group"
                style={
                  isMobile
                    ? {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline',
                      }
                    : {}
                }>
                {sourceOptions.map((option, idx) => {
                  return <CustomCheckbox className={option.value === 'Others' ? 'rocket' : ''} label={option.label} key={idx} value={option.value} />;
                })}
              </Checkbox.Group>
            </Form.Item>
            {/* {showInputOthers && ( */}

            {/* )} */}
          </Col>
        </Row>

        {/* <Row gutter={8}>
          <Col span={24}>
            <Form.Item className="form-itemz-j" label="If you selected others, please specify here" name="howdidyouhearother">
              <Input />
            </Form.Item>
          </Col>
        </Row> */}

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item className="form-itemz-j" label="Do you know anyone who is/was working or enrolled at Tutor BASE? (please specify if any)" name="referral" rules={[{ required: true, message: 'Please input your referral.' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item className="form-itemz-j" label="Please explain in no more than 5 sentences why you want to teach at Tutor BASE." name="reasons" rules={[{ required: true, message: 'Please input your reason.' }]}>
              <TextArea maxLength={200} rows={5} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <SubjectCheckboxes styles={styles} data={props.data} setData={props.setData} />
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item className="form-itemz-j" label="How many hours per week on average are you available to tutor?" name="hours" rules={[{ required: true, message: 'Please input your available hours.' }]}>
              <Input placeholder="e.g. 40 hours" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <WorkScheduleCheckboxes styles={styles} data={props.data} setData={props.setData} resetBoxes={clearBoxes} />
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item className="form-itemz-j" label="Please describe your teaching or tutoring experience (if any)." name="experience" rules={[{ required: true, message: 'Please input your experience.' }]}>
              <TextArea maxLength={200} rows={5} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item className="form-itemz-j" label="Do you have any training or experience in working with children with learning disabilities?" name="disability" rules={[{ required: true, message: 'Please input your disability experience.' }]}>
              <Radio.Group>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item className="form-itemz-j" label="Are there any special skills you can teach for our summer fun programs and master classes?" name="summerSkills" rules={[{ required: true, message: 'Please input your skills.' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ApplicationForTutorPosition;
