import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './combineReducers';

// import { composeWithDevTools } from 'redux-devtools-extension';
// import { persistStore, persistCombineReducers } from 'redux-persist';
// import storage from 'redux-persist/es/storage';

const initialState = {};
const middleware = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ ? compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) : compose(applyMiddleware(thunk));
const store = createStore(reducers, initialState, middleware);

export default store;

// const middleware = [thunk];
// const config = {
//   key: 'root', // 'primary',
//   storage,
// };
// console.log('[ROOT REDUCERS] ', reducers);
// let persistedReducer = persistCombineReducers(config, reducers);

// export default () => {
//   let store = createStore(persistedReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
//   let persistor = persistStore(store);
//   return {
//     store,
//     persistor,
//   };
// };
