import React from 'react';
import { format } from 'date-fns';

// styling
import styles from './index.module.less';

const DisplayPersonalInformation = (props) => {
  const formatDate = (v) => {
    if (v) return format(new Date(v), 'dd MMM yyyy');
    return '';
  };

  return (
    <>
      <h5>Personal Information</h5>

      <div className={styles.wrapper_level0}>
        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Application Date:</p>
          <p>{formatDate(props.app?.applicationDate)}</p>
        </div>
        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Date Available to Start:</p>
          <p>{formatDate(props.app?.availableDate)}</p>
        </div>
        <br />
        <br />
        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Full Name:</p>
          <p>{props.app?.fullName}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Preferred Pronouns:</p>
          <p>{props.app?.pronouns}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Mobile Number:</p>
          <p>{props.app?.phone}</p>
        </div>
        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Email Address:</p>
          <p>{props.app?.email}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Facebook Account Link:</p>
          <p>{props.app?.facebook}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Complete Address:</p>
          <p>{props.app?.address}</p>
        </div>
      </div>
    </>
  );
};

export default DisplayPersonalInformation;
