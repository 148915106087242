import React from 'react';

// antd
import { Button, Tooltip, Modal } from 'antd';

// antd icons
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// styling
import '../index.module.less';

const { confirm } = Modal;

export const zoomListColumn = () => [
  {
    title: 'Session ID',
    dataIndex: 'SessionId',
    render: (record) => <span style={{ color: '#6495ed', cursor: 'pointer' }}>{record}</span>,
  },
  {
    title: 'Meeting ID',
    dataIndex: 'meetingID',
    render: (record) => (record !== null ? record : 'Empty'),
  },
  {
    title: 'Meeting Passcode',
    dataIndex: 'meetingPasscode',
    render: (record) => (record !== null ? record : 'Empty'),
  },
  {
    title: 'Meeting URL',
    dataIndex: 'meetingURL',
    render: (record) => (record !== null ? record : 'Empty'),
  },
];

export const studentListColumn = (removeLabel) => [
  {
    title: 'ID',
    dataIndex: 'UserId',
    key: 'UserId',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (record) => <span style={{ color: '#6495ed', cursor: 'pointer' }}>{record}</span>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Actions',
    dataIndex: '',
    key: 'actions',
    render: (record) => {
      const handleShowConfirmDeleteModal = () => {
        confirm({
          title: 'Are you sure you want to delete this record and all related data?',
          icon: <ExclamationCircleOutlined />,
          onOk() {
            removeLabel(record);
          },
          onCancel() {
            return null;
          },
        });
      };

      return (
        <Tooltip placement="top" title="Delete record">
          <Button
            icon={<DeleteOutlined />}
            onClick={(event) => {
              event.stopPropagation();
              handleShowConfirmDeleteModal();
            }}
          />
        </Tooltip>
      );
    },
  },
];

export const tutorListColumn = (removeLabel) => [
  {
    title: 'ID',
    dataIndex: 'UserId',
    key: 'UserId',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (record) => <span style={{ color: '#6495ed', cursor: 'pointer' }}>{record}</span>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Actions',
    dataIndex: '',
    key: 'actions',
    render: (record) => {
      const handleShowConfirmDeleteModal = () => {
        confirm({
          title: 'Are you sure you want to delete this record and all related data?',
          icon: <ExclamationCircleOutlined />,
          onOk() {
            removeLabel(record);
          },
          onCancel() {
            return null;
          },
        });
      };

      return (
        <Tooltip placement="top" title="Delete record">
          <Button
            icon={<DeleteOutlined />}
            onClick={(event) => {
              event.stopPropagation();
              handleShowConfirmDeleteModal();
            }}
          />
        </Tooltip>
      );
    },
  },
];
