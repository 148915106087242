import React, { useState, useEffect } from 'react';

// redux
import { useSelector } from 'react-redux';
import { tutorbase } from '@redux/combineActions';
// hooks
// import useOpener from '@hooks/useOpener';

import NavbarDashboard from '@components/layouts/NavbarDashboard';
import HeaderDashboard from '@components/layouts/HeaderDashboard';

import Spinner from '@components/layouts/Spinner';
import ReportsList from '@components/reports/ReportsList';

// styling
import styles from './index.module.less';

const Reports = () => {
  // const { isOpen: isAddSlotOpen, toggleOpen: toggleAddSlotOpen } = useOpener();
  const { user: userState } = useSelector((state) => state.tutorbase);
  const { data, sessions } = userState;
  const user = data;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1600);
  }, []);

  if (loading) return <Spinner />;

  return (
    <>
      <NavbarDashboard />
      <HeaderDashboard />
      <div className={styles.reportWrapper}>
        <div className={styles.reportSectionWrapper}>
          {/* <h3>End-Of-Session Reports</h3> */}

          <div className={styles.reportsLog}>
            <ReportsList //
              sessions={sessions}
              addReport={tutorbase.report.addReport}
              editReport={tutorbase.report.editReport}
              headCheck={tutorbase.report.headCheck}
              parentCheck={tutorbase.report.parentCheck}
              user={user}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
