import React, { useEffect, useState } from 'react';
import _ from 'lodash';
// antd
import { Checkbox, Col, Form, Row } from 'antd';
// hooks
import useOpener from '@hooks/useOpener';
import useWindowSize from '@hooks/useWindowSize';

import { useDebounce } from '@utils/methods';

const weekdays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

const WorkScheduleCheckboxes = (props) => {
  const { resetBoxes, styles } = props;
  const { isMobile } = useWindowSize();
  const { isOpen: isMonOpen, toggleOpen: toggleMonOpen } = useOpener();
  const { isOpen: isTueOpen, toggleOpen: toggleTueOpen } = useOpener();
  const { isOpen: isWedOpen, toggleOpen: toggleWedOpen } = useOpener();
  const { isOpen: isThursOpen, toggleOpen: toggleThursOpen } = useOpener();
  const { isOpen: isFriOpen, toggleOpen: toggleFriOpen } = useOpener();
  const { isOpen: isSatOpen, toggleOpen: toggleSatOpen } = useOpener();
  const { isOpen: isSunOpen, toggleOpen: toggleSunOpen } = useOpener();

  const [weekJar, setWeekJar] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);

  const weekMatrix = {
    MONDAY: {
      visible: isMonOpen,
    },
    TUESDAY: {
      visible: isTueOpen,
    },
    WEDNESDAY: {
      visible: isWedOpen,
    },
    THURSDAY: {
      visible: isThursOpen,
    },
    FRIDAY: {
      visible: isFriOpen,
    },
    SATURDAY: {
      visible: isSatOpen,
    },
    SUNDAY: {
      visible: isSunOpen,
    },
  };

  const currentDays = props.data.time.map((e) => e.day);
  const availableDays = _.xor(weekdays, currentDays);

  const addData = useDebounce((i, day, e) => {
    if (e.target.checked && !props.data.time[i].time.includes(e.target.value)) {
      const newArr = [...props.data.time];
      newArr[i] = { ...newArr[i], time: [...newArr[i].time, e.target.value] };
      props.setData({ ...props.data, time: newArr });
    } else if (!e.target.checked && props.data.time[i].time.includes(e.target.value)) {
      const newArr = [...props.data.time];
      newArr[i] = {
        ...newArr[i],
        time: newArr[i].time.filter((t) => t !== e.target.value),
      };
      props.setData({ ...props.data, time: newArr });
    }
  }, 300);

  useEffect(() => {
    if (weekJar.length === 0) {
      if (isMonOpen) {
        toggleMonOpen();
      }
      if (isTueOpen) {
        toggleTueOpen();
      }
      if (isWedOpen) {
        toggleWedOpen();
      }
      if (isThursOpen) {
        toggleThursOpen();
      }
      if (isFriOpen) {
        toggleFriOpen();
      }
      if (isSatOpen) {
        toggleSatOpen();
      }
      if (isSunOpen) {
        toggleSunOpen();
      }
    } else {
      if (weekJar.includes('MONDAY') && !isMonOpen) {
        toggleMonOpen();
      }
      if (weekJar.includes('TUESDAY') && !isTueOpen) {
        toggleTueOpen();
      }
      if (weekJar.includes('WEDNESDAY') && !isWedOpen) {
        toggleWedOpen();
      }
      if (weekJar.includes('THURSDAY') && !isThursOpen) {
        toggleThursOpen();
      }
      if (weekJar.includes('FRIDAY') && !isFriOpen) {
        toggleFriOpen();
      }
      if (weekJar.includes('SATURDAY') && !isSatOpen) {
        toggleSatOpen();
      }
      if (weekJar.includes('SUNDAY') && !isSunOpen) {
        toggleSunOpen();
      }

      const filtered = [];
      weekJar.map((w) => {
        const existing = props.data.time.find((x) => x.day === w);
        if (!existing) filtered.push(w);
        return w;
      });
      props.setData({
        ...props.data,
        time: props.data.time.concat(filtered.map((w) => ({ day: w, time: [] }))),
      });
    }
  }, [weekJar]);

  useEffect(() => {
    if (resetBoxes && resetBoxes === true) {
      setWeekJar([]);
      setSelectedDays([]);
      props.setData({ ...props.data, time: [] });
    }
  }, [resetBoxes]);

  const hideShowSched = (v) => weekMatrix[v.day].visible;

  const handleDayChange = (v) => {
    if (v.length === 0) {
      setWeekJar([]);
      setSelectedDays(v);
      props.setData({ ...props.data, time: [] });
    } else {
      weekJar.map((w) => {
        if (!v.includes(w)) {
          if (w === 'MONDAY') {
            toggleMonOpen();
          }
          if (w === 'TUESDAY') {
            toggleTueOpen();
          }
          if (w === 'WEDNESDAY') {
            toggleWedOpen();
          }
          if (w === 'THURSDAY') {
            toggleThursOpen();
          }
          if (w === 'FRIDAY') {
            toggleFriOpen();
          }
          if (w === 'SATURDAY') {
            toggleSatOpen();
          }
          if (w === 'SUNDAY') {
            toggleSunOpen();
          }
        }
        return w;
      });

      setWeekJar(v);
      setSelectedDays(v);
    }
  };

  return (
    <>
      <div className={styles.rowWrapper}>
        <Form.Item className="form-itemz-j">
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item className="form-itemz-j" label="When are you available to work?">
                <Checkbox.Group options={weekdays} value={selectedDays} onChange={handleDayChange} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </div>
      <div className="work-schedule-wrapper">
        <div className="work-schedules">
          <div style={{ padding: 10, display: 'inline-flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
            {props.data.time.map((e, i) => {
              return (
                <div key={i} className={hideShowSched(e) ? `d-flex flex-column justify-content-center align-items-center` : `d-none`}>
                  <div className="dropdown show">
                    <label>{e.day}</label>

                    <div className="dropdown-menu " aria-labelledby="dropdownMenuLink">
                      {availableDays.map((avail) => {
                        return (
                          <p className="dropdown-item" id={avail}>
                            {avail}
                          </p>
                        );
                      })}
                    </div>
                  </div>

                  {isMobile ? (
                    <>
                      {(e.day === 'SATURDAY' || e.day === 'SUNDAY') && (
                        <>
                          <div className="schedule-checkboxes">
                            <div className="pb-4">
                              <div className="form-check">
                                <input value="5:00 a.m. – 6:00 a.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}4`} />
                                <label className="form-check-label" htmlFor={`${e.day}4`}>
                                  5:00 a.m. – 6:00 a.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="6:00 a.m. – 7:00 a.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}5`} />
                                <label className="form-check-label" htmlFor={`${e.day}5`}>
                                  6:00 a.m. – 7:00 a.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="7:00 a.m. – 8:00 a.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}6`} />
                                <label className="form-check-label" htmlFor={`${e.day}6`}>
                                  7:00 a.m. – 8:00 a.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="8:00 a.m. – 9:00 a.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}7`} />
                                <label className="form-check-label" htmlFor={`${e.day}7`}>
                                  8:00 a.m. – 9:00 a.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="9:00 a.m. – 10:00 a.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}8`} />
                                <label className="form-check-label" htmlFor={`${e.day}8`}>
                                  9:00 a.m. – 10:00 a.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="10:00 a.m. – 11:00 a.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}9`} />
                                <label className="form-check-label" htmlFor={`${e.day}9`}>
                                  10:00 a.m. – 11:00 a.m.
                                </label>
                              </div>

                              {/* <div className="form-check">
                                <input value="11:00 a.m. – 12:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}10`} />
                                <label className="form-check-label" htmlFor={`${e.day}1`}>
                                  11:00 a.m. – 12:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="12:00 p.m. – 1:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}11`} />
                                <label className="form-check-label" htmlFor={`${e.day}1`}>
                                  12:00 p.m. – 1:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="1:00 p.m. – 2:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}12`} />
                                <label className="form-check-label" htmlFor={`${e.day}1`}>
                                  1:00 p.m. – 2:00 p.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="2:00 p.m. – 3:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}1`} />
                                <label className="form-check-label" htmlFor={`${e.day}1`}>
                                  2:00 p.m. – 3:00 p.m.
                                </label>
                              </div> */}
                              {/* <div className="form-check">
                                <input value="1:00 p.m. – 2:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}2`} />
                                <label className="form-check-label" htmlFor={`${e.day}2`}>
                                  2:00 p.m. – 3:00 p.m.
                                </label>
                              </div> */}
                            </div>
                          </div>

                          {/* <div className="schedule-checkboxes">
                            <div className="pb-4">
                              <div className="form-check">
                                <input value="2:00 p.m. – 3:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}3`} />
                                <label className="form-check-label" htmlFor={`${e.day}3`}>
                                  3:00 p.m. – 4:00 p.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="3:00 p.m. – 4:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}4`} />
                                <label className="form-check-label" htmlFor={`${e.day}4`}>
                                  4:00 p.m. – 5:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="4:00 p.m. – 5:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}5`} />
                                <label className="form-check-label" htmlFor={`${e.day}5`}>
                                  5:00 p.m. – 6:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="5:00 p.m. – 6:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}6`} />
                                <label className="form-check-label" htmlFor={`${e.day}6`}>
                                  6:00 p.m. – 7:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="7:00 p.m. – 8:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}7`} />
                                <label className="form-check-label" htmlFor={`${e.day}7`}>
                                  7:00 p.m. – 8:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="8:00 p.m. – 9:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}8`} />
                                <label className="form-check-label" htmlFor={`${e.day}8`}>
                                  8:00 p.m. – 9:00 p.m.
                                </label>
                              </div>
                            </div>
                          </div> */}
                        </>
                      )}

                      {e.day !== 'SATURDAY' && e.day !== 'SUNDAY' && (
                        <div style={{ fontSize: 12, borderTop: '1px solid black', marginRight: 20 }} className="subject-checkboxes pt-4">
                          {/* <div className="schedule-checkboxes">
                            <div className="pb-4">
                              <div className="form-check">
                                <input value="1:00 p.m. – 2:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}1`} />
                                <label className="form-check-label" htmlFor={`${e.day}1`}>
                                  1:00 p.m. – 2:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="1:00 p.m. – 2:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}2`} />
                                <label className="form-check-label" htmlFor={`${e.day}2`}>
                                  2:00 p.m. – 3:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="2:00 p.m. – 3:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}3`} />
                                <label className="form-check-label" htmlFor={`${e.day}3`}>
                                  3:00 p.m. – 4:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="3:00 p.m. – 4:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}4`} />
                                <label className="form-check-label" htmlFor={`${e.day}4`}>
                                  4:00 p.m. – 5:00 p.m.
                                </label>
                              </div>
                            </div>
                          </div> */}

                          <div className="schedule-checkboxes">
                            <div className="pb-4">
                              <div className="form-check">
                                <input value="5:00 p.m. – 6:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}4`} />
                                <label className="form-check-label" htmlFor={`${e.day}4`}>
                                  5:00 p.m. – 6:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="6:00 p.m. – 7:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}5`} />
                                <label className="form-check-label" htmlFor={`${e.day}5`}>
                                  6:00 p.m. – 7:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="7:00 p.m. – 8:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}6`} />
                                <label className="form-check-label" htmlFor={`${e.day}6`}>
                                  7:00 p.m. – 8:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="8:00 p.m. – 9:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}7`} />
                                <label className="form-check-label" htmlFor={`${e.day}7`}>
                                  8:00 p.m. – 9:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="9:00 p.m. – 10:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}8`} />
                                <label className="form-check-label" htmlFor={`${e.day}8`}>
                                  9:00 p.m. – 10:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="10:00 p.m. – 11:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}9`} />
                                <label className="form-check-label" htmlFor={`${e.day}9`}>
                                  10:00 p.m. – 11:00 p.m.
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div style={{ fontSize: 12, borderTop: '1px solid black', marginRight: 20 }} className="d-flex subject-checkboxes pt-4">
                      {(e.day === 'SATURDAY' || e.day === 'SUNDAY') && (
                        <>
                          <div className="schedule-checkboxes">
                            <div className="pb-4">
                              <div className="form-check">
                                <input value="5:00 a.m. – 6:00 a.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}4`} />
                                <label className="form-check-label" htmlFor={`${e.day}4`}>
                                  5:00 a.m. – 6:00 a.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="6:00 a.m. – 7:00 a.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}5`} />
                                <label className="form-check-label" htmlFor={`${e.day}5`}>
                                  6:00 a.m. – 7:00 a.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="7:00 a.m. – 8:00 a.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}6`} />
                                <label className="form-check-label" htmlFor={`${e.day}6`}>
                                  7:00 a.m. – 8:00 a.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="8:00 a.m. – 9:00 a.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}7`} />
                                <label className="form-check-label" htmlFor={`${e.day}7`}>
                                  8:00 a.m. – 9:00 a.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="9:00 a.m. – 10:00 a.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}8`} />
                                <label className="form-check-label" htmlFor={`${e.day}8`}>
                                  9:00 a.m. – 10:00 a.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="10:00 a.m. – 11:00 a.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}9`} />
                                <label className="form-check-label" htmlFor={`${e.day}9`}>
                                  10:00 a.m. – 11:00 a.m.
                                </label>
                              </div>

                              {/* <div className="form-check">
                                <input value="11:00 a.m. – 12:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}10`} />
                                <label className="form-check-label" htmlFor={`${e.day}1`}>
                                  11:00 a.m. – 12:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="12:00 p.m. – 1:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}11`} />
                                <label className="form-check-label" htmlFor={`${e.day}1`}>
                                  12:00 p.m. – 1:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="1:00 p.m. – 2:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}12`} />
                                <label className="form-check-label" htmlFor={`${e.day}1`}>
                                  1:00 p.m. – 2:00 p.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="2:00 p.m. – 3:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}1`} />
                                <label className="form-check-label" htmlFor={`${e.day}1`}>
                                  2:00 p.m. – 3:00 p.m.
                                </label>
                              </div> */}

                              {/* <div className="form-check">
                                <input value="1:00 p.m. – 2:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}2`} />
                                <label className="form-check-label" htmlFor={`${e.day}2`}>
                                  2:00 p.m. – 3:00 p.m.
                                </label>
                              </div> */}
                            </div>
                          </div>

                          {/* <div className="schedule-checkboxes">
                            <div className="pb-4">
                              <div className="form-check">
                                <input value="2:00 p.m. – 3:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}3`} />
                                <label className="form-check-label" htmlFor={`${e.day}3`}>
                                  3:00 p.m. – 4:00 p.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="3:00 p.m. – 4:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}4`} />
                                <label className="form-check-label" htmlFor={`${e.day}4`}>
                                  4:00 p.m. – 5:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="4:00 p.m. – 5:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}5`} />
                                <label className="form-check-label" htmlFor={`${e.day}5`}>
                                  5:00 p.m. – 6:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="5:00 p.m. – 6:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}6`} />
                                <label className="form-check-label" htmlFor={`${e.day}6`}>
                                  6:00 p.m. – 7:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="7:00 p.m. – 8:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}7`} />
                                <label className="form-check-label" htmlFor={`${e.day}7`}>
                                  7:00 p.m. – 8:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="8:00 p.m. – 9:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}8`} />
                                <label className="form-check-label" htmlFor={`${e.day}8`}>
                                  8:00 p.m. – 9:00 p.m.
                                </label>
                              </div>
                            </div>
                          </div> */}
                        </>
                      )}

                      {e.day !== 'SATURDAY' && e.day !== 'SUNDAY' && (
                        <>
                          {/* <div className="schedule-checkboxes">
                            <div className="pb-4">
                              <div className="form-check">
                                <input value="1:00 p.m. – 2:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}1`} />
                                <label className="form-check-label" htmlFor={`${e.day}1`}>
                                  1:00 p.m. – 2:00 p.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="1:00 p.m. – 2:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}2`} />
                                <label className="form-check-label" htmlFor={`${e.day}2`}>
                                  2:00 p.m. – 3:00 p.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="2:00 p.m. – 3:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}3`} />
                                <label className="form-check-label" htmlFor={`${e.day}3`}>
                                  3:00 p.m. – 4:00 p.m.
                                </label>
                              </div>
                              <div className="form-check">
                                <input value="3:00 p.m. – 4:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}4`} />
                                <label className="form-check-label" htmlFor={`${e.day}4`}>
                                  4:00 p.m. – 5:00 p.m.
                                </label>
                              </div>
                            </div>
                          </div> */}
                          <div className="schedule-checkboxes">
                            <div className="pb-4">
                              <div className="form-check">
                                <input value="5:00 p.m. – 6:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}4`} />
                                <label className="form-check-label" htmlFor={`${e.day}4`}>
                                  5:00 p.m. – 6:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="6:00 p.m. – 7:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}5`} />
                                <label className="form-check-label" htmlFor={`${e.day}5`}>
                                  6:00 p.m. – 7:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="7:00 p.m. – 8:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}6`} />
                                <label className="form-check-label" htmlFor={`${e.day}6`}>
                                  7:00 p.m. – 8:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="8:00 p.m. – 9:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}7`} />
                                <label className="form-check-label" htmlFor={`${e.day}7`}>
                                  8:00 p.m. – 9:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="9:00 p.m. – 10:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}8`} />
                                <label className="form-check-label" htmlFor={`${e.day}8`}>
                                  9:00 p.m. – 10:00 p.m.
                                </label>
                              </div>

                              <div className="form-check">
                                <input value="10:00 p.m. – 11:00 p.m." onClick={(a) => addData(i, e.day, a)} type="checkbox" className="form-check-input" id={`${e.day}9`} />
                                <label className="form-check-label" htmlFor={`${e.day}9`}>
                                  10:00 p.m. – 11:00 p.m.
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkScheduleCheckboxes;
