import React from 'react';
import { Switch } from 'antd';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { tutorbase } from '@redux/combineActions';

import '@styles/settings.less';

const Notifications = () => {
  const dispatch = useDispatch();
  const {
    common: {
      user: { account },
    },
  } = useSelector((state) => state);

  const handleDisable = () => {
    if (account.role !== 1 && account.eReminder === 0) return true;
    return false;
  };

  const handleSubmitNotif = (e) => {
    const v = e.target ? e.target.value : e;
    let payload;

    // 3 9 1
    if (e.target) {
      payload = {
        UserId: account.UserId,
        option: +v,
      };
      // true/false
    }

    if (v === true && !e.target) {
      payload = {
        UserId: account.UserId,
        option: 1,
      };
    } else if (v === false && !e.target) {
      payload = {
        UserId: account.UserId,
        option: 0,
      };
      const options = document.querySelectorAll('.form-select option');
      for (let i = 0, l = options.length; i < l; i += 1) {
        options[i].selected = options[i].defaultSelected;
      }
    }
    dispatch(tutorbase.schedule.setNotif(payload));
  };

  const emailNotifTime = () => {
    if (account.role !== 1) {
      return account.eReminder === 0 ? 1 : account.eReminder;
    }
    return 1;
  };

  const emailNotifStatus = () => {
    let stat = false;
    if (account.eReminder && account.eReminder === 0) stat = false;
    if (account.eReminder && account.eReminder !== 0) stat = true;
    return stat;
  };

  return (
    <div className="notif-wrapper">
      <div className="notif-settings">
        {/* {account.role === 1 ? (
          <>
            {' '}
            <div className="notif-setting">
              <h4>Account Type</h4>
              <div className="d-flex flex-row">
                <div className="form-check" onClick={(e) => changeType(e)}>
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Student" checked={type === 'Student'} />
                    Student
                  </label>
                </div>

                <div className="form-check">
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Teacher" checked={type === 'Teacher'} onClick={(e) => changeType(e)} />
                    Teacher
                  </label>
                </div>
              </div>
            </div>
            <div className="notif-setting">
              <label htmlFor="tname">
                Tutor&apos;s Name
                <select id="tname" className="form-select" style={{ width: '18rem', boxShadow: 'none' }} aria-label="Default select example" onChange={(e) => setUserId(e.target.value)}>
                  <option selected>Select Name...</option>
                  {type === 'Student'
                    ? students.map((e) => {
                        return <option value={e.UserId}>{e.name}</option>;
                      })
                    : tutors.map((e) => {
                        return <option value={e.UserId}>{e.name}</option>;
                      })}
                </select>
              </label>
            </div>
          </>
        ) : null} */}

        <div className="notif-setting">
          <label htmlFor="eNotifStat">Email Notification Status</label>
          <Switch id="eNotifStat" onChange={handleSubmitNotif} checked={emailNotifStatus()} />
        </div>

        <div className="notif-setting">
          <label htmlFor="eNotifTime">Email Notification Time</label>
          <select //
            id="eNotifTime"
            placeholder="Select Time..."
            className="form-select"
            style={{ width: '18rem', boxShadow: 'none' }}
            aria-label="emailnotiftime"
            disabled={handleDisable()}
            onChange={handleSubmitNotif}
            value={emailNotifTime()}>
            <option value={3}>3 hours before session</option>
            <option value={9}>9:00 am on the day of session</option>
            <option value={1}>1 day before session</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
