import React from 'react';
import { format, startOfWeek } from 'date-fns';
import moment from 'moment';

import { Button, Space, Table, Tag } from 'antd';
// antd icons
import { DeleteOutlined } from '@ant-design/icons';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { common, tutorbase } from '@redux/combineActions';

// hooks
import useOpener from '@hooks/useOpener';

// utils
import { useDebounce } from '@utils/methods';

import BulkScheduleModal from './components/BulkScheduleModal';

// styling
import styles from './index.module.less';

const sessionWeek = startOfWeek(new Date(), {
  weekStartsOn: 1,
});

const BulkScheduling = () => {
  const dispatch = useDispatch();
  const { isOpen: isAddSlotOpen, toggleOpen: toggleAddSlotOpen } = useOpener();
  const {
    tutorbase: {
      user: { students },
      calendar: { fromDate, sessions },
    },
  } = useSelector((state) => state);

  const submitBulkSession = (v) => {
    dispatch(tutorbase.session.addBulkSession({ sessions: v })).then(() => {
      dispatch(common.user.fetchUpdatedDetails()).then(() => {
        toggleAddSlotOpen();
        dispatch(tutorbase.schedule.fetchHistory({ fromDate }));
      });
    });
  };

  const handleDelete = useDebounce((e, v) => {
    e.stopPropagation();
    dispatch(tutorbase.session.removeSession(v)).then(() => {
      dispatch(tutorbase.schedule.fetchHistory({ fromDate }));
    });
  }, 300);

  const handleBulkSchedule = useDebounce(() => {
    toggleAddSlotOpen();
  }, 300);

  if (!fromDate || !sessions) return null;

  const sortByCustomOrder = (a, b) => {
    const order = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const indexA = order.indexOf(a);
    const indexB = order.indexOf(b);
    return indexA - indexB;
  };

  const columns = [
    {
      title: 'Date',
      render: (record) => moment(record.date).format('MMM D YYYY').toString(),
    },
    {
      title: 'Student Name',
      render: (record) => {
        const student = students.find((s) => s.id === record.StudentId);
        return student.name;
      },
    },
    {
      title: 'Paid',
      render: (record) => <Tag color={record.isPaid ? 'green' : 'red'}>{record.isPaid ? 'Yes' : 'No'}</Tag>,
    },
    {
      title: 'Total Hours',
      dataIndex: 'defaultTime',
      key: 'defaultTime',
    },
    {
      title: 'Days: Hours',
      render: (record) => {
        const days = {};
        record.Schedules.map((s) => {
          if (!days[s.day]) {
            days[s.day] = s.hours;
          } else {
            days[s.day] += s.hours;
          }
          return s;
        });

        return (
          <>
            {Object.keys(days)
              .sort(sortByCustomOrder)
              .map((s, idx) => {
                // const color = idx % 2 === 0 ? 'geekblue' : 'green';
                return (
                  <Tag className="lightgray" key={idx}>
                    {s.toUpperCase()}: {days[s]}
                  </Tag>
                );
              })}
          </>
        );
      },
    },
    {
      title: 'Actions',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          {/* <Button className={styles.btnLeft} icon={<EditOutlined />} onClick={handleEdit} /> */}
          <Button disabled={record.isPaid} className={styles.btnLeft} icon={<DeleteOutlined />} onClick={(e) => handleDelete(e, record.id)} />
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.bulkWrapper}>
      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <h2 className={styles.sessionWeek}>
          Session Week: {moment(fromDate).format('MMM DD').toString()} - {moment(fromDate).add(6, 'days').format('MMM DD, YYYY').toString()}
        </h2>
        <Button disabled={moment(fromDate).isBefore(sessionWeek)} type="button" className={`btn list-btn-primary ${styles.zoomCta}`} onClick={handleBulkSchedule}>
          Set Sessions
        </Button>
      </div>

      <Table columns={columns} dataSource={sessions} />

      <BulkScheduleModal //
        sessionDate={format(new Date(fromDate), 'dd MMMM yyyy')}
        students={students ?? []}
        onSubmit={submitBulkSession}
        visible={isAddSlotOpen}
        onCancel={toggleAddSlotOpen}
      />
    </div>
  );
};

export default BulkScheduling;
