import React from 'react';

const DisplayWorkSchedules = (props) => {
  const { time } = props.app;

  if (!time) return null;

  return (
    <div>
      {time &&
        time.map((s) => {
          return (
            <>
              <span style={{ fontWeight: 500 }}>{s.day}:</span>
              <br />
              {s.time.map((t) => (
                <>
                  <span>{t}</span>
                  <br />
                </>
              ))}
              <br />
            </>
          );
        })}
    </div>
  );
};

export default DisplayWorkSchedules;
