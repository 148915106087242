import React from 'react';

// styling
import styles from './index.module.less';

const DisplayEducationalBackground = (props) => {
  return (
    <>
      <h5>Educational Background</h5>

      <div className={styles.wrapper_level0}>
        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>High School:</p>
          <p>{props.app?.highSchool}</p>
        </div>
        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>High School Strand:</p>
          <p>{props.app?.highSchoolStrand}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Year Graduated:</p>
          <p>{props.app?.highSchoolGraduation}</p>
        </div>
        <br />
        <br />
        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>College/Institution:</p>
          <p>{props.app?.College}</p>
        </div>
        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Course:</p>
          <p>{props.app?.collegeCourse}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Year Graduated/Graduating:</p>
          <p>{props.app?.collegeGraduation}</p>
        </div>
        <br />
        <br />
        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Academic Achievements:</p>
          <p>{props.app?.academicAchievements}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>
            Special Skills <span>(if applicable)</span>:
          </p>
          <p>{props.app?.specialSkills}</p>
        </div>

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Languages and Proficiency:</p>
          <p>{props.app?.languages}</p>
        </div>
      </div>
    </>
  );
};

export default DisplayEducationalBackground;
