import React from 'react';
import spinner from '@images/icons/spinner.gif';

// styling
import styles from './index.module.less';

export default () => (
  <div //
    className={styles.spinnerWrapper}
    data-aos="fade-in"
    data-aos-offset="0"
    data-aos-easing="ease-in-sine"
    data-aos-duration="800">
    <img src={spinner} className={styles.spinnerImage} alt="loading..." />
  </div>
);
