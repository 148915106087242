import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

// redux
import { useSelector } from 'react-redux';
// import { common } from '@redux/combineActions';

// hooks
import useWindowSize from '@hooks/useWindowSize';


import { Link, useHistory } from 'react-router-dom';

import '@styles/navbar.less';
// import styles from './index.module.less';

const navbarLogo = 'https://tutorbasecenter.s3.ap-southeast-1.amazonaws.com/navbaroneline-oct18.png';
const navbarLogoMobile = 'https://tutorbasecenter.s3.ap-southeast-1.amazonaws.com/navbartwolines-oct18.png';

const Navbar = () => {
  // const dispatch = useDispatch();
  const { isMobile, isTablet } = useWindowSize();

  const history = useHistory();
  const {
    user: { authenticated },
  } = useSelector((state) => {
    // console.log('[NAVBAR STATEROOO] ', state);
    return state.common;
  });

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 900px)',
  });

  // const [data, setData] = useState({
  //   email: '',
  //   password: '',
  // });

  // const [rememberMe, setRememberMe] = useState(false);

  // HEAD
  // const [data, setData] = useState({
  //   email: 'head@email.com',
  //   password: 'head1',
  // });

  // TEACHER
  // const [data, setData] = useState({
  //   email: 'teacher1@email.com',
  //   password: 'teacher1',
  // });

  // STUDENT
  // const [data, setData] = useState({
  //   email: 'student1@email.com',
  //   password: 'student1',
  // });

  // const submitLogin = (e) => {
  //   e.preventDefault();
  //   if (rememberMe) {
  //     localStorage.setItem('pink', JSON.stringify({ remember: rememberMe, cred: data }));
  //   } else {
  //     localStorage.removeItem('pink');
  //   }
  //   dispatch(common.user.userLogin(data));
  // };

  window.onscroll = () => {
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      const checkPoint = isMobile ? 203 : 603;
      // navbar.id = window.scrollY !== 0 ? 'scrolled' : 'navbar';
      navbar.id = window.scrollY >= checkPoint ? 'scrolled' : 'navbar';
    }
    // console.log('[IS THIS THA LISTENAH? navbar.id] ', navbar.id);
    // console.log('[IS THIS THA LISTENAH? window.scrollY] ', window.scrollY);
  };

  // const handleLogout = () => {
  //   dispatch(common.user.userLogout());
  // };

  // const handleRememberMeChange = (e) => {
  //   setRememberMe(e.target.checked);
  // };

  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    navbar.id = 'navbar';

    const pink = localStorage.getItem('pink');
    if (pink !== null) {
      // const { remember, cred } = JSON.parse(pink);
      // setRememberMe(remember);
      // setData(cred);
    }
  }, []);

  if (authenticated) history.push('/dashboard');

  return (
    <nav className="navbar navbar-expand-lg fixed-top tb-navbar" data-aos="fade-in" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-duration="600">
      <div className="container">
        {/* <Link to="/" className="navbar-brand">
          <img src={navbarLogo} alt="logo" />
        </Link> */}

        {isTabletOrMobileDevice ? (
          <div className="navbar-brand">
            <img src={navbarLogoMobile} alt="logo" />
          </div>
        ) : (
          <div className="navbar-brand">
            <img src={navbarLogo} alt="logo" />
          </div>
        )}

        {isTabletOrMobileDevice && (
          <div className="nav">
            {/* <Link className="nav-link" to="/login">
              Login
            </Link> */}

            <button type="button" className="nav-toggler" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
              <i className={`fas fa-bars ${isTablet ? 'fa-2x' : 'fa-1x'}`} />
            </button>
          </div>
        )}

        <div className={isTabletOrMobileDevice ? 'collapse navbar-collapse' : 'nav'} id="navbarSupportedContent1">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            {authenticated && (
              <li className="nav-item">
                <Link className="nav-link" to="/dashboard">
                  Dashboard
                </Link>
              </li>
            )}

            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/services">
                Services
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/joinourteam">
                Join Our Team
              </Link>
            </li>

            {/* {!isTabletOrMobileDevice && authenticated && (
              <li className="nav-item logout">
                <div className="nav-link" to="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={handleLogout}>
                  Log Out
                </div>
              </li>
            )} */}

            {/* {!isTabletOrMobileDevice && !authenticated && (
              <li className="nav-item dropdown show">
                <div className="loginx" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Login
                </div>

                <div className="dropdown-menu dropdown-menu-right mt-1" aria-labelledby="dropdownMenuLink">
                  <form className="px-4 py-3">
                    <div className="form-group pb-3">
                      <label htmlFor="exampleDropdownFormEmail1">
                        Email address
                        <input type="email" className="form-control mt-1" id="exampleDropdownFormEmail1" placeholder="email@example.com" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                      </label>
                    </div>

                    <div className="form-group pb-3">
                      <label htmlFor="exampleDropdownFormPassword1">
                        Password
                        <input type="password" className="form-control mt-1" id="exampleDropdownFormPassword1" placeholder="Password" value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} />
                      </label>
                    </div>

                    <div className="form-check pb-3">
                      <label className="form-check-label" htmlFor="dropdownCheck">
                        Remember me
                        <input type="checkbox" checked={rememberMe} onChange={handleRememberMeChange} className="form-check-input" id="dropdownCheck" />
                      </label>
                    </div>

                    <button type="submit" onClick={(e) => submitLogin(e)} className="btn btn-primary log">
                      Login
                    </button>
                  </form>
                </div>
              </li>
            )} */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

Navbar.shouldUpdateScroll = () => {
  return false;
};

export default Navbar;
