// UI
export const SET_LOADING = 'SET_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const SET_FROM_DOE_BUTTON = 'SET FROM DOE BUTTON';

// USER
export const SET_USER_ROLE = 'SET USER ROLE';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const NOTIF_SET = 'NOTIF_SET';

// FORM
export const ADD_FORM = 'ADD_FORM';
export const UPDATE_FORM = 'COMMON_UPDATE_FORM';
export const REMOVE_FORM = 'REMOVE_FORM';

export const MINATO_TUTORBASE = 'MINATO_TUTORBASE';
export const USER_LOGOUT = 'USER_LOGOUT';
