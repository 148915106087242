import React from 'react';

// styling
import styles from './index.module.less';

const DisplayEmploymentHistory = (props) => {
  return (
    <div>
      <h5>Employment History</h5>

      <div className={styles.wrapper_level0}>
        {props.app &&
          props.app.workexp?.map((cp) => {
            return (
              <>
                <div className={styles.wrapper_level1}>
                  <p className={styles.wrapper_level2}>Company:</p>
                  <p>{cp.company}</p>
                </div>
                <div className={styles.wrapper_level1}>
                  <p className={styles.wrapper_level2}>Position:</p>
                  <p>{cp.position}</p>
                </div>
                <div className={styles.wrapper_level1}>
                  <p className={styles.wrapper_level2}>
                    Years Worked <span>(from-to)</span>:
                  </p>
                  <p>{cp.yearsWorked}</p>
                </div>
                <div className={styles.wrapper_level1}>
                  <p className={styles.wrapper_level2}>Reason/s for Leaving:</p>
                  <p>{cp.leavingReason}</p>
                </div>
                <br />
                <br />
              </>
            );
          })}

        <div className={styles.wrapper_level1}>
          <p className={styles.wrapper_level2}>Volunteer Experience:</p>
          <p>{props.app?.informalWork}</p>
        </div>
      </div>
    </div>
  );
};

export default DisplayEmploymentHistory;
