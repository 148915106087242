import React, { useEffect, useRef } from 'react';

import styles from './index.module.less';

const TestiSection = ({ id }) => {
  const sectionRefs = useRef({});

  useEffect(() => {
    const sectionRef = sectionRefs.current[id];
    if (sectionRef) {
      setTimeout(() => {
        sectionRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);

      // const elementRect = sectionRef.getBoundingClientRect();
      //       const offset = elementRect.top - (window.innerHeight - elementRect.height) / 2;
      //       const top5a = window.scrollY + offset;
      //       const top5 = top5a; // - 580;

      //       console.log('[T O P  5 w] ', window.scrollY);
      //       console.log('[T O P  5 o] ', offset);
      //       console.log('[T O P  5 a] ', top5a);
      //       console.log('[T O P  5 t] ', top5);
      //       console.log('[//////////] ');

      //       setTimeout(() => {
      //         window.scrollTo({
      //           top: top5,
      //           behavior: 'smooth',
      //         });
      //       }, 100);
    }

    if (!id) {
      window.scroll(0, 0);
    }
  }, [id, sectionRefs]);

  const items = [
    <>
      <div key="1" className={styles.hanger} />
      <div className={styles.awardsWrapperVert}>
        <div
          ref={(el) => {
            sectionRefs.current[1] = el;
          }}
          className={styles.testiBody}>
          <span className={styles.firstLetter}>W</span>
          hen I see my teachers in Tutor BASE, they help me get <span className={styles.blueTextBody}>high grades in school.</span> I am able to accomplish difficult projects and assignments with their aid. We also have some <span className={styles.blueTextBody}>advanced studies to help me get</span> ahead of my subjects in school. I learned to be responsible towards my studies with the support of Teacher Anne and my assigned tutor. I am able to understand the lessons more with their careful explanation and assistance
          <span className={styles.firstLetter}>.</span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span>
            {Array.from(Array(5), (e, i) => {
              return <ion-icon key={`star${i}`} name="star" />;
            })}
          </span>
          <span className={styles.nameTextTesti}>Zach R.</span>
          <span className={styles.subText}>Student</span>
        </div>
      </div>
    </>,

    <>
      <div key="2" className={styles.hanger} />
      <div className={styles.awardsWrapperVert}>
        <div
          ref={(el) => {
            sectionRefs.current[2] = el;
          }}
          className={styles.testiBody}>
          <span className={styles.firstLetter}>I</span>
          &nbsp;have been staying in Tutor BASE on and off since I wanted to become more responsible and independent, but I come back because sometimes, I could not handle studying on my own. I would get distracted easily and end up not doing what I needed to do if I&apos;m just self-studying. <span className={styles.blueTextBody}>Studying in Tutor BASE made me focus a lot more since there is someone guiding me.</span> Now that there is a pandemic, it is a little bit harder to learn since no one is there in real life to teach you. Because of this pandemic, I would have to be sitting in front of a laptop for quite a while. But in the end, it is still{' '}
          <span className={styles.blueTextBody}>
            worth it since I get to learn and am able to focus more in my studies with Tutor BASE
            <span className={styles.firstLetter}>.</span>
          </span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span>
            {Array.from(Array(5), (e, i) => {
              return <ion-icon key={`star${i}`} name="star" />;
            })}
          </span>
          <span className={styles.nameTextTesti}>Zoe R.</span>
          <span className={styles.subText}>Student</span>
        </div>
      </div>
    </>,

    <>
      <div key="3" className={styles.hanger} />
      <div className={styles.awardsWrapperVert}>
        <div
          ref={(el) => {
            sectionRefs.current[3] = el;
          }}
          className={styles.testiBody}>
          <span className={styles.firstLetter}>T</span>
          utor BASE Center has truly helped our children <span className={styles.blueTextBody}>embrace school learning with comfort and ease.</span> Our teenagers Zach and Zoe have been <span className={styles.blueTextBody}>well prepared to face their academic life in their own pace</span> each developing their own study habits through these years. Their <span className={styles.blueTextBody}>personalized tutorial strategies coupled with care</span> is the center&apos;s winning formula. We thank Teacher Anne, her colleagues, and staff for their continuing efforts to adapt in any situation so that our children may progressively move ahead with the new norms of education
          <span className={styles.firstLetter}>.</span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span>
            {Array.from(Array(5), (e, i) => {
              return <ion-icon key={`star${i}`} name="star" />;
            })}
          </span>
          <span className={styles.nameTextTesti}>Arnon and Eleanor R.</span>
          <span className={styles.subText}>Parents of Zach and Zoe</span>
        </div>
      </div>
    </>,

    <>
      <div key="4" className={styles.hanger} />
      <div className={styles.awardsWrapperVert}>
        <div
          ref={(el) => {
            sectionRefs.current[4] = el;
          }}
          className={styles.testiBody}>
          <span className={styles.firstLetter}>A</span>
          fter all of these months, I think <span className={styles.blueTextBody}>I have learned even better in Tutor BASE compared to in school, and I can say that it truly helped me improve my grades</span>. I am aware that it might be difficult for some students to learn properly in online school due to some issues, and I also experience the same feeling. With this, I can imagine myself struggling even more in my studies without special classes or tutor. Overall, I want to say I appreciate my tutor(s) for all the lessons they provided to aid me in my studies
          <span className={styles.firstLetter}>.</span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span>
            {Array.from(Array(5), (e, i) => {
              return <ion-icon key={`star${i}`} name="star" />;
            })}
          </span>
          <span className={styles.nameTextTesti}>Nate C.</span>
          <span className={styles.subText}>Student</span>
        </div>
      </div>
    </>,

    <>
      <div key="5" className={styles.hanger} />
      <div className={styles.awardsWrapperVert}>
        <div
          ref={(el) => {
            sectionRefs.current[5] = el;
          }}
          className={styles.testiBody}>
          <span className={styles.firstLetter}>T</span>
          hank you very much, Tutor BASE, for all the support you’ve given my son for the past four years. Your{' '}
          <span className={styles.blueTextBody}>
            one-on-one tutorials have helped him breeze through his academics, thus boosting his confidence
            <span className={styles.firstLetter}>.</span>
          </span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span>
            {Array.from(Array(5), (e, i) => {
              return <ion-icon key={`star${i}`} name="star" />;
            })}
          </span>
          <span className={styles.nameTextTesti}>Evelyn C.</span>
          <span className={styles.subText}>Mom of Nate</span>
        </div>
      </div>
    </>,

    <>
      <div key="6" className={styles.hanger} />
      <div className={styles.awardsWrapperVert}>
        <div
          ref={(el) => {
            sectionRefs.current[6] = el;
          }}
          className={styles.testiBody}>
          <span className={styles.firstLetter}>B</span>
          efore enrolling in Tutor BASE, my grades in both Science and Mathematics were at their lowest, but now that I have enrolled in their tutoring classes, my grades started to climb up. The teachers at Tutor BASE are <span className={styles.blueTextBody}>well-prepared and very knowledgeable when it comes to helping me understand my lessons.</span> For instance, before I took tutoring sessions of Tutor BASE, I wasn&apos;t able to understand the concepts of Mean and Variance for our Statistics and Probability subject in senior high, but <span className={styles.blueTextBody}>now I can confidently say that I am able to easily solve the problems given to me</span> in this subject
          <span className={styles.firstLetter}>.</span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span>
            {Array.from(Array(5), (e, i) => {
              return <ion-icon key={`star${i}`} name="star" />;
            })}
          </span>
          <span className={styles.nameTextTesti}>Isaac S.</span>
          <span className={styles.subText}>Student</span>
        </div>
      </div>
    </>,

    <>
      <div key="7" className={styles.hanger} />
      <div className={styles.awardsWrapperVert}>
        <div
          ref={(el) => {
            sectionRefs.current[7] = el;
          }}
          className={styles.testiBody}>
          <span className={styles.firstLetter}>T</span>
          he school year has been pretty challenging for me and my son. Good thing Tutor BASE has been a constant support for us. Their readiness to cater online tutor sessions made it possible to help my son in his academic endeavors amidst the ongoing pandemic. One thing that I like most is the center&apos;s one-on-one teaching method; this makes my son fully understand his lessons, while the tutor is able to provide <span className={styles.blueTextBody}>the best teaching techniques suitable for the student with undivided attention. </span> I believe this arrangement is a very effective way to help students cope with their academic challenges. Tutor BASE has been our partner for how many years now, even before this pandemic, and all throughout <span className={styles.blueTextBody}>I have seen my son excel.</span> I must say, as a single-working parent, <span className={styles.blueTextBody}>I am more than satisfied, I am at ease, and I am greatly overwhelmed with the support.</span>{' '}
          Thank you, Tutor BASE, for an excellent job
          <span className={styles.firstLetter}>!</span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span>
            {Array.from(Array(5), (e, i) => {
              return <ion-icon key={`star${i}`} name="star" />;
            })}
          </span>
          <span className={styles.nameTextTesti}>Bambi S.</span>
          <span className={styles.subText}>Mom of Isaac</span>
        </div>
      </div>
    </>,

    <>
      <div key="8" className={styles.hanger} />
      <div className={styles.awardsWrapperVert}>
        <div
          ref={(el) => {
            sectionRefs.current[8] = el;
          }}
          className={styles.testiBody}>
          <span className={styles.firstLetter}>B</span>
          efore being enrolled in Tutor BASE, I found it difficult to answer my homework and other school requirements on my own. But now that I have Tutor BASE helping me in my schoolwork, I can manage to perform well in my class outputs and be able to have an improvement in my grades. Moreover, <span className={styles.blueTextBody}>I am now able to understand my lessons well and work confidently on the requirements</span> that are based on the lessons because{' '}
          <span className={styles.blueTextBody}>
            my tutor explains all the concepts clearly during my tutorial sessions
            <span className={styles.firstLetter}>.</span>
          </span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span>
            {Array.from(Array(5), (e, i) => {
              return <ion-icon key={`star${i}`} name="star" />;
            })}
          </span>
          <span className={styles.nameTextTesti}>Sean N.</span>
          <span className={styles.subText}>Student</span>
        </div>
      </div>
    </>,

    <>
      <div key="9" className={styles.hanger} />
      <div className={styles.awardsWrapperVert}>
        <div
          ref={(el) => {
            sectionRefs.current[9] = el;
          }}
          className={styles.testiBody}>
          <span className={styles.firstLetter}>T</span>
          utor BASE has been my partner in helping my son in school for 3 years. <span className={styles.blueTextBody}>Teacher Anne and her tutors helped my son improve his academic skills.</span> I appreciate the <span className={styles.blueTextBody}>well-structured sessions and honest and comprehensive feedback.</span> Thank you to T. Anne and to the Tutor BASE team for the <span className={styles.blueTextBody}>encouragement and support</span> you have given Sean for the past years
          <span className={styles.firstLetter}>.</span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span>
            {Array.from(Array(5), (e, i) => {
              return <ion-icon key={`star${i}`} name="star" />;
            })}
          </span>
          <span className={styles.nameTextTesti}>Karen N.</span>
          <span className={styles.subText}>Mom of Sean</span>
        </div>
      </div>
    </>,

    <>
      <div key="10" className={styles.hanger} />
      <div className={styles.awardsWrapperVert}>
        <div
          ref={(el) => {
            sectionRefs.current[10] = el;
          }}
          className={styles.testiBody}>
          <span className={styles.firstLetter}>T</span>
          hroughout my stay in Tutor BASE Center as a student, it became my <span className={styles.blueTextBody}> second learning home </span>aside from my school wherein I was able to <span className={styles.blueTextBody}> develop my skills and grow into an even better student. </span>In the Center I was guided by tutors who do not only aim to teach me for me to be able to pass in my examinations, but also taught me how to <span className={styles.blueTextBody}> become better as a person. </span>With that, I am grateful that I will be able to graduate this year with the empowering knowledge I gained from the Center
          <span className={styles.firstLetter}>.</span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span>
            {Array.from(Array(5), (e, i) => {
              return <ion-icon key={`star${i}`} name="star" />;
            })}
          </span>
          <span className={styles.nameTextTesti}>Mika P.</span>
          <span className={styles.subText}>Student</span>
        </div>
      </div>
    </>,

    <>
      <div key="11" className={styles.hanger} />
      <div className={styles.awardsWrapperVert}>
        <div
          ref={(el) => {
            sectionRefs.current[11] = el;
          }}
          className={styles.testiBody}>
          <span className={styles.firstLetter}>T</span>
          utor BASE has helped my daughter in a big way. Her <span className={styles.blueTextBody}>lessons have become clearer to her.</span> Of course, <span className={styles.blueTextBody}> her grades have also improved </span> compared to when she was being handled by her previous tutors. Thank you, Tutor BASE
          <span className={styles.firstLetter}>.</span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span>
            {Array.from(Array(5), (e, i) => {
              return <ion-icon key={`star${i}`} name="star" />;
            })}
          </span>
          <span className={styles.nameTextTesti}>Ton P.</span>
          <span className={styles.subText}>Mom of Mika</span>
        </div>
      </div>
    </>,
  ];

  return (
    <div className="service-section">
      <div className="title-service-section" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800">
        <div className="post-mini-title">Hear from Our Satisfied Students and Parents</div>
        <div className="post-big-title">Testimonials</div>
        {/* <div className="service-text-post">
          <p>Discover How Our Expert Tutors are Transforming Lives and Boosting Academic Performance</p>
        </div> */}
      </div>

      <div className="service-cards-container d-flex">{items}</div>
    </div>
  );
};

export default TestiSection;
