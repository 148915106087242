import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Button, Card, Checkbox, Col, Select, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { tutorbase } from '@redux/combineActions';

// hooks
import useOpener from '@hooks/useOpener';
import useWindowSize from '@hooks/useWindowSize';

// constants
import constants from '@utils/constants';

// utils
import { spaceShipLoader, useDebounce } from '@utils/methods';

import AddReportModal from '../modals/AddReportModal';
import EditReportModal from '../modals/EditReportModal';
import ScheduleList from '../ScheduleList';

import styles from './index.module.less';

const { Column, ColumnGroup } = Table;
const { Option } = Select;

const initialData = {
  StudentId: 0,
  StudentName: '',
  StudentGrade: '',
  StudentSchool: '',
  TutorId: '',
  TutorName: '',
  Schedule: '',
  ScheduleId: '',
  subject: '',
  date: '',
  reportGoal: '',
  reportAdditional: '',
  SessionId: '',
};

// const fromDate = startOfWeek(new Date(), {
//   weekStartsOn: 1,
// });

// show the next week set
// const lastDate = addDays(new Date(fromDate), 7);

const ReportsList = () => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const { isOpen: isAddReportOpen, toggleOpen: toggleAddReportOpen } = useOpener();
  const { isOpen: isEditReportOpen, toggleOpen: toggleEditReportOpen } = useOpener();

  const {
    common: {
      user: { account },
    },
    tutorbase: {
      user: { sessions, students, tutors },
    },
  } = useSelector((state) => state);

  const [schedules, setSchedules] = useState([]);
  const [data, setData] = useState(initialData);
  const [studentId, setStudentId] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [activeSchedule, setActiveSchedule] = useState(null);

  const handleHeadCheck = useDebounce((e, record) => {
    e.preventDefault();
    const { ScheduleId, StudentId } = record;
    dispatch(tutorbase.report.headCheck({ id: ScheduleId })).then(() => {
      setStudentId(StudentId);
      setActiveSchedule(ScheduleId);
    });
  }, 300);

  const handleParentCheck = useDebounce((e, record) => {
    e.preventDefault();
    console.log('[handleParentCheck e record] ', e, record);
    const { ScheduleId, StudentId } = record;
    dispatch(tutorbase.report.parentCheck({ id: ScheduleId })).then((res) => {
      console.log('[handleParentCheck RES] ', res);
      setStudentId(StudentId);
      setActiveSchedule(ScheduleId);
    });
  }, 300);

  const handleAddReport = useDebounce((v) => {
    setStudentId(v.StudentId);
    setData({
      ...data,
      ...v,
    });
    toggleAddReportOpen();
  }, 300);

  // View Report
  const fetchTableData = (v) => {
    console.log('[fetchTableData] ', v);

    let resvlt = [];

    if (account.role < 3) {
      resvlt = [v];
    }

    if (account.role === 3) {
      let dataStudent;
      const vIsArray = Array.isArray(v);
      if (vIsArray) {
        dataStudent = v.filter((s) => {
          if (s === null) return false;
          return s.isHeadChecked;
        });
      } else {
        dataStudent = [v].filter((s) => {
          if (s === null) return false;
          return s.isHeadChecked;
        });
      }

      resvlt = dataStudent;
    }

    setTableData(resvlt);
  };

  const fetchSessionInfo = (e) => {
    const id = typeof e === 'number' ? e : e.target.value;
    const sezz = sessions?.filter((sess) => sess.id === id);
    const schedz = _.flatten(sezz.map((ss) => ss.Schedules));
    const schedzIsArray = Array.isArray(schedz);
    if (sezz) {
      if (account.role === constants.ROLE_TYPE.STUDENT) {
        const rptz = _.flatten(
          schedz.map((sc) => {
            return {
              ...sc.Report,
              Tvtz: sc.Tutor,
            };
          })
        );
        if (rptz) {
          fetchTableData(rptz);
        }
      } else {
        setData(initialData);
        setTableData([]);
      }

      setSchedules(spaceShipLoader(schedzIsArray, schedz));
    } else {
      setSchedules([]);
      setData(initialData);
    }
  };

  useEffect(() => {
    if (account && sessions && studentId && activeSchedule) {
      if (account.role === constants.ROLE_TYPE.HEAD) {
        const sezz = sessions.filter((s) => s.StudentId === studentId);
        const schedz = _.flatten(sezz.map((ss) => ss.Schedules));
        const sched = schedz.find((sc) => sc.id === activeSchedule);
        if (sched) {
          fetchTableData({ ...sched.Report, Tvtz: sched.Tutor });
          setActiveSchedule(null);
        }
      }

      if (account.role === constants.ROLE_TYPE.STUDENT) {
        const sezz = sessions.filter((s) => s.StudentId === studentId);
        // const schedz = _.flatten(sezz.filter((ss) => ss.Report !== null).map((ss) => ss.Schedules));
        const schedz = _.flatten(sezz.map((sz) => sz.Schedules));
        // .filter((ss) => ss.Report !== null);
        const rptz = _.flatten(
          schedz
            .filter((sc) => sc.Report !== null)
            .map((sc) => {
              return { ...sc.Report, Tvtz: sc.Tutor };
            })
        );

        if (rptz) {
          fetchTableData(rptz);
          setActiveSchedule(null);
        }
      }
    }
  }, [account, studentId, activeSchedule, sessions]);

  useEffect(() => {
    if (account && sessions) {
      if (account.role === constants.ROLE_TYPE.HEAD) {
        const sezz = sessions.filter((s) => s.StudentId === studentId);
        // const schedz = _.flatten(sezz.map((ss) => ss.Schedules));
        if (sezz && sezz.length > 0) {
          fetchSessionInfo(sezz[0].id);
        }
      }
    }
  }, [account, studentId, sessions]);

  useEffect(() => {
    if (account && sessions) {
      if (account.role === constants.ROLE_TYPE.STUDENT) {
        const sezz = sessions.filter((s) => s.StudentId === account.id);
        if (sezz && sezz.length > 0) {
          fetchSessionInfo(sezz[0].id);
        }
      }
    }
  }, [account, sessions]);

  const submitReport = (v) => {
    const payload = { ...data, ...v };
    console.log('[SUBMIT REPORT payload] ', payload);
    console.log('[SUBMIT REPORT DATA] ', data);
    console.log('[SUBMIT REPORT V] ', v);

    dispatch(tutorbase.report.addReport(payload)).then((res) => {
      if (res.success) {
        console.log('[SUBMIT REPORT res] ', res);
        toggleAddReportOpen();
        setStudentId(payload.StudentId);
        setActiveSchedule(payload.ScheduleId);
        // need to put Tvtz..
        fetchTableData(res.data);
      }
    });
  };

  const handleSubmitReport = (v) => {
    dispatch(tutorbase.report.editReport(v)).then((res) => {
      if (res.success) {
        toggleEditReportOpen();
      }
    });
  };

  // const closeBtn = () => {
  //   const options = document.querySelectorAll('.modal-select option');
  //   for (let i = 0, l = options.length; i < l; i += 1) {
  //     options[i].selected = options[i].defaultSelected;
  //   }

  //   setData({ ...data, subject: '', reportGoal: '', reportAdditional: '' });
  // };

  const handleEditRecord = useDebounce((record) => {
    const stvd = students.find((std) => std.id === record.StudentId);
    const tvtz = tutors.find((tz) => tz.id === record.TutorId);

    setData({
      ...data,
      StudentName: stvd?.name ?? '-',
      TutorName: tvtz?.name ?? '-',
      ScheduleId: record.ScheduleId,
      subject: record.subject,
      reportGoal: record.reportGoal,
      reportAdditional: record.reportAdditional,
      id: record.id,
    });
    toggleEditReportOpen();
  }, 300);

  const renderTeacherColumn = (record) => {
    if (!showTable) return null;
    if (account.role === constants.ROLE_TYPE.HEAD) {
      // console.log('[renderTeacherColumn] ', record);
      return (
        <>
          {/* <input className="form-check-input" type="checkbox" style={{ outline: 'none' }} id="flexCheckDefault" onChange={(f) => handleHeadCheck(f, record)} />
          <label className="form-check-label" htmlFor="flexCheckDefault" /> */}
          <Checkbox checked={record.isHeadChecked} onChange={(f) => handleHeadCheck(f, record)} />
        </>
      );
    }

    if (record.isHeadChecked) {
      return <i className="fas fa-check" style={{ color: 'green', fontSize: '13px' }} />;
    }

    return <i className="fas fa-minus" style={{ color: 'grey', fontSize: '13px' }} />;
  };

  const renderParentColumn = (record) => {
    if (!showTable) return null;
    if (account.role === constants.ROLE_TYPE.STUDENT) {
      // console.log('[renderParentColumn] ', record);
      return (
        <>
          {/* <input className="form-check-input" type="checkbox" style={{ outline: 'none' }} id="flexCheckDefault" onChange={(f) => handleParentCheck(f, record)} defaultChecked={record.isParentChecked} />
          <label className="form-check-label" htmlFor="flexCheckDefault" /> */}
          <Checkbox checked={record.isParentChecked} onChange={(f) => handleParentCheck(f, record)} />
        </>
      );
    }

    if (record.isParentChecked) {
      return <i className="fas fa-check" style={{ color: 'green', fontSize: '13px' }} />;
    }

    return <i className="fas fa-minus" style={{ color: 'grey', fontSize: '13px' }} />;
  };

  const renderCardView = () => {
    let resvlt = [];
    const reports = schedules
      .filter((e) => e.Report !== null)
      .map((e) => {
        return { ...e.Report, Tvtz: e.Tutor };
      });
    const dataStudent = reports.filter((s) => {
      if (s === null) return false;
      return s.isHeadChecked;
    });

    if (account.role < 3) {
      resvlt = reports;
    }
    if (account.role === 3) {
      resvlt = dataStudent;
    }

    if (resvlt.length === 0 && account.role === 3) {
      return <p>No session reports found.</p>;
    }

    return (
      <Card className={styles.cardRow}>
        <Col span={24}>
          {account.role !== 3 && resvlt && resvlt.length === 0 && <h5>Select a session to add/edit a report.</h5>}
          {resvlt &&
            resvlt.length > 0 &&
            resvlt.map((r, idx) => (
              <Card key={idx} className={styles.cardRowInner}>
                <Col span={24}>
                  <br />
                  <span>Report Details:</span>
                  <hr />
                  <br />
                  <span>
                    Date: <b>{moment(r.date).format('DD-MM-YYYY').toString()}</b>
                  </span>
                  <br />
                  <br />
                  <span>
                    Tutor: <b>{r?.Tvtz.name}</b>
                  </span>
                  <br />
                  <br />
                  <span>
                    Subjects/Topics Discussed: <br />
                    <b>{r.subject}</b>
                  </span>
                  <br />
                  <br />
                  <span>
                    Points Of Improvement: <br />
                    <b>{r.reportGoal}</b>
                  </span>
                  <br />
                  <br />
                  <span>
                    Additional Notes: <br />
                    <b>{r.reportAdditional}</b>
                  </span>
                  <br />
                  <br />
                  <br />

                  <div className={styles.ctaWrapper}>
                    {account.role !== 3 && (
                      <>
                        <Button //
                          className={`btn btn-sm btn-primary ${styles.btnLeft}`}
                          onClick={() => handleEditRecord(r)}>
                          Edit Report
                        </Button>
                        <br />
                      </>
                    )}

                    {account.role === constants.ROLE_TYPE.HEAD && (
                      <>
                        <span>Head Teacher:</span>
                        <Button //
                          className={`btn btn-sm ${styles.btnLeft} ${r.isHeadChecked ? 'btn-paid' : 'btn-primary'}`}
                          onClick={(f) => handleHeadCheck(f, r)}>
                          {r.isHeadChecked ? 'Approved' : 'Approve'}
                        </Button>
                      </>
                    )}

                    <span>Parents:</span>
                    <Button //
                      className={`btn btn-sm ${styles.btnLeft} ${r.isParentChecked ? 'btn-paid' : 'btn-primary'}`}
                      disabled={account.role < constants.ROLE_TYPE.STUDENT}
                      onClick={(f) => handleParentCheck(f, r)}>
                      {r.isParentChecked ? 'Approved' : 'Approve'}
                    </Button>
                  </div>
                </Col>
              </Card>
            ))}
        </Col>
      </Card>
    );
  };

  const renderTableView = () => {
    return (
      <>
        <h5>Session Report:</h5>
        <Table //
          style={{ display: showTable ? 'block' : 'none' }}
          className={styles.reportListTable}
          dataSource={showTable ? tableData : []}
          bordered
          pagination={{ position: ['bottomCenter'] }}>
          <Column title="Date" dataIndex="date" key="date" width="11%" align="center" render={(e) => format(new Date(e), 'dd MMM yyyy')} />
          {account.role === constants.ROLE_TYPE.STUDENT && (
            <Column
              title="Tutor"
              dataIndex="subject"
              width="36%"
              align="center"
              key="address"
              render={(e, record) => {
                console.log('[RECORD] ', record);
                if (!record.Tvtz) {
                  console.log('[TVTS] FFFF');
                }

                return record.Tvtz ? record.Tvtz.name : '';
              }}
            />
          )}
          <Column
            title="Subjects/Topics Discussed & Task Accomplished"
            dataIndex="subject"
            width="36%"
            align="center"
            key="address"
            render={(e, record) => {
              return account.role === 1 ? (
                <div className={styles.subjectLink} onClick={() => handleEditRecord(record)}>
                  {e}
                </div>
              ) : (
                e
              );
            }}
          />
          <Column title="Points Of Improvement & Goal for Next Session" dataIndex="reportGoal" width="19%" align="center" key="tags" />
          <Column
            title={
              <p>
                Additional Notes
                <br />
                (extension, sub, etc.)
              </p>
            }
            dataIndex="reportAdditional"
            align="center"
            key="tags"
          />
          <ColumnGroup title="Checked By" align="center">
            <Column //
              title="T. Anne"
              width="9%"
              align="center"
              render={(record) => renderTeacherColumn(record)}
            />
            <Column
              title="Parents"
              align="center"
              render={(record) => renderParentColumn(record)}
              // render={(e, record) => <p>DEBVG2</p>}
            />
          </ColumnGroup>
        </Table>
      </>
    );
  };

  const filteredSchedulesByAccount = (sch) => {
    let resvlt = true;
    // if (account.role === constants.ROLE_TYPE.HEAD) {

    // }
    if (account.role === constants.ROLE_TYPE.TUTOR) {
      resvlt = sch.TutorId === account.id;
    }
    if (account.role === constants.ROLE_TYPE.STUDENT) {
      resvlt = sch.StudentId === account.id;
    }

    return resvlt;
  };

  const listValidStudents = () => {
    const validStudz = [];
    sessions
      .filter((sz) => sz.isPaid === true && sz.Schedules.length > 0)
      .map((sz) => {
        // filter by account role
        // if (account.role === constants.ROLE_TYPE.STUDENT) {
        // sz.Schedules.filter((sch) => moment(sch.date).isBetween(fromDate, lastDate)).map((sch) => {
        sz.Schedules.filter(filteredSchedulesByAccount).map((sch) => {
          const existing = validStudz.find((vs) => vs.Student.id === sch.Student.id);
          if (!existing) {
            validStudz.push({ id: sz.id, Student: sch.Student });
          }
          return sch;
        });

        return sz;
      });

    return validStudz.map((stdz, idx) => (
      <Option key={idx} value={stdz.id}>
        {stdz.Student.name}
      </Option>
    ));
  };

  useEffect(() => setShowTable(!isMobile), []);

  if (account.role === 3 && schedules && schedules.length === 0) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2 style={{ color: '#9370db', margin: '0 auto', fontSize: 20 }}>No sessions at the moment.</h2>
      </div>
    );
  }

  return (
    <>
      {account.role !== 3 ? (
        <div className={styles.reportsMenu}>
          <div className={`${styles.reportsDropDownButtonWrapper} mt-3 d-flex flex-column mb-5`}>
            <h5>Students:</h5>
            <div className={styles.reportsMenuAction}>
              <Select //
                id="studentName"
                className={styles.studentSessionDropdown}
                showSearch
                placeholder="Select Student"
                optionFilterProp="children"
                value={data.SessionId ? data.SessionId : undefined}
                onChange={(e) => fetchSessionInfo(e)}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                {listValidStudents()}
              </Select>

              {/* <Button className={`btn ${styles.btnAddReports}`} onClick={() => toggleAddReportOpen()}>
                <i className="fas fa-plus" /> Add Report
              </Button> */}
            </div>

            <ScheduleList schedules={schedules} addReport={handleAddReport} editReport={fetchTableData} />
          </div>
        </div>
      ) : null}

      {isMobile ? renderCardView() : renderTableView()}

      <AddReportModal //
        data={data}
        schedules={schedules && schedules.length > 0 ? schedules : []}
        submitReport={submitReport}
        visible={isAddReportOpen}
        onCancel={toggleAddReportOpen}
      />

      <EditReportModal //
        data={data}
        schedules={schedules && schedules.length > 0 ? schedules : []}
        submitReport={handleSubmitReport}
        visible={isEditReportOpen}
        onCancel={toggleEditReportOpen}
      />
    </>
  );
};

export default ReportsList;
