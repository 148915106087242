import { combineReducers } from 'redux';

import calendar from './calendar';
import user from './user';
import job from './job';

const commonReducer = combineReducers({
  calendar,
  user,
  job,
});

export default commonReducer;
