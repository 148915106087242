import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// redux
import { useSelector } from 'react-redux';

const PrivateRoute = (props) => {
  const { common } = useSelector((state) => state);

  if (!common) return null;

  const { authenticated } = common.user;
  if (authenticated) {
    // authorised so return component
    const { component: Component, ...rest } = props;
    return <Route {...rest} render={() => <Component {...props} />} />;
  }

  return <Redirect to="/" />;
};

export default PrivateRoute;

/*
<Route
{...rest}

render={props => {
  if (loading) {
	return <h2>Loading...</h2>;
  } else if (!authenticated) {
	return <Redirect to="/" />;
  } else {
	return <Component {...props} />;
  }
}}
/>
*/
