// Constants
const constants = {
  ROLE_TYPE: {
    HEAD: 1,
    TUTOR: 2,
    STUDENT: 3,
  },
  dateFormat: {
    displayDate: 'DD/MM/YYYY',
    displayTime: 'HH:mm',
    displayDateTime: 'DD/MM/YYYY. hh:mmA',
  },
  validBanks: {
    'AllBank Inc. Bank': 'PH_ABP',
    'Asia United Bank (AUB)': 'PH_AUB',
    'Bangko Mabuhay (A Rural Bank), Inc.': 'PH_BMB',
    'Bank of Commerce Bank': 'PH_BOC',
    'Bank of the Philippine Islands (BPI) Bank': 'PH_BPI',
    'Binangonan Rural Bank (BRBDigital cashcards)': 'PH_BRB',
    'BPI Direct BanKO, Inc., A Savings Bank': 'PH_BPIDB',
    // 'BPI Family Savings Bank': 'PH_BPI',
    'BDO Network Bank': 'PH_ONB',
    'BDO Unibank Bank': 'PH_BDO',
    'Camalig Bank': 'PH_CMG',
    'CARD Bank': 'PH_CRD',
    'Cebuana Lhuillier Rural Bank, Inc.': 'PH_CEBRUR',
    'China Bank': 'PH_CBC',
    'China Bank Savings': 'PH_CBS',
    'Citystate Savings Bank': 'PH_CSB',
    'CTBC Bank (Philippines) Corp.': 'PH_CTBC',
    'DCPAY Philippines Bank': 'PH_DCP',
    'Development Bank of the Philippines': 'PH_DBP',
    'Dungganon Bank (A Microfinance Rural Bank), Inc.': 'PH_DBI',
    'East West Banking Corporation': 'PH_EWB',
    'EastWest Rural Bank or Komo': 'PH_EWR',
    'Equicom Savings Bank, Inc.': 'PH_EQB',
    'ING Bank N.V.': 'PH_ING',
    'ISLA Bank': 'PH_ISLA',
    'Land Bank of The Philippines': 'PH_LBP',
    'Malayan Bank Savings and Mortgage Bank, Inc.': 'PH_MSB',
    'Maybank Philippines Bank': 'PH_MPI',
    'Metropolitan Bank and Trust Company (Metrobank)': 'PH_MET',
    'Omnipay, Inc. Bank': 'PH_OMNI',
    'Partner Rural Bank (Cotabato), Inc.': 'PH_PAR',
    'Philippine Bank of Communications (PBCOM)': 'PH_PBC',
    'Philippine Business Bank, Inc., A Savings Bank': 'PH_PBB',
    'Philippine National Bank (PNB)': 'PH_PNB',
    'PNB Savings Bank': 'PH_PNB',
    'Philippine Savings Bank (PSBank)': 'PH_PSB',
    'Philippine Trust Company Bank': 'PH_PTC',
    'Philippine Veterans Bank': 'PH_PVB',
    'Producers Bank': 'PH_PRB',
    'Queen City Development Bank, Inc.': 'PH_QCB',
    'Quezon Capital Rural Bank, Inc.': 'PH_QCRB',
    'Rizal Commercial Banking Corporation (RCBC)': 'PH_RCBC',
    'RCBC Savings Bank Inc.': 'PH_RSBI',
    'Robinsons Bank': 'PH_ROB',
    'Rural Bank of Guinobatan, Inc.': 'PH_RBG',
    'Security Bank Corporation': 'PH_SEC',
    'Sterling Bank of Asia': 'PH_SBA',
    'Sun Savings Bank': 'PH_SSB',
    'UnionBank of the Philippines (UBP)': 'PH_UBP',
    'UnionBank EON Bank': 'PH_UBPEON',
    'United Coconut Planters Bank (UCPB)': 'PH_UCPB',
    'Wealth Development Bank Corporation': 'PH_WDB',
    'Yuanta Savings Bank': 'PH_YUANSB',
    'UCPB Savings Bank': 'PH_UCBSB',
    'GCash E-Wallet': 'PH_GCASH',
    'PayMaya E-Wallet': 'PH_PAYMAYA',
    'Coins.PH E-Wallet': 'PH_COINS',
    'Grabpay E-wallet': 'PH_GRABPAY',
    'Starpay Corporation E-wallet': 'PH_SPY',
  },
};

export default constants;
