import React, { useCallback, useEffect, useState } from 'react';

import { startOfWeek, format, addDays } from 'date-fns';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { tutorbase } from '@redux/combineActions';

import NavbarDashboard from '@components/layouts/NavbarDashboard';

import paymentOptions from '@images/paymentoptions.png';
import { formatPrice, useDebounce } from '@utils/methods';

import './index.less';

const Checkout = () => {
  const dispatch = useDispatch();
  const {
    common: {
      user: { account },
    },
    tutorbase: {
      user: { sessions },
    },
  } = useSelector((state) => state);

  const [loadingButton, setLoadingButton] = useState(false);

  const initialPrice = account.international ? 500 : 400;

  const [data, setData] = useState(null);

  const [fromDate, setFromDate] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));

  const fetchData = useCallback(() => {
    if (account || sessions) {
      const session = sessions.filter((s) => s.isPaid === false);
      if (session.length > 0) {
        setFromDate(
          startOfWeek(new Date(session[0].date), {
            weekStartsOn: 1,
          })
        );
      }

      setData({
        StudentId: account.id,
        SessionId: session.length > 0 ? session[0].id : undefined,
        email: account.email,
        studentName: account.name,
        hours: session.length > 0 ? session[0].defaultTime : undefined,
      });
    }
  }, [account, sessions]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const numberOfHoursClick = (e) => {
  //   e.preventDefault();
  //   if (!data.hours) {
  //     return;
  //   }

  //   if (e.target.id === 'plus') {
  //     setData({ ...data, hours: data.hours + 1 });
  //   } else {
  //     setData({
  //       ...data,
  //       hours: data.hours > 1 ? data.hours - 1 : data.hours,
  //     });
  //   }
  // };

  // const fromDate = startOfWeek(new Date(), {
  //   weekStartsOn: 1,
  // });

  // const mutateEndDate = (n) => {
  //   const date = endOfWeek(new Date(), {
  //     weekStartsOn: n,
  //   });
  //   return date;
  // };

  const submitPayment = useDebounce((e) => {
    e.preventDefault();
    const checkoutData = {
      ...data,
      paymentAmount: initialPrice * data.hours,
    };

    setLoadingButton(true);
    setTimeout(() => setLoadingButton(false), 3500);

    dispatch(tutorbase.payment.createPayment(checkoutData));
  }, 300);

  const disablePayment = () => {
    let result = 0;
    if (data.hours) result = data.hours * initialPrice;
    // console.log('[disablePayment r dh] ', result, data.hours);
    return result === 0; // || data.hours < 5;
  };

  const validateTotalAmount = () => {
    let result = 0;
    if (data.hours) result = data.hours * initialPrice;
    return formatPrice(result);
  };

  // console.log('[[[ CHECKOUT sessions ]]] ', sessions);
  // console.log('[[[ CHECKOUT data]]] ', data);
  if (data === null) return null;

  return (
    <div className="checkout-wrapper">
      <NavbarDashboard />

      <div className="checkout-form-wrapper">
        <h5>Session Booking</h5>
        <h3>Checkout</h3>

        <div className="checkout-form-packer">
          <div className="checkout-form">
            <form className="form">
              <div className="col-md-8 mb-4">
                <label htmlFor="studentName" className="form-label">
                  Student Name:
                  <h4>{data.studentName}</h4>
                  {/* <input type="text" className="form-control" id="studentName" value={data.studentName} onChange={(e) => setData({ ...data, studentName: e.target.value })} /> */}
                </label>
              </div>

              <div className="col-md-8 mb-4">
                <label htmlFor="numberOfHours" className="form-label">
                  Number of Hour(s)
                  <h4>{data.hours}</h4>
                </label>
              </div>

              <div className="total-payment mt-1">
                <h6>Total :</h6>
                <h6>{validateTotalAmount()} PHP</h6>
              </div>
            </form>
            <button disabled={disablePayment()} type="submit" className="btn btn-primary mt-4 checkout-btn" onClick={(e) => submitPayment(e)}>
              {loadingButton ? (
                <>
                  <i className="fa fa-spinner fa-spin" style={{ color: 'white' }} /> Loading Payment
                </>
              ) : (
                'Proceed to Payment'
              )}
            </button>
          </div>
          <div className="checkout-summary">
            <div className="summary">
              <div>
                <h6>Session Booking for </h6>
                <h6>
                  Week {format(fromDate, 'dd MMM').toString()} - {format(addDays(fromDate, 6), 'dd MMM yyyy').toString()}{' '}
                </h6>
              </div>

              <div className="payment-options">
                <span>Secure payment by</span>

                <div
                  className="payment-options-img"
                  style={{
                    backgroundImage: `url(${paymentOptions})`,
                    backgroundPosition: 'left',
                    backgroundSize: '90%',
                    backgroundRepeat: 'no-repeat',
                    width: '300px',
                    height: '80px',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
