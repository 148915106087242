import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col } from 'antd';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import moment from 'moment';

// redux
import { useDispatch, useSelector } from 'react-redux';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import { formatPrice, useDebounce } from '@utils/methods';

import '@styles/payments.less';

// styling
import styles from './index.module.less';

const StudentPaymentList = () => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();

  const {
    common: {
      user: { account },
    },
    tutorbase: {
      user: { payment, sessions },
    },
  } = useSelector((state) => state);

  const [activePayment, setActivePayment] = useState(null);
  const [checkoutDate, setCheckoutDate] = useState(null);
  const [paidPayments, setPaidPayments] = useState([]);
  //  const [data, setData] = useState(null);
  // const [bar, setBar] = useState(null);
  const [point, setPoint] = useState({
    unpaid: false,
    paid: true,
    verified: false,
    completed: false,
  });

  const getSessionWeek = (id) => {
    const session = sessions.find((sz) => sz.id === id);
    return session ? `${moment(session.date).format('MMM DD')} - ${moment(session.date).add(6, 'days').format('MMM DD, YYYY')}` : undefined;
  };

  const fetchData = useCallback(() => {
    const bars = (v) => {
      console.log('[BARS] ', v);

      if (v.status === 'PAID' && account.showLink === true) {
        // setBar('100%');
        setPoint({ unpaid: true, paid: true, verified: true, completed: true });
      } else if (v.status === 'PAID' && account.showLink === false) {
        // setBar('65%');
        setPoint({ ...point, unpaid: true, paid: true, verified: true });
      } else if (v.status === 'PENDING') {
        // setBar('35%');
        setPoint({ ...point, unpaid: true, paid: true });
      } else {
        // setBar('0%');
      }
    };

    // if (payment.active && Object.keys(payment.active).length > 0 && account) {
    if (payment.active && account) {
      const aPayment = payment.active;
      setActivePayment(aPayment);
      setCheckoutDate(aPayment.updatedAt ? format(new Date(aPayment?.updatedAt), 'dd MMM yyyy') : null);
      // setData({
      //   UserId: account.UserId,
      //   transactionId: payment.active.transactionId,
      // });
      bars(aPayment);
    }

    if (payment.list && payment.list.length > 0 && account) {
      setPaidPayments(payment.list);
    }
    // dispatch(tutorbase.payment.getPayment(data));
  }, [dispatch, account, payment]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /* <iframe title="ifr" src=${activePayment.invoiceUrl} width="400" height="430">
            </iframe>
  */
  const launchModal = useDebounce(() => {
    window.location.href = activePayment.invoiceUrl;
    // Swal.fire({
    //   showConfirmButton: false,
    //   confirmButtonText: `Pay Later`,
    //   html: `
    //         <div className="swal-table-wrapper">
    //         <iframe title="ifr" src=${activePayment.invoiceUrl} width="400" height="430">
    //         </iframe>
    //         </div>
    //         `,
    // }).then(() => {
    //   window.location = '/payments';
    // });
  }, 300);

  // if (activePayment === null) return null;

  const gNamStyle = () => {
    console.log('[gNamStyle] ', activePayment);

    if (activePayment !== null) {
      if (activePayment.status === 'PAID' && account.showLink) return { width: '100%' };
      if (activePayment.status === 'PAID' && !account.showLink) return { width: '65%' };
      if (activePayment.status === 'PENDING') return { width: '65%' };
    }

    return { width: '0%' };
  };

  const paymentStatusClass = (v) => {
    let pstatus = 'red-text';
    console.log('[paymentStatusClass] ', v);

    if (v !== null) {
      if (v.status === 'PENDING') pstatus = 'red-text';
      if (v.status === 'PAID' && account.showLink === false) pstatus = 'blue-text';
      if (v.status === 'PAID' && account.showLink === true) pstatus = 'green-text';
    }

    return pstatus;
  };

  const paymentStatusValue = (v) => {
    let pclass = 'UNPAID';
    if (v !== null) {
      if (v.status === 'PENDING') pclass = 'UNPAID';
      if (v.status === 'PAID' && account.showLink === false) pclass = 'PAID';
      if (v.status === 'PAID' && account.showLink === true) pclass = 'COMPLETED';
    }

    return pclass;
  };

  return (
    <div className="payment-list-wrapper student-list">
      <div className="payment-status-wrap d-flex flex-column gap-2 justify-content-center align-items-center">
        {activePayment !== null && (
          <div className="payment-track mb-5">
            <div className="progress">
              <div id="dvh" className="progress-bar hmm" role="progressbar" style={gNamStyle()} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" />
            </div>

            <div className="payment-step-wrap">
              <Link className={point.unpaid ? 'active' : 'payment-step'}>
                <i className="fas fa-calendar-week" />{' '}
              </Link>
              <Link className={point.paid ? 'active' : 'payment-step'}>
                <i style={{ fontSize: '13px' }} className="fas fa-money-check-alt" />
              </Link>
              <Link className={point.verified || (activePayment.status === 'PAID' && account.showLink === false) ? 'active' : 'payment-step'}>
                <i style={{ fontSize: '17px' }} className="far fa-clock" />{' '}
              </Link>
              <Link className={point.completed ? 'active shine' : 'payment-step'}>
                <i style={{ fontSize: '14px', marginTop: '2px' }} className="fas fa-check" />{' '}
              </Link>
            </div>
          </div>
        )}

        <h5 className="green-text">Payment Status</h5>
        <div className="flex-inline-wrap">
          {activePayment === null &&
            paidPayments &&
            paidPayments.map((paidPayment, idx) => (
              <div style={{ marginRight: 5 }} key={idx}>
                {paidPayment !== null && (
                  <>
                    {/* <table style={{ width: '30vw', background: '#f1f1f1' }}>
                    <tr>
                      <td colSpan="1">
                        {paidPayment.status === 'PENDING' && <h3>Waiting for Payment</h3>}
                        {paidPayment.status === 'PAID' && account.showLink === false && <h3>Awaiting Verification</h3>}
                        {paidPayment.status === 'PAID' && account.showLink === true && <h3>Payment Verified!</h3>}
                      </td>
                    </tr>
                  </table> */}
                    {isMobile ? (
                      <Card className={styles.cardRow}>
                        <Col span={24}>
                          <span>
                            Checkout Date: <b>{format(new Date(paidPayment.paymentDate), 'dd MMM yyyy') ?? '-'}</b>
                          </span>
                          <br />
                          <span>
                            Session Week: <b>{getSessionWeek(paidPayment.SessionId) ?? '-'}</b>
                          </span>
                          <br />
                          <span>
                            Student Name: <b>{paidPayment.studentName}</b>
                          </span>
                          <br />
                          <span>
                            Hours: <b>{paidPayment.hours} hr/s.</b>
                          </span>
                          <br />
                          <span>
                            Payment Amount: <b>{formatPrice(paidPayment.paymentAmount)} PHP</b>
                          </span>
                          <br />
                          <span>
                            Payment Status: <b>{paymentStatusValue(paidPayment)}</b>
                          </span>
                          <br />
                        </Col>
                      </Card>
                    ) : (
                      <table className="student-payment-table mt-4">
                        <tbody>
                          <tr>
                            <td>
                              Checkout Date <span>:</span>
                            </td>
                            <td>{format(new Date(paidPayment.paymentDate), 'dd MMM yyyy') ?? '-'}</td>
                          </tr>

                          <tr>
                            <td>
                              Session Week <span>:</span>
                            </td>
                            <td>{getSessionWeek(paidPayment.SessionId) ?? '-'}</td>
                          </tr>

                          <tr>
                            <td>
                              Student Name <span>:</span>
                            </td>
                            <td>{paidPayment.studentName}</td>
                          </tr>

                          <tr>
                            <td>
                              Hours <span>:</span>
                            </td>
                            <td>
                              {paidPayment.hours} <span style={{ color: '#555555' }}>{paidPayment.hours === 1 ? 'hour' : 'hours'}</span>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Payment Amount <span>:</span>
                            </td>
                            <td>{formatPrice(paidPayment.paymentAmount)} PHP</td>
                          </tr>

                          <tr>
                            <td>
                              Payment Status <span>:</span>
                            </td>
                            <td className={paymentStatusClass(paidPayment)}>{paymentStatusValue(paidPayment)}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </>
                )}
              </div>
            ))}
        </div>

        {activePayment !== null && activePayment.status === 'PENDING' && <h3>Waiting for Payment</h3>}
        {activePayment !== null && activePayment.status === 'PAID' && account.showLink === false && <h3>Awaiting Verification</h3>}
        {activePayment !== null && activePayment.status === 'PAID' && account.showLink === true && <h3>Payment Verified!</h3>}

        {activePayment !== null && isMobile && (
          <Card className={styles.cardRow}>
            <Col span={24}>
              <span>
                Checkout Date: <b>{checkoutDate}</b>
              </span>
              <br />
              <span>
                Session Week: <b>{getSessionWeek(activePayment.SessionId) ?? '-'}</b>
              </span>
              <br />
              <span>
                Student Name: <b>{activePayment.studentName}</b>
              </span>
              <br />
              <span>
                Hours: <b>{activePayment.hours} hr/s.</b>
              </span>
              <br />
              <span>
                Payment Amount: <b>{formatPrice(activePayment.paymentAmount)} PHP</b>
              </span>
              <br />
              <span>
                Payment Status: <b>{paymentStatusValue(activePayment)}</b>
              </span>
              <br />
            </Col>
          </Card>
        )}

        {activePayment !== null && !isMobile && (
          <table className="student-payment-table mt-4">
            <tbody>
              <tr>
                <td>
                  Checkout Date <span>:</span>
                </td>
                <td>{checkoutDate ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  Student Name <span>:</span>
                </td>
                <td>{activePayment.studentName}</td>
              </tr>

              <tr>
                <td>
                  Hours <span>:</span>
                </td>
                <td>
                  {activePayment.hours} <span style={{ color: '#555555' }}>{activePayment.hours === 1 ? 'hour' : 'hours'}</span>
                </td>
              </tr>

              <tr>
                <td>
                  Payment Amount <span>:</span>
                </td>
                <td>{formatPrice(activePayment.paymentAmount)} PHP</td>
              </tr>

              <tr>
                <td>
                  Payment Status <span>:</span>
                </td>
                <td className={paymentStatusClass(activePayment)}>{paymentStatusValue(activePayment)}</td>
              </tr>
            </tbody>
          </table>
        )}

        {activePayment !== null && activePayment.status === 'PENDING' ? (
          <button type="button" className="btn btn-primary mt-4" onClick={() => launchModal()}>
            Pay Now
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default StudentPaymentList;
