import React from 'react';
import { Button, Descriptions, Modal } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import constants from '@utils/constants';

import { useDebounce } from '@utils/methods';

const ViewSlotModal = (props) => {
  const { account, info, sessions, visible, onCancel } = props;
  const history = useHistory();
  const { isMobile } = useWindowSize();
  // console.log('[ViewSlotModal props] ', props);

  const fetchSessionTime = () => {
    const time = info?.time ?? 0;
    const hours = info?.hours ?? 0;
    const endHours = time + hours;
    const startTime = moment(`${time}:00`, 'HH:mm').format('h:mma');
    const endTime = moment(`${endHours}:00`, 'HH:mm').format('h:mma');

    return `${startTime} - ${endTime}`;
  };

  const handleCancel = useDebounce(() => onCancel(), 200);

  const isZoomDetailsAvailable = () => {
    const sez = sessions?.find((sz) => sz.id === info?.SessionId);
    const isStudentPaid = sez?.isPaid;
    const isMeetingUrlValid = sez?.ZoomDetail.isVisible && sez?.ZoomDetail.meetingURL && sez?.ZoomDetail.meetingURL !== null;

    console.log('[VIEW isZoomDetailsAvailable sez, isStudentPaid, isMeetingUrlValid] ', sez, isStudentPaid, isMeetingUrlValid);
    return isStudentPaid && isMeetingUrlValid;
  };

  const handleCheckStatus = useDebounce(() => {
    history.push('/payments');
  }, 200);

  const handleJoinMeeting = useDebounce(() => {
    const sez = sessions?.find((sz) => sz.id === info?.SessionId);
    window.open(sez?.ZoomDetail.meetingURL, '_blank');
  }, 200);

  const isPastSessionSchedule = () => moment(info?.slotId.date, 'MMMDD').isBefore(moment());

  // console.log('[SESSION WEEK] ', sessionWeek);
  // console.log('[SESSION WEEK MMM] ', moment(info?.slotId.date, 'MMMDD').isBefore(moment()));

  return (
    <Modal //
      centered
      closable={false}
      title="Session Schedule"
      open={visible}
      destroyOnClose
      width={isMobile ? '95%' : '40%'}
      footer={
        <Button className="btn-primary" onClick={() => handleCancel()}>
          OK
        </Button>
      }
      zIndex={1021}>
      <Descriptions column={1} bordered>
        <Descriptions.Item
          label={
            <>
              <i className="far fa-calendar-alt" /> <span>Schedule Date:</span>
            </>
          }>
          {moment(info?.slotId.date, 'MMMDD').format('dddd, MMMM D, YYYY')}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <>
              <i className="far fa-clock" /> <span>Time:</span>
            </>
          }>
          {fetchSessionTime()}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <>
              <i className="fa fa-graduation-cap" />
              <span>Student:</span>
            </>
          }>
          {info?.Student.name}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <>
              <i className="fa fa-chalkboard-teacher" /> <span>Tutor:</span>
            </>
          }>
          {info?.Tutor.name}
        </Descriptions.Item>
        {/* <Descriptions.Item
          label={
            <>
              <i className="fa fa-book-open" /> <span>Subject:</span>
            </>
          }>
          {info?.subject}
        </Descriptions.Item> */}
        <Descriptions.Item
          label={
            <>
              <i className="fa fa-video" /> <span>Zoom Meeting:</span>
            </>
          }>
          {isZoomDetailsAvailable() && (
            <Button style={{ width: '50%' }} disabled={isPastSessionSchedule()} type="primary" onClick={() => handleJoinMeeting()}>
              {isPastSessionSchedule() ? 'Session Finished' : 'Join Meeting'}
            </Button>
          )}

          {!isZoomDetailsAvailable() && account.role === constants.ROLE_TYPE.TUTOR && (
            <Button style={{ width: '50%' }} disabled type="primary">
              Pending Payment
            </Button>
          )}

          {!isZoomDetailsAvailable() && account.role === constants.ROLE_TYPE.STUDENT && (
            <Button style={{ width: '50%' }} type="primary" onClick={() => handleCheckStatus()}>
              Check Status
            </Button>
          )}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default ViewSlotModal;
