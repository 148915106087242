import React from 'react';

// antd
import { Card, Typography } from 'antd';

import constants from '@utils/constants';

// import '@styles/settings.less';
import styles from './index.module.less';

const { Title } = Typography;

const roleColorMatrix = {
  1: 'blueviolet',
  2: 'coral',
  3: 'cornflowerblue',
};

const RoleCard = ({ account }) => {
  const roleIconStyle = () => {
    return {
      color: roleColorMatrix[account.role],
      fontSize: '10rem',
    };
  };

  const roleBomb = () => {
    if (account.role === constants.ROLE_TYPE.HEAD) return 'Head Teacher';
    if (account.role === constants.ROLE_TYPE.TUTOR) return 'Tutor';
    return 'Student';
  };

  return (
    <Card title="Role">
      <div className={`flex-center-start flex-col ${styles.roleCard}`}>
        <div>
          {account.role === constants.ROLE_TYPE.HEAD && <i className="fab fa-old-republic" style={roleIconStyle()} />}
          {account.role === constants.ROLE_TYPE.TUTOR && <i className="fas fa-jedi" style={roleIconStyle()} />}
          {account.role === constants.ROLE_TYPE.STUDENT && <i className="fab fa-jedi-order" style={roleIconStyle()} />}
        </div>
        <Title>{roleBomb()}</Title>
      </div>
    </Card>
  );
};

export default RoleCard;
