// got module isolation
import React, { useRef, useState } from 'react';
import { Collapse, Form } from 'antd';

// redux
import { useDispatch } from 'react-redux';
import { tutorbase } from '@redux/combineActions';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import Spinner from '@components/layouts/Spinner';

import PersonalInformation from '../applicationForms/PersonalInformation';
import EducationalBackground from '../applicationForms/EducationalBackground';
import EmploymentHistory from '../applicationForms/EmploymentHistory';
import ApplicationForTutorPosition from '../applicationForms/ApplicationForTutorPosition';
import StatementAgreement from '../applicationForms/StatementAgreement';
import AdditionalInfo from '../applicationForms/AdditionalInfo';

// import '@styles/joinourteam.less';
import styles from './index.module.less';

const { Panel } = Collapse;

const arrSubjectGroups = ['subjectGroup1', 'subjectGroup2', 'subjectGroup3', 'subjectGroup4', 'subjectGroup5', 'subjectGroup6'];
const arrSubjectGroupOthers = ['subjectGroup1Others', 'subjectGroup2Others', 'subjectGroup3Others', 'subjectGroup4Others', 'subjectGroup5Others', 'subjectGroup6Others'];

const initialData = {
  additional: '',
  address: '',
  applicationDate: '',
  availableDate: '',
  disability: '',
  email: '',
  experience: '',
  facebook: '',
  file: {},
  fullName: '',
  hours: '',
  phone: '',
  pronouns: '',
  referral: '',
  sourceInformation: { source: [], others: null },
  specialSkills: '',
  time: [],

  highSchool: '',
  highSchoolStrand: '',
  highSchoolGraduation: '',
  college: '',
  collegeCourse: '',
  collegeGraduation: '',

  languages: '',
  subjects: [],
  company: [{ company: '', position: '', yearsWorked: '', leavingReason: '' }],
  workexp: [{ company: '', position: '', yearsWorked: '', leavingReason: '' }],
  position: '',
  yearsWorked: '',
  leavingReason: '',
  companyOther: '',
  informalWork: '',
  rt: '',
  subjectGroup1: [],
  subjectGroup2: [],
  subjectGroup3: [],
  subjectGroup4: [],
  subjectGroup5: [],
  subjectGroup6: [],
  subjectGroup1Others: '',
  subjectGroup2Others: '',
  subjectGroup3Others: '',
  subjectGroup4Others: '',
  subjectGroup5Others: '',
  subjectGroup6Others: '',
  ht: undefined,
};

const SectionApplicationForm = () => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const [data, setData] = useState(initialData);
  const [showError, setShowError] = useState(false);
  // const [errMsg, setErrMsg] = useState(false);

  const [ht, setHt] = useState('');
  // const [hkey, setHkey] = useState('');
  const cRef = useRef(null);

  const [clearImage, setClearImage] = useState(false);

  const [loading, setLoading] = useState(false);

  const panelHeaderTitle = (v) => {
    return <div style={{ fontSize: isMobile ? 18 : 20 }}>{v}</div>;
  };

  const handleFormValuesChange = (v) => {
    if (arrSubjectGroupOthers.includes(Object.keys(v)[0])) {
      const fld = Object.keys(v)[0];
      setData({
        ...data,
        [fld]: v[`${Object.keys(v)[0]}`],
      });
    }
    if (arrSubjectGroups.includes(Object.keys(v)[0])) {
      const fld = Object.keys(v)[0];
      setData({
        ...data,
        [fld]: v[`${Object.keys(v)[0]}`],
      });
    }
    // console.log('[HANDLEFORMVALUECHANGE 22 v] ', v);
    // console.log('[HANDLEFORMVALUECHANGE 33 form.getFieldsValue] ', form.getFieldsValue());
  };

  const onFinishFailed = (v) => {
    // setLoading(false);
    const errs = [];
    console.log('[onFinishFailed] ', v);
    form.setFields(v.errorFields);
    v.errorFields.map((ef) => {
      ef.errors.map((ee) => {
        const chop = ee.replace(/,/g, '');
        errs.push(`${chop}<br/>`);
        return ee;
      });
      return ef;
    });

    setShowError(true);
    // setErrMsg(errs.join(''));
    if (cRef.current) cRef.current.resetCaptcha();
    setHt('');

    return false;

    // const errorFieldRef = formRef.current.querySelector('.ant-form-item-has-error input').ref;
    // errorFieldRef.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = () => {
    try {
      // setLoading(true);
      setShowError(false);
      form
        .validateFields()
        .then((values) => {
          // console.log('[V A L V E] ', values);

          if (ht === '') {
            // setLoading(false);
            setShowError(true);
            //  setErrMsg('Need to verify captcha.');
            return false;
          }

          if (!values.agreement || (values.agreement && values.agreement.length < 12)) {
            // setLoading(false);
            if (cRef.current) cRef.current.resetCaptcha();
            setShowError(true);
            // setErrMsg('Please acknowledge by ticking all the boxes.');
            setHt('');
            return false;
          }

          if (!values.file) {
            // setLoading(false);
            if (cRef.current) cRef.current.resetCaptcha();
            setShowError(true);
            // setErrMsg('Please upload a photo.');
            setHt('');
            return false;
          }

          if (!values.hours) {
            if (cRef.current) cRef.current.resetCaptcha();
            setShowError(true);
            // setErrMsg('Please input available tutoring hours.');
            setHt('');
            return false;
          }

          if (!values.languages) {
            if (cRef.current) cRef.current.resetCaptcha();
            setShowError(true);
            // setErrMsg('Please input languages/dialects spoken.');
            setHt('');
          }

          const resValues = values;

          const {
            //
            subjectGroup1,
            subjectGroup2,
            subjectGroup3,
            subjectGroup4,
            subjectGroup5,
            subjectGroup6,
            subjectGroup1Others,
            subjectGroup2Others,
            subjectGroup3Others,
            subjectGroup4Others,
            subjectGroup5Others,
            subjectGroup6Others,
          } = data;
          // console.log('[HANDLE SUBMIT THEN 2 resValues] ', resValues);

          const payload = {
            ...resValues,
            facebook: resValues.facebookInput,
            workexp: resValues.workexp ? JSON.stringify(resValues.workexp) : '',
            sourceInformation: JSON.stringify({
              source: resValues.howdidyouhear ? resValues.howdidyouhear : [],
              others: resValues.howdidyouhearother ? resValues.howdidyouhearother : null,
            }),
            // referral,
            // reasons,
            subjects: JSON.stringify({
              //
              subjectGroup1,
              subjectGroup2,
              subjectGroup3,
              subjectGroup4,
              subjectGroup5,
              subjectGroup6,
              subjectGroup1Others,
              subjectGroup2Others,
              subjectGroup3Others,
              subjectGroup4Others,
              subjectGroup5Others,
              subjectGroup6Others,
            }),
            // hours,
            time: data.time ? JSON.stringify(data.time) : '',
            // experience,
            // disability,
            // summerSkills,
            additional: resValues.inputAdditional,
            ht,
          };

          // console.log('[[[[ F I N ]]]] ', payload);
          dispatch(tutorbase.job.jobApply(payload)).then((res) => {
            if (res.success) {
              // setLoading(false);
              form.resetFields();
              setData(initialData);
              setHt('');
              setClearImage(true);
              if (cRef.current) cRef.current.resetCaptcha();
            }
          });

          return false;
        })
        .catch((er) => {
          console.log('[HANDLE SUBMIT CATCH 2 er] ', er);
          // setLoading(false);
          setShowError(true);
          // setErrMsg('Error.');
        });
    } catch (e) {
      console.log('[HANDLE SUBMIT CATCH 1 er] ', e);
      // setLoading(false);
      setShowError(true);
      // setErrMsg('Error.');
    }
  };

  // const validations = () => {
  //   // Fetch all the forms we want to apply custom Bootstrap validation styles to
  //   const forms = document.querySelectorAll('.needs-validation');

  //   // Loop over them and prevent submission
  //   Array.prototype.slice.call(forms).forEach((form) => {
  //     form.addEventListener(
  //       'submit',
  //       (event) => {
  //         if (!form.checkValidity()) {
  //           event.preventDefault();
  //           event.stopPropagation();
  //         }

  //         form.classList.add('was-validated');
  //       },
  //       false
  //     );
  //   });
  // };

  // const handleFetchDriveLink = useCallback(() => {
  //   dispatch(tutorbase.drive.fetchDriveLink()).then((res) => {
  //     if (res.success) {
  //       setData({ ...data, rt: res.data.rt });
  //     }
  //   });
  // }, [dispatch]);

  // useEffect(() => handleFetchDriveLink(), [handleFetchDriveLink]);

  // console.log('[[[ D A T A ]]] ', data);

  if (loading) return <Spinner />;

  return (
    <>
      <div className={styles['application-form-wrapper']}>
        <h3>Application Form</h3>
        <p>
          <span className={styles['green-text']}>Notice to the Applicant: </span>
          The information you provide in this application form is subject to verification by Tutor BASE Before and After-School Enrichment Center. In the case we discover that you have withheld or misrepresented the information you have provided in this form, your application will be automatically rejected or your employment with the Center will automatically be terminated.
        </p>
        <p>
          Please <b>input N/A</b> if the question in any field is not applicable to you. Once you have submitted this application form, the Recruitment Committee will review it and contact you as soon as possible if you have been chosen to proceed to the next step of the application process.
        </p>
        <p>We wish you all the best and we are looking forward to receiving your application!</p>
        {/* ${styles['form-wrapper']}  */}
      </div>
      <div>
        <div>
          <Form // ${styles['application-form']}
            onFinishFailed={onFinishFailed}
            className="gap-3 needs-validation"
            layout="vertical"
            form={form}
            ref={formRef}
            onValuesChange={handleFormValuesChange}
            onFinish={handleSubmit}>
            {/* <form className="application-form gap-3 needs-validation" noValidate encType="multipart/form-data" onSubmit={(e) => submitApply(e)} method="post"> */}

            <div className={styles['application-form-wrapper-acc']}>
              <div className={styles['form-wrapper']}>
                <div className={styles['application-form']}>
                  <Collapse style={{ width: '100%' }} accordion={false}>
                    <Panel header={panelHeaderTitle('Personal Information')} key="1">
                      <PersonalInformation form={form} styles={styles} clearImage={clearImage} />
                    </Panel>
                    <Panel header={panelHeaderTitle('Educational Background')} key="2">
                      <EducationalBackground data={data} form={form} styles={styles} />
                    </Panel>
                    <Panel header={panelHeaderTitle('Employment History')} key="3">
                      <EmploymentHistory data={data} form={form} styles={styles} />
                    </Panel>

                    <Panel header={panelHeaderTitle('Application for Tutor Position')} key="4">
                      <ApplicationForTutorPosition data={data} setData={setData} form={form} styles={styles} resetBoxes={clearImage} />
                    </Panel>

                    <Panel header={panelHeaderTitle('Additional Information')} key="5">
                      <AdditionalInfo styles={styles} />
                    </Panel>

                    <Panel header={panelHeaderTitle('Statement Agreement')} key="6">
                      <StatementAgreement //
                        setHt={setHt}
                        // setHkey={setHkey}
                        cRef={cRef}
                        showError={showError}
                        // errMsg={errMsg}
                        form={form}
                        styles={styles}
                      />
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SectionApplicationForm;
