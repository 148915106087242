import React from 'react';

// antd
import { Button, Col, Divider, Form, Input, Row } from 'antd';

// hooks
import useWindowSize from '@hooks/useWindowSize';

const CompanyInput = ({ styles, field, onRemove }) => {
  const { isMobile } = useWindowSize();
  // const currentField = getFieldValue('workexp')[field.name];

  // const handleDuplicateClick = () => {
  //   onDuplicate(field);
  // };

  return (
    <div className={styles.rowWrapper}>
      {isMobile ? (
        <>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item {...field} className="form-itemz-j" label="Company" name={[field.name, 'company']} fieldKey={[field.fieldKey, 'company']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item {...field} className="form-itemz-j" label="Position" name={[field.name, 'position']} fieldKey={[field.fieldKey, 'position']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item {...field} className="form-itemz-j" label="Years Worked (from-to)" name={[field.name, 'yearsWorked']} fieldKey={[field.fieldKey, 'yearsWorked']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item {...field} className="form-itemz-j" label="Reason/s for Leaving" name={[field.name, 'leavingReason']} fieldKey={[field.fieldKey, 'leavingReason']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <div className="mt-2 align-right">
            <Button className="mr-8" type="danger" onClick={() => onRemove(field.name)}>
              Remove
            </Button>
            {/* <Button onClick={handleDuplicateClick}>Duplicate</Button> */}
          </div>
        </>
      ) : (
        <>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item {...field} className="form-itemz-j" label="Company" name={[field.name, 'company']} fieldKey={[field.fieldKey, 'company']}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item {...field} className="form-itemz-j" label="Position" name={[field.name, 'position']} fieldKey={[field.fieldKey, 'position']}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item {...field} className="form-itemz-j" label="Years Worked (from-to)" name={[field.name, 'yearsWorked']} fieldKey={[field.fieldKey, 'yearsWorked']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item {...field} className="form-itemz-j" label="Reason/s for Leaving" name={[field.name, 'leavingReason']} fieldKey={[field.fieldKey, 'leavingReason']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <div className="mt-2 align-right">
            <Button className="mr-8" type="danger" onClick={() => onRemove(field.name)}>
              Remove
            </Button>
            {/* <Button onClick={handleDuplicateClick}>Duplicate</Button> */}
          </div>
        </>
      )}

      <Divider />
    </div>
  );
};

export default CompanyInput;
