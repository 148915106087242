import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { common } from '@redux/combineActions';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import constants from '@utils/constants';

import '@styles/navbar.less';
// import styles from './index.module.less';

const navbarLogo = 'https://tutorbasecenter.s3.ap-southeast-1.amazonaws.com/navbaroneline-oct18.png';


const NavbarDashboard = () => {
  const dispatch = useDispatch();
  const { isTablet } = useWindowSize();

  const {
    common: {
      user: { account, authenticated },
    },
  } = useSelector((state) => state);

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 800px)',
  });

  window.onscroll = () => {
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      navbar.id = window.scrollY !== 0 ? 'scrolled' : 'navbar';
    }
  };

  const headTermsLink = () => {
    if (account.role === constants.ROLE_TYPE.STUDENT || account.role === constants.ROLE_TYPE.HEAD) return 'dropdown-item';
    return 'disabled dropdown-item';
  };

  const handleLogout = () => {
    // console.log('[HANDLELOGOUT]');
    dispatch(common.user.userLogout());
  };

  if (!authenticated) {
    return <Redirect to="/" />;
  }

  return (
    <nav className="gg navbar navbar-expand-lg fixed-top tb-navbar">
      <div className="container" data-aos="fade-in" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-duration="600">
        {/* <Link
          className="navbar-brand"
          // onClick={() => {
          //   window.location.href = '/main';
          // }}
          to="/main">
          <img src={navbarLogo} alt="logo" />
        </Link> */}

        <div className="navbar-brand">
          <img src={navbarLogo} alt="logo" />
        </div>

        {isTabletOrMobileDevice ? (
          <div className="nav">
            <Link className="nav-link" to="/login" onClick={handleLogout}>
              Log Out
            </Link>

            <button type="button" className="nav-toggler" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
              <i className={`fas fa-bars ${isTablet ? 'fa-2x' : 'fa-1x'}`} />
            </button>
          </div>
        ) : null}

        <div className={isTabletOrMobileDevice ? 'collapse navbar-collapse mt-3 flex-center-end' : 'nav'} id="navbarSupportedContent1">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/index"
                // onClick={() => {
                //   window.location.href = '/index';
                // }}
              >
                Home
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => {
                  window.location.href = '/gdrive';
                }}>
                Google Drive
              </Link>
            </li> */}

            <li className="nav-item">
              <Link
                to="/reports"
                className="nav-link"
                // onClick={() => {
                //   window.location.href = '/reports';
                // }}
              >
                Reports
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/payments"
                className="nav-link"
                // onClick={() => {
                //   window.location.href = '/payments';
                // }}
              >
                Payments
              </Link>
            </li>

            {account.role === constants.ROLE_TYPE.STUDENT && (
              <li className="nav-item">
                <Link
                  to="/tos"
                  className=" nav-link"
                  // onClick={() => {
                  //   window.location.href = '/tos';
                  // }}
                >
                  Terms of Service
                </Link>
              </li>
            )}
            {account.role === constants.ROLE_TYPE.TUTOR && (
              <li className="nav-item">
                <Link
                  to="/honorscode"
                  className="nav-link"
                  // onClick={() => {
                  //   window.location.href = '/honorscode';
                  // }}
                >
                  Tutors’ Honor Code
                </Link>
              </li>
            )}

            {account.role === constants.ROLE_TYPE.HEAD && (
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Contracts
                </a>
                <ul className="dropdown-menu p-3" aria-labelledby="navbarDropdown">
                  <li className="mt-1">
                    <Link to="/tos" className={headTermsLink()}>
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/honorscode" className={headTermsLink()}>
                      Tutors&apos; Honor Code
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            <li className="nav-item">
              <Link
                to="/settings"
                className="nav-link"
                // onClick={() => {
                //   window.location.href = '/settings';
                // }}
              >
                <i className="fa fa-cog" />
              </Link>
            </li>

            {isTabletOrMobileDevice ? null : (
              <li className="nav-item logout">
                <Link className="nav-link" to="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={handleLogout}>
                  Log Out
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

NavbarDashboard.shouldUpdateScroll = () => {
  return false;
};

export default NavbarDashboard;
