import React, { useCallback, useState, useEffect } from 'react';

// redux
import { useDispatch } from 'react-redux';
import { tutorbase } from '@redux/combineActions';

import Navbar from '@components/layouts/NavbarDashboard';
import Spinner from '@components/layouts/Spinner';
import Banner from '@components/layouts/Banner';

import SectionWhyChoose from '@components/landing/SectionWhyChoose';
import Carousel from '@components/landing/Carousel';
import SectionRates from '@components/landing/SectionRates';
import SectionBookingYour from '@components/landing/SectionBookingYour';

import '@styles/homepage.less';

const MainPage = () => {
  const dispatch = useDispatch();
  // const {
  //   common: {
  //     user: { account },
  //   },
  // } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);
  const [showSection, setShowSection] = useState(false);

  const fetchScheduleJobApp = useCallback(() => {
    // const { id, role, UserId } = account;
    // dispatch(
    //   tutorbase.schedule.getSchedule({
    //     id: role === constants.ROLE_TYPE.HEAD ? id : UserId,
    //   })
    // );

    dispatch(tutorbase.job.listJobApp());
  }, [dispatch]);

  useEffect(() => {
    fetchScheduleJobApp();
    const timer = setTimeout(() => {
      setShowSection(true);
    }, 1000);
    setTimeout(() => setLoading(false), 900);
    window.scroll(0, 0);

    return () => clearTimeout(timer);
  }, [fetchScheduleJobApp]);

  if (loading) return <Spinner />;

  return (
    <div className="wrap">
      <Navbar />
      <Banner />
      {showSection && (
        <>
          <SectionWhyChoose />
          <Carousel />
          <SectionRates />
          <SectionBookingYour />
        </>
      )}
    </div>
  );
};

export default MainPage;
