import React from 'react';
// antd
import { Checkbox, Col, Form, Input, Row } from 'antd';

// hooks
import useWindowSize from '@hooks/useWindowSize';

const SubjectCheckboxes = (props) => {
  const { styles } = props;
  const { isMobile } = useWindowSize();

  // const addData = (e) => {
  //   // if (e.target.checked && !props.data.subjects.includes(e.target.value)) {
  //   // 	props.setData({
  //   // 		...props.data,
  //   // 		subjects: [...props.data.subjects, e.target.value],
  //   // 	});
  //   // } else if (
  //   // 	!e.target.checked &&
  //   // 	props.data.subjects.includes(e.target.value)
  //   // ) {
  //   // 	props.setData({
  //   // 		...props.data,
  //   // 		subjects: props.data.subjects.filter((item) => item !== e.target.value),
  //   // 	});
  //   // } else {
  //   // 	return;
  //   // }

  //   const subject = props.data.subjects.find((sub) => sub.area === e.target.name);

  //   if (!subject) {
  //     if (e.target.type === 'text') {
  //       props.setData({
  //         ...props.data,
  //         subjects: [
  //           ...props.data.subjects,
  //           {
  //             area: e.target.name,
  //             subjects: [],
  //             others: e.target.value,
  //           },
  //         ],
  //       });
  //     } else if (e.target.type !== 'text' && e.target.value !== 'others') {
  //       props.setData({
  //         ...props.data,
  //         subjects: [
  //           ...props.data.subjects,
  //           {
  //             area: e.target.name,
  //             subjects: [e.target.value],
  //             others: null,
  //           },
  //         ],
  //       });
  //     }
  //   } else {
  //     if (e.target.type === 'text') {
  //       props.setData({
  //         ...props.data,
  //         subjects: [
  //           ...props.data.subjects.filter((sub) => sub.area !== e.target.name),
  //           {
  //             area: subject.area,
  //             subjects: subject.subjects,
  //             others: e.target.value,
  //           },
  //         ],
  //       });
  //     } else if (e.target.checked && e.target.value !== 'others') {
  //       props.setData({
  //         ...props.data,
  //         subjects: [
  //           ...props.data.subjects.filter((sub) => sub.area !== e.target.name),
  //           {
  //             area: subject.area,
  //             subjects: [...subject.subjects, e.target.value],
  //             others: subject.others,
  //           },
  //         ],
  //       });
  //     } else if (!e.target.checked && subject.subjects.includes(e.target.value)) {
  //       if (subject.subjects.length === 1 && subject.others === null) {
  //         props.setData({
  //           ...props.data,
  //           subjects: [...props.data.subjects.filter((sub) => sub.area !== e.target.name)],
  //         });
  //       } else {
  //         props.setData({
  //           ...props.data,
  //           subjects: [
  //             ...props.data.subjects.filter((sub) => sub.area !== e.target.name),
  //             {
  //               area: subject.area,
  //               subjects: subject.subjects.filter((sub) => sub !== e.target.value),
  //               others: subject.others,
  //             },
  //           ],
  //         });
  //       }
  //     } else if (!e.target.checked && e.target.value === 'others') {
  //       if (subject.subjects.length === 0) {
  //         props.setData({
  //           ...props.data,
  //           subjects: [...props.data.subjects.filter((sub) => sub.area !== e.target.name)],
  //         });
  //       } else {
  //         props.setData({
  //           ...props.data,
  //           subjects: [
  //             ...props.data.subjects.filter((sub) => sub.area !== e.target.name),
  //             {
  //               area: subject.area,
  //               subjects: subject.subjects,
  //               others: null,
  //             },
  //           ],
  //         });
  //       }
  //     } else {
  //       return;
  //     }
  //   }
  // };

  return (
    <div className={styles.rowWrapper}>
      <Form.Item className="form-itemz-j" label="Discipline area(s) and subject(s) you can teach in (choose as many as applicable):">
        {isMobile ? (
          <div style={{ padding: 10 }}>
            <div style={{ marginRight: 50 }}>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item style={{ fontWeight: 600 }} className="form-itemz-j" label="English" name="subjectGroup1">
                    <Checkbox.Group
                      style={{
                        width: '100%',
                      }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Reading">Reading</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Writing">Writing</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Grammar">Grammar</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Literature">Literature</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Others">Others (please specify)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="form-itemz-j" name="subjectGroup1Others">
                    <Row>
                      <Col span={24}>
                        <Input />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item style={{ fontWeight: 600 }} className="form-itemz-j" label="Filipino" name="subjectGroup2">
                    <Checkbox.Group
                      style={{
                        width: '100%',
                      }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Pagbasa">Pagbasa</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Pagsulat">Pagsulat</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Gramatika">Gramatika</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Panitikan">Panitikan</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="others">Others (please specify)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="form-itemz-j" name="subjectGroup2Others">
                    <Row>
                      <Col span={24}>
                        <Input />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div style={{ marginRight: 50 }}>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item style={{ fontWeight: 600 }} className="form-itemz-j" label="Mathematics" name="subjectGroup3">
                    <Checkbox.Group
                      style={{
                        width: '100%',
                      }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="General Mathematics">General Mathematics</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Algebra">Algebra</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Business Math">Business Math</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Geometry">Geometry</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Logical Reasoning">Logical Reasoning</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Numerical Reasoning">Numerical Reasoning</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Pre-Calculus">Pre-Calculus</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Calculus">Calculus</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Trigonometry">Trigonometry</Checkbox>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Checkbox value="Others">Others (please specify)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="form-itemz-j" name="subjectGroup3Others">
                    <Row>
                      <Col span={24}>
                        <Input />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div style={{ marginRight: 50 }}>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item style={{ fontWeight: 600 }} className="form-itemz-j" label="Science" name="subjectGroup4">
                    <Checkbox.Group
                      style={{
                        width: '100%',
                      }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="General Science">General Science</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Biology">Biology</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Chemistry">Chemistry</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Earth Science">Earth Science</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Geology">Geology</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Medical Science">Medical Science</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Physics">Physics</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Others">Others (please specify)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="form-itemz-j" name="subjectGroup4Others">
                    <Row>
                      <Col span={24}>
                        <Input />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div style={{ marginRight: 50 }}>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item style={{ fontWeight: 600 }} className="form-itemz-j" label="Social Science" name="subjectGroup5">
                    <Checkbox.Group
                      style={{
                        width: '100%',
                      }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Civics">Civics</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Sibika">Sibika</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Economics">Economics</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Geography">Geography</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Heograpiya">Heograpiya</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="History">History</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Kasaysayan">Kasaysayan</Checkbox>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Checkbox value="Social Studies">Social Studies</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Araling Panlipunan">Araling Panlipunan</Checkbox>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Checkbox value="Others">Others (please specify)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="form-itemz-j" name="subjectGroup5Others">
                    <Row>
                      <Col span={24}>
                        <Input />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div style={{ marginRight: 50 }}>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item style={{ fontWeight: 600 }} className="form-itemz-j" label="Other Subjects" name="subjectGroup6">
                    <Checkbox.Group
                      style={{
                        width: '100%',
                      }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Arts">Arts</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Christian Living Education">Christian Living Education</Checkbox>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col span={24}>
                          <Checkbox value="Education">Education</Checkbox>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Computer">Computer</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Home Economics">Home Economics</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Music">Music</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Research">Research</Checkbox>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Checkbox value="Values Education">Values Education</Checkbox>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Checkbox value="Others">Others (please specify)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="form-itemz-j" name="subjectGroup6Others">
                    <Row>
                      <Col span={24}>
                        <Input />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div style={{ padding: 10, display: 'flex' }}>
            <div style={{ marginRight: 50 }}>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-j" label="English" name="subjectGroup1">
                    <Checkbox.Group
                      style={{
                        width: '100%',
                      }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Reading">Reading</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Writing">Writing</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Grammar">Grammar</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Literature">Literature</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Others">Others (please specify)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="form-itemz-j" name="subjectGroup1Others">
                    <Row>
                      <Col span={24}>
                        <Input />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-j" label="Filipino" name="subjectGroup2">
                    <Checkbox.Group
                      style={{
                        width: '100%',
                      }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Pagbasa">Pagbasa</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Pagsulat">Pagsulat</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Gramatika">Gramatika</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Panitikan">Panitikan</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="others">Others (please specify)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="form-itemz-j" name="subjectGroup2Others">
                    <Row>
                      <Col span={24}>
                        <Input />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div style={{ marginRight: 50 }}>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-j" label="Mathematics" name="subjectGroup3">
                    <Checkbox.Group
                      style={{
                        width: '100%',
                      }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="General Mathematics">General Mathematics</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Algebra">Algebra</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Business Math">Business Math</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Geometry">Geometry</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Logical Reasoning">Logical Reasoning</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Numerical Reasoning">Numerical Reasoning</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Pre-Calculus">Pre-Calculus</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Calculus">Calculus</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Trigonometry">Trigonometry</Checkbox>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Checkbox value="Others">Others (please specify)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="form-itemz-j" name="subjectGroup3Others">
                    <Row>
                      <Col span={24}>
                        <Input />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div style={{ marginRight: 50 }}>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-j" label="Science" name="subjectGroup4">
                    <Checkbox.Group
                      style={{
                        width: '100%',
                      }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="General Science">General Science</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Biology">Biology</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Chemistry">Chemistry</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Earth Science">Earth Science</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Geology">Geology</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Medical Science">Medical Science</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Physics">Physics</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Others">Others (please specify)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="form-itemz-j" name="subjectGroup4Others">
                    <Row>
                      <Col span={24}>
                        <Input />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div style={{ marginRight: 50 }}>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-j" label="Social Science" name="subjectGroup5">
                    <Checkbox.Group
                      style={{
                        width: '100%',
                      }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Civics">Civics</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Sibika">Sibika</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Economics">Economics</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Geography">Geography</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Heograpiya">Heograpiya</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="History">History</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Kasaysayan">Kasaysayan</Checkbox>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Checkbox value="Social Studies">Social Studies</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Araling Panlipunan">Araling Panlipunan</Checkbox>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Checkbox value="Others">Others (please specify)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="form-itemz-j" name="subjectGroup5Others">
                    <Row>
                      <Col span={24}>
                        <Input />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div style={{ marginRight: 50 }}>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item className="form-itemz-j" label="Other Subjects" name="subjectGroup6">
                    <Checkbox.Group
                      style={{
                        width: '100%',
                      }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Arts">Arts</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Christian Living Education">Christian Living Education</Checkbox>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col span={24}>
                          <Checkbox value="Education">Education</Checkbox>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Computer">Computer</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Home Economics">Home Economics</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Music">Music</Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Checkbox value="Research">Research</Checkbox>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Checkbox value="Values Education">Values Education</Checkbox>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Checkbox value="Others">Others (please specify)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="form-itemz-j" name="subjectGroup6Others">
                    <Row>
                      <Col span={24}>
                        <Input />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Form.Item>
    </div>
  );
};

export default SubjectCheckboxes;
