import React, { useState, useEffect } from 'react';
// import { startOfWeek } from 'date-fns';
import { Button, Space, Table } from 'antd';
import moment from 'moment-timezone';

// redux
import { useSelector } from 'react-redux';

// hooks
// import useWindowSize from '@hooks/useWindowSize';

// utils
import { spaceShipLoader } from '@utils/methods';

// constants
// import constants from '@utils/constants';

// import AddReportModal from '../modals/AddReportModal';
// import EditReportModal from '../modals/EditReportModal';

import styles from './index.module.less';

// const { Column, ColumnGroup } = Table;
// const { Option } = Select;

// const initialData = {
//   StudentId: 0,
//   StudentName: '',
//   StudentGrade: '',
//   StudentSchool: '',
//   TutorId: '',
//   TutorName: '',
//   Schedule: '',
//   ScheduleId: '',
//   subject: '',
//   date: '',
//   reportGoal: '',
//   reportAdditional: '',
//   SessionId: '',
// };

// const today = moment();
// const fromDate = startOfWeek(new Date(), {
//   weekStartsOn: 1,
// });

// show the next week set
// const lastDate = addDays(new Date(fromDate), 7);

const ScheduleList = ({ schedules, addReport, editReport }) => {
  // const dispatch = useDispatch();
  // const { isMobile } = useWindowSize();

  const {
    tutorbase: {
      user: { sessions },
    },
  } = useSelector((state) => {
    // console.log('[BVLKBVLK STATE] ', state);
    return state;
  });

  // const [showTable, setShowTable] = useState(false);

  const [dataSource, setDataSource] = useState([]);

  const fetchSessionTime = ({ time, hours }) => {
    const endHours = time + hours;
    const startTime = moment(`${time}:00`, 'HH:mm').format('h:mma');
    const endTime = moment(`${endHours}:00`, 'HH:mm').format('h:mma');

    return `${startTime} - ${endTime}`;
  };

  useEffect(() => {
    setDataSource(spaceShipLoader(true, schedules));
  }, [schedules]);

  const columns = [
    {
      title: 'Date and Time',
      render: (record) => {
        const tme = moment.tz(record.date, 'Asia/Singapore');
        const firstSlot = JSON.parse(JSON.parse(record.slotId))[0];
        return `${moment(tme).local().format('ddd, MMMM D, YYYY')}, ${moment(`${firstSlot.time}:00`, 'HH:mm').format('h:mma')}`;
      },
    },
    {
      title: 'Student Name',
      render: (record) => {
        return record.Student.name;
      },
    },
    {
      title: 'Tutor Name',
      render: (record) => {
        return record.Tutor.name;
      },
    },
    {
      title: 'Total Hours',
      dataIndex: 'hours',
      key: 'hours',
    },
    {
      title: 'Actions',
      key: 'action',
      render: (record) => {
        const sz = sessions.find((s) => s.StudentId === record.StudentId);
        const pload = {
          StudentId: record.StudentId,
          StudentName: sz.Student.name,
          StudentGrade: sz.Student.grade,
          StudentSchool: sz.Student.school,
          TutorId: record.Tutor.id,
          TutorName: record.Tutor.name,
          SessionId: record.SessionId,
          Schedule: fetchSessionTime({ time: record.time, hours: record.hours }),
        };

        return (
          <Space size="middle">
            {record.Report !== null && (
              <Button type="button" className={`btn list-btn-primary ${styles.sessionScheduleCta} ${styles.viewReport}`} onClick={() => editReport(record.Report)}>
                View Report
              </Button>
            )}
            {record.Report === null && (
              <Button type="button" className={`btn list-btn-primary ${styles.sessionScheduleCta} ${styles.zoomCta}`} onClick={() => addReport(pload)}>
                &nbsp;Add Report
              </Button>
            )}

            {/* <Button className={styles.btnLeft} icon={<EditOutlined />} onClick={handleEdit} /> */}
            {/* <Button disabled={record.isPaid} className={styles.btnLeft} icon={<DeleteOutlined />} onClick={(e) => handleDelete(e, record.id)} /> */}
          </Space>
        );
      },
    },
  ];

  return (
    <div className={styles.bulkWrapper}>
      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <h5>Session Schedules:</h5>
      </div>
      <Table columns={columns} dataSource={dataSource} />
    </div>
  );
};

export default ScheduleList;
