import React from 'react';
import { Button, Checkbox, Form, Row, Col } from 'antd';
import HCaptcha from '@hcaptcha/react-hcaptcha';

// hooks
import useWindowSize from '@hooks/useWindowSize';

const StatementAgreement = ({ cRef, setHt, showError, styles }) => {
  const { isMobile } = useWindowSize();
  const handleVerificationSuccess = (token, ekey) => {
    setHt(token);
    // setHkey(ekey);
  };

  return (
    <>
      <h5>PLEASE READ AND CHECK EACH STATEMENT TO SIGNIFY THAT YOU AGREE.</h5>

      {/* className={styles.formCheckInput}  */}

      <div className="checkbox-list">
        <Form.Item style={{ fontWeight: 500 }} className="form-itemz-s" label="" name="agreement">
          <Checkbox.Group
            style={{
              width: '100%',
              padding: '0rem 10%',
            }}>
            <Row>
              <Col span={24} style={{ marginBottom: '2rem' }}>
                <Checkbox value="1">I hereby certify that the information I provided in this application form is true and complete to the best of my knowledge. I understand that discovery of falsification of the facts set forth in this form will result in immediate rejection of my application or termination of my employment, regardless of the time elapsed before discovery.</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginBottom: '2rem' }}>
                <Checkbox value="2">I authorize Tutor BASE Before and After-School Enrichment Center to thoroughly examine and investigate my employment history and past experience, educational background, and other matters related to my application.</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginBottom: '2rem' }}>
                <Checkbox value="3">I authorize Tutor BASE Before and After-School Enrichment Center to contact and thoroughly interview the employers or companies I have listed in my employment history, if applicable.</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginBottom: '2rem' }}>
                <Checkbox value="4">I understand that I must disclose any criminal records or history of academic warning, probation, suspension, or dismissal at any level of my education.</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginBottom: '2rem' }}>
                <Checkbox value="5">I understand that this is only an application for part-time independent contractual employment and that I am not liable to receive benefits from Tutor BASE Before and After-School Enrichment Center. I also understand that no employment contract is being offered at this time.</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginBottom: '2rem' }}>
                <Checkbox value="6">I understand that there is no guarantee that my application will be accepted.</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginBottom: '2rem' }}>
                <Checkbox value="7">I understand that Tutor BASE Before and After-School Enrichment Center does not discriminate in its employment practices against any person because of race, color, nationality or ethnic origin, sex, gender, age, disability, or other factors.</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginBottom: '2rem' }}>
                <Checkbox value="8">I understand that employment at Tutor BASE Before and After-School Enrichment Center requires me to read and sign a Tutors&apos; Honor Code.</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginBottom: '2rem' }}>
                <Checkbox value="9">I understand that I will be a part of a pool of tutors available to teach students, and that each tutor is to be assigned to a student whose areas for improvement coincide with the tutor&apos;s areas of expertise. As such, I understand that upon being hired as a tutor, I may not immediately be assigned to tutoring a student. I also understand that I may be assigned as a substitute tutor for a student whom another tutor handles in the latter&apos;s temporary absence.</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginBottom: '2rem' }}>
                <Checkbox value="10">In addition to weekly tutoring, I will attend occasional important meetings as called by the Head Teacher, usually held on Fridays.</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginBottom: '2rem' }}>
                <Checkbox value="11">In addition to weekly tutoring, I will install the Viber application which is used as the main platform for communication among tutors.</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginBottom: '2rem' }}>
                <Checkbox value="12">I understand that Tutor BASE Before and After-School Enrichment Center has the authority to terminate my employment at any time given a valid and justifiable reason to do so.</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        {/* {showError && <div style={{ marginTop: '1rem', textAlign: 'center', color: 'red', fontSize: 20 }} dangerouslySetInnerHTML={{ __html: errMsg }} />} */}
        {showError && <div style={{ marginTop: '1rem', textAlign: 'center', color: 'red', fontSize: isMobile ? '1.1em' : 20 }}>Please complete answering the form. Please input N/A if the question in any field is not applicable to you.</div>}
        {/* ${styles.full}  */}

        <div className="d-flex justify-content-center mt-4" style={{ height: 70 }}>
          <HCaptcha //
            sitekey={process.env.REACT_APP_HC_SKEY}
            onVerify={handleVerificationSuccess}
            ref={cRef}
          />
        </div>

        <div className="why-btn-wrapper d-flex justify-content-center mt-4">
          <Button type="button" className={`btn why-btn ${styles.ctaAgreement}`} htmlType="submit">
            {/* onClick={(e) => submitApply(e)} */}
            SUBMIT APPLICATION <i className="far fa-arrow-alt-circle-right" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default StatementAgreement;
