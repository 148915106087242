import React from 'react';
import * as FaIcons from 'react-icons/fa';

// hooks
import useWindowSize from '@hooks/useWindowSize';

const ServicesSection = () => {
  const style = { fill: 'white', fontSize: '3rem' };
  const { isMobile } = useWindowSize();

  // data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800"
  return (
    <div className="service-section">
      <div className="title-service-section">
        <div className="post-mini-title">What Tutor BASE Offers</div>
        <div className="post-big-title">Our Services</div>
        <div className="service-text-post">
          {isMobile ? (
            <p>All classes simulate side-by-side tutoring in a remote setting to fully facilitate a quality learning experience.</p>
          ) : (
            <p>
              All classes simulate side-by-side tutoring in a <br />
              remote setting to fully facilitate a quality learning experience.
            </p>
          )}
        </div>
      </div>

      <div className="service-cards-container d-flex">
        {/* <div> */}
        <div
          // className="d-flex flex-row justify-content-center"
          className="row-container">
          <div className="service-card blue-card d-flex" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800">
            <div className="service-card-content">
              <div className="service-card-icon">
                <i>
                  <FaIcons.FaUniversity style={style} />
                </i>
              </div>
              <div className="text-service">One-on-One Tutorials for English, Math, Science, & Other Subjects</div>
            </div>
          </div>
          <div className="service-card pink-card d-flex" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="200">
            <div className="service-card-content">
              <div className="service-card-icon">
                <i>
                  <FaIcons.FaHome style={style} />
                </i>
              </div>
              <div className="text-service">
                Homework Assistance, <br /> Project Assistance, & Test Preparation
              </div>
            </div>
          </div>
          <div className="service-card blue-card d-flex" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="400">
            <div className="service-card-content">
              <div className="service-card-icon">
                <i>
                  <FaIcons.FaBookReader style={style} />
                </i>
              </div>
              <div className="text-service">
                Review of Past <br /> Lessons and Tutorials for Advanced Lessons
              </div>
            </div>
          </div>
        </div>

        <div className="row-container">
          <div className="service-card pink-card d-flex" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800">
            <div className="service-card-content">
              <div className="service-card-icon">
                <i>
                  <FaIcons.FaUserGraduate style={style} />
                </i>
              </div>
              <div className="text-service">Supplementary Learning Materials for Homeschoolers</div>
            </div>
          </div>
          <div className="service-card blue-card d-flex" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="200">
            <div className="service-card-content">
              <div className="service-card-icon">
                <i>
                  <FaIcons.FaSlideshare style={style} />
                </i>
              </div>
              <div className="text-service">Academic Support for Children with Special Needs</div>
            </div>
          </div>
          <div className="service-card pink-card d-flex" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-delay="400">
            <div className="service-card-content">
              <div className="service-card-icon">
                <i>
                  <FaIcons.FaLanguage style={style} />
                </i>
              </div>
              <div className="text-service">Summer Fun Programs and Master Classes</div>
              {/* <div className="text-service-sub">(Foreign Languages, Creative Arts, etc.)</div> */}
              <div className="text-service-sub">(Creative Arts, Foreign Languages, etc.)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
