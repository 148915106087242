import React, { useMemo } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Link, useHistory } from 'react-router-dom';

// hooks
import useWindowSize from '@hooks/useWindowSize';

import nc1 from '@sliders/overlay/nccaa-1.jpg';
import nc2 from '@sliders/overlay/nccaa-2.jpg';
import np1 from '@sliders/overlay/npqea-1.jpg';
import np2 from '@sliders/overlay/npqea-2.jpg';
import pa1 from '@sliders/overlay/pacse-1.jpg';
import pa2 from '@sliders/overlay/pacse-2.jpg';

import styles from './index.module.less';

const Carousel = () => {
  const history = useHistory();
  const { isMobile, isTablet } = useWindowSize();

  const countSlidesToShow = (b) => {
    let v = 3;
    if (isMobile) {
      v = 1;
    }
    if (isTablet) {
      v = 2;
    }

    if (!isMobile && !isTablet) {
      v = 3;
    }
    return v;
  };

  const smSettings = useMemo(() => {
    return {
      arrows: false,
      autoplay: true,
      dots: false,
      infinite: true,
      slidesToShow: countSlidesToShow('slidesShow'),
      slidesToScroll: countSlidesToShow('slidesScroll'),
      centerPadding: '10px',
      autoPlaySpeed: isMobile ? 6000 : 6000,
      speed: isMobile ? 6000 : 8000,
      cssEase: 'linear',
      pauseOnHover: true,
      // pauseOnFocus: true,
    };
  }, [isMobile, isTablet]);

  const webSettings = useMemo(() => {
    return {
      arrows: false,
      autoplay: true,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: '10px',
      autoPlaySpeed: isMobile ? 6000 : 6000,
      speed: isMobile ? 6000 : 8000,
      cssEase: 'linear',
      pauseOnHover: true,
      // pauseOnFocus: true,
    };
  }, [isMobile]);

  const testiItems = [
    <div key={1} className={styles.awardsWrapperSlick}>
      <div className={styles.testiBodySlick}>
        <span className={styles.firstLetter}>W</span>
        hen I see my teachers in Tutor BASE, they help me get <span className={styles.blueTextBody}>high grades in school.</span>..
        {/* <span className={styles.quoteEnd}></span> */}
      </div>

      <div className={`d-flex flex-column justify-content-center align-items-center ${styles.testiStar}`}>
        <span>
          {Array.from(Array(5), (e, i) => {
            return <ion-icon key={`star${i}`} name="star" />;
          })}
        </span>
        <span className={styles.nameTextTesti}>Zach R.</span>
        <span className={styles.subText}>Student</span>
      </div>
    </div>,

    <div key={2} className={styles.awardsWrapperSlick}>
      <div className={styles.testiBodySlick}>
        <span className={styles.firstLetter}>I</span>
        &nbsp;have been staying in Tutor BASE on and off since I wanted to become more responsible...
        {/* <span className={styles.quoteEnd}></span> */}
      </div>

      <div className={`d-flex flex-column justify-content-center align-items-center ${styles.testiStar}`}>
        <span>
          {Array.from(Array(5), (e, i) => {
            return <ion-icon key={`star${i}`} name="star" />;
          })}
        </span>
        <span className={styles.nameTextTesti}>Zoe R.</span>
        <span className={styles.subText}>Student</span>
      </div>
    </div>,

    <div key={3} className={styles.awardsWrapperSlick}>
      <div className={styles.testiBodySlick}>
        <span className={styles.firstLetter}>T</span>
        utor BASE Center has truly helped our children <span className={styles.blueTextBody}>embrace school learning with comfort and ease</span>...
        {/* <span className={styles.quoteEnd}></span> */}
      </div>

      <div className={`d-flex flex-column justify-content-center align-items-center ${styles.testiStar}`}>
        <span>
          {Array.from(Array(5), (e, i) => {
            return <ion-icon key={`star${i}`} name="star" />;
          })}
        </span>
        <span className={styles.nameTextTesti}>Arnon and Eleanor R.</span>
        <span className={styles.subText}>Parents of Zach and Zoe</span>
      </div>
    </div>,

    <div key={4} className={styles.awardsWrapperSlick}>
      <div className={styles.testiBodySlick}>
        <span className={styles.firstLetter}>A</span>
        fter all of these months, I think <span className={styles.blueTextBody}>I have learned even better in Tutor BASE compared to in school</span>...
        {/* <span className={styles.quoteEnd}></span> */}
      </div>

      <div className={`d-flex flex-column justify-content-center align-items-center ${styles.testiStar}`}>
        <span>
          {Array.from(Array(5), (e, i) => {
            return <ion-icon key={`star${i}`} name="star" />;
          })}
        </span>
        <span className={styles.nameTextTesti}>Nate C.</span>
        <span className={styles.subText}>Student</span>
      </div>
    </div>,

    <div key={5} className={styles.awardsWrapperSlick}>
      <div className={styles.testiBodySlick}>
        <span className={styles.firstLetter}>T</span>
        hank you very much, Tutor BASE, for all the support you&apos;ve given my son for the past four years...
        {/* <span className={styles.quoteEnd}></span> */}
      </div>

      <div className={`d-flex flex-column justify-content-center align-items-center ${styles.testiStar}`}>
        <span>
          {Array.from(Array(5), (e, i) => {
            return <ion-icon key={`star${i}`} name="star" />;
          })}
        </span>
        <span className={styles.nameTextTesti}>Evelyn C.</span>
        <span className={styles.subText}>Mom of Nate</span>
      </div>
    </div>,

    <div key={6} className={styles.awardsWrapperSlick}>
      <div className={styles.testiBodySlick}>
        <span className={styles.firstLetter}>B</span>
        efore enrolling in Tutor BASE, my grades in both Science and Math were at their lowest, but now that I have enrolled...
        {/* <span className={styles.quoteEnd}></span> */}
      </div>

      <div className={`d-flex flex-column justify-content-center align-items-center ${styles.testiStar}`}>
        <span>
          {Array.from(Array(5), (e, i) => {
            return <ion-icon key={`star${i}`} name="star" />;
          })}
        </span>
        <span className={styles.nameTextTesti}>Isaac S.</span>
        <span className={styles.subText}>Student</span>
      </div>
    </div>,

    <div key={7} className={styles.awardsWrapperSlick}>
      <div className={styles.testiBodySlick}>
        <span className={styles.firstLetter}>T</span>
        he school year has been pretty challenging for me and my son. Good thing Tutor BASE has been...
        {/* <span className={styles.quoteEnd}></span> */}
      </div>

      <div className={`d-flex flex-column justify-content-center align-items-center ${styles.testiStar}`}>
        <span>
          {Array.from(Array(5), (e, i) => {
            return <ion-icon key={`star${i}`} name="star" />;
          })}
        </span>
        <span className={styles.nameTextTesti}>Bambi S.</span>
        <span className={styles.subText}>Mom of Isaac</span>
      </div>
    </div>,

    <div key={8} className={styles.awardsWrapperSlick}>
      <div className={styles.testiBodySlick}>
        <span className={styles.firstLetter}>B</span>
        efore being enrolled in Tutor BASE, I found it difficult to answer my homework and other school requirements on my own...
        {/* <span className={styles.quoteEnd}></span> */}
      </div>

      <div className={`d-flex flex-column justify-content-center align-items-center ${styles.testiStar}`}>
        <span>
          {Array.from(Array(5), (e, i) => {
            return <ion-icon key={`star${i}`} name="star" />;
          })}
        </span>
        <span className={styles.nameTextTesti}>Sean N.</span>
        <span className={styles.subText}>Student</span>
      </div>
    </div>,

    <div key={9} className={styles.awardsWrapperSlick}>
      <div className={styles.testiBodySlick}>
        <span className={styles.firstLetter}>T</span>
        utor BASE has been my partner in helping my son in school for 3 years...
        {/* <span className={styles.quoteEnd}></span> */}
      </div>

      <div className={`d-flex flex-column justify-content-center align-items-center ${styles.testiStar}`}>
        <span>
          {Array.from(Array(5), (e, i) => {
            return <ion-icon key={`star${i}`} name="star" />;
          })}
        </span>
        <span className={styles.nameTextTesti}>Karen N.</span>
        <span className={styles.subText}>Mom of Sean</span>
      </div>
    </div>,

    <div key={10} className={styles.awardsWrapperSlick}>
      <div className={styles.testiBodySlick}>
        <span className={styles.firstLetter}>T</span>
        hroughout my stay in Tutor BASE Center as a student, it became my <span className={styles.blueTextBody}> second learning home </span>...
        {/* <span className={styles.quoteEnd}></span> */}
      </div>

      <div className={`d-flex flex-column justify-content-center align-items-center ${styles.testiStar}`}>
        <span>
          {Array.from(Array(5), (e, i) => {
            return <ion-icon key={`star${i}`} name="star" />;
          })}
        </span>
        <span className={styles.nameTextTesti}>Mika P.</span>
        <span className={styles.subText}>Student</span>
      </div>
    </div>,

    <div key={11} className={styles.awardsWrapperSlick}>
      <div className={styles.testiBodySlick}>
        <span className={styles.firstLetter}>T</span>
        utor BASE has helped my daughter in a big way. Her <span className={styles.blueTextBody}>lessons have become clearer to her</span>...
        {/* <span className={styles.quoteEnd}></span> */}
      </div>

      <div className={`d-flex flex-column justify-content-center align-items-center ${styles.testiStar}`}>
        <span>
          {Array.from(Array(5), (e, i) => {
            return <ion-icon key={`star${i}`} name="star" />;
          })}
        </span>
        <span className={styles.nameTextTesti}>Ton P.</span>
        <span className={styles.subText}>Mom of Mika</span>
      </div>
    </div>,
  ];

  // const items = [
  //   <div className={styles.awardsWrapper}>
  //     <img className={styles.awardsImage} src={testi0} alt="testi0" />
  //     <div>
  //       <div className={styles.awardsTitle}>Most Outstanding Tutorial Center in Quezon City</div>
  //       <div className={`d-flex flex-column pt-3 ${styles.blueText}`}>
  //         <span className={styles.nameText}>National Customers’ Choice Annual Awards </span>
  //         <span className={styles.subText}>2015</span>
  //       </div>
  //     </div>
  //   </div>,

  //   <div className={styles.awardsWrapper}>
  //     <img className={styles.awardsImage} src={testi2} alt="testi2" />
  //     <div>
  //       <div className={styles.awardsTitle}>Best Tutorial Center in Quezon City</div>
  //       <div className={`d-flex flex-column pt-3 ${styles.blueText}`}>
  //         <span className={styles.nameText}>National Product Quality Excellence Awards and Q Asia Seal of Product and Quality Service</span>
  //         <span className={styles.subText}>2016</span>
  //       </div>
  //     </div>
  //   </div>,

  //   <div className={styles.awardsWrapper}>
  //     <img className={styles.awardsImage} src={testi3} alt="testi3" />
  //     <div>
  //       <div className={styles.awardsTitle}>Outstanding Customer Excellence for Quality Value Tutorial Center</div>
  //       <div className={`d-flex flex-column pt-3 ${styles.blueText}`}>
  //         <span className={styles.nameText}>Philippine Awards for Customer Service Excellence</span>
  //         <span className={styles.subText}>2017</span>
  //       </div>
  //     </div>
  //   </div>,
  // ];

  const mvtatedItems = [
    <div key={1} className={styles.awardsWrapperz}>
      <div className={styles.imageBox}>
        <img className={styles.awardsImagez1} src={nc1} alt="testi0" />
        <img className={styles.awardsImagez2} src={nc2} alt="testi2" />
      </div>

      <div className={styles.awardsOverlay}>
        <div className={styles.awardsTextBox}>
          <div className={styles.awardsTitlez}>National Customers’ Choice Annual Awards</div>
          <div className={`d-flex flex-column pt-3 ${styles.awardsSubz}`}>
            <span className={styles.nameTextz}>Most Outstanding Tutorial Center in Quezon City</span>
            <span className={styles.subTextz}>2015</span>
          </div>
        </div>
      </div>
    </div>,
    <div key={2} className={styles.awardsWrapperz}>
      <div className={styles.imageBox}>
        <img className={styles.awardsImagez1} src={np1} alt="testi2" />
        <img className={styles.awardsImagez2} src={np2} alt="testi0" />
      </div>

      <div className={styles.awardsOverlay}>
        <div className={styles.awardsTextBox}>
          <div className={styles.awardsTitlez}>Best Tutorial Center in Quezon City</div>
          <div className={`d-flex flex-column pt-3 ${styles.awardsSubz}`}>
            <span className={styles.nameTextz}>National Product Quality Excellence Awards and Q Asia Seal of Product and Quality Service</span>
            <span className={styles.subTextz}>2016</span>
          </div>
        </div>
      </div>
    </div>,

    <div key={3} className={styles.awardsWrapperz}>
      <div className={styles.imageBox}>
        <img className={styles.awardsImagez1} src={pa1} alt="testi2" />
        <img className={styles.awardsImagez2} src={pa2} alt="testi0" />
      </div>
      <div className={styles.awardsOverlay}>
        <div className={styles.awardsTextBox}>
          <div className={styles.awardsTitlez}>Outstanding Customer Excellence for Quality Value Tutorial Center</div>
          <div className={`d-flex flex-column pt-3 ${styles.awardsSubz}`}>
            <span className={styles.nameTextz}>Philippine Awards for Customer Service Excellence</span>
            <span className={styles.subTextz}>2017</span>
          </div>
        </div>
      </div>
    </div>,
  ];

  const handleTestiClick = (e, v) => history.push(`/testimonials/${v}`);

  const renderItems = () => {
    return testiItems.map((content, index) => {
      const ctr = index + 1;
      return (
        <div className={styles.testiWrapper} key={ctr} onClick={(e) => handleTestiClick(e, ctr)}>
          {content}
        </div>
      );
    });
  };

  return (
    <>
      <div className={styles.aliceWrapper}>
        <h5>You Can Trust Tutor BASE!</h5>
        <h3>Awards</h3>
        <Slider {...webSettings}>{mvtatedItems}</Slider>
        <br />
        <br />
      </div>
      <div className={styles.carouselWrapper}>
        <h3>Testimonials</h3>
        <Slider {...smSettings}>{renderItems()}</Slider>
        <div className={`${styles['why-btn-wrapper']} ${styles['view-full-testi']}`}>
          <span className={styles['why-btn']}>
            <Link to="/testimonials">VIEW FULL TESTIMONIALS</Link>
            <i className="far fa-arrow-alt-circle-right" />
          </span>
        </div>
      </div>
    </>
  );
};

export default Carousel;
