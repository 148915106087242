import React, { useState, useEffect } from 'react';

import Spinner from '@components/layouts/Spinner';
import NavbarDashboard from '@components/layouts/NavbarDashboard';
import HeaderDashboard from '@components/layouts/HeaderDashboard';

import './index.less';

const TutorsHonorsCode = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1600);
  }, []);

  if (loading) return <Spinner />;

  return (
    <>
      <NavbarDashboard />
      <HeaderDashboard title="Terms of Service" />
      <div className="tos-wrapper">
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="text-decoration-underline">
              <b>Tutors&apos; Honor Code {/* and Contract of Agreement */}</b>
            </h3>
            {/* <span className="text-black-50">
									Please read carefully before accepting and signing at the end
									of this Agreement. This form is to be submitted online to the
									Center by you, the Tutor, before you are officially hired.
								</span> */}
          </div>
          <div className="col-md-12 mt-5">
            <p>
              <ol>
                <b>
                  <li>Provision of Services</li>
                </b>
                <p className="mt-2">
                  <b>1.1</b> You are a part-time, independent service provider of Tutor BASE Before and After- School Enrichment Center and you are committed to teach for a minimum of 10 months/entire academic school year (August-June), to a maximum of 12 months/one whole year and 2 months of summer classes starting from August to July of the current academic school year.
                </p>
                <p>
                  <b>1.2</b> You are not a regular employee of Tutor BASE Center and the company is not liable to give you any benefit mandated by law.
                </p>
                <p>
                  <b>1.3</b> You are entirely self-employed and your appointment in Tutor BASE Center as a Tutor is considered a “side hustle” or part-time contractual independent job, and nothing in this agreement shall constitute an employer/employee relationship.
                </p>

                <b>
                  <li>Payment Scheme</li>
                </b>
                <p className="mt-2">
                  <b>2.1</b> Tutor BASE Center practices a 30% share scheme and this fee depends on the current fee/rate per hour of the Student.
                </p>
                <p>
                  <b>2.2</b> Thus, your hourly compensation will be ₱108.00/hour for local Students residing in the Philippines, and ₱126.00/hour for international Students residing abroad (all levels).
                </p>
                <p>
                  <b>2.3</b> Your compensation or Tutor fee will be given as early as Friday or Saturday at the latest of the current week, depending on the online accessibility of the bank provider.
                </p>
                <p>
                  <b>2.4</b> The Center transfers funds through the payments system on the specialized Tutor BASE website.
                </p>
                <p>
                  <b>2.5</b> Funds will then be transferred into the Gcash, GrabPay, Paymaya, or bank account of your choice. Note that only accounts in the following banks can be linked to the website:
                </p>
                <p className="mt-2">
                  <ul>
                    <li>Bank of the Philippine Islands (BPI)</li>
                    {/* <li>BPI Family Savings Bank</li> */}
                    <li>BDO Unibank</li>
                    <li>China Bank</li>
                    <li>China Bank Savings</li>
                    <li>East West Banking Corporation</li>
                    <li>Land Bank of the Philippines</li>
                    <li>Metropolitan Bank and Trust Company (Metrobank)</li>
                    <li>Philippine National Bank (PNB)</li>
                    <li>PNB Savings Bank</li>
                    <li>Rizal Commercial Banking Corporation (RCBC)</li>
                    <li>Security Bank Corporation</li>
                    <li>United Coconut Planters Bank (UCPB)</li>
                  </ul>
                </p>

                <b>
                  <li>Tutors&apos; Online Decorum in the Center</li>
                </b>
                <p className="mt-2">
                  <b>3.1</b> Tutors are required to install the Viber application and make a Viber account, as the said application is used as the main platform for communication among tutors. Tutors are to always be active in Viber because announcements from the Head Teacher will always be posted there. The Center will add you to the Viber Tutors&apos; group chat using your registered mobile number.
                </p>
                <p>
                  <b>3.2</b> Tutors are expected to always reply or acknowledge the Head Teacher&apos;s messages, especially if it is regarding an urgent matter. A brief reply such as “Yes, Teacher,” or “This is noted, Teacher,” is important to let the Head Teacher know that you received her message/s well. Please do not ignore messages sent on the Tutors&apos; group chat.
                </p>
                <p>
                  <b>3.3</b> All messages of concern or otherwise shall be coursed through the official Viber group chat. When writing messages of concern regarding Students, Tutors are encouraged to follow the sandwich method of feedback, which begins with positive comments, followed by constructive criticism, and is followed by positive comments again. When Tutors have messages to relay to the Student or Parent/Guardian, they are to send it to the Viber Tutors&apos; group chat; in turn, the Head Teacher will forward it to the Student or Parent/Guardian involved. Please take note and follow the format for messaging set by the Center for uniformity and formality:
                  <p className="mt-2 ml-3">
                    Fr. T. [Tutor&apos;s Name] -- <br />
                    Good morning/afternoon/evening, Mommy/Daddy/Student,
                    <br />
                    [Message]
                    <br />
                    Thank you!
                    <br />
                  </p>
                </p>
                <p>
                  <b>3.4</b> All Tutors should be able to make themselves available for a remote meeting at least once a week, usually held on Fridays or when the Head Teacher calls for a need for it.
                </p>
                <p>
                  <b>3.5</b> Tutors are expected to be punctual online, especially for tutorial sessions with Students and meetings with the Head Teacher. It is recommended that Tutors have their device ready at least 15 minutes before the scheduled tutorial session with the Student or meeting with the Head Teacher.
                </p>
                <p>
                  <b>3.6</b> Tutors expected to turn on their device&apos;s video and microphone once their tutorial session starts with the student. Tutors are encouraged to use visual materials such as PowerPoint presentations or the whiteboard function of the Zoom application. This is to effectively simulate face-to-face learning.
                </p>
                <p>
                  <b>3.7</b> Tutors are expected to be encouraging and motivating to all Students; presentable and dressed appropriately; polite, diligent, helpful, and focused all throughout the tutorial session; and acting in a fair and respectable manner at all times.
                </p>
                <p>
                  <b>3.8</b> Tutors are expected to bear in mind that some Students who are enrolled in the Center are very young or have special needs; thus, patience and kindness on the Tutor&apos;s part should be exercised at all times when handling these Students.
                </p>
                <p>
                  <b>3.9</b> Short breaks are allowed for Students who are being unruly, restless, and unfocused. However, the Tutor must resume right away so as not to waste time.
                </p>
                <p>
                  <b>3.10</b> While it is important to establish a good working teacher-student relationship, Tutors must always maintain a certain amount of formality and professionalism with the Student. Tutors must learn to draw the line of boundaries to avoid too much familiarity that could lead to the Student disrespecting your authority as their Tutor (and vice versa).
                </p>
                <p>
                  <b>3.11</b> Slouching, sleeping, or taking prolonged breaks while tutoring even in a remote setting is highly discouraged. Dead air or prolonged periods of silence during tutorial sessions should also be avoided.
                </p>
                <p>
                  <b>3.12</b> Eating heavy meals is highly discouraged while the tutorial session is ongoing, but light snacks such as cookies, crackers, chips, cereals, and other simple finger food or beverages are allowed. Tutors are generally discouraged to eat while the tutorial session is ongoing.
                </p>
                <p>
                  <b>3.13</b> Tutors are requested to Like the Facebook page of the Center and add the Head Teacher as a contact on their Facebook account.
                </p>

                <b>
                  <li>Description of Services</li>
                </b>
                <p className="mt-2">
                  <b>4.1</b> Upon being hired as a part-time Tutor, the Tutor will be required to sit on another Tutor&apos;s tutorial session for at least seven (7) days or a week before officially being assigned to handle a Student. This is so that the new Tutor may observe and understand how a typical tutorial session in the Center is conducted. The Tutor will be assigned to handle a Student when they are deemed ready to do so by the Head Teacher.
                </p>
                <p>
                  <b>4.2</b> Tutors must make sure that the Student finishes their tasks as soon as possible, and must prepare the Student for the next day&apos;s activities or requirements in school. As such, in a tutorial session with a Student, the first task Tutors must do is check the Student&apos;s homework requirements. Tutors must always accomplish the “to-do” tasks given as homework before anything else, and be mindful of deadlines. Projects that require more time to finish should be checked regularly to ensure that they will be submitted on time.
                </p>
                <p>
                  <b>4.3</b> The next important task for Tutors to accomplish is to review for the following day&apos;s exams or quizzes. If no upcoming exams or quizzes are set, always review the Student in advance and focus on their areas of improvement to enhance their learning and mastery of that particular subject.
                </p>
                <p>
                  <b>4.4</b> Tutors are encouraged to give mastery checks (seatwork or short quizzes) after a lesson is taught to ensure that the Student has understood the lesson that you discussed.
                </p>
                <p>
                  <b>4.5</b> Tutors may give or send homework to the Student if they were not given any homework from their school, or if their Parents/Guardians request us to do so. It is recommended that only short homework that will help enhance the Student&apos;s mastery of the lesson be given; make sure that it will not be too difficult or time-consuming to be accomplished by the Student as we do not want frustration or pressure to set in.
                </p>
                <p>
                  <b>4.6</b> Tutors are encouraged to make their tutorial sessions effective, memorable, engaging, and if possible, experiential. Furthermore, practice the SMART rule in delivering your lessons: Specific, Measurable, Action-oriented, Realistic, and Time-bound.
                </p>
                <p>
                  <b>4.7</b> Take note and be mindful of the learning styles of Students. Some are visual learners, some are auditory learners, some are read-write learners, and some are kinesthetic learners. It is always best to make lessons with them effective, and Tutors can only be assured of this by studying and observing their learning styles and tailor-fitting teaching styles to Students.
                </p>
                <p>
                  <b>4.8</b> Make sure to monitor the Student&apos;s grades or scores in their school&apos;s quizzes, exams, and other requirements to ensure that progress is being made in the Student&apos;s learning. This will also help in measuring our performance and impact on the Students as Tutors.
                </p>
                <p>
                  <b>4.9</b> Tutors must keep the Head Teacher informed of any issues or problems that are observed with each Student as they develop in their studies.
                </p>
                <p>
                  <b>4.10</b> Tutors must make sure to use the teaching hours allotted to handle each Student wisely to truly make the session/s worthwhile. Tutors are encouraged to plan and structure each tutorial session to optimize time to benefit the Student. After your tutorial session, make sure to remind your Student to be prepared for their next day in school and end the online meeting on time.
                </p>
                <p>
                  <b>4.11</b> At the end of every tutorial session, Tutors are required to write a report for the Parent/Guardian to be informed of the learning progress of the Student. An end-of-session report includes an outline of the subjects or topics discussed with the Student, tasks accomplished with the Student, points for improvement of the Student, goals for the next tutorial session, and any other additional concerns or notes (this may include time extensions, substitute tutors, etc.). The said information is to be inputted into the End-of- Session Report section of the Tutor BASE Center website.
                </p>
                <p>
                  <b>4.12</b> If you are or will be late for the tutorial session due to circumstances beyond your control, Tutors must immediately inform the Head Teacher and the other Tutors in the Viber group chat, even if you will be as little as 5 minutes late. Tutors must make up for this lost time where appropriate by staying longer than the allocated tutorial session online, or by getting assistance from other available Tutors to handle the Student while waiting for you. Note that this may not always be accomplished if you have another Student to handle after or if other Tutors are unavailable; hence, it is always best to prepare ahead of time and seek help from the team beforehand if there are any technical problems seen or expected from your end.
                </p>
                <p>
                  <b>4.13</b> In the event that a deadline is set by the school a day after a tutorial session and the Student is not yet finished with the task or if a Student is not deemed to be ready for a test the following day, time extensions in increments of 1 hour may be given, provided that you inform the Head Teacher at least 30 minutes beforehand so she could, in turn, inform their Parents/Guardians in real time.
                </p>
                <p>
                  <b>4.14</b> Tutoring evaluation forms will be given randomly and at no specific time to the Student or Parent/Guardian to check if Tutors are performing well and embodying the Center&apos;s goals. This is in order to be aware of what areas need to be improved on in your teaching.
                </p>
                <p>
                  <b>4.15</b> The Center will keep track of the hours you have worked by signing in for you in the Tutor log-in program file managed by the Head Teacher. However, the Tutor is still encouraged to keep an up-to-date record and schedule of all hours worked on their own. This is to ensure correct and proper calculation of the compensation you will receive at the end of the week.
                </p>
                <p>
                  <b>4.16</b> All Tutors are entitled to an account on the Tutor BASE Center website that grants them access to (1) their daily tutorial session schedule; (2) weekly Zoom meeting details; (3) Google Drive folders of Students they handle; (4) the payments system for receiving compensation; (5) the end-of-session reports system; and (6) a copy of this Honor Code for reference.
                </p>

                <b>
                  <li>Assignment to Students and Substitute Tutoring</li>
                </b>
                <p className="mt-2">
                  <b>5.1</b> Each Tutor is to be part of a pool of Tutors available to teach Students, and each Tutor is to be assigned to a Student whose areas for improvement match the Tutor&apos;s areas of expertise. As such, upon being hired, a Tutor may not immediately be assigned to tutoring a Student.
                </p>
                <p>
                  <b>5.2</b> In the event that you need to be absent from or late to an upcoming tutorial session, you are responsible to find a substitute Tutor. Make sure you endorse any pending lessons or homework to finish and inform the Head Teacher beforehand about this arrangement.
                </p>
                <p>
                  <b>5.3</b> In connection to the previous point, you may also be assigned as a substitute Tutor for a Student whom another Tutor handles in the latter&apos;s temporary absence.
                </p>
                <p>
                  <b>5.4</b> The Center has the right to re-assign you to another Student and schedule if for any valid reason the Student or Parent/Guardian requests for it or if the Head Teacher sees it fit.
                </p>

                <b>
                  <li>Center Operations During Holidays and Emergency Situations</li>
                </b>
                <p className="mt-2">
                  <b>6.1</b> Should there be a national or public holiday that falls within an upcoming week, the Center will still be open for tutorial sessions. The Center requests that Tutors inform us before the week has begun whether they will be available to handle tutorial sessions that fall on holidays. If the Tutor decides to miss tutorial sessions with us due to a holiday, the Tutor is expected to follow the protocol described in clause 5.2. In connection to this, the corresponding fee will be deducted from the weekly compensation accordingly.
                </p>
                <p className="mt-2">
                  <b>6.2</b> Should inclement weather conditions, natural calamities, man-made disasters, or widespread electricity or internet losses arise or be anticipated, the Center will temporarily halt operations. Any tutorial sessions that a Tutor is in charge of will be rescheduled to a later date. Operations will resume as usual when conditions have improved or when it is predicted to be safe and secure to do so, and the Center will inform all Tutors accordingly.
                </p>

                <b>
                  <li>Obligations of the Tutor</li>
                </b>
                <p className="mt-2">
                  <b>7.1</b> The Center strongly reiterates that you are not to disclose to others confidential information or knowledge pertaining to the Student. This includes but is not limited to the Student&apos;s complete name, school, academic performance, learning disabilities or conditions, etc. You are also not allowed to share the Student&apos;s end-of-session reports or Google Drive folder containing their school files and learning materials to others. Furthermore, you are not allowed to take and post photos or recordings of or with the Student without their explicit consent. This is to ensure the privacy of the Student, to avoid any possible altercation with the Parents/Guardians, and to conform to the Data Privacy Act of 2012.
                </p>
                <p>
                  <b>7.2</b> You are not allowed under any circumstance to disclose any personal details about yourself to Students, and details about the business affairs and operations of the Center to other individuals. This includes but is not limited to your complete name, educational background, other jobs you have, etc. You are also not allowed to share any of your contact details to Students and Parents/Guardians, such as your mobile number, Facebook account or other social media accounts, email address, etc. This is because Students or their Parents/Guardians are not allowed to directly contact or communicate with you outside the Zoom application during tutorial sessions.
                </p>
                <p>
                  <b>7.3</b> All communication between the Tutor and the Student or Parent/Guardian is to be coursed through the official Viber group chat and will be disseminated by the Head Teacher to the respective party or parties involved.
                </p>

                <b>
                  <li>Termination</li>
                </b>
                <p className="mt-2">
                  <b>8.1</b> In the event that you commit a minor breach under this agreement, the Center will give you a warning so that you can be informed of what you did wrong and be reminded not to repeat it.
                </p>
                <p>
                  <b>8.2</b> Tutor BASE Center has the right to terminate this Agreement with immediate effect if necessary, for any of the following reasons:
                  <div className="mt-2 ml-3">
                    <p>
                      <b>8.2.1</b> You receive three (3) warnings or repeatedly commit minor breaches under this agreement.
                    </p>
                    <p>
                      <b>8.2.2</b> You act in such a way that discredits Tutor BASE Center, the Head Teacher, or the Student.
                    </p>
                    <p>
                      <b>8.2.3</b> Falsification of the facts set forth in your application form is discovered, regardless of the time elapsed before discovery.
                    </p>
                    <p>
                      <b>8.2.4</b> Any previously undeclared criminal records or history of academic warning, probation, suspension, or dismissal at any level of your education is discovered, regardless of the time elapsed before discovery. or
                    </p>
                    <p>
                      <b>8.2.5</b> You have been found guilty of any criminal offense.
                    </p>
                  </div>
                </p>
                <p>
                  <b>8.3</b> Tutor BASE Center does not discriminate in its employment practices against any person because of race, color, nationality or ethnic origin, sex, gender, age, disability, or other factors.
                </p>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TutorsHonorsCode;
