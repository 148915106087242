import Swal from 'sweetalert2';

import {
  //
  postListDrive,
  postListDriveStud,
  putLinkDriveEdit,
  postCreateDirectory,
  postLinkDrive,
  getGoogleAuthorization,
  getDriveLink,
  postDriveRoot,
} from '@service/api/drive';

// types
import * as types from '../types';

export const fetchRootDirectory = (payload) => async () => {
  const res = await postDriveRoot(payload);
  return res;
};

export const setRt = (payload) => (dispatch) => {
  localStorage.setItem('rt', JSON.stringify(payload));
  dispatch({
    type: types.DRIVE_RT,
    payload,
  });
};

export const authorizeWithGoogle = (payload) => async () => {
  const res = await getGoogleAuthorization(payload);
  return res;
};

export const listDriveStud = (payload) => async (dispatch) => {
  const res = await postListDriveStud(payload);
  dispatch({
    type: types.DRIVE_LIST,
    payload: res.data,
  });

  return res;
};

export const listDrive = (payload) => async (dispatch) => {
  const res = await postListDrive(payload);
  dispatch({
    type: types.DRIVE_LIST,
    payload: res.data,
  });
  return res;
};

export const linkDriveEdit = (payload) => async (dispatch) => {
  const res = await putLinkDriveEdit(payload);
  Swal.fire({
    icon: 'success',
    customClass: {
      confirmButton: 'swal-btn',
    },
    html: `
        <h3>${res.msg}<h3/>
        <h4>${res.data.link}<h4/>
        `,
    confirmButtonText: `OK`,
  });

  dispatch({
    type: types.DRIVE_LINK_TUTOR_EDIT,
    payload: res.data,
  });

  return res;
};

export const linkDrive = (payload) => async (dispatch) => {
  const res = await postLinkDrive(payload);

  dispatch({
    type: types.DRIVE_LINK_TUTOR,
    payload: res.data,
  });

  return res;
};

export const createStudentDirectory = (payload) => async () => {
  const res = await postCreateDirectory(payload);
  return res;
};

export const fetchDriveLink = () => async (dispatch) => {
  const res = await getDriveLink();

  if (res.success) {
    dispatch({
      type: types.DRIVE_LINK_TUTOR,
      payload: res.data,
    });
  }

  return res;
};
