import React, { useState, useEffect } from 'react';

import Spinner from '@components/layouts/Spinner';
import Navbar from '@components/layouts/Navbar';
import Banner from '@components/layouts/Banner';

import SectionWhyChoose from '@components/landing/SectionWhyChoose';
import Carousel from '@components/landing/Carousel';
import SectRates from '@components/landing/SectRates';
import SectionRates from '@components/landing/SectionRates';
import SectionBookingYour from '@components/landing/SectionBookingYour';

import './index.less';

const LandingPage = () => {
  const [loading, setLoading] = useState(true);
  const [showSection, setShowSection] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSection(true);
    }, 1000);
    setTimeout(() => setLoading(false), 900);
    window.scroll(0, 0);
    return () => clearTimeout(timer);
  }, []);

  if (loading) return <Spinner />;

  return (
    <div className="wrap">
      <Navbar />
      <Banner />
      {showSection && (
        <>
          <SectionWhyChoose />
          <Carousel />
          <SectRates />
          {/* <SectionRates /> */}
          <SectionBookingYour />
        </>
      )}
    </div>
  );
};

export default LandingPage;
