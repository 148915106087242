import * as types from '../types';

const initialState = {
  authenticated: false,
  account: {},
  isAdmin: false,
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.MINATO_TUTORBASE:
      // console.log('[MINATO common] ', payload);
      return state;
    case types.USER_LOGOUT:
      // console.log('[MINATO LOGOUT common] ', payload);
      return initialState;
    case types.SET_USER_ROLE:
      return { ...state, isAdmin: payload };
    case types.SET_AUTHENTICATED:
      return { ...state, authenticated: true };
    case types.SET_UNAUTHENTICATED:
      return initialState;
    case types.SET_USER_DETAILS:
      return {
        ...state,
        authenticated: true,
        account: { ...payload, mReminder: +payload.mReminder, eReminder: +payload.eReminder },
      };
    case types.NOTIF_SET:
      if (state.account) {
        return {
          ...state,
          account: { ...state.account, eReminder: +payload.option, mReminder: +payload.option },
        };
      }
      return state;
    default:
      return state;
  }
};

export default reducer;
